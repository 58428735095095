import {
  type ConsentDetails,
  SERVICE_ID,
} from 'common/utils/getIsServiceConsentGiven';
import {
  initAnalyticsContext,
  type IntegrationsSettings,
  loadAnalytics,
} from 'src/core/utils/analytics';

type Props = {
  user?: {
    id: string;
    intercom_hash: string;
    lang: string;
    fullname: string;
    email: string;
    created_at: string;
    is_account_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
    is_requester: boolean;
  };
  company?: {
    id: string;
    type: string;
    created_from: string;
    organisation: {
      id: string;
    };
  };
  supervisor?: {
    name: string;
    email: string;
  };
  lang?: string;
};

type UCEvent = {
  detail: {
    action: 'onAcceptAllServices' | 'onDenyAllServices' | 'onUpdateServices';
    type: 'IMPLICIT' | 'EXPLICIT';
    event: string;
  };
} & Event;

export const initUserCentrics = async ({
  user,
  company,
  supervisor,
  lang,
}: Props) => {
  if (window.__ucCmp) {
    if (user?.lang) {
      window.__ucCmp.changeLanguage(user.lang);
    } else if (lang) {
      window.__ucCmp.changeLanguage(lang);
    }

    // Load analytics if the user has given consent
    try {
      const consent: ConsentDetails = await window.__ucCmp.getConsentDetails();
      const segmentConsent = consent.services[SERVICE_ID.segment];
      if (segmentConsent?.consent?.given) {
        const integrationsSettings: IntegrationsSettings = {
          'Google Analytics':
            consent.services[SERVICE_ID.googleAnalytics]?.consent?.given,
          FullStory: consent.services[SERVICE_ID.fullStory]?.consent?.given,
          'Amplitude (Actions)':
            consent.services[SERVICE_ID.amplitude]?.consent?.given,
          Intercom: consent.services[SERVICE_ID.intercom]?.consent?.given, // todo intercom: Remove this check when we have the direct Intercom integration
          SatisMeter: consent.services[SERVICE_ID.satisMeter]?.consent?.given,
        };
        loadAnalytics(integrationsSettings);
        initAnalyticsContext({
          user,
          company,
          supervisor,
          integrationsSettings,
        });
      }
    } catch {
      // do nothing
    }
  }

  window.addEventListener('ucEvent', ((event: UCEvent) => {
    if (
      event.detail.event === 'consent_status' &&
      event.detail.type === 'EXPLICIT'
    ) {
      window.location.reload();
    }
  }) as EventListener);
};
