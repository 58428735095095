import { useLocalStorage } from 'react-use';

import withErrorBoundary from 'src/core/common/components/withErrorBoundary';
import { LocalStorageKey } from 'src/core/constants/storage';

import { MultiEntityHubOverviewPageContainer } from './MultiEntityHubOverviewPageContainer';
import { IntroModal } from '../components/IntroModal/IntroModal';

export const MultiEntityHubContainer = withErrorBoundary({
  scope: 'multi-entity-hub',
  team: 'control',
})(() => {
  const [introModalAlreadyDisplayed, toggleIntroModalEncountered] =
    useLocalStorage<boolean>(
      LocalStorageKey.MultiEntityHubIntroModalEncountered,
      false,
    );

  return (
    <>
      {!introModalAlreadyDisplayed && (
        <IntroModal onModalHidden={() => toggleIntroModalEncountered(true)} />
      )}
      <MultiEntityHubOverviewPageContainer />
    </>
  );
});
