/* eslint-disable camelcase */
import { useFeature } from 'common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import { useCompany } from '../../app/hooks/useCompany';
import { type RequestType } from '../models/requestType';

/**
 * These enablement flags answer the question:
 * - is the request type actived ?
 * Sometimes creation activated is different from the request type activated.
 * For example, some request have been created for a time but later the
 * creation is disabled, the customer can still see the requests in the list.
 */
export const useRequestTypeEnablements = () => {
  const company = useCompany();

  const features = {
    hasPlasticCards: useFeature(FEATURES.PLASTIC_CARDS),
    hasExpenseClaims: useFeature(FEATURES.EXPENSE_CLAIMS),
    hasSubmitMyInvoice: useFeature(FEATURES.SUBMIT_MY_INVOICE),
    hasMileageAllowances: useFeature(FEATURES.MILEAGE_ALLOWANCES),
    hasPerDiemAllowances: useFeature(FEATURES.PER_DIEM_ALLOWANCES),
    hasPurchaseOrdersActivated: useFeature(FEATURES.PURCHASE_ORDERS_ACTIVATED),
    hasVirtualCards: useFeature(FEATURES.VIRTUAL_CARDS),
  };

  // Names matching naming conventions (camel case)
  const enablements = {
    // Owner: Cards-UX Squad
    isCardLoadEnabled: features.hasPlasticCards,
    // Owner: Invoice Automation Squad
    isCreditNoteEnabled: features.hasSubmitMyInvoice,
    // Owner: Travel & Expenses Squad
    isExpenseEnabled: features.hasExpenseClaims,
    // Owner: Invoice Automation Squad
    isInvoiceEnabled: features.hasSubmitMyInvoice,
    // Owner: Travel & Expenses Squad
    isMileageAllowanceEnabled:
      features.hasMileageAllowances && !!company.mileage_scheme,
    // Owner: Travel & Expenses Squad
    isPerDiemAllowanceEnabled:
      features.hasPerDiemAllowances &&
      company.country === 'DE' &&
      company.currency === 'EUR',
    // Owner: Procurement Squad
    isPurchaseOrderEnabled: features.hasPurchaseOrdersActivated,
    // Owner: Cards-UX Squad
    isSinglePurchaseEnabled: features.hasVirtualCards,
    // Owner: Cards-UX Squad
    isSubscriptionEnabled: features.hasVirtualCards,
    // Owner: Cards-UX Squad
    isSubscriptionIncreaseEnabled: features.hasVirtualCards,
  };

  // Request type mapping
  const requestTypeEnabled: Record<RequestType, boolean> = {
    card_load: enablements.isCardLoadEnabled,
    credit_note: enablements.isCreditNoteEnabled,
    expense: enablements.isExpenseEnabled,
    invoice: enablements.isInvoiceEnabled,
    mileage_allowance: enablements.isMileageAllowanceEnabled,
    per_diem_allowance: enablements.isPerDiemAllowanceEnabled,
    purchase_order: enablements.isPurchaseOrderEnabled,
    single_purchase: enablements.isSinglePurchaseEnabled,
    subscription: enablements.isSubscriptionEnabled,
    subscription_increase: enablements.isSubscriptionIncreaseEnabled,
  };

  return {
    enablements,
    requestTypeEnabled,
  };
};
