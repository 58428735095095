import { Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { StatusTag } from 'modules/onboarding/components/TaskList/components/StatusTag';
import { type UserTask } from 'modules/onboarding/setup-hub/types/userTask';
import { type HubTask } from 'modules/onboarding/types/hubTask';

import styles from './HubTaskWrapper.module.css';

type Props = {
  title: string;
  showTagStatus?: boolean;
  description: string;
  children?: React.ReactNode;
  task: HubTask | UserTask;
  duration?: string;
  disabledMessage?: string;
  actions?: React.ReactNode[];
};

export const HubTaskWrapper = ({
  title,
  showTagStatus,
  description,
  children,
  task,
  duration,
  disabledMessage,
  actions,
}: Props) => {
  const { t } = useTranslation('global');
  const isDisabled = task.status === 'disabled';
  const showCompletedTitle = task.status === 'completed';

  return (
    <div className={styles.task}>
      <div className="flex items-center gap-8">
        <h3
          className={cx('title-m', {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'text-secondary-bg-secondary': isDisabled,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'line-through': showCompletedTitle,
          })}
        >
          {title}
          {duration && (
            <> • {t('onboardingHub.misc.timeNeeded', { minutes: duration })}</>
          )}
        </h3>
        {showTagStatus && <StatusTag status={task.status} />}
      </div>
      {isDisabled && !!disabledMessage && (
        <span className="mt-8 flex items-center title-m">
          <Icon size="m" name="lock-close" className="mr-8" />
          <span>{disabledMessage}</span>
        </span>
      )}
      <p className="mt-16 text-secondary-bg-secondary">{description}</p>
      {children}
      <div className="mt-16 flex gap-16 empty:hidden">{actions}</div>
    </div>
  );
};
