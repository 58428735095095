import { CommonSelect } from './CommonSelect';
import { useSpendTypeOptions } from '../../hooks/useSpendTypeOptions';

export const SpendTypeSelect = ({
  selectedValues,
  onSelect,
}: {
  selectedValues: string[] | undefined | null;
  onSelect: (values: string[] | null) => void;
}) => {
  const spendTypeOptions = useSpendTypeOptions();

  return (
    <CommonSelect
      selectedValues={selectedValues}
      onSelect={onSelect}
      baseOptions={spendTypeOptions}
      placeholder="Select a spend type"
      dimensionName="spendType"
    />
  );
};
