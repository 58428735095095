import { type MonetaryValue } from 'ezmoney';

import { type I18nKey } from 'src/core/common/hooks/useTranslation';

type AmountDiscount = {
  type: 'amount';
  value: MonetaryValue;
};

type PerUnitDiscount = {
  type: 'per_unit';
  value: MonetaryValue;
};

type PercentageDiscount = {
  type: 'percentage';
  ratio: number;
};

export type Discount = AmountDiscount | PerUnitDiscount | PercentageDiscount;

type Tier = {
  base: MonetaryValue;
  factor: MonetaryValue;
  floor: number;
};

type Usage = { [unit: string]: Tier[] };

type Pricing = {
  constant: MonetaryValue;
  discounts: Discount[];
  usage: Usage;
  totalAmount: MonetaryValue;
};

enum FusebillAddon {
  Ereceipts = 'ereceipts',
  SupplementaryWallets = 'supplementary_wallets',
  SamlSso = 'saml_sso',
  CardInsurance = 'card_insurance',
  EreceiptUsers = 'ereceipt_users',
  IncludedExpenses = 'included_expenses',
}

export type FusebillSubscription = {
  name: string;
  status: string;
  schedule: string;
  responsibleCompany: { id: string; name: string };
  plan: { code: string; name: string };
  price: Pricing;
  addons: { [addon in FusebillAddon]?: Pricing };
  version: 'v1' | 'v2';
};

type AddonSlug =
  | 'samlSso'
  | 'cardInsurance'
  | 'supplementaryWallets'
  | 'includedExpenses'
  | 'expensesIncludedLimited'
  | 'extraExpenses'
  | 'expensesExtraCharged'
  | 'entitiesBranchEntitiesIncluded'
  | 'entitiesBranchCurrenciesIncluded'
  | 'entitiesBranchExpenseEntitiesIncluded'
  | 'addonModuleAccountsPayable'
  | 'addonModuleAIBasedAutomationAndFraudDetection'
  | 'addonModuleIntegrationNetsuite'
  | 'addonModuleIntegrationOpenApi'
  | 'addonModuleIntegrationSage100'
  | 'addonModuleIntegrationNativeTravel'
  | 'addonModuleAdvancedMultiEntityManagement'
  | 'addonModuleAdvancedWorkflowAndPolicies'
  | 'addonModuleIntakeToProcure'
  | 'addonModuleIntegrationHR'
  | 'internationalPayment'
  | 'expensesIncludedUnlimited';

export type Addon = {
  amount: MonetaryValue;
  quantity: number;
  slug: AddonSlug;
  pricing: {
    price: MonetaryValue;
  };
};

export const getAddonBySlug = (
  addons: Addon[],
  slug: AddonSlug,
): Addon | undefined => {
  return addons.find((addon) => addon.slug === slug);
};

type PlanSlug =
  | 'free'
  | 'starter'
  | 'essentials'
  | 'growth'
  | 'scale'
  | 'scale-2023'
  | 'subsidiary'
  | 'enterprise'
  | 'premium'
  | 'freemium'
  | 'foundations'
  | 'foundations-250'
  | 'foundations-700'
  | 'foundations-unlimited';

export type ChargebeeSubscription = {
  chargebeeSubscriptionId: string;
  addons: Addon[];
  amount: MonetaryValue;
  plan: {
    slug: PlanSlug;
  };
  parentCompanyName?: string;
  coupons?: string[];
  entitiesCount?: {
    activeEntities: number;
    availableEntities: number;
    isAllowedToOpenEntity: boolean;
  };
};

export type CompanySubscription = FusebillSubscription | ChargebeeSubscription;

export const isChargebeeSubscription = (
  subscription: CompanySubscription | undefined,
): subscription is ChargebeeSubscription => {
  if (!subscription) {
    return false;
  }

  return (
    'chargebeeSubscriptionId' in subscription &&
    typeof subscription.chargebeeSubscriptionId === 'string'
  );
};

export const addonToDisplayTranslationKeys: Partial<
  Record<AddonSlug, I18nKey>
> = {
  supplementaryWallets: 'billing.companyPlan.addons.supplementaryWallet',
  cardInsurance: 'billing.companyPlan.addons.cardInsurance',
  samlSso: 'billing.companyPlan.addons.samlSSO',
  expensesExtraCharged: 'billing.companyPlan.addons.expensesExtraCharged',
  addonModuleAccountsPayable:
    'billing.companyPlan.addons.addonModuleAccountsPayable',
  addonModuleAIBasedAutomationAndFraudDetection:
    'billing.companyPlan.addons.addonModuleAIBasedAutomationAndFraudDetection',
  addonModuleIntegrationNetsuite:
    'billing.companyPlan.addons.addonModuleIntegrationNetsuite',
  addonModuleIntegrationOpenApi:
    'billing.companyPlan.addons.addonModuleIntegrationOpenApi',
  addonModuleIntegrationSage100:
    'billing.companyPlan.addons.addonModuleIntegrationSage100',
  addonModuleIntegrationNativeTravel:
    'billing.companyPlan.addons.addonModuleIntegrationNativeTravel',
  addonModuleAdvancedMultiEntityManagement:
    'billing.companyPlan.addons.addonModuleAdvancedMultiEntityManagement',
  addonModuleAdvancedWorkflowAndPolicies:
    'billing.companyPlan.addons.addonModuleAdvancedWorkflowAndPolicies',
  addonModuleIntakeToProcure:
    'billing.companyPlan.addons.addonModuleIntakeToProcure',
  addonModuleIntegrationHR:
    'billing.companyPlan.addons.addonModuleIntegrationHR',
  internationalPayment: 'billing.companyPlan.addons.internationalPayment',
};

export const getCompanyPlanTranslationKey = (plan: PlanSlug): I18nKey => {
  switch (plan) {
    case 'starter':
      return 'billing.companyPlan.plans.starter';
    case 'essentials':
      return 'billing.companyPlan.plans.essentials';
    case 'growth':
      return 'billing.companyPlan.plans.growth';
    case 'scale':
      return 'billing.companyPlan.plans.scale';
    case 'scale-2023':
      return 'billing.companyPlan.plans.scale';
    case 'subsidiary':
      // @ts-expect-error subsidiary might not exist anymore
      return 'billing.companyPlan.plans.subsidiary';
    case 'free':
      return 'billing.companyPlan.plans.free';
    case 'enterprise':
      return 'billing.companyPlan.plans.enterprise';
    case 'premium':
      return 'billing.companyPlan.plans.premium';
    case 'freemium':
      return 'billing.companyPlan.plans.freemium';
    case 'foundations':
      return 'billing.companyPlan.plans.foundations';
    case 'foundations-250':
      return 'billing.companyPlan.plans.foundations250';
    case 'foundations-700':
      return 'billing.companyPlan.plans.foundations700';
    case 'foundations-unlimited':
      return 'billing.companyPlan.plans.foundationsUnlimited';
    default:
      return 'misc.unknown';
  }
};
