import { Avatar, Button, ListBox, Modal } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { AutocompleteMultipleSearch } from 'common/components/AutocompleteSearch';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type TeamUserRaw, type TeamRaw } from 'modules/members/models/teams';
import FEATURES from 'src/core/constants/features';

const formatUser = (user: TeamUserRaw, companyId: string) => {
  const userName = user.pending ? user.email : user.fullname;
  const userGroups = (
    user.data_by_company?.[companyId]?.groups_names ?? []
  ).join(', ');
  return {
    key: user.id,
    label: `${userName}${userGroups ? ` (${userGroups})` : ''}`,
    avatar: user.avatar,
    isPending: Boolean(user.pending),
    userName,
    userGroups,
  };
};

type Props = {
  title: string;
  users: TeamUserRaw[];
  group: TeamRaw;
  onSelectMembers: ({
    group,
    membersToAdd,
    membersToRemove,
  }: {
    group: TeamRaw;
    membersToAdd: TeamUserRaw[];
    membersToRemove: TeamUserRaw[];
  }) => void;
  onCancel: () => void;
};

export const TeamMembersModal = ({
  title,
  users,
  group,
  onSelectMembers,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  const [selectedUsers, setSelectedUsers] = useState<
    {
      key: string;
      label: string;
      avatar: string;
      isPending: boolean;
      userName: string | null;
      userGroups: string;
    }[]
  >(
    (group.users ?? group.members_preview).map((user) =>
      formatUser(user, companyId),
    ),
  );

  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );

  const getTeamAdmin = () => {
    const adminId = group.admin_ids?.[0];
    return users.find((user) => user.id === adminId);
  };

  const handleSelectMembers = () => {
    const initialUsers = group.users ?? group.members_preview;
    const membersToAdd = selectedUsers.filter(
      (user) => !initialUsers.some((u) => user.key === u.id),
    );
    const membersToRemove = initialUsers.filter(
      (user) => !selectedUsers.some((u) => user.id === u.key),
    );
    onSelectMembers({
      group,
      membersToAdd: users.filter((user) =>
        membersToAdd.some((u) => u.key === user.id),
      ),
      membersToRemove,
    });
  };

  const teamAdmin = getTeamAdmin();
  const selectableUsers = isCostCentersFeatureEnabled
    ? users
    : users.filter((user) => user.id !== teamAdmin?.id);

  return (
    <Modal
      isOpen
      iconName="person-group"
      iconVariant="info"
      title={title}
      subtitle={t(
        isCostCentersFeatureEnabled
          ? 'members.inviteTeamMembersDescription'
          : 'members.invitingMembersDisclaimer',
        { teamName: group.name },
      )}
      onClose={onCancel}
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={onCancel}
        />,
        <Button
          key="selectMembers"
          text={
            group.members_preview.length === 1
              ? t('members.inviteMembers')
              : t('misc.save')
          }
          onClick={handleSelectMembers}
        />,
      ]}
    >
      <div className="text-left">
        <AutocompleteMultipleSearch
          fit="parent"
          values={selectedUsers}
          placeholder={t('members.addUsers')}
          options={selectableUsers.map((user) => formatUser(user, companyId))}
          translations={{
            selectAll: t('misc.selectAll'),
            selected: t('approvalPolicy.members.selected', {
              count: selectedUsers.length,
            }),
          }}
          onSelect={(newSelectedUsers) => setSelectedUsers(newSelectedUsers)}
        />
        <ListBox
          className="mt-24"
          options={selectedUsers}
          rowHeight="compact"
          getOptionId={({ key }) => key}
          emptyState={{ title: t('members.teamHasNoUsers') }}
        >
          {(option) => (
            <div className="flex items-center gap-8">
              <Avatar
                src={option.avatar}
                size={32}
                text={option.userName ?? ''}
              />
              <div>
                <span className={option.isPending ? 'text-primary' : undefined}>
                  {option.userName}
                </span>
                {option.userGroups && <span> {`(${option.userGroups})`}</span>}
              </div>
            </div>
          )}
        </ListBox>
      </div>
    </Modal>
  );
};
