import { Avatar } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { AutocompleteNoOptions } from 'common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

import { useMembersQueryRaw } from '../../hooks/useMembersQueryRaw';
import { type RawMember, type Member } from '../../models/member';

import './MemberManagerAutoComplete.css';

export type ManagerOption = {
  key: string;
  label: string;
  avatar: string | undefined;
  pending: boolean;
};
type Props = {
  currentMember: Member;
  isDisabled: boolean;
  isDropdownReversed?: boolean;
  onSelect: (member: ManagerOption | undefined) => void;
};

export const MemberManagerAutoComplete = ({
  currentMember,
  isDisabled,
  isDropdownReversed,
  onSelect,
}: Props) => {
  const { t } = useTranslation('global');

  const membersQueryRawState = useMembersQueryRaw();
  const members = unwrapQuery(membersQueryRawState) ?? [];

  const currentManager = currentMember.manager
    ? {
        key: currentMember.manager.id,
        label: currentMember.manager.fullNameOrEmail,
        avatar: currentMember.manager.avatar ?? '',
        pending: currentMember.manager.pending,
      }
    : undefined;

  const [selectedMember, setSelectedMember] = useState<
    ManagerOption | undefined
  >(currentManager);

  const options = members
    .filter((member) => member.id !== currentMember.id)
    .map((member) => ({
      key: member.id,
      label: getMemberLabel(member),
      avatar: member.avatar,
      pending: member.pending,
    }));

  return (
    <AutocompleteSearch
      className="MemberManagerAutoCompleteForm__prevent-dropdown-overflow"
      showClearSelectionButton={!isDisabled}
      fit="parent"
      placeholder={t('members.editModal.selectMember')}
      value={selectedMember}
      options={options}
      placement={isDropdownReversed ? 'top-start' : 'bottom-start'}
      onSelect={(member) => {
        setSelectedMember(
          member
            ? {
                key: member.key,
                label: member.label,
                avatar: member.avatar ?? '',
                pending: member.pending,
              }
            : undefined,
        );
        onSelect(member);
      }}
      isDisabled={isDisabled}
      renderPrefix={() => {
        if (!selectedMember) {
          return null;
        }
        return (
          <Avatar
            key={selectedMember.key}
            src={selectedMember.avatar}
            text={selectedMember.label}
            size={24}
          />
        );
      }}
      renderOption={(member) => (
        <div className="flex cursor-pointer items-center gap-8 overflow-hidden px-8 py-8 hover:bg-primary-hover">
          <Avatar src={member.avatar} text={member.label} size={32} />
          <div className="flex flex-col overflow-hidden">
            <span className="truncate text-primary body-m">{member.label}</span>
            <span className="text-primary body-s">
              {getMemberStatus(member, t)}
            </span>
          </div>
        </div>
      )}
      renderNoOptions={(rawValue) => <AutocompleteNoOptions value={rawValue} />}
    />
  );
};

const getMemberLabel = (member: RawMember) => {
  return member.pending ? `${member.email}` : `${member.fullname}`;
};

const getMemberStatus = (
  memberOption: ManagerOption,
  t: TGlobalFunctionTyped,
) => {
  return memberOption.pending
    ? t('members.editModal.memberStatusPending')
    : t('members.editModal.memberStatusActive');
};
