import { SwitchField } from '@dev-spendesk/grapes';

import { type Dimension } from '../../approvalWorkflow';

type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  dimensionName: Dimension['type'];
  isChecked: boolean;
};

export const ApplyToAllSwitchField = ({ onChange, isChecked, dimensionName }: Props) => {
  return (
    <SwitchField
      fit="parent"
      className="mt-8"
      label={getFieldLabel(dimensionName)}
      helpText={getFieldHelpText(dimensionName)}
      isChecked={isChecked}
      onChange={onChange}
    />
  );
};

const getFieldLabel = (dimensionName: string) => {
  switch (dimensionName) {
    case 'spendType':
      return 'Apply to all future spend types';
    case 'costCenter':
      return 'Apply to all future cost centers';
    default:
      return '';
  }
};

const getFieldHelpText = (dimensionName: string) => {
  switch (dimensionName) {
    case 'spendType':
      return 'All future new spend types will follow this approval workflow.';
    case 'costCenter':
      return 'All future new cost centers will follow this approval workflow.';
    default:
      return '';
  }
};
