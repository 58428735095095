import { ReleaseDetailsLink } from 'modules/app/layout/components/VerticalNavigation/ReleaseDetailsLink';
import { SetupGuideNavigationItem } from 'modules/onboarding/setup-hub/components/SetupGuideNavigationItem';

import { CompanyHeader } from './CompanyHeader/CompanyHeader';
import { HelpButton } from './HelpButton/HelpButton';
import { ImpersonationOrganisationSwitcher } from './ImpersonationOrganisationSwitcher/ImpersonationOrganisationSwitcher';
import { JiraIssueReporter } from './JiraIssueReporter/JiraIssueReporter';
import { Logo } from './Logo/Logo';
import styles from './Navigation.module.css';
import { NavigationProvider } from './NavigationContext';
import { NewRequestButton } from './NewRequestButton/NewRequestButton';
import { ProfileEntry } from './ProfileEntry/ProfileEntry';
import { useNavigationItems } from './helpers';
import { useCompany } from '../../../hooks/useCompany';
import { useUser } from '../../../hooks/useUser';

import './Navigation.css';

export const Navigation = () => {
  const user = useUser();
  const company = useCompany();

  const { canCreateRequest, links } = useNavigationItems();

  return (
    <NavigationProvider>
      <header className={styles.top}>
        <Logo />
        <CompanyHeader />
        <ImpersonationOrganisationSwitcher />
      </header>
      <div className={styles.content}>
        {canCreateRequest && <NewRequestButton />}
        <ul className="flex flex-col gap-4">
          {links.map(({ name, component: Component }) => (
            <li key={name}>
              <Component user={user} company={company} />
            </li>
          ))}
        </ul>
      </div>
      <footer className={styles.footer}>
        <ul className="flex flex-col gap-4">
          <ReleaseDetailsLink />
          <JiraIssueReporter />
          <li>
            <SetupGuideNavigationItem />
          </li>
          <HelpButton />
          <li>
            <ProfileEntry />
          </li>
        </ul>
      </footer>
    </NavigationProvider>
  );
};
