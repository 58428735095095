import { Button, Icon } from '@dev-spendesk/grapes';
import React from 'react';
import { useKey } from 'react-use';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  onBack?: () => void;
  onNext: () => void;
  backLabel?: string;
  nextLabel?: string;
  isNextDisabled?: boolean;
  isNextLoading?: boolean;
  canPressEnterForNext?: boolean;
};

export function StepperFooter({
  onBack,
  onNext,
  backLabel,
  nextLabel,
  isNextDisabled,
  isNextLoading,
  canPressEnterForNext,
}: Props) {
  const { t } = useTranslation('global');

  useKey(
    'Enter',
    () => {
      if (canPressEnterForNext) {
        onNext();
      }
    },
    {},
    [onNext, canPressEnterForNext],
  );

  return (
    <footer className="fixed bottom-0 left-0 z-10 w-full border-0 border-t border-solid border-default bg-primary-default">
      <div className="m-auto flex h-[80px] w-[632px] items-center justify-between">
        {typeof onBack === 'function' && (
          <Button
            variant="secondaryNeutral"
            aria-label="back"
            onClick={onBack}
            text={backLabel || t('misc.back')}
            iconName="arrow-left"
          />
        )}
        <div className="flex-1" />
        <Button
          variant="primaryBrand"
          text={nextLabel || t('misc.next')}
          onClick={onNext}
          isDisabled={isNextDisabled}
          isLoading={isNextLoading}
          iconName="arrow-right"
          iconPosition="right"
          aria-label="next"
        />
        {canPressEnterForNext && (
          <span className="sm:block ml-4 hidden text-primary body-m">
            {t('misc.nextOnPress')}
            <Icon
              name="arrow-uturn-left-down"
              size="l"
              className="ml-8 rounded border border-solid p-4 align-middle"
            />
          </span>
        )}
      </div>
    </footer>
  );
}
