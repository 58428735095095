import { Callout, InfoTip } from '@dev-spendesk/grapes';
import cx from 'classnames';
import * as Ezmoney from 'ezmoney';
import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { getImpersonator } from 'src/core/selectors/globalSelectors';
import { isOrganisationOwner } from 'src/core/selectors/users';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import styles from './CompanyPlanSubscription.module.css';
import { type ChargebeeSubscription, getAddonBySlug } from '../../../../models';
import { CompanyPlanAddons } from '../CompanyPlanAddons';
import { CompanyPlanNewWallet } from '../CompanyPlanNewWallet';

type Props = {
  companySubscription: ChargebeeSubscription;
  numberOfTransactions: number;
};

export const CompanyPlanSubscription = ({
  companySubscription,
  numberOfTransactions,
}: Props) => {
  const { t } = useTranslation('global');
  const hasBranchCreationFeature = useFeature(FEATURES.ORGANISATION_BRANCHES);
  const isUserOrganisationOwner = useSelector(isOrganisationOwner);
  const impersonator = useSelector(getImpersonator);
  const { addons, amount, coupons } = companySubscription;

  const includedExpenses =
    getAddonBySlug(addons, 'includedExpenses') ||
    getAddonBySlug(addons, 'expensesIncludedLimited');
  const unlimitedExpenses = getAddonBySlug(addons, 'expensesIncludedUnlimited');
  const extraExpenses =
    getAddonBySlug(addons, 'extraExpenses') ||
    getAddonBySlug(addons, 'expensesExtraCharged');

  if (!addons.length) {
    return null;
  }

  const { formattedPriceBeforeDiscount, formattedDiscountAmount, hasDiscount } =
    formatPriceBeforeDiscount(amount, coupons);

  return (
    <div className="mt-24 flex w-full justify-around border-0 border-b border-solid border-default pb-24 body-m">
      <div
        className={cx(styles.CompanyPlanSubscriptionSection, 'min-w-[120px]')}
      >
        <h2>{t('billing.companyPlan.thisMonth')}</h2>
        {hasDiscount && (
          <div className="mb-8 text-secondary-bg-primary title-xl">
            <span className="mr-8 line-through">
              {formattedPriceBeforeDiscount}
            </span>
            <InfoTip
              content={t('billing.companyPlan.discountTooltip', {
                amount: formattedDiscountAmount,
                date: '31/12/2022',
              })}
            />
          </div>
        )}
        <span className="title-xl">{formatMonetaryValue(amount)}</span>
      </div>
      <div className={cx(styles.CompanyPlanSubscriptionSection, 'flex-1')}>
        <h2>{t('billing.companyPlan.expenses.title')}</h2>
        <span>
          {includedExpenses ? (
            <Trans
              i18nKey="billing.companyPlan.expenses.description"
              values={{
                countExpenses: numberOfTransactions,
                totalExpenses: includedExpenses.quantity,
              }}
              components={[
                <strong
                  key="description"
                  className={
                    includedExpenses.quantity < numberOfTransactions
                      ? 'text-alert-default'
                      : ''
                  }
                >
                  -
                </strong>,
              ]}
            />
          ) : (
            <>
              <Trans
                i18nKey="billing.companyPlan.expenses.descriptionWithoutIncludedExpenses"
                values={{
                  countExpenses: numberOfTransactions,
                }}
                components={[
                  <strong key="descriptionWithoutIncludedExpenses">-</strong>,
                ]}
              />
              {unlimitedExpenses && !extraExpenses && (
                <Callout
                  className="mt-16"
                  title={t(
                    'billing.companyPlan.expenses.expensesIncludedUnlimited',
                  )}
                  variant="info"
                />
              )}
            </>
          )}
          {extraExpenses && (
            <InfoTip
              className="ml-8"
              content={t('billing.companyPlan.expenses.infoExtraExpenses', {
                amount: formatMonetaryValue(extraExpenses.pricing.price),
              })}
            />
          )}
        </span>
      </div>
      <div className={cx(styles.CompanyPlanSubscriptionSection, 'flex-1')}>
        <h2>{t('billing.companyPlan.addons.title')}</h2>
        <CompanyPlanAddons addons={addons} />
      </div>
      {hasBranchCreationFeature && isUserOrganisationOwner && !impersonator && (
        <div className="p-16">
          <CompanyPlanNewWallet />
        </div>
      )}
    </div>
  );
};

const formatPriceBeforeDiscount = (
  price: Ezmoney.MonetaryValue,
  couponIds: string[] | undefined,
): {
  formattedPriceBeforeDiscount: string;
  formattedDiscountAmount: string;
  hasDiscount: boolean;
} => {
  let discountAmount = Ezmoney.create(0, price.currency, 2);

  if (couponIds?.includes(`UPGRADE_100_${price.currency}_2022`)) {
    const coupon100 = Ezmoney.create(10_000, price.currency, 2);
    discountAmount = Ezmoney.add(discountAmount, coupon100);
  }

  if (couponIds?.includes(`UPGRADE_50_${price.currency}_2022`)) {
    const coupon50 = Ezmoney.create(5000, price.currency, 2);
    discountAmount = Ezmoney.add(discountAmount, coupon50);
  }

  return {
    formattedPriceBeforeDiscount: formatMonetaryValue(
      Ezmoney.add(price, discountAmount),
      {
        precision: 0,
      },
    ),
    formattedDiscountAmount: formatMonetaryValue(discountAmount, {
      precision: 0,
    }),
    hasDiscount: Ezmoney.toNumber(discountAmount) > 0,
  };
};
