import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';

import { GET_BY_IDS_EXPENSE_ACCOUNTS } from './queries';
import { expenseAccountToLabel } from './reshaper';

type RawExpenseAccount = {
  id: string;
  name: string;
  code: string;
  isArchived: boolean;
  isDefault: boolean;
};

type ExpenseAccount = {
  id: string;
  name: string;
};

export type LoadExpenseAccountsByIdsRawResponse = {
  company: {
    chartOfAccounts: {
      expenseAccounts: { edges: { node: RawExpenseAccount }[] };
    };
  };
};

export type LoadExpenseAccountsByIdsVariables = {
  ids: string[];
};

export const useLoadExpenseAccountsByIds = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  return async (ids: string[]): Promise<ExpenseAccount[]> => {
    if (!ids.length) {
      return [];
    }

    const data = await queryClient.fetchQuery(
      ['useLoadExpenseAccountsByIds', companyId, ids],
      getFetcher<LoadExpenseAccountsByIdsRawResponse>({
        companyId,
        getRequest: () => ({
          type: 'graphQL',
          target: 'v2',
          query: GET_BY_IDS_EXPENSE_ACCOUNTS,
          variables: {
            ids,
          },
          method: 'get',
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return reshapeFilterValues(
      data.company.chartOfAccounts.expenseAccounts.edges,
    );
  };
};

/**
 * Reshapers
 */

const reshapeFilterValues = (
  edges: {
    node: RawExpenseAccount;
  }[],
): ExpenseAccount[] => {
  return edges
    .map(({ node }) =>
      node.isArchived
        ? null
        : {
            id: node.id,
            name: expenseAccountToLabel(node),
          },
    )
    .filter((item): item is ExpenseAccount => item !== null);
};
