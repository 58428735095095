import { Button, Modal } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  isOpen: boolean;
  onCancel(): void;
  onProcessed(): Promise<void>;
  totalAmount: string;
  numberOfRequests: number;
};

export const ApproveRequestsModal = ({
  isOpen,
  onCancel,
  onProcessed,
  totalAmount,
  numberOfRequests,
}: Props) => {
  const { t } = useTranslation('global');
  const [isLoading, setIsLoading] = useState(false);

  const handleApproveRequests = async () => {
    setIsLoading(true);
    try {
      await onProcessed();
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onCancel}
          text={t('misc.cancel')}
          variant="secondaryNeutral"
          isDisabled={isLoading}
        />,
        <Button
          key="confirm"
          text={t('requests.approveRequestsWithCount', {
            count: numberOfRequests,
          })}
          variant="primaryInfo"
          onClick={handleApproveRequests}
          isDisabled={isLoading}
          isLoading={isLoading}
        />,
      ]}
      iconName="triangle-warning"
      title={t('requests.toApproveList.approveModalTitle')}
      subtitle={t('requests.toApproveList.approveModalTotal', {
        amount: totalAmount,
      })}
      iconVariant="warning"
      isOpen={isOpen}
    />
  );
};
