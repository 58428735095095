import { useCompany } from 'modules/app/hooks/useCompany';
import { useQuery } from 'src/core/api/hooks/useQuery';

export type PartnerOnboardingError = {
  entityType: string;
  entityId: string;
  partnerErrorCode: string;
  errorDescription: string;
};

type Response = {
  partnerOnboardingErrors: PartnerOnboardingError[];
};

type RawResponse = {
  data: Response;
};

export const usePartnerOnboardingErrorsQuery = ({
  isEnabled,
  refetchInterval,
}: {
  isEnabled: boolean;
  refetchInterval?: number;
}) => {
  const { id: companyId } = useCompany();

  return useQuery<Response, RawResponse>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/onboarding/kyc/sfs/uk/missing-information',
    },
    options: { refetchInterval },
    isEnabled,
    key: ['missing-information', companyId],
    reshapeData: (data) => {
      return data.data;
    },
  });
};
