import {
  Accordion,
  AccordionItem,
  Button,
  HighlightIcon,
  Icon,
} from '@dev-spendesk/grapes';
import React, { useMemo, useState } from 'react';

import { type I18nKey, useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import { StatusTag } from 'modules/onboarding/components/TaskList/components/StatusTag';
import { CompanySetupTask } from 'modules/onboarding/onboarding-hub/pages/OnboardingHub/tasks/CompanySetupTask';
import { KycTask } from 'modules/onboarding/onboarding-hub/pages/OnboardingHub/tasks/KycTask';
import { MemberInviteTask } from 'modules/onboarding/onboarding-hub/pages/OnboardingHub/tasks/MemberInviteTask';
import { WalletFundingTask } from 'modules/onboarding/onboarding-hub/pages/OnboardingHub/tasks/WalletFundingTask';
import { HubTaskItem } from 'modules/onboarding/setup-hub/pages/SetupHubPage/tasks/HubTaskItem';
import { UserTaskItem } from 'modules/onboarding/setup-hub/pages/SetupHubPage/tasks/UserTaskItem';
import type { UserTask } from 'modules/onboarding/setup-hub/types/userTask';
import { type HubSection } from 'modules/onboarding/types/hubSection';
import type { HubTask } from 'modules/onboarding/types/hubTask';

import { useMemberQuery } from './hooks/useMemberQuery';
import { TransferSectionOwnershipModal } from '../../../../onboarding-hub/components/TransferSectionOwnershipModal';

type Props = {
  section: HubSection<HubTask | UserTask>;
  title: I18nKey;
  isOpen?: boolean;
};

const getSectionDisabledMessage = (
  section: HubSection<HubTask | UserTask>,
): I18nKey | null => {
  if (['wallet', 'account_setup'].includes(section.id)) {
    return 'onboardingHub.misc.disabledMessage';
  }
  if (['accounts_chart', 'exports_settings'].includes(section.id)) {
    return 'setupHub.accounting.disabledMessage';
  }
  return null; // this should not happen - if it does, we need to cover the case with a new copy
};

export const HubSectionWrapper = ({ section, title, isOpen }: Props) => {
  const { t } = useTranslation('global');
  const user = useUser();

  const completedTasksNumber = useMemo(
    () => section.tasks.filter((task) => task.status === 'completed').length,
    [section.tasks],
  );

  const areAllTasksDisabled = section.tasks.every(
    (task) => task.status === 'disabled',
  );

  const disabledMessage = getSectionDisabledMessage(section);

  const [isTransferModalOpened, setIsTransferModalOpened] = useState(false);

  const assigneeId = useMemo(
    () =>
      (
        section.tasks.find(
          (task) => 'assignedUserId' in task && task.assignedUserId,
        ) as HubTask | null
      )?.assignedUserId ?? null,
    [section],
  );
  const memberQuery = useMemberQuery(assigneeId);
  const assigneeEmail = useMemo(() => {
    return memberQuery.status === 'success' ? memberQuery.data.email : null;
  }, [assigneeId, memberQuery]);

  const isTransferEnabled =
    section.isTransferable &&
    !areAllTasksDisabled &&
    !completedTasksNumber &&
    (!assigneeId || (user.is_account_owner && assigneeId === user.id));

  const getStatus = () => {
    if (areAllTasksDisabled) {
      return 'disabled';
    }
    if (completedTasksNumber === section.tasks.length) {
      return 'completed';
    }
    return 'ongoing';
  };

  return (
    <>
      <div className="rounded-12 border-solid border-default p-24">
        <Accordion>
          <AccordionItem
            open={isOpen}
            renderHeader={() => (
              <div className="mr-16 flex w-full flex-row items-center justify-between">
                <div className="flex items-center gap-12">
                  <HighlightIcon
                    name={section.iconName}
                    variant={section.iconVariant}
                    size={40}
                  />
                  <div
                    className={
                      areAllTasksDisabled
                        ? 'text-secondary-bg-primary'
                        : 'text-primary'
                    }
                  >
                    <h2 className="title-l">{t(title)}</h2>
                    {areAllTasksDisabled && disabledMessage && (
                      <span className="mt-4 flex items-center gap-4 body-s">
                        <Icon size="m" name="lock-close" aria-hidden="true" />
                        {t(disabledMessage)}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  {isTransferEnabled && (
                    <Button
                      text={t('onboardingHub.misc.taskTransferCta')}
                      variant="tertiaryNeutral"
                      onClick={() => setIsTransferModalOpened(true)}
                      className="mr-12"
                      iconName="arrow-left-right"
                    />
                  )}
                  <StatusTag status={getStatus()} />
                </div>
              </div>
            )}
          >
            <div className="flex flex-col gap-24 px-24 pt-16">
              {section.tasks.map((task) => {
                if (
                  ['control_settings', 'accounting'].includes(
                    (task as HubTask).category,
                  )
                ) {
                  return (
                    <HubTaskItem
                      key={task.id}
                      task={task as HubTask}
                      sectionId={section.id}
                    />
                  );
                }
                switch (task?.type) {
                  case 'kyc_review':
                    return <KycTask key={task.id} task={task} />;
                  case 'wallet_funding':
                    return <WalletFundingTask key={task.id} task={task} />;
                  case 'company_setup':
                    return <CompanySetupTask key={task.id} task={task} />;
                  case 'member_invite':
                    return <MemberInviteTask key={task.id} task={task} />;
                  case 'download_app':
                  case 'subscription':
                  case 'single_use':
                  case 'expense_claim':
                  case 'upload_receipt':
                  case 'learn_to_approve':
                    return (
                      <UserTaskItem
                        key={task.id}
                        task={task}
                        sectionId={section.id}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </AccordionItem>
        </Accordion>
      </div>
      {assigneeId && assigneeId !== user.id && (
        <span className="-mt-12 flex items-center gap-8 text-info-default">
          <Icon size="m" name="circle-information" aria-hidden="true" />
          {t('onboardingHub.misc.taskTransferredToInfo', {
            email: assigneeEmail,
          })}
        </span>
      )}
      {isTransferEnabled && (
        <TransferSectionOwnershipModal
          section={section as HubSection<HubTask>}
          isOpen={isTransferModalOpened}
          onClose={() => setIsTransferModalOpened(false)}
        />
      )}
    </>
  );
};
