import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';
import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';

import { GET_BY_IDS_TAX_ACCOUNTS } from './queries';
import { taxAccountToLabel } from './reshaper';

type RawFieldValue = {
  id: string;
  name: string;
  code: string;
  rate: string;
  isArchived?: boolean | null;
};

type TaxAccount = {
  id: string;
  name: string;
  code: string;
  rate: string;
};

export type LoadTaxAccountsByIdsRawResponse = {
  company: {
    chartOfAccounts: {
      taxAccounts: { edges: { node: RawFieldValue }[] };
    };
  };
};

export type LoadTaxAccountsByIdsVariables = {
  ids: string[];
};

export const useLoadTaxAccountsByIds = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();
  const { t } = useTranslation('global');

  return async (ids: string[]): Promise<TaxAccount[]> => {
    if (!ids.length) {
      return [];
    }

    const data = await queryClient.fetchQuery(
      ['useLoadTaxAccountsByIds', companyId, ids],
      getFetcher<LoadTaxAccountsByIdsRawResponse>({
        companyId,
        getRequest: () => ({
          type: 'graphQL',
          target: 'v2',
          query: GET_BY_IDS_TAX_ACCOUNTS,
          variables: {
            ids,
          },
          method: 'get',
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return reshapeFilterValues(
      data.company.chartOfAccounts.taxAccounts.edges,
      t,
    );
  };
};

/**
 * Reshapers
 */

const reshapeFilterValues = (
  edges: {
    node: RawFieldValue;
  }[],
  t: TGlobalFunctionTyped,
): TaxAccount[] => {
  return edges
    .map(({ node }) =>
      node.isArchived
        ? null
        : {
            id: node.id,
            name: taxAccountToLabel(node, t),
          },
    )
    .filter((item): item is TaxAccount => item !== null);
};
