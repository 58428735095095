import { Skeleton, SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';

export const RequestsListLoader = () => (
  <div className="mt-24 flex h-full w-full flex-col bg-primary-default">
    <SkeletonTable
      numberOfRows={10}
      columns={[
        {
          cell: <Skeleton height="var(--unit-32)" width="100%" />,
          header: <SkeletonText width="100%" />,
        },
        {
          cell: <Skeleton height="var(--unit-32)" width="100%" />,
          header: <SkeletonText width="100%" />,
        },
        {
          cell: <Skeleton height="var(--unit-32)" width="100%" />,
          header: <SkeletonText width="100%" />,
        },
        {
          cell: <Skeleton height="var(--unit-32)" width="100%" />,
          header: <SkeletonText width="100%" />,
        },
        {
          cell: <Skeleton height="var(--unit-32)" width="100%" />,
          header: <SkeletonText width="100%" />,
        },
        {
          cell: <Skeleton height="var(--unit-32)" width="100%" />,
          header: <SkeletonText width="100%" />,
        },
      ]}
      withColumnSeparator
      withHeader
    />
  </div>
);
