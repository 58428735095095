import { type Node, type Edge } from '@xyflow/react';
import React, { useState, useCallback, type ReactNode, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import { WorkflowContext } from './WorkflowContext';
import { editApprovalWorkflow } from './api/editApprovalWorkflow';
import { makeTreeLayout } from './helpers/makeTreeLayout';
import { transformNodesAndEdgesToApprovalWorkflow } from './helpers/transformNodesAndEdgesToApprovalWorkflow';
import { type CustomNode } from './node';
import { useCompanyId } from '../app/hooks/useCompanyId';

export const WorkflowProvider = ({ children }: { children: ReactNode }) => {
  const [nodes, setNodesState] = useState<CustomNode[]>([]);
  const [edges, setEdgesState] = useState<Edge[]>([]);
  const [selectedNodeId, setSelectedNodeIdState] = useState<string | null>(
    null,
  );
  const [selectedNodeLevel, setSelectedNodeLevel] = useState<number | null>(
    null,
  );
  const [newNodeId, setNewNodeId] = useState<string | null>(null);

  useEffect(() => {
    if (newNodeId) {
      const level = getNodeLevel(newNodeId, edges);
      setSelectedNodeLevel(level);
    }
  }, [edges, newNodeId]);

  const setNodes = useCallback((ns: Node[]) => {
    setNodesState(ns as CustomNode[]);
  }, []);

  const setEdges = useCallback((es: Edge[]) => {
    setEdgesState(es);
  }, []);

  const setSelectedNodeId = useCallback(
    (nodeId: string | null) => {
      setSelectedNodeIdState(nodeId);
      if (nodeId) {
        const level = getNodeLevel(nodeId, edges);
        setSelectedNodeLevel(level);
      }
      if (nodeId === null) {
        setNewNodeId(null);
      }
    },
    [edges],
  );

  const createAndConnectNode = useCallback(
    ({
      sourceNodeId,
      shouldSelectNewNode = true,
    }: {
      sourceNodeId: string;
      shouldSelectNewNode?: boolean;
    }) => {
      const id = `node-${uuid()}`;
      setNewNodeId(id);
      const newNode: CustomNode = {
        id,
        type: 'baseNode',
        data: {
          schemeId: undefined,
          dimension: undefined,
          values: undefined,
        },
        position: { x: 0, y: 0 },
      };

      setNodesState((nds) => [newNode, ...nds]);
      setEdgesState((eds) => [
        ...eds,
        {
          id: `edge-${uuid()}`,
          source: sourceNodeId,
          target: newNode.id,
          type: 'labelEdge',
          data: {
            labelText: 'If',
            labelVariant: 'purple',
          },
        },
      ]);

      if (shouldSelectNewNode) {
        setSelectedNodeId(id);
      }
    },
    [],
  );

  const getSiblingNodes = useCallback(
    (nodeId: string) => {
      const sourceEdge = edges.find((edge) => edge.target === nodeId);
      if (!sourceEdge) {
        return [];
      }
      const siblingNodeIds = edges
        .filter((edge) => edge.source === sourceEdge.source)
        .map((edge) => edge.target);
      return siblingNodeIds
        .map((siblingNodeId) => nodes.find((node) => node.id === siblingNodeId))
        .filter((node): node is CustomNode => node !== undefined);
    },
    [edges, nodes],
  );

  const getParentNode = useCallback(
    (nodeId: string) => {
      const sourceEdge = edges.find((edge) => edge.target === nodeId);
      if (!sourceEdge) {
        return null;
      }
      return nodes.find((node) => node.id === sourceEdge.source) ?? null;
    },
    [edges, nodes],
  );

  const deleteNode = useCallback(
    (nodeId: string) => {
      const descendantNodeIds = findDescendantNodes(nodeId, nodes, edges);
      const newNodes = nodes.filter((node) => !descendantNodeIds.includes(node.id));
      const newEdges = edges.filter(
        (edge) =>
          !descendantNodeIds.includes(edge.source) &&
          !descendantNodeIds.includes(edge.target),
      );
      setNodesState(newNodes);
      setEdgesState(newEdges);
      // TODO: this should be moved out of this function
      handleSubmitApprovalFlow(newNodes, newEdges);
    },
    [nodes, edges],
  );

  const updateNode = useCallback(
    (id: string, newData: Partial<CustomNode['data']>) => {
      setNodesState((previousNodes) => {
        return previousNodes.map((node) => {
          return node.id === id && node.type === 'baseNode'
            ? { ...node, data: { ...node.data, ...newData } }
            : node;
        });
      });
    },
    [],
  );

  const companyId = useCompanyId();
  const handleSubmitApprovalFlow = (
    updatedNodes: CustomNode[],
    updatedEdges: Edge[],
  ) => {
    const approvalWorkflow = transformNodesAndEdgesToApprovalWorkflow(
      updatedNodes,
      updatedEdges,
    );
    editApprovalWorkflow({ companyId, approvalWorkflow });
  };

  const { layoutedEdges, layoutedNodes } = makeTreeLayout(nodes, edges);

  return (
    <WorkflowContext.Provider
      value={{
        nodes: layoutedNodes,
        edges: layoutedEdges,
        selectedNodeId,
        newNodeId,
        selectedNodeLevel,
        setNodes,
        setEdges,
        updateNode,
        setSelectedNodeId,
        deleteNode,
        createAndConnectNode,
        getSiblingNodes,
        getParentNode,
        handleSubmitApprovalFlow,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};

const getNodeLevel = (nodeId: string, edges: Edge[]): number => {
  const targettingEdge = edges.find((edge) => edge.target === nodeId);
  return targettingEdge ? 1 + getNodeLevel(targettingEdge.source, edges) : 0;
};

const findDescendantNodes = (
  nodeId: string,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  nodes: CustomNode[],
  // eslint-disable-next-line @typescript-eslint/no-shadow
  edges: Edge[],
): string[] => {
  const childEdges = edges.filter((edge) => edge.source === nodeId);
  const childNodeIds = childEdges.map((edge) => edge.target);
  const allDescendantNodeIds = childNodeIds.flatMap((childNodeId) =>
    findDescendantNodes(childNodeId, nodes, edges),
  );
  return [nodeId, ...allDescendantNodeIds];
};
