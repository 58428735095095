import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

// See also RequestType in modules/requests/models/requestType.ts
export const SPEND_TYPES = [
  'card_load',
  // 'credit_note', // Does not require an approval
  'expense',
  'invoice',
  'mileage_allowance',
  'per_diem_allowance',
  'purchase_order',
  'single_purchase', // i.e. virtual card
  'subscription',
  'subscription_increase',
] as const;

type SpendType = (typeof SPEND_TYPES)[number];

export const getSpendTypeTranslation = (
  t: TGlobalFunctionTyped,
  spendType: string,
) => {
  switch (spendType as SpendType) {
    case 'card_load':
      return t('approvalWorkflows.spendTypes.cardLoad');
    // case 'credit_note':
    //   return t('Credit note');
    case 'expense':
      return t('approvalWorkflows.spendTypes.expense');
    case 'invoice':
      return t('approvalWorkflows.spendTypes.invoice');
    case 'mileage_allowance':
      return t('approvalWorkflows.spendTypes.mileageAllowance');
    case 'per_diem_allowance':
      return t('approvalWorkflows.spendTypes.perDiemAllowance');
    case 'purchase_order':
      return t('approvalWorkflows.spendTypes.purchaseOrder');
    case 'single_purchase':
      return t('approvalWorkflows.spendTypes.singlePurchase');
    case 'subscription_increase':
      return t('approvalWorkflows.spendTypes.subscriptionIncrease');
    case 'subscription':
      return t('approvalWorkflows.spendTypes.subscription');
    default:
      return spendType;
  }
};
