import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';

import { expenseAccountToLabel } from './reshaper';

/**
 * @public
 */
export type RawExpenseAccount = {
  id: string;
  name: string;
  code: string;
  isArchived: boolean;
  isDefault: boolean;
};

/**
 * @public
 */
export type ExpenseAccount = {
  id: string;
  name: string;
};

export type SearchExpenseAccountsRawResponse = {
  expenseAccounts: { account: RawExpenseAccount }[];
};

export const useSearchExpenseAccounts = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  return async (search: string): Promise<ExpenseAccount[]> => {
    const data = await queryClient.fetchQuery(
      ['useSearchExpenseAccounts', companyId, search],
      getFetcher<SearchExpenseAccountsRawResponse>({
        companyId,
        getRequest: () => ({
          type: 'rest',
          target: 'companyAPI',
          endpoint: `/accounting-integration/chart-of-accounts/expense-accounts`,
          params: {
            search,
          },
          method: 'get',
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return reshapeFilterValues(data.expenseAccounts);
  };
};

/**
 * Reshapers
 */

const reshapeFilterValues = (
  nodes: SearchExpenseAccountsRawResponse['expenseAccounts'],
): ExpenseAccount[] => {
  return nodes
    .map(({ account: node }) =>
      node.isArchived
        ? null
        : {
            id: node.id,
            name: expenseAccountToLabel(node),
          },
    )
    .filter((item): item is ExpenseAccount => item !== null);
};
