import { DATE_FORMAT, Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { addDays } from 'date-fns';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { upperFirst } from 'common/utils/string';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { useCompanyAccountOwnerQuery } from 'modules/members/hooks/useCompanyAccountOwnerQuery';

import styles from './WelcomeBanner.module.css';

export const WelcomeBanner = () => {
  const { t, localeFormat } = useTranslation('global');
  const company = useCompany();
  const nextWeek = addDays(new Date(company.created_at), 7);
  const displayedDate = localeFormat(nextWeek, DATE_FORMAT.MEDIUM);
  const user = useUser();
  const isUserAo = user?.is_account_owner;
  const accountOwnerQuery = useCompanyAccountOwnerQuery(!isUserAo);

  const getAdminTitle = (): string => {
    if (
      accountOwnerQuery.status === 'success' &&
      accountOwnerQuery.data?.first_name
    ) {
      return t('onboardingHub.welcomeBanner.adminTitle', {
        aoName: upperFirst(accountOwnerQuery.data.first_name),
      });
    }
    return t('onboardingHub.welcomeBanner.adminTitleNoAOName');
  };

  if (!isUserAo && accountOwnerQuery.status === 'loading') {
    return;
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <h3 className={cx(styles.title, 'mb-16 text-primary heading-xl')}>
          {isUserAo ? t('onboardingHub.welcomeBanner.title') : getAdminTitle()}
        </h3>
        <p className={cx(styles.description, 'mb-16 text-primary body-m')}>
          {isUserAo
            ? t('onboardingHub.welcomeBanner.description')
            : t('onboardingHub.welcomeBanner.adminDescription')}
        </p>
        <span className="flex items-center text-primary body-s">
          <Icon size="m" name="calendar" className="mr-8" />
          {t('onboardingHub.welcomeBanner.deadline', { date: displayedDate })}
        </span>
      </div>
      <img
        className={styles.logo}
        src="/static/img/logo-black-small.svg"
        alt="spendesk-logo"
      />
    </div>
  );
};
