import React, { useMemo } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { HubTaskWrapper } from 'modules/onboarding/components/HubTaskWrapper';
import { type HubTask } from 'modules/onboarding/types/hubTask';

import { AdyenKycTaskContent } from './components/AdyenKycTaskContent';
import { DefaultKycTaskContent } from './components/DefaultKycTaskContent';

type Props = {
  task: HubTask;
};

export const KycTask = ({ task }: Props) => {
  const { t } = useTranslation('global');

  const company = useCompany();

  const content = useMemo(() => {
    return company.currency === 'GBP' ? (
      <AdyenKycTaskContent task={task} />
    ) : (
      <DefaultKycTaskContent task={task} />
    );
  }, [task, company.currency]);

  return (
    <HubTaskWrapper
      title={t('onboardingHub.kycTask.subtitle')}
      description={t('onboardingHub.kycTask.description')}
      task={task}
      duration="5-20"
    >
      {content}
    </HubTaskWrapper>
  );
};
