import { HighlightIcon } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type AccountPayableSuppliersRow } from '../../types';
import { AccountPayableSuppliersRowIcon } from '../AccountPayableSuppliersRowIcon';

import './AccountPayableSuppliersNameCell.css';

type Props = {
  supplierName: string | null;
} & Pick<AccountPayableSuppliersRow, 'totalCount' | 'logosUrls'>;

export const AccountPayableSuppliersNameCell = ({
  supplierName,
  totalCount,
  logosUrls,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className="AccountPayableSuppliersNameCell__name">
      {totalCount === 0 && (
        <HighlightIcon variant="info" name="circle-information" size={32} />
      )}
      {totalCount > 0 && (
        <AccountPayableSuppliersRowIcon logosUrls={logosUrls} />
      )}
      {totalCount === 0 && (
        <span className="AccountPayableSuppliersNameCell__label-no-supplier">
          {t('bookkeep.accountsPayable.list.noSupplier')}
        </span>
      )}
      {totalCount === 1 && <span className="truncate">{supplierName}</span>}
      {totalCount > 1 && (
        <span className="AccountPayableSuppliersNameCell__label-multiple-suppliers">
          {t('bookkeep.accountsPayable.list.multipleSuppliers', {
            count: totalCount,
          })}
        </span>
      )}
    </div>
  );
};
