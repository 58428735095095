import { type Edge } from '@xyflow/react';

import {
  type ApprovalWorkflowNode,
  type ApprovalWorkflow,
  type ApprovalWorkflowBranch,
  type Dimension,
} from '../approvalWorkflow';
import { type CustomNode } from '../node';

const makeOutcome = (approvalSchemeId: string) => ({
  outcome: { approvalSchemeId },
});

const makeOptionalOutcome = (approvalSchemeId?: string) =>
  approvalSchemeId ? makeOutcome(approvalSchemeId) : {};

/**
 * Transforms UI nodes and edges into the ApprovalWorkflow structure.
 *
 * @param nodes - Array of CustomNode objects from the UI.
 * @param edges - Array of Edge objects from the UI.
 * @returns ApprovalWorkflow representing the approval workflow.
 * @throws Error if root nodes are missing or child node data is incomplete.
 */
export const transformNodesAndEdgesToApprovalWorkflow = (
  nodes: CustomNode[],
  edges: Edge[],
): ApprovalWorkflow => {
  const standardApprovalFlowNode = nodes.find(
    (node) => node.type === 'standardApprovalFlowNode',
  );
  const rootNode = nodes.find((node) => node.type === 'labelNode');
  const standardApprovalSchemeId = standardApprovalFlowNode?.data.schemeId;

  if (!rootNode || !standardApprovalSchemeId) {
    throw new Error(
      `Root nodes not found. Found: standardApprovalFlowNode: ${!!standardApprovalFlowNode}, standardApprovalSchemeId: ${!!standardApprovalSchemeId}, labelNode: ${!!rootNode}`,
    );
  }

  return {
    id: standardApprovalFlowNode.id,
    definition: {
      ...makeOutcome(standardApprovalSchemeId),
      values: null,
      branch: buildBranch(rootNode, nodes, edges),
    },
  };
};

const buildBranch = (
  node: CustomNode,
  nodes: CustomNode[],
  edges: Edge[],
): ApprovalWorkflowBranch | undefined => {
  const outgoingEdges = edges.filter(
    (edge) => edge.source === node.id && edge.id !== 'initialEdge',
  );
  const childNodes = outgoingEdges
    .map((edge) => nodes.find((n) => n.id === edge.target))
    .filter(Boolean) as CustomNode[];

  const [firstChildNode] = childNodes;
  if (!firstChildNode) {
    return undefined; // Leaf node
  }

  const dimension: Dimension | undefined =
    firstChildNode.type === 'baseNode'
      ? firstChildNode.data.dimension
      : undefined;

  return {
    dimension: dimension ?? { type: 'spendType' },
    nodes: childNodes.map<ApprovalWorkflowNode>((childNode) => {
      const childBranch = buildBranch(childNode, nodes, edges);
      const childNodeData =
        childNode.type === 'baseNode' ? childNode.data : undefined;
      if (!childNodeData) {
        throw new Error('Child node data not found');
      }
      return {
        values: childNodeData.values ?? null,
        ...makeOptionalOutcome(childNodeData.schemeId),
        ...(childBranch ? { branch: childBranch } : {}),
      };
    }),
  };
};
