import { EmptyState, Button } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  onResetFilters: () => void;
};

export const PayableListEmptyResult = ({ onResetFilters }: Props) => {
  const { t } = useTranslation('global');

  return (
    <EmptyState
      className="my-64 flex flex-col items-center"
      title={t('payables.filterEmptyResult.title')}
      subtitle={t('payables.filterEmptyResult.subtitle')}
      iconName="circle-information"
      actions={[
        <Button
          key="cta"
          variant="primaryBrand"
          onClick={onResetFilters}
          text={t('payables.filterEmptyResult.cta')}
        />,
      ]}
    />
  );
};
