import { HighlightIcon, type IconName } from '@dev-spendesk/grapes';
import React from 'react';

type Props = {
  formatedAmount: string;
  formatedAmountDescription: string;
  isAccountLoaded: boolean;
  iconName: IconName;
  hasWarning?: boolean;
};

export const HomepageWalletDetailSecondaryAmount = ({
  formatedAmount,
  formatedAmountDescription,
  isAccountLoaded,
  iconName,
  hasWarning = false,
}: Props) => {
  return (
    <div className="flex items-center gap-12">
      <HighlightIcon
        name={iconName}
        size={32}
        variant={hasWarning ? 'apricot' : 'purple'}
        className="shrink-0"
      />
      {!isAccountLoaded ? (
        <div className="flex flex-col gap-4">
          <div className="h-20 w-[100px] rounded-8 bg-secondary-default" />
          <div className="h-16 w-[120px] rounded-8 bg-secondary-default" />
        </div>
      ) : (
        <div className="flex flex-col">
          <h3 className="text-primary title-m">{formatedAmount}</h3>
          <p className="text-secondary-bg-secondary body-s">
            {formatedAmountDescription}
          </p>
        </div>
      )}
    </div>
  );
};
