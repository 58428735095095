import { Button, Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import { useGoToPaymentMethods } from 'modules/company/general-settings';

import { Block } from './Block';
import BinocularWoman from './binocular-woman.svg';
import Calculator from './calculator.svg';
import Pot from './pot.svg';

export const EnableTransfersForExpenses = () => {
  const { t } = useTranslation('global');
  const goToPaymentMethods = useGoToPaymentMethods();
  const { isAccountOwner } = useUserRoles();

  return (
    <section className="m-24 flex h-full flex-col items-center justify-center gap-48">
      <h1 className="max-w-[650px] text-center text-primary title-xl">
        {t('invoices.transfer.featureDisabledExpense.title')}
      </h1>
      <main>
        <div className="mx-auto grid w-full max-w-lg grid-cols-3">
          <Block
            imgSrc={BinocularWoman}
            title={t('invoices.transfer.featureDisabledExpense.blockA.title')}
            description={t(
              'invoices.transfer.featureDisabledExpense.blockA.description',
            )}
          />
          <Block
            imgSrc={Pot}
            title={t('invoices.transfer.featureDisabledExpense.blockB.title')}
            description={t(
              'invoices.transfer.featureDisabledExpense.blockB.description',
            )}
          />
          <Block
            imgSrc={Calculator}
            title={t('invoices.transfer.featureDisabledExpense.blockC.title')}
            description={t(
              'invoices.transfer.featureDisabledExpense.blockC.description',
            )}
          />
        </div>
      </main>
      <div className="flex justify-center">
        {isAccountOwner ? (
          <Button
            variant="primaryBrand"
            text={t('invoices.transfer.featureDisabledExpense.action')}
            onClick={() => {
              goToPaymentMethods();
            }}
          />
        ) : (
          <Callout
            variant="info"
            title={t(
              'invoices.transfer.featureDisabledExpense.actionController',
            )}
          />
        )}
      </div>
    </section>
  );
};
