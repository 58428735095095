import { Select } from '@dev-spendesk/grapes';

import { useWorkflowContext } from '../../WorkflowContext';
import { type Dimension, DIMENSIONS } from '../../approvalWorkflow';

export const DimensionSelect = ({
  value,
  onChange,
}: {
  value: { key: string; label: string } | undefined;
  onChange: (value: { key: string; label: string }) => void;
}) => {
  const { getParentNode, selectedNodeId, selectedNodeLevel } =
    useWorkflowContext();

  // filter out options selected in the parent node
  const selectedParentNode = selectedNodeId
    ? getParentNode(selectedNodeId)
    : null;
  const parentDimension =
    selectedParentNode && selectedParentNode.type === 'baseNode'
      ? selectedParentNode.data.dimension
      : null;
  const filteredOptions = parentDimension
    ? getDimensionOptions().filter(
      (option) => option.key !== parentDimension.type,
    )
    : [];

  return (
    <Select
      name="dimension"
      options={filteredOptions}
      value={value}
      // Disable if it's the first level that is a spend type by default or if there is only one option
      isDisabled={selectedNodeLevel === 1 || filteredOptions.length === 1}
      onSelect={(option) => onChange(option)}
      fit="parent"
      placeholder="Select dimension"
    />
  );
};

export const getDimensionOptions = () => {
  return DIMENSIONS.map((dimension) => ({
    key: dimension,
    label: getDimensionTranslation(dimension),
  }));
};

const getDimensionTranslation = (dimensionName: Dimension['type']) => {
  switch (dimensionName) {
    case 'spendType':
      return 'Spend Type';
    case 'costCenter':
      return 'Cost Center';
    // case 'ExpenseCategory':
    //   return 'Expense Category';
    default:
      return dimensionName;
  }
};
