import {
  Avatar,
  DATE_FORMAT,
  HighlightIcon,
  Table,
} from '@dev-spendesk/grapes';
import { useState } from 'react';

import { type MileageScheme } from 'modules/requests/models/mileageScheme';
import type { RequestAPI } from 'modules/requests/types';
import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';
import { PageNoResult } from 'src/core/common/components/PageNoResult';
import { SupplierLogo } from 'src/core/common/components/SupplierLogo';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useParams } from 'src/core/common/hooks/useParams';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routes } from 'src/core/constants/routes';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { RequestStateTag } from 'src/core/modules/requests/list/components/RequestStateTag/RequestStateTag';
import { RequestsTableActions } from 'src/core/modules/requests/list/components/RequestsListBox/RequestsTableActions';
import { RequestsListLoader } from 'src/core/modules/requests/list/components/RequestsListsLoader';
import {
  getRequestDistanceFormatted,
  hasOverriddenDistance,
} from 'src/core/modules/requests/models/mileageRequest';
import { SubnavigationItem } from 'src/core/modules/requests/utils/navigation';
import { getAmountToDisplay } from 'src/core/utils/entities/request';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';
import { formatMoney } from 'src/core/utils/money';

import { getSection, sectionGroupByRequestType } from './utils';
import type { RequestStats, Sections } from '../requestsSectionsProps';

type Request = RequestAPI & {
  mileage_scheme: MileageScheme;
};

type Props = {
  requests: Request[];
  sectionsStats: Sections<RequestStats>;
  onOptionClick: (request: Request) => void;
  activeRequest: string | undefined;
  type: SubnavigationItem;
  checkedRequests: string[];
  onRequestToggle: (requestId: string, checked: boolean) => void;
  onRequestToggleAll: (isChecked: boolean) => void;
  hasFiltersApplied?: boolean;
  isLoading?: boolean;
};

export const RequestsTable = ({
  requests,
  onOptionClick,
  sectionsStats,
  type,
  isLoading,
  hasFiltersApplied,
}: Props) => {
  const { t, localeFormat } = useTranslation('global');
  const { id: pathRequestId } = useParams(routes.REQUESTS.path);

  const user = useUser();
  const hasMileageEditDistance = useFeature(FEATURES.MILEAGE_EDIT_DISTANCE);

  const [selectedRowIds, setSelectedRowsIds] = useState<string[]>([]);

  if (isLoading) {
    return <RequestsListLoader />;
  }

  if (requests.length === 0) {
    return (
      <PageNoResult
        mode={type === SubnavigationItem.Mine ? 'mineRequest' : 'request'}
        hasFiltersApplied={hasFiltersApplied}
      />
    );
  }

  return (
    <>
      <Table
        className="my-24"
        columns={[
          ...(type !== 'mine'
            ? [
                {
                  id: 'user',
                  width: '272px',
                  renderCell: (request: Request) => {
                    return (
                      <div className="max-w-full flex items-center gap-4">
                        <Avatar
                          text={
                            request.user?.fullname ?? request.user?.email ?? ''
                          }
                          src={request.user?.avatar ?? ''}
                          className="shrink-0"
                          lazyLoad
                        />
                        <div className="flex flex-col truncate body-m">
                          <span className="text-primary">
                            <EllipsisTooltip
                              text={
                                request.user?.fullname ??
                                request.user?.email ??
                                ''
                              }
                            />
                          </span>
                          {request.cost_center && (
                            <span className="text-secondary-bg-secondary">
                              <EllipsisTooltip
                                text={request.cost_center?.name}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  },
                  header: t('requests.employeeHeaderLabel'),
                },
              ]
            : []),
          {
            id: 'amount',
            width: '144px',
            renderCell: (request) => {
              return formatMoney(
                getAmountToDisplay(request),
                request.currency_declared,
              );
            },
            header: t('requests.toApproveList.amount'),
          },
          {
            id: 'description',
            renderCell: (request) => {
              if (request.type === 'mileage_allowance') {
                return (
                  <div className="flex items-center gap-4 truncate">
                    <EllipsisTooltip
                      text={`${getRequestDistanceFormatted({
                        mileageRequest: request,
                      })}${
                        hasMileageEditDistance && hasOverriddenDistance(request)
                          ? ` ${t('requests.panel.mileage.manualInput')}`
                          : ''
                      } • ${request.description}`}
                    />
                  </div>
                );
              }
              return (
                <div className="truncate">
                  <EllipsisTooltip text={request.description} />
                </div>
              );
            },
            header: t('requests.toApproveList.description'),
          },
          {
            id: 'supplier',
            renderCell: (request) => {
              if (request.type === 'mileage_allowance') {
                return (
                  <div className="flex items-center gap-8 truncate">
                    <HighlightIcon
                      name="car"
                      size={32}
                      variant="peach"
                      className="shrink-0"
                    />
                    <EllipsisTooltip
                      text={t('forms.paymentType.mileageAllowance')}
                    />
                  </div>
                );
              }
              if (request.type === 'per_diem_allowance') {
                return (
                  <div className="flex items-center gap-8 truncate">
                    <HighlightIcon
                      className="shrink-0"
                      name="globe-eu-africa"
                      size={32}
                      variant="lemon"
                    />
                    <EllipsisTooltip
                      text={t('forms.paymentType.perDiemAllowance')}
                    />
                  </div>
                );
              }
              return (
                <div className="flex gap-8">
                  {request?.supplier?.name ? (
                    <div className="flex items-center gap-8 truncate">
                      <SupplierLogo name={request.supplier.name} size={32} />
                      <EllipsisTooltip text={request.supplier.name} />
                    </div>
                  ) : (
                    t('misc.na')
                  )}
                </div>
              );
            },
            header: t('requests.toApproveList.supplier'),
          },
          {
            id: 'status',
            width: '272px',
            renderCell: (request) => (
              <div className="text-wrap">
                <RequestStateTag request={request} />
              </div>
            ),
            header: t('misc.status'),
          },
          {
            id: 'date',
            width: '144px',
            renderCell: (request) =>
              localeFormat(new Date(request.created_at), DATE_FORMAT.MEDIUM),
            header: t('requests.toApproveList.date'),
          },
        ]}
        data={requests}
        groupBy={(request) => sectionGroupByRequestType[request.type]}
        getIsRowActive={(request) => request.id === pathRequestId}
        getRowId={(request) => request.id}
        selectedRowIds={selectedRowIds}
        onRowClick={onOptionClick}
        onRowSelectionChange={(_, id, checked) => {
          setSelectedRowsIds((options) => {
            if (checked) {
              return options.concat(id);
            }
            return options.filter((optionId) => optionId !== id);
          });
        }}
        onAllRowsSelectionChange={(_, ids, checked) => {
          setSelectedRowsIds(checked ? ids : []);
        }}
        renderGroupedRowHeader={(value) => {
          const section = getSection(type, user)[value];

          if (!section) {
            return null;
          }
          const translationKey = section.translationKey;
          const sectionStats = sectionsStats[section.sectionStatsKey];
          return (
            <div className="flex items-center py-8 uppercase text-secondary-bg-secondary body-s">
              <span className="">{t(translationKey)}</span>
              <div className="ml-auto flex items-center gap-8">
                <span>
                  {t('misc.requestWithCount', {
                    count: sectionStats?.count,
                  })}
                </span>
                {sectionStats?.totalAmount && (
                  <>
                    <span
                      className="rounded-full h-4 w-4"
                      style={{ background: 'var(--color-border-default)' }}
                    />
                    <span>{formatMonetaryValue(sectionStats.totalAmount)}</span>
                  </>
                )}
              </div>
            </div>
          );
        }}
      />
      <RequestsTableActions
        selectedRowIds={selectedRowIds}
        type={type}
        unselectRequests={() => setSelectedRowsIds([])}
        requests={requests}
      />
    </>
  );
};
