import {
  Avatar,
  Button,
  DATE_FORMAT,
  EmptyState,
  FloatingActionBar,
  Table,
  Tooltip,
} from '@dev-spendesk/grapes';
import { add, format, fromNumber } from 'ezmoney';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';
import { QuerySuspenseBase } from 'common/components/QuerySuspenseBase/QuerySuspenseBase';
import { SupplierLogo } from 'common/components/SupplierLogo';
import { UnexpectedErrorContainer } from 'common/components/UnexpectedError/UnexpectedErrorContainer';
import { useFeature } from 'common/hooks/useFeature';
import { useParams } from 'common/hooks/useParams';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { type AppDispatch } from 'modules/app/redux/store';
import { useRequestsApprovalsMutation } from 'modules/requests/api/useRequestsApprovalsMutation';
import { useRequestsDenialsMutation } from 'modules/requests/api/useRequestsDenialsMutation';
import {
  type RequestToApprove,
  useRemoveRequestsFromRequestsToApproveCache,
  useRequestsToApproveQuery,
} from 'modules/requests/api/useRequestsToApproveQuery';
import { DenyRequestModal } from 'modules/requests/components/common/DenyRequestModal';
import { RequestsToApproveListType } from 'modules/requests/list/components/RequestsToApproveList/RequestsToApproveListType';
import { recordRequestTypetoI18NKey } from 'modules/requests/models/requestType';
import { SubnavigationItem } from 'modules/requests/utils/navigation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { metersToDistanceUnit } from 'src/core/utils/geo';
import { formatNumberToDistance } from 'src/core/utils/number';
import { logger } from 'src/utils/datadog-log-wrapper';

import { ApproveRequestsModal } from './ApproveRequestsModal';
import { fetchRequestsStats } from '../../../redux/legacy/actions';
import { RequestsListLoader } from '../RequestsListsLoader';

export const RequestsToApproveList = () => {
  const { localeFormat, t } = useTranslation('global');
  const history = useHistory();
  const company = useCompany();
  const dispatch = useDispatch<AppDispatch>();
  const { id: pathRequestId } = useParams(routes.REQUESTS.path);
  const { successNotif, dangerNotif } = useNotifications();
  const hasMileageEditDistance = useFeature(FEATURES.MILEAGE_EDIT_DISTANCE);
  const { removeRequestsFromRequestsToApproveCache } =
    useRemoveRequestsFromRequestsToApproveCache();
  const companyId = useCompanyId();

  const [selectedRowIds, setSelectedRowsIds] = useState<string[]>([]);
  const [isDenyModalOpen, setIsDenyRequestModalOpen] = useState(false);
  const [approveModalState, setApproveModalState] = useState<
    | {
        totalAmount: string;
        numberOfRequests: number;
        selectedRowIds: string[];
      }
    | undefined
  >(undefined);
  const usersRequestsQueryState = useRequestsToApproveQuery({
    enabled: true,
  });
  const { mutateAsync: approveRequests } = useRequestsApprovalsMutation();
  const { mutateAsync: denyRequests } = useRequestsDenialsMutation();

  const handleRequestsApprove = async (requestIds: string[]) => {
    try {
      const response = await approveRequests(requestIds);
      if (response.data?.errorIds?.length > 0) {
        if (response.data?.successIds?.length > 0) {
          successNotif(t('requests.toApproveList.approveSuccessPartial'));
        }
        dangerNotif(t('requests.toApproveList.approveErrorPartial'));
      } else {
        successNotif(t('requests.toApproveList.approveSuccessAll'));
      }
      // TODO: Could update the query cache instead of refetching
      await usersRequestsQueryState.refetch();
      return {
        successIds: response.data?.successIds ?? [],
        errorIds: response.data?.errorIds ?? [],
      };
    } catch {
      dangerNotif(t('requests.toApproveList.approveErrorAll'));
      return {
        successIds: [],
        errorIds: selectedRowIds,
      };
    }
  };

  const handleRequestsDeny = async (
    requestIds: string[],
    denialReason: string,
  ) => {
    try {
      const response = await denyRequests({
        requestIds,
        denialReason,
      });
      if (response.data?.errorIds?.length > 0) {
        if (response.data?.successIds?.length > 0) {
          successNotif(t('requests.toApproveList.denySuccessPartial'));
        }
        dangerNotif(t('requests.toApproveList.denyErrorPartial'));
      } else {
        successNotif(t('requests.toApproveList.denySuccessAll'));
      }
      dispatch(fetchRequestsStats());
      setIsDenyRequestModalOpen(true);
      return {
        successIds: response.data?.successIds ?? [],
        errorIds: response.data?.errorIds ?? [],
      };
    } catch {
      dangerNotif(t('requests.toApproveList.denyErrorAll'));
      return { errorIds: selectedRowIds, successIds: [] };
    }
  };

  const getTotalAmountForRequestIds = (
    requestsToApprove: RequestToApprove[],
    selectedRequestIds: string[],
  ) => {
    return selectedRequestIds.reduce(
      (accumulator, requestId) => {
        const request = requestsToApprove.find((r) => r.id === requestId);
        if (request && request.amounts.declared?.inCompanyCurrency) {
          return add(accumulator, request.amounts.declared.inCompanyCurrency);
        }
        return accumulator;
      },
      fromNumber(0, company.currency as string),
    );
  };

  return (
    <QuerySuspenseBase
      queryState={usersRequestsQueryState}
      loading={<RequestsListLoader />}
      fallback={() => <UnexpectedErrorContainer useAutoNotificationWording />}
    >
      {(usersRequestsData) => {
        if (usersRequestsData.data.requests.length === 0) {
          return (
            <EmptyState
              iconName="circle-information"
              iconVariant="info"
              title={t('pageEmptyState.toApproveRequests.title')}
              subtitle={t('pageEmptyState.toApproveRequests.subtitle')}
            />
          );
        }
        return (
          <div className="flex w-full flex-col">
            <Table
              className="my-24"
              columns={[
                {
                  id: 'type',
                  width: '56px',
                  align: 'center',
                  renderCell: (request) => (
                    <Tooltip
                      content={
                        t(recordRequestTypetoI18NKey[request.type]) ??
                        request.type
                      }
                    >
                      <RequestsToApproveListType requestType={request.type} />
                    </Tooltip>
                  ),
                  header: t('requests.toApproveList.type'),
                },
                {
                  id: 'amount',
                  width: '144px',
                  align: 'right',
                  renderCell: (request) => {
                    if (
                      request.amounts.declared.inOriginalCurrency &&
                      request.amounts.declared.inCompanyCurrency &&
                      request.amounts.declared.inCompanyCurrency.currency !==
                        request.amounts.declared.inOriginalCurrency.currency
                    ) {
                      return (
                        <div className="flex flex-col">
                          <span>
                            {format(request.amounts.declared.inCompanyCurrency)}
                          </span>
                          <span className="text-secondary-bg-secondary">
                            {format(
                              request.amounts.declared.inOriginalCurrency,
                            )}
                          </span>
                        </div>
                      );
                    }
                    return request.amounts.declared.inCompanyCurrency
                      ? format(request.amounts.declared.inCompanyCurrency)
                      : t('misc.na');
                  },
                  header: t('requests.toApproveList.amount'),
                },
                {
                  id: 'description',
                  renderCell: (request) => {
                    if (
                      request.type === 'mileage_allowance' &&
                      request.mileageData
                    ) {
                      return (
                        <div className="flex items-center gap-4 truncate">
                          <EllipsisTooltip
                            text={`${formatNumberToDistance({
                              number: metersToDistanceUnit({
                                distanceMeters: request.mileageData.distance,
                                distanceUnit: request.mileageData.distanceUnit,
                                precision: 2,
                              }),
                              distanceUnit: request.mileageData.distanceUnit,
                            })}${
                              hasMileageEditDistance &&
                              Boolean(request.mileageData.distanceFromMap) &&
                              request.mileageData.distanceFromMap !==
                                request.mileageData.distance
                                ? ` ${t('requests.panel.mileage.manualInput')}`
                                : ''
                            } • ${request.description}`}
                          />
                        </div>
                      );
                    }

                    if (request.type === 'mileage_allowance') {
                      return (
                        <div className="flex items-center gap-4 truncate">
                          <EllipsisTooltip text={request.description} />
                        </div>
                      );
                    }
                    return (
                      <div className="truncate">
                        <EllipsisTooltip text={request.description} />
                      </div>
                    );
                  },
                  header: t('requests.toApproveList.description'),
                },
                {
                  id: 'supplier',
                  renderCell: (request) => {
                    if (!request.supplierId) {
                      return t('misc.na');
                    }
                    const supplier = usersRequestsData.data.suppliers.find(
                      (s) => s.id === request.supplierId,
                    );
                    return supplier ? (
                      <div className="flex items-center gap-8">
                        <SupplierLogo name={supplier.name} />
                        <span>{supplier.name}</span>
                      </div>
                    ) : (
                      t('misc.na')
                    );
                  },
                  header: t('requests.toApproveList.supplier'),
                },
                {
                  id: 'date',
                  width: '144px',
                  align: 'right',
                  renderCell: (request) =>
                    localeFormat(
                      new Date(request.createdAt),
                      DATE_FORMAT.MEDIUM,
                    ),
                  header: t('requests.toApproveList.date'),
                },
              ]}
              data={usersRequestsData.data.requests}
              groupBy={(request) => request.userId}
              getRowId={(request) => request.id}
              selectedRowIds={selectedRowIds}
              getIsRowActive={(request) => request.id === pathRequestId}
              onRowClick={(request) => {
                history.push(
                  routeFor(routes.REQUESTS.path, {
                    company: companyId,
                    id: request.id,
                    type: 'to-approve',
                  }),
                );
              }}
              onRowSelectionChange={(_, id, checked) => {
                setSelectedRowsIds((options) => {
                  if (checked) {
                    return options.concat(id);
                  }
                  return options.filter((optionId) => optionId !== id);
                });
              }}
              onAllRowsSelectionChange={(_, ids, checked) => {
                setSelectedRowsIds(checked ? ids : []);
              }}
              renderGroupedRowHeader={(_, aggregatedRequests) => {
                const userId = aggregatedRequests[0].userId;
                const user = usersRequestsData.data.users.find(
                  (u) => u.id === userId,
                );
                const sum = aggregatedRequests.reduce(
                  (accumulator, row) => {
                    if (row.amounts.declared?.inCompanyCurrency) {
                      return add(
                        accumulator,
                        row.amounts.declared?.inCompanyCurrency,
                      );
                    }

                    return accumulator;
                  },
                  fromNumber(0, company.currency as string),
                );

                if (!user) {
                  logger.error(
                    `RequestsToApproveList: User not found for id: ${userId}`,
                    {
                      scope: 'requests::to-approve',
                      team: 'travel-and-expenses',
                    },
                  );
                  return <div />;
                }
                const userName = `${user.firstName} ${user.lastName}`;

                return (
                  <div className="flex items-center gap-8 py-8">
                    <Avatar src={user.avatarUrl ?? undefined} text={userName} />
                    <p className="grow text-primary body-m">{userName}</p>
                    <p className="uppercase">
                      {t('misc.requestWithCount', {
                        count: aggregatedRequests.length,
                      })}
                    </p>
                    <Button
                      variant="secondaryNeutral"
                      text={t('requests.toApproveList.approveAll', {
                        amount: format(sum),
                        count: aggregatedRequests.length,
                      })}
                      onClick={async () => {
                        setApproveModalState({
                          totalAmount: format(sum),
                          numberOfRequests: aggregatedRequests.length,
                          selectedRowIds: aggregatedRequests.map(
                            ({ id }) => id,
                          ),
                        });
                        track(
                          AnalyticEventName.REQUEST_CLICK_APPROVE_USER_REQUESTS,
                        );
                      }}
                    />
                  </div>
                );
              }}
            />
            {selectedRowIds.length > 0 && (
              <FloatingActionBar
                actions={[
                  {
                    text: t('misc.approve'),
                    onClick: async () => {
                      setApproveModalState({
                        totalAmount: format(
                          getTotalAmountForRequestIds(
                            usersRequestsData.data.requests,
                            selectedRowIds,
                          ),
                        ),
                        numberOfRequests: selectedRowIds.length,
                        selectedRowIds,
                      });
                      track(AnalyticEventName.REQUEST_BULK_ACTION, {
                        type: 'approve',
                        number: selectedRowIds.length,
                        tab: SubnavigationItem.ToApprove,
                      });
                    },
                    iconName: 'circle-check',
                  },
                  {
                    text: t('misc.deny'),
                    onClick: () => {
                      setIsDenyRequestModalOpen(true);
                    },
                    iconName: 'hexagone-cross',
                  },
                ]}
              >
                {t('misc.nSelected', { count: selectedRowIds.length })}
              </FloatingActionBar>
            )}
            <DenyRequestModal
              isOpen={isDenyModalOpen}
              onCancel={() => setIsDenyRequestModalOpen(false)}
              onProcessed={async (denialReason: string) => {
                const { successIds, errorIds } = await handleRequestsDeny(
                  selectedRowIds,
                  denialReason,
                );
                removeRequestsFromRequestsToApproveCache(successIds);
                track(AnalyticEventName.REQUEST_BULK_ACTION, {
                  type: 'deny',
                  number: selectedRowIds.length,
                  tab: SubnavigationItem.ToApprove,
                });
                setSelectedRowsIds(errorIds);
                setIsDenyRequestModalOpen(false);
              }}
            />
            {approveModalState ? (
              <ApproveRequestsModal
                isOpen
                onCancel={() => setApproveModalState(undefined)}
                onProcessed={async () => {
                  const { errorIds, successIds } = await handleRequestsApprove(
                    approveModalState.selectedRowIds,
                  );
                  setApproveModalState(undefined);
                  setSelectedRowsIds(errorIds);
                  removeRequestsFromRequestsToApproveCache(successIds);
                  dispatch(fetchRequestsStats());
                }}
                totalAmount={approveModalState?.totalAmount ?? ''}
                numberOfRequests={approveModalState?.numberOfRequests ?? 0}
              />
            ) : null}
          </div>
        );
      }}
    </QuerySuspenseBase>
  );
};
