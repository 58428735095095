import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';

type Response = {
  url: string;
};

type RawResponse = {
  data: Response;
};

type Options = {
  isEnabled?: boolean;
  refetchInterval?: number;
}

export const useHostedOnboarding = (callbackUrl: string, options?: Options) => {
  const companyId = useCompanyId();
  return useQuery<Response, RawResponse>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/onboarding/kyc/sfs/uk/hosted-onboarding-url',
      params: {
        callbackUrl,
      },
    },
    options: {
      refetchInterval: options?.refetchInterval,
    },
    isEnabled: options?.isEnabled,
    key: ['hosted-onboarding-url', companyId],
  });
};
