import {
  Callout,
  CheckboxInput,
  Table,
  type TableColumn,
} from '@dev-spendesk/grapes';

import { useTranslation } from '../../../../../../common/hooks/useTranslation';
import {
  type PublicApiScopeListEntry,
  getPublicApiScopeTranslation,
} from '../../../../domain/public-api/scopes';

type Props = {
  mode: 'view' | 'edit';
  scopes: PublicApiScopeListEntry[];
  onScopeChange: (scopeName: string) => void;
  selectedScopes: Set<string>;
  formError?: string;
};

const ScopesForm = ({
  mode,
  scopes,
  selectedScopes,
  onScopeChange,
  formError,
}: Props) => {
  const { t } = useTranslation('global');

  const columns: TableColumn<PublicApiScopeListEntry>[] = [
    {
      id: 'name',
      header: t('publicApi.scopes.name'),
      renderCell: ({ name }) => getScopeName(name),
    },
    {
      id: 'description',
      header: t('misc.description'),
      renderCell: ({ name }) => getScopeDescription(name),
    },
    {
      id: 'read',
      header: t('publicApi.scopes.read'),
      width: '20%',
      renderCell(scope) {
        return (
          <CheckboxInput
            aria-label={getScopeName(scope.name)}
            isChecked={selectedScopes.has(scope.name)}
            onChange={() => onScopeChange(scope.name)}
            isDisabled={mode === 'view'}
          />
        );
      },
    },
  ];

  const getScopeName = getPublicApiScopeTranslation({
    scopeEntries: scopes,
    type: 'title',
  });
  const getScopeDescription = getPublicApiScopeTranslation({
    scopeEntries: scopes,
    type: 'description',
  });

  return (
    <>
      <h3 className="mb-8 title-m">{t('publicApi.scopes.title')}</h3>
      <p className="mb-24 text-secondary-bg-primary body-m">
        {t('publicApi.scopes.description')}
      </p>
      <Table data={scopes} columns={columns} getRowId={(scope) => scope.name} />
      {formError && (
        <Callout title={formError} variant="alert" className="mt-8" />
      )}
    </>
  );
};

export default ScopesForm;
