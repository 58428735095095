import React from 'react';

import { ProtectedRoute } from 'common/components/ProtectedRoute';
import { useUser } from 'modules/app/hooks/useUser';
import { OnboardingHub } from 'modules/onboarding/onboarding-hub';
import { routes } from 'src/core/constants/routes';

import { TopBanners } from './components/TopBanners/TopBanners';

export const OnboardingHubLayoutContainer = () => {
  const user = useUser();

  return (
    <>
      <TopBanners />
      <ProtectedRoute
        path={routes.ONBOARDING_HUB.path}
        isAccessAllowed={user.is_account_owner || user.is_admin}
      >
        <OnboardingHub />
      </ProtectedRoute>
    </>
  );
};
