import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useUser } from 'modules/app/hooks/useUser';
import { TopBanner } from 'src/core/common/components/TopBanner/TopBanner';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';

import { useFetchCompanyBillingStatus } from '../hooks/useFetchCompanyBillingStatus';

export const OverdueInvoiceNotification = () => {
  const { t } = useTranslation('global');
  const user = useUser();
  const company = useCompany();
  const fundWalletUrl = routeFor(routes.COMPANY_BANK_FUNDING.path, {
    company: company.id,
  });

  const handleButtonClick = () => {
    window.location.href = fundWalletUrl;
  };

  const billingStatusQueryState = useFetchCompanyBillingStatus(company.id);

  if (billingStatusQueryState.status !== 'success') {
    return null;
  }

  if (!user.is_account_owner && !user.is_controller) {
    return null;
  }

  const billingStatus = billingStatusQueryState.data;

  const isCompanyAtRisk =
    billingStatus &&
    billingStatus.status?.billingInvoicePaymentStatus === 'atRisk';

  if (isCompanyAtRisk) {
    return (
      <TopBanner
        title={`${t('overdueInvoice.notification.title')}. ${t('overdueInvoice.notification.description')}`}
        variant="alert"
      >
        <Button
          variant="secondaryNeutral"
          text={t('overdueInvoice.notification.cta')}
          onClick={handleButtonClick}
        />
      </TopBanner>
    );
  }

  return null;
};
