import { Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import styles from './Stepper.module.css';

type Props = {
  hasSpendeskLogo?: boolean;
  steps: string[];
  currentStepIndex: number;
};

export function StepperHeader({
  hasSpendeskLogo,
  steps,
  currentStepIndex,
}: Props) {
  return (
    <header className="absolute left-0 top-0 mb-16 h-[80px] w-full border-0 border-b border-solid border-default">
      {hasSpendeskLogo && (
        <img
          src="/static/img/spendesk-black-logo.svg"
          width={143}
          alt="Spendesk logo"
          className={styles.spendeskLogo}
        />
      )}
      <ul className="m-auto flex h-full w-[632px] justify-between">
        {steps.map((step, index) => (
          <>
            <li key={step} className="flex items-center">
              <div className="mx-8 flex items-center">
                <div
                  className={cx('flex justify-center rounded-[100%] p-4', {
                    'bg-primary-brand-default text-complementary':
                      index <= currentStepIndex,
                    'border-[1px] border-solid border-default':
                      index > currentStepIndex,
                  })}
                >
                  {index < currentStepIndex && <Icon size="s" name="check" />}
                  {index === currentStepIndex && (
                    <Icon size="s" name="arrow-down" />
                  )}
                  {index > currentStepIndex && (
                    <span className="h-[14px] w-[14px]" />
                  )}
                </div>
                <span
                  className={cx('ml-8 flex-grow body-m', {
                    'text-secondary-bg-primary': index > currentStepIndex,
                  })}
                >
                  {step}
                </span>
              </div>
            </li>
            {index < steps.length - 1 && (
              <div className="separator flex-1 self-center" />
            )}
          </>
        ))}
      </ul>
    </header>
  );
}
