import { useMutation } from 'react-query';

import { useCompany } from 'modules/app/hooks/useCompany';
import { apiV2Url } from 'src/core/utils/api';

export type RequestsApprovals = {
  successIds: string[];
  errorIds: string[];
};

export const useRequestsApprovalsMutation = () => {
  const company = useCompany();
  return useMutation(
    ['v2', 'requests', 'approvals'],
    async (requestIds: string[]): Promise<{ data: RequestsApprovals }> => {
      const response = await fetch(
        apiV2Url('/requests/approvals', company.id),
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ requestIds }),
        },
      );
      if (!response.ok) {
        throw new Error('Failed to approve requests');
      }
      return response.json();
    },
    {
      retry: false,
    },
  );
};
