import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

import { taxAccountToLabel } from './reshaper';

/**
 * @public
 */
export type RawTaxAccount = {
  id: string;
  name: string;
  code: string;
  rate: string;
  isArchived: boolean;
  isDefault: boolean;
};

/**
 * @public
 */
export type TaxAccount = {
  id: string;
  name: string;
};

export type SearchTaxAccountsRawResponse = RawTaxAccount[];

export const useSearchTaxAccounts = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();
  const { t } = useTranslation('global');

  return async (search: string): Promise<TaxAccount[]> => {
    const data = await queryClient.fetchQuery(
      ['useSearchTaxAccounts', companyId, search],
      getFetcher<SearchTaxAccountsRawResponse>({
        companyId,
        getRequest: () => ({
          type: 'rest',
          target: 'companyAPI',
          endpoint: `/accounting-integration/chart-of-accounts/tax-accounts`,
          params: {
            search,
          },
          method: 'get',
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return reshapeFilterValues(data, t);
  };
};

/**
 * Reshapers
 */

const reshapeFilterValues = (
  nodes: SearchTaxAccountsRawResponse,
  t: TGlobalFunctionTyped,
): TaxAccount[] => {
  return nodes
    .map((node) =>
      node.isArchived
        ? null
        : {
            id: node.id,
            name: taxAccountToLabel(node, t),
          },
    )
    .filter((item): item is TaxAccount => item !== null);
};
