import { Button, SkeletonButton, SkeletonTag } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { apiUrls } from 'src/core/config';
import { useNotifications } from 'src/core/modules/app/notifications';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { logger } from 'src/utils/datadog-log-wrapper';

import {
  PIN_REVEAL_IDS,
  TIMEOUT_IN_SECONDS,
  bootstrapMarqetaPinReveal,
} from './bootstrapMarqetaPinReveal';
import { loadMarqetaScriptV2 } from './loadMarqetaScriptV2';
import lock from './lock.svg';

type Props = {
  onClose: () => void;
  cardId?: string;
  companyId: string;
};

const gentlyDestroyMarqetaPinReveal = () => {
  if (window.marqeta && typeof window.marqeta.destroy === 'function') {
    window.marqeta.destroy();
  }
};

export const SFSRevealPinContent = ({ onClose, cardId, companyId }: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);

  const [count, setCount] = useState(TIMEOUT_IN_SECONDS);
  const [isRunning, toggleIsRunning] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const isWebviewActive = urlParams.has('webview');

  useInterval(
    () => {
      if (count > 1) {
        setCount(Math.max(0, count - 1));
      }
      if (count <= 1) {
        onClose();
      }
    },
    isRunning ? 1000 : null,
  );

  useEffect(() => {
    if (!cardId) {
      return;
    }

    const loading = async () => {
      try {
        setIsLoading(true);

        // Loading the iframe if not already present in the DOM
        await loadMarqetaScriptV2();

        // Launching the marqeta pin reveal flow
        await bootstrapMarqetaPinReveal({
          companyId,
          cardId,
          apiUrl: apiUrls.api,
          onRevealSuccess: () => {
            track(AnalyticEventName.CARD_PIN_REVEAL_SUCCESS);
            toggleIsRunning(true);
          },
          onIframeFailure: () => {
            logger.info('Failure when loading marqeta pin reveal iframe', {
              team: 'travel-and-expenses',
              scope: 'card::marqeta-pin-reveal',
            });
            dangerNotif(t('card.revealPinError'));
          },
          onIframeSuccess: () => {
            // Not adding a tracking for the success (as it's the happy path)
            setIsLoading(false);
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    };
    loading();

    return gentlyDestroyMarqetaPinReveal;
  }, [cardId]);

  return (
    <div
      className={classNames(
        'relative flex h-full w-full flex-col items-center justify-center',
        { 'bg-gradient-to-r from-[#4B1BAF] to-[#5D21D2]': !isRunning },
      )}
    >
      <div
        className={classNames(
          'mt-[90px] flex w-full flex-col items-center justify-center',
          { hidden: !isRunning },
        )}
      >
        <div className="text-primary title-m">
          {t('card.revealPinDescription')}
        </div>
        <div
          className="relative left-[96px]"
          id={PIN_REVEAL_IDS.cardPin}
          data-pin="hidden"
        />
      </div>
      {!isRunning && (
        <div className="flex flex-col items-center">
          <img src={lock} alt="" width="137" height="138" />
          {isLoading ? (
            <SkeletonTag className="mb-24 mt-16" width="296px" />
          ) : (
            <span className="mb-24 mt-16 text-complementary body-m">
              {t('card.revealPinContentText')}
            </span>
          )}
        </div>
      )}
      <div
        className={classNames('', {
          hidden: isRunning,
          relative: !isWebviewActive,
          absolute: isWebviewActive,
          'bottom-[4%] w-full px-24': isWebviewActive,
        })}
      >
        <div id={PIN_REVEAL_IDS.toggleCardPin} />
        {isLoading ? (
          <SkeletonButton width={isWebviewActive ? '100%' : '220px'} />
        ) : (
          <Button
            fit="parent"
            text={t('card.revealPin')}
            onClick={() => {
              logger.error(
                'User was able to click on the revealPin button without Marqeta iframe on top',
                {
                  team: 'travel-and-expenses',
                  scope: 'card::marqeta-pin-reveal',
                },
              );
              dangerNotif(t('card.revealPinError'));
            }}
            variant="secondaryNeutral"
          />
        )}
      </div>
      {isRunning && (
        <span className="absolute bottom-0 left-0 right-0 pb-40 text-center text-primary body-m">
          {t('card.revealPinTimeoutLabel', { count })}
        </span>
      )}
    </div>
  );
};
