import { Button } from '@dev-spendesk/grapes';

import { TopBanner } from 'common/components/TopBanner/TopBanner';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { useHostedOnboarding } from 'modules/kyc/hooks/useHostedOnboarding';
import { useKycStatusCode } from 'modules/kyc/hooks/useKycStatusCode';
import { usePartnerOnboardingErrorsQuery } from 'modules/kyc/hooks/usePartnerOnboardingErrorsQuery';
import FEATURES from 'src/core/constants/features';

export const MissingKycInformationBanner = () => {
  const isFeatureEnabled = useFeature(FEATURES.TMP_ADYEN_MISSING_INFORMATION);

  const company = useCompany();
  const kycStatus = useKycStatusCode();
  const user = useUser();

  const { t } = useTranslation('global');

  const isUKCompany = company.currency === 'GBP'
  const isAo = user.is_account_owner;
  const isKycCompleted = kycStatus === 'completed';

  const missingInformations = usePartnerOnboardingErrorsQuery({
    isEnabled: isAo && isUKCompany && isFeatureEnabled && isKycCompleted,
  });
  const hostedOnboarding = useHostedOnboarding(window.location.href, {
    isEnabled: isAo && isUKCompany && isFeatureEnabled && isKycCompleted,
    /**
     * The link has an expiration of 15min, refetch it every 10min
     */
    refetchInterval: 10_000 * 60,
  });

  const hasMissingInformation =
    missingInformations.status === 'success' &&
    missingInformations.data.partnerOnboardingErrors.length > 0;

  /**
   * This banner should only be visible to the AO for UK companies. And only after
   * the KYC has been approved. This is to handle when Adyen is requesting additional
   * information after the onboarding.
   */
  if (!isUKCompany || !isAo || !isKycCompleted || !hasMissingInformation || !isFeatureEnabled) {
    return null;
  }

  const handleButtonClick = async () => {
    if (hostedOnboarding.status === 'success') {
      window.location.href = hostedOnboarding.data.url;
    }
  };

  return (
    <TopBanner
      variant="alert"
      testId="missing-kyc-information-banner"
      title={t('missingKycInformationBanner.someOfYourAccountCapabilitiesHaveBeenRevoked')}
    >
      <div>
        <Button
          data-testid="missing-kyc-information-banner:button"
          variant="secondaryNeutral"
          text={t('missingKycInformationBanner.updateMyInformation')}
          onClick={handleButtonClick}
        />
      </div>
    </TopBanner>
  )
};
