import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Stepper, StepperFooter } from 'common/components/Stepper';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { routeFor } from 'src/core/constants/routes';

import styles from './TaskStepLayout.module.css';
import { VideoBanner } from './components/VideoBanner';
import { type VideoPageModalProps } from '../VideoPageModal/types';

export type TaskStep = {
  label: I18nKey;
  path: string;
};

type Props = {
  children: React.ReactNode;
  childrenWidth?: string;
  description?: string;
  extraDescription?: React.ReactNode;
  isLastStep?: boolean;
  isNextDisabled?: boolean;
  onBack: () => void;
  backButtonText?: string;
  onNext: () => void;
  nextButtonText?: string;
  title: string | React.ReactNode;
  video?: Pick<VideoPageModalProps, 'title' | 'url'>;
  breadcrumbSteps?: TaskStep[];
};

export const TaskStepLayout = ({
  children,
  childrenWidth,
  description,
  extraDescription,
  isLastStep = false,
  isNextDisabled = false,
  onBack,
  backButtonText,
  onNext,
  nextButtonText,
  title,
  video,
  breadcrumbSteps,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const location = useLocation();

  const currentStepIndex = useMemo(() => {
    if (breadcrumbSteps) {
      return breadcrumbSteps.findIndex((step) => {
        const stepPath = routeFor(step.path, {
          company: companyId,
        });
        return location.pathname === stepPath;
      });
    }
    return -1;
  }, [breadcrumbSteps]);

  const nextButtonLabel = useMemo(() => {
    if (nextButtonText) {
      return nextButtonText;
    }
    return isLastStep ? t('misc.done') : t('misc.next');
  }, [nextButtonText, isLastStep]);

  const renderStepContent = () => {
    return (
      <>
        <div
          className={classNames(styles.body, {
            [styles.bodyWithHeader]: breadcrumbSteps,
            [styles.bodyWithoutHeader]: !breadcrumbSteps,
          })}
        >
          <div className={classNames(styles.section, styles.sectionLeft)}>
            <div className="my-auto">
              <h1 className="title-xxl">{title}</h1>
              {description && (
                <p className="mt-24 text-secondary-bg-primary body-l">
                  {description}
                </p>
              )}
              {extraDescription && (
                <div className="mt-24">{extraDescription}</div>
              )}
              {video && (
                <div className="mt-48">
                  <VideoBanner {...video} />
                </div>
              )}
            </div>
          </div>
          <div className={classNames(styles.section, styles.sectionRight)}>
            <div className={styles.children} style={{ width: childrenWidth }}>
              {children}
            </div>
          </div>
        </div>
        <StepperFooter
          isNextDisabled={isNextDisabled}
          onNext={onNext}
          onBack={onBack}
          backLabel={backButtonText}
          nextLabel={nextButtonLabel}
        />
      </>
    );
  };

  return (
    <>
      {breadcrumbSteps ? (
        <Stepper
          stepsTitles={breadcrumbSteps.map((step) => t(step.label))}
          currentStepIndex={currentStepIndex}
          hasSpendeskLogo
        >
          {renderStepContent()}
        </Stepper>
      ) : (
        renderStepContent()
      )}
    </>
  );
};
