import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { useUpdatePayableFilterQueryCache } from './usePayableFilter';

/**
 * @public
 */
export type DeletePayableFilterError = unknown;

/**
 * @public
 */
export type DeletePayableFilterRawResponse = object;

/**
 * @public
 */
export type DeletePayableFilterResponse = void;

/**
 * @public
 */
export type DeletePayableFilterPayload = string;

/**
 * @public
 */
export type DeletePayableFilterParams = {
  filterId: string;
};

/**
 * Mutation
 **/

export const useDeletePayableFilter = (): MutationState<
  DeletePayableFilterPayload,
  DeletePayableFilterResponse,
  DeletePayableFilterError
> => {
  const updatePayableFilterQueryCache = useUpdatePayableFilterQueryCache();

  const [mutate, ...rest] = useMutation<
    { filterId: string; endpointParams: { filterId: string } },
    DeletePayableFilterRawResponse
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: (params) => {
        const filterId =
          params && typeof params === 'object' && 'filterId' in params
            ? params.filterId
            : '';
        if (!filterId) {
          throw new Error('Filter ID is required');
        }
        return `/bookkeeping/payables/filters/${filterId}`;
      },
      method: 'delete',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ payload }) => {
        updatePayableFilterQueryCache(payload.filterId);

        track(AnalyticEventName.PAYABLES_SAVED_FILTER_DELETED, {
          savedFilterId: payload.filterId,
          pageUrl: window.location.href,
        });
      },
    },
    reshapeData() {},
  });

  return [
    (filterId) => mutate({ filterId, endpointParams: { filterId } }),
    ...rest,
  ];
};
