import { Skeleton } from '@dev-spendesk/grapes';
import React from 'react';

import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';

import { TextFitter } from '../../../TextFitter';

const BLOCK_MIN_WIDTH = '145px';
const MIN_BLOCK_HEIGHT = '164px';

export const EntityItemActionsGroup = ({
  title,
  contents,
}: {
  title: string;
  contents: React.ReactNode[];
}) => (
  <div className="flex h-full flex-col gap-8 rounded-8">
    <TextFitter maxLines={2}>
      <EllipsisTooltip text={title} className="text-primary title-m" />
    </TextFitter>
    <div className="flex flex-col gap-8">
      {React.Children.map(contents, (content) => (
        <div key={`entity-item-actions-group-${content?.toString()}`}>
          {content}
        </div>
      ))}
    </div>
  </div>
);

export const EntityStatisticBlockSkeleton = () => (
  <div
    style={{
      minHeight: MIN_BLOCK_HEIGHT,
      minWidth: BLOCK_MIN_WIDTH,
      width: '100%',
    }}
  >
    <Skeleton width="100%" height="100%" className="flex rounded-8" />
  </div>
);
