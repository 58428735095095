import { Button } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import { HubTaskWrapper } from 'modules/onboarding/components/HubTaskWrapper';
import { type HubTask } from 'modules/onboarding/types/hubTask';
import { routeFor } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { routes } from '../../../routes';

type Props = {
  task: HubTask;
};

export const CompanySetupTask = ({ task }: Props) => {
  const history = useHistory();
  const { t } = useTranslation('global');
  const user = useUser();

  const companyId = useCompanyId();
  const isDisabled =
    task.status === 'disabled' ||
    (!!task.assignedUserId && task.assignedUserId !== user.id);
  const isCompleted = task.status === 'completed';

  const startTask = () => {
    const path = routeFor(routes.COMPANY_SETUP_APPROVAL_POLICY.path, {
      company: companyId,
    });
    history.push(path);
  };

  return (
    <HubTaskWrapper
      title={t('onboardingHub.accountSetupTask.companySetupSubTask.title')}
      showTagStatus
      description={t(
        'onboardingHub.accountSetupTask.companySetupSubTask.description',
      )}
      disabledMessage={t(
        'onboardingHub.accountSetupTask.companySetupSubTask.disabledMessage',
      )}
      task={task}
      duration="10"
      actions={[
        !isCompleted && (
          <Button
            variant="secondaryNeutral"
            key="company-setup"
            isDisabled={isDisabled}
            onClick={startTask}
            text={t('onboardingHub.accountSetupTask.companySetupSubTask.cta')}
          />
        ),
      ]}
    />
  );
};
