import { HighlightIcon } from '@dev-spendesk/grapes';

import { type RequestType } from 'src/core/modules/requests/models/requestType';
import { processUnexpectedValue } from 'src/core/utils/switchGuard';
import { logger } from 'src/utils/datadog-log-wrapper';

type Props = {
  requestType: RequestType;
};

export const RequestsToApproveListType = ({ requestType }: Props) => {
  switch (requestType) {
    case 'card_load':
    case 'subscription_increase':
      return <HighlightIcon size={32} name="card-arrow-up" variant="purple" />;
    case 'single_purchase':
      return <HighlightIcon size={32} name="card-lightning" variant="purple" />;
    case 'subscription':
      return (
        <HighlightIcon size={32} name="card-rounded-arrows" variant="purple" />
      );
    case 'credit_note':
    case 'invoice':
    case 'purchase_order':
      return <HighlightIcon size={32} name="invoice" variant="peach" />;
    case 'expense':
      return <HighlightIcon size={32} name="receipt" variant="lemon" />;
    case 'mileage_allowance':
      return <HighlightIcon size={32} name="car" variant="lemon" />;
    case 'per_diem_allowance':
      return <HighlightIcon size={32} name="globe-eu-africa" variant="lemon" />;
    default:
      processUnexpectedValue(requestType, () =>
        logger.error('Failed  to match the request type', {
          team: 'travel-and-expenses',
          scope: 'requests:to-approve',
          requestType,
        }),
      );
      return (
        <HighlightIcon size={32} name="receipt-question" variant="carbon" />
      );
  }
};
