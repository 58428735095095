import { SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const CustomFieldsLoader = () => {
  return (
    <>
      <div className="mb-16">
        <SkeletonText size="xl" width="150px" className="mb-8" />
        <SkeletonText />
      </div>
      <SkeletonTable
        numberOfRows={3}
        columns={[
          {
            header: <SkeletonText width="30%" />,
            cell: <SkeletonText width="50%" />,
            width: '50%',
          },
          {
            header: <SkeletonText width="30%" />,
            cell: <SkeletonText width="50%" />,
            width: '50%',
          },
        ]}
        rowHeight="compact"
        withHeader
      />
    </>
  );
};
