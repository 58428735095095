import { useParams } from 'react-router-dom';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { routes, routeFor } from 'src/core/constants/routes';
import history from 'src/core/history';

import { GraphQLProvider } from '../../components/GraphQLProvider';
import { PayableListContainer } from '../containers/PayableListContainer';
import { PayablePanelContainer } from '../containers/PayablePanelContainer';
import { PayablesFiltersContainer } from '../containers/PayablesFiltersContainer';
import { FiltersContextProvider } from '../containers/PayablesFiltersContainer/hooks';

export const PayablesAllPage = () => {
  const { id: payableId, company: companyId }: { id: string; company: string } =
    useParams();

  const goToPayable = (id: string = '') => {
    history.push(
      `${routeFor(`${routes.PAYABLES_ALL.path}`, { id, company: companyId })}${
        history.location.search
      }`,
    );
  };

  const panel = payableId && (
    <PayablePanelContainer
      payableId={payableId}
      onClose={() => goToPayable()}
    />
  );

  // @TODO: Not sure GraphQL is needed here, check if it can be removed
  return (
    <GraphQLProvider>
      <ErrorBoundary
        context={{ scope: 'all-payables', team: 'finance-accountant' }}
      >
        <FiltersContextProvider>
          <div className="page__container mx-40" style={{ overflowY: 'unset' }}>
            <PayablesFiltersContainer />

            <PayableListContainer payableId={payableId} onClick={goToPayable} />
            {panel}
          </div>
        </FiltersContextProvider>
      </ErrorBoundary>
    </GraphQLProvider>
  );
};
