import {
  type Dispatch,
  type AnyAction,
  type ThunkDispatch,
} from '@reduxjs/toolkit';
import i18next from 'i18next';

import { addNotification, NotificationType } from 'modules/app/notifications';
import { updateWireTransferActivationStatus } from 'modules/company/billing-legacy/redux/actions'; // FIXME circular dependency with company/billing-legacy module
import type { WireTransferPayableEntity } from 'modules/company/general-settings/models/wireTransfer';
import { postScaProcedure } from 'src/auth/modules/StrongCustomerAuthentication/api/postScaProcedure';
import { validateMfaProcedureWithSca } from 'src/auth/modules/StrongCustomerAuthentication/api/validateMfaProcedureWithSca';
import { baseAPI, companyAPI } from 'src/core/api/axios';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';

import * as types from './actionTypes';
import { type Procedure, type Factor } from './models';

export type RequestMfaProcedureSuccess = {
  type: types.REQUEST_MFA_PROCEDURE_SUCCESS;
  payload: Procedure;
};

export type RequestMfaProcedureLoading = {
  type: types.REQUEST_MFA_PROCEDURE_LOADING;
};

export type RequestMfaProcedureError = {
  type: types.REQUEST_MFA_PROCEDURE_ERROR;
};

export type ValidateMFATokenSuccess = {
  type: types.VALIDATE_MFA_TOKEN_SUCCESS;
};

export type ValidateMFATokenLoading = {
  type: types.VALIDATE_MFA_TOKEN_LOADING;
};

export type ValidateMFATokenError = {
  type: types.VALIDATE_MFA_TOKEN_ERROR;
};

export type ToggleWireTransferActivationSuccess = {
  type: types.TOGGLE_WIRETRANSFER_ACTIVATION_SUCCESS;
};

export type ToggleWireTransferActivationError = {
  type: types.TOGGLE_WIRETRANSFER_ACTIVATION_ERROR;
};

export type ToggleWireTransferActivationLoading = {
  type: types.TOGGLE_WIRETRANSFER_ACTIVATION_LOADING;
};

type ResetTokenVerificationStates = {
  type: types.RESET_TOKEN_VERIFICATION_STATES;
};

type RegenerateProcedureSuccess = {
  type: types.REGENERATE_PROCEDURE_SUCCESS;
  payload: Procedure;
};

type RegenerateProcedureError = {
  type: types.REGENERATE_PROCEDURE_ERROR;
};

type RegenerateProcedureLoading = {
  type: types.REGENERATE_PROCEDURE_LOADING;
};

type FetchPhoneFactorSuccess = {
  type: types.FETCH_PHONE_FACTOR_SUCCESS;
  payload: Factor;
};

type FetchPhoneFactorError = {
  type: types.FETCH_PHONE_FACTOR_ERROR;
};

type FetchPhoneFactorLoading = {
  type: types.FETCH_PHONE_FACTOR_LOADING;
};

export type MFAAction =
  | RequestMfaProcedureLoading
  | RequestMfaProcedureSuccess
  | RequestMfaProcedureError
  | ValidateMFATokenSuccess
  | ValidateMFATokenLoading
  | ValidateMFATokenError
  | ToggleWireTransferActivationSuccess
  | ToggleWireTransferActivationError
  | ToggleWireTransferActivationLoading
  | ResetTokenVerificationStates
  | RegenerateProcedureSuccess
  | RegenerateProcedureError
  | RegenerateProcedureLoading
  | FetchPhoneFactorSuccess
  | FetchPhoneFactorError
  | FetchPhoneFactorLoading;

export const fetchPhoneFactor = () => async (dispatch: Dispatch<MFAAction>) => {
  dispatch(fetchPhoneFactorLoading());

  try {
    const type = 'phone';
    const { data } = await baseAPI.get(`/factors?type=${type}`);
    const phoneFactor = data.find((factor: Factor) => factor.type === type);

    dispatch(fetchPhoneFactorSuccess(phoneFactor));

    if (phoneFactor && phoneFactor.procedureId) {
      dispatch(
        requestMfaProcedureSuccess({
          id: phoneFactor.procedureId,
          factorId: phoneFactor.id,
        }),
      );
    }
  } catch (error) {
    dispatch(fetchPhoneFactorError());
    throw error;
  }
};

export const fetchPhoneFactorSuccess = (
  activeFactor: Factor,
): FetchPhoneFactorSuccess => ({
  type: types.FETCH_PHONE_FACTOR_SUCCESS,
  payload: activeFactor,
});
export const fetchPhoneFactorLoading = (): FetchPhoneFactorLoading => ({
  type: types.FETCH_PHONE_FACTOR_LOADING,
});
const fetchPhoneFactorError = (): FetchPhoneFactorError => ({
  type: types.FETCH_PHONE_FACTOR_ERROR,
});

export const requestMfaProcedure =
  (phoneNumber: string, token?: string) =>
  async (dispatch: ThunkDispatch<AppState, null, MFAAction>) => {
    dispatch(resetTokenVerificationStates());
    dispatch(requestMfaProcedureLoading());
    let resp;

    try {
      const type = 'phone';
      const factorsResp = await baseAPI.get(`/factors?type=${type}`);
      const factors: Factor[] = factorsResp.data;
      const payload = {
        params: phoneNumber,
        type,
        token,
      };

      if (factors.length === 1) {
        const factor = factors[0];
        resp = await baseAPI.put(`/factors/${factor.id}`, payload);
      } else {
        resp = await baseAPI.post(`/factors`, payload);
      }
    } catch (error) {
      dispatch(requestMfaProcedureError());
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18next.t('errors:somethingWrong_short'),
        }),
      );
      throw error;
    }

    dispatch(requestMfaProcedureSuccess(resp.data));
    dispatch(fetchPhoneFactorSuccess(resp.data.factor));
  };

export const requestMfaProcedureSuccess = (
  procedure: Procedure,
): RequestMfaProcedureSuccess => ({
  type: types.REQUEST_MFA_PROCEDURE_SUCCESS,
  payload: procedure,
});
export const requestMfaProcedureLoading = (): RequestMfaProcedureLoading => ({
  type: types.REQUEST_MFA_PROCEDURE_LOADING,
});
export const requestMfaProcedureError = (): RequestMfaProcedureError => ({
  type: types.REQUEST_MFA_PROCEDURE_ERROR,
});

export const validateMFAToken =
  (token: string, flow: 'auth' | 'standard', scaToken?: string) =>
  async (
    dispatch: ThunkDispatch<AppState, null, MFAAction>,
    getState: () => AppState,
  ): Promise<void> => {
    dispatch(validateMFATokenLoading());

    try {
      if (flow === 'standard') {
        const procedureId = getState().mfaActivation.procedure.id;

        await baseAPI.post(
          `/multifactor-authenticators/${procedureId}/validation?token=${token}`,
        );
      }

      if (flow === 'auth' && scaToken) {
        await validateMfaProcedureWithSca(token, scaToken);
      }

      dispatch(validateMFATokenSuccess());
    } catch (error) {
      if (error.response.status !== 400) {
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18next.t('errors:somethingWrong_short'),
          }),
        );
        dispatch(validateMFATokenError());
        throw error;
      } else {
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18next.t('wiretransfer.modal.errors.wrongCode'),
          }),
        );
        dispatch(validateMFATokenError());
      }
    }
  };

export const validateMFATokenLoading = (): ValidateMFATokenLoading => ({
  type: types.VALIDATE_MFA_TOKEN_LOADING,
});
export const validateMFATokenError = (): ValidateMFATokenError => ({
  type: types.VALIDATE_MFA_TOKEN_ERROR,
});
export const validateMFATokenSuccess = (): ValidateMFATokenSuccess => ({
  type: types.VALIDATE_MFA_TOKEN_SUCCESS,
});

export const activateWireTransfer =
  (wireTransferPayableEntity: WireTransferPayableEntity) =>
  async (
    // FIXME: we should have a type for `fetchWireTransferActivationStatusSuccess` action
    // instead of using `AnyAction`
    dispatch: ThunkDispatch<AppState, null, MFAAction | AnyAction>,
    getState: () => AppState,
  ): Promise<void> => {
    dispatch(toggleWireTransferActivationLoading());
    const state = getState();
    const companyId = getCompanyId(state);

    const typeParameter =
      wireTransferPayableEntity === 'expenses' ? 'expenseClaim' : 'invoice';
    try {
      const { data } = await companyAPI.post(
        `/features/wiretransfer?activate=true&type=${typeParameter}`,
        undefined,
        { companyId },
      );
      dispatch(resetTokenVerificationStates());
      dispatch(
        updateWireTransferActivationStatus({
          wireTransferPayableEntity,
          status: data,
        }),
      );
      dispatch(toggleWireTransferActivationSuccess());
    } catch (error) {
      dispatch(toggleWireTransferActivationError());
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18next.t('errors:somethingWrong_short'),
        }),
      );
      throw error;
    }
  };

export const toggleWireTransferActivationLoading =
  (): ToggleWireTransferActivationLoading => ({
    type: types.TOGGLE_WIRETRANSFER_ACTIVATION_LOADING,
  });
export const resetTokenVerificationStates =
  (): ResetTokenVerificationStates => ({
    type: types.RESET_TOKEN_VERIFICATION_STATES,
  });
export const toggleWireTransferActivationError =
  (): ToggleWireTransferActivationError => ({
    type: types.TOGGLE_WIRETRANSFER_ACTIVATION_ERROR,
  });

export const toggleWireTransferActivationSuccess =
  (): ToggleWireTransferActivationSuccess => ({
    type: types.TOGGLE_WIRETRANSFER_ACTIVATION_SUCCESS,
  });

export const regenerateProcedure =
  (flow: 'auth' | 'standard', scaToken?: string) =>
  async (
    dispatch: ThunkDispatch<AppState, null, MFAAction>,
    getState: () => AppState,
  ): Promise<void> => {
    dispatch(resetTokenVerificationStates());

    try {
      if (flow === 'standard') {
        dispatch(regenerateProcedureLoading());

        const procedureId = getState().mfaActivation.procedure.id;

        const { data } = await baseAPI.post(
          `/multifactor-authenticators/${procedureId}/regeneration`,
        );

        dispatch(regenerateProcedureSuccess(data));
      }

      if (flow === 'auth' && scaToken) {
        await postScaProcedure(scaToken, true);
      }
    } catch (error) {
      dispatch(regenerateProcedureError());
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18next.t('wiretransfer.modal.errors.regenerateProcedure'),
        }),
      );
      throw error;
    }
  };

export const regenerateProcedureLoading = (): RegenerateProcedureLoading => ({
  type: types.REGENERATE_PROCEDURE_LOADING,
});
const regenerateProcedureError = (): RegenerateProcedureError => ({
  type: types.REGENERATE_PROCEDURE_ERROR,
});
export const regenerateProcedureSuccess = (
  procedure: Procedure,
): RegenerateProcedureSuccess => ({
  type: types.REGENERATE_PROCEDURE_SUCCESS,
  payload: procedure,
});
