import { colors, DATE_FORMAT, Icon, Tooltip } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { formatMoney } from 'src/core/utils/money';

import { type MonthStats } from '../../graphql/allPayments/stats';

type Props = {
  paymentStat: MonthStats;
};

export const PaymentTableGroup = ({ paymentStat }: Props) => {
  const { t, localeFormat } = useTranslation('global');

  const companyCurrency = useCompanyCurrency();

  return (
    <div className="flex justify-between py-8 uppercase text-secondary-bg-secondary body-s">
      {paymentStat.year !== null && paymentStat.month !== null ? (
        <span>
          {localeFormat(
            new Date(paymentStat.year, paymentStat.month),
            DATE_FORMAT.CUSTOM,
            {
              month: 'short',
              year: 'numeric',
            },
          )}
        </span>
      ) : (
        <span className="flex items-center gap-8">
          {t('payments.pendingTransactions')}
          <Tooltip
            content={t('payments.pendingTransactionsNotice')}
            placement="bottom"
            triggerAsChild
          >
            <Icon
              name="clock-filled"
              color={colors.contentDecorativeIcon}
              size="s"
            />
          </Tooltip>
        </span>
      )}
      <span className="flex items-center gap-8">
        <span>
          {t('misc.paymentWithCount', { count: paymentStat.total_count })}
        </span>
        <span className="text-disabled">•</span>
        <span>
          {formatMoney(
            paymentStat.total_amount_billed + paymentStat.total_fx_fee_amount,
            companyCurrency,
          )}
          {paymentStat.total_fx_fee_amount !== 0 &&
            ` (${t('misc.includingFxFeesWithAmount', {
              amount: formatMoney(
                paymentStat.total_fx_fee_amount,
                companyCurrency,
              ),
            })})`}
        </span>
      </span>
    </div>
  );
};
