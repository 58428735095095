import { type ApprovalRule } from 'src/core/modules/company/structure/approval-flows';

export const defaultApprovalRules: ApprovalRule[] = [
  {
    id: '1',
    from: {
      value: null,
      currency: 'EUR',
    },
    upTo: {
      value: null,
      currency: 'EUR',
    },
    steps: [
      {
        rights: [],
      },
    ],
  },
];
