import { compose } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { type Company } from 'modules/app/hooks/useCompany';
import { type User } from 'modules/app/hooks/useUser';
import { addNotification } from 'modules/app/notifications';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { type Team } from 'modules/members/models/teams';
import type { CustomFieldDefinition } from 'modules/requests/models/customFieldDefinition';
import { type MileageScheme } from 'modules/requests/models/mileageScheme';
import * as actions from 'modules/requests/redux/legacy/actions';
import { initialFiltersState } from 'modules/requests/redux/legacy/reducer';
import { getSearchFilters } from 'modules/requests/redux/legacy/selectors';
import type { RequestAPI } from 'modules/requests/types';
import { getRequestTabFromUrlParams } from 'modules/requests/utils/getRequestTabFromUrlParams';
import {
  hasApproverSubNav,
  isDraftsTab,
  SubnavigationItem,
} from 'modules/requests/utils/navigation';
import { fetchCustomFields } from 'src/core/actions/customFields';
import { uploadFile } from 'src/core/actions/files';
import { fetchGroups } from 'src/core/actions/groups';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { routes } from 'src/core/constants/routes';
import { type AppState } from 'src/core/reducers';
import { getImpersonator } from 'src/core/selectors/globalSelectors';
import { getUsers } from 'src/core/selectors/users';

import Requests from './Requests';
import {
  type Sections,
  stateToSectionsProps,
  type RequestStats,
} from './requestsSectionsProps';

const usePrevious = <T,>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  // @ts-expect-error: Not an helpful comment
  return ref.current ?? initialFiltersState;
};

type Props = {
  sectionsStats: Sections<RequestStats>;
  requestsSections: Sections<
    (RequestAPI & { mileage_scheme: MileageScheme })[]
  >;
  // additional data
  company: Company;
  user: User;
  groups?: Team[];
  customFields?: CustomFieldDefinition[];
  behavior: {
    isLoading: boolean;
    errors: null | Record<string, unknown>;
  };
  searchFilters?: {
    search?: string;
  };
  // functions
  fetchGroupsFromProps: () => void;
  fetchRequestsSections: (filters: unknown, groupBy: unknown) => void;
  fetchRequestsSectionsStats: (filters: unknown, groupBy: unknown) => void;
  addRequestLocally: () => void;
  approveRequest: () => Promise<void>;
  updateRequest: () => Promise<void>;
  updateRequestLocally: () => void;
  removeRequestLocally: () => void;
  fetchCustomFieldsFromProps: (bool: boolean) => Promise<void>;
  setRequestFilters: () => void;
  setTextFilter: () => void;
  resetTextFilter: () => void;
  loadCardRequest: () => Promise<void>;
  uploadFileFromProps: () => void;
  pushNotif: () => void;
  fetchDraftRequests: () => Promise<void>;
};

const RequestsContainer = ({
  addRequestLocally,
  approveRequest,
  fetchCustomFieldsFromProps,
  fetchDraftRequests,
  fetchGroupsFromProps,
  fetchRequestsSections,
  fetchRequestsSectionsStats,
  loadCardRequest,
  pushNotif,
  removeRequestLocally,
  resetTextFilter,
  setRequestFilters,
  setTextFilter,
  uploadFileFromProps,
  updateRequest,
  updateRequestLocally,
  company,
  searchFilters,
  user,
  sectionsStats,
  requestsSections,
  customFields,
  groups,
  behavior,
}: Props) => {
  const match = useRouteMatch(routes.REQUESTS.path);
  const history = useHistory();
  const [lastType, setLastType] = useState(match.params.type);
  const lastSearchFilters = usePrevious(searchFilters);

  const costCentersQuery = useCostCentersQuery();
  const costCenters = unwrapQuery(costCentersQuery) ?? [];

  const isToApproveTabRevampEnabled = useFeature(
    FEATURES.TMP_REQUESTS_TO_APPROVE_TAB_REVAMP,
  );

  useEffect(() => {
    fetchGroupsFromProps();
    fetchCustomFieldsFromProps(true);

    resetTextFilter();

    setLastType(match.params.type);
    if (type === 'to-approve' && isToApproveTabRevampEnabled) {
      return;
    }
    fetchData(match.params.type);
  }, []);

  useEffect(() => {
    if (type === 'to-approve' && isToApproveTabRevampEnabled) {
      if (match.params.type !== lastType) {
        setLastType(match.params.type);
      }
      return;
    }
    if (match.params.type === 'drafts' && searchFilters?.search) {
      resetTextFilter();
    }

    if (match.params.type !== lastType) {
      setLastType(match.params.type);
      fetchData(match.params.type);
    }
  }, [match.params.type]);

  useEffect(() => {
    if (type === 'to-approve' && isToApproveTabRevampEnabled) {
      return;
    }
    if (!isEqual(searchFilters, lastSearchFilters)) {
      fetchData(match.params.type);
    }
  }, [JSON.stringify(searchFilters)]);

  const fetchData = (typeFromQueryParams: SubnavigationItem) => {
    if (isDraftsTab(typeFromQueryParams)) {
      fetchDraftRequests();
    } else {
      const filters = getFilters(typeFromQueryParams);
      const groupBy = getGroupBy(typeFromQueryParams);

      fetchRequestsSections(filters, groupBy);
    }
  };

  const handleFetchRequestsSectionsStats = () => {
    const { type } = match.params;
    const filters = getFilters(type);
    const groupBy = getGroupBy(type);
    fetchRequestsSectionsStats(filters, groupBy);
  };

  const getFilters = (type: SubnavigationItem) => {
    const filters = searchFilters;
    if (type === SubnavigationItem.Mine) {
      return {
        ...filters,
        requester: [user.id],
      };
    }
    if (type === SubnavigationItem.ToApprove) {
      return {
        ...filters,
        toApprove: true,
      };
    }

    return filters;
  };

  const getGroupBy = (type: SubnavigationItem) => {
    return type === SubnavigationItem.All && hasApproverSubNav(user)
      ? 'type'
      : 'typeAndMineTeam';
  };

  const { newRequestType, type, id: requestId } = match.params;

  return (
    <Requests
      type={getRequestTabFromUrlParams(type)}
      company={company}
      user={user}
      sectionsStats={sectionsStats}
      requestsSections={requestsSections}
      hasFilters={
        searchFilters &&
        Object.values(searchFilters).some((value) => Boolean(value))
      }
      groups={groups}
      customFields={customFields}
      costCenters={costCenters}
      isLoading={behavior.isLoading}
      itemId={requestId}
      newRequestType={newRequestType}
      history={history}
      addRequestLocally={addRequestLocally}
      updateRequest={updateRequest}
      approveRequest={approveRequest}
      updateRequestLocally={updateRequestLocally}
      removeRequestLocally={async (...parameters: unknown[]) => {
        // @ts-expect-error: Not an helpful comment
        await removeRequestLocally(...parameters);
        handleFetchRequestsSectionsStats();
      }}
      loadCardRequest={loadCardRequest}
      uploadFile={uploadFileFromProps}
      pushNotif={pushNotif}
      setRequestFilters={setRequestFilters}
      setTextFilter={setTextFilter}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  const {
    global: { company, user },
    requests,
  } = state;
  const { groups, customFields, behavior, expenseCategory } = requests;

  return {
    // page content
    ...stateToSectionsProps(state),
    company,
    user,
    users: getUsers(state),
    groups,
    customFields,
    expenseCategory,
    impersonator: getImpersonator(state),
    // config and page states
    behavior,
    searchFilters: getSearchFilters(state),
  };
};

const mapDispatchToProps = {
  fetchRequestsSections: actions.fetchRequests,
  fetchRequestsSectionsStats: actions.fetchRequestsSectionsStats,
  addRequestLocally: actions.addRequestLocally,
  approveRequest: actions.approveRequest,
  updateRequest: actions.updateRequest,
  updateRequestLocally: actions.updateRequestLocally,
  removeRequestLocally: actions.removeRequestLocally,
  removeDraftRequestLocally: actions.removeDraftRequestLocally,
  loadCardRequest: actions.loadCardRequest,
  fetchDraftRequests: actions.fetchDraftRequests,
  fetchRequestsStats: actions.fetchRequestsStats,
  setRequestFilters: actions.setRequestFilters,
  setTextFilter: actions.setTextFilter,
  resetTextFilter: actions.resetTextFilter,
  fetchCustomFieldsFromProps: fetchCustomFields,
  fetchGroupsFromProps: fetchGroups,
  uploadFileFromProps: uploadFile,
  pushNotif: addNotification,
};

export default hot(
  compose<() => JSX.Element>(
    connect(mapStateToProps, mapDispatchToProps),
    withErrorBoundary({ scope: 'requests', team: 'travel-and-expenses' }),
  )(RequestsContainer),
);
