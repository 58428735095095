import { type TrackingConsent } from '@datadog/browser-core/src/domain/trackingConsent';
import { datadogLogs } from '@datadog/browser-logs';

import CONFIG from '../core/config';

if (CONFIG.datadog) {
  datadogLogs.init({
    clientToken: CONFIG.datadog.clientToken,
    site: 'datadoghq.eu',
    service: 'app-desktop',
    env: CONFIG.environmentName,
    sessionSampleRate: 100,
    version: process.env.REACT_APP_SHA,
    forwardErrorsToLogs: false,
    trackingConsent: 'not-granted',
  });
}

export const addSpendeskContextToDatadogLogs = ({
  userId,
  companyId,
  consent,
}: {
  userId: string;
  companyId: string;
  consent: TrackingConsent;
}) => {
  if (CONFIG.datadog && Boolean(datadogLogs.getInitConfiguration())) {
    if (consent) {
      datadogLogs.setGlobalContextProperty('userId', userId);
      datadogLogs.setGlobalContextProperty('companyId', companyId);
    }
    datadogLogs.setTrackingConsent(consent);
  }
};
