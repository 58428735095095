import { type Language } from 'src/core/config/i18n';

export const getNewReleaseBaseUrl = (lang: Language) => {
  switch (lang) {
    case 'fr':
      return 'https://www.spendesk.com/fr/platform/platform-releases/nouveautes-dhiver-2025?utm_source=product&utm_medium=post&utm_campaign=winter%20releases%202025';
    case 'de':
      return 'https://www.spendesk.com/de/platform/platform-releases/winter-updates-2025?utm_source=product&utm_medium=post&utm_campaign=winter%20releases%202025';
    default:
      return 'https://www.spendesk.com/en/platform/platform-releases/winter-updates-2025?utm_source=product&utm_medium=post&utm_campaign=winter%20releases%202025';
  }
};
