import { type TrackingConsent } from '@datadog/browser-core/src/domain/trackingConsent';
import { datadogRum } from '@datadog/browser-rum-slim';

import CONFIG from '../core/config';

if (CONFIG.datadog) {
  datadogRum.init({
    applicationId: CONFIG.datadog.appId,
    clientToken: CONFIG.datadog.clientToken,
    site: 'datadoghq.eu',
    service: 'app-desktop',
    env: CONFIG.environmentName,
    version: process.env.REACT_APP_SHA,
    sessionSampleRate: 20,
    trackLongTasks: true,
    trackUserInteractions: false,
    allowedTracingUrls: [
      CONFIG.apiUrls.base,
      CONFIG.apiUrls.api,
      CONFIG.apiUrls.auth,
    ],
    traceSampleRate: 20,
    trackingConsent: 'not-granted',
  });
}

export const addSpendeskContextToDatadogRum = ({
  userId,
  companyId,
  consent,
}: {
  userId: string;
  companyId: string;
  consent: TrackingConsent;
}) => {
  if (CONFIG.datadog && Boolean(datadogRum.getInitConfiguration())) {
    if (consent) {
      datadogRum.setUser({
        id: userId,
        companyId,
      });
    }
    datadogRum.setTrackingConsent(consent);
  }
};
