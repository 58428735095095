import { AutocompleteMultiple, FormField } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';

import { useWorkflowContext } from '../../WorkflowContext';
import { type Dimension } from '../../approvalWorkflow';
import { ApplyToAllSwitchField } from '../ApplyToAllSwitchField';

export type Option = {
  key: string;
  label: string;
};

type Props = {
  selectedValues: string[] | undefined | null;
  onSelect: (values: string[] | null) => void;
  baseOptions: Option[];
  placeholder: string;
  dimensionName: Dimension['type'];
};

export const CommonSelect = ({
  selectedValues,
  onSelect,
  baseOptions,
  placeholder,
  dimensionName,
}: Props) => {
  const { selectedNodeId, getSiblingNodes } = useWorkflowContext();

  // Compute sibling values (only for base nodes)
  const selectedSiblingNodesValues = selectedNodeId
    ? getSiblingNodes(selectedNodeId)
      .filter((node) => node.id !== selectedNodeId)
      .filter((node) => node.type === 'baseNode')
      .map((node) => node.data.values)
      .filter((values) => values !== undefined)
      .flat()
    : [];

  const getFilteredOptions = () =>
    baseOptions.filter(
      (option) => !selectedSiblingNodesValues.includes(option.key),
    );

  const [options, setOptions] = useState<Option[]>(getFilteredOptions());
  const [isApplyToAllSwitchDisplayed, setIsApplyToAllSwitchDisplayed] =
    useState(false);
  const [isApplyToAllChecked, setIsApplyToAllChecked] = useState(false);

  // Update toggle display and state based on current selection and sibling nodes.
  useEffect(() => {
    const hasNullSibling = selectedSiblingNodesValues.includes(null);
    const allValuesSelected =
      selectedValues === null || selectedValues?.length === options.length;
    setIsApplyToAllSwitchDisplayed(!hasNullSibling && allValuesSelected);
    setIsApplyToAllChecked(selectedValues === null);
  }, [selectedValues, options.length, selectedSiblingNodesValues]);

  useEffect(() => {
    const newOptions = getFilteredOptions();
    if (JSON.stringify(newOptions) !== JSON.stringify(options)) {
      setOptions(newOptions);
    }
  }, [selectedSiblingNodesValues, baseOptions]);

  const handleApplyToAllChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const checked = event.target.checked;
    setIsApplyToAllChecked(checked);
    if (checked) {
      onSelect(null);
    } else {
      onSelect(options.map((option) => option.key));
    }
  };

  // If "apply to all" is active, show all options; otherwise, only show the selected ones.
  const getValues = () => {
    if (isApplyToAllChecked) {
      return options;
    }
    return options.filter((option) => selectedValues?.includes(option.key));
  };

  return (
    <>
      {baseOptions.length > 0 && (
        <FormField className="text-left" label="Is">
          <AutocompleteMultiple
            fit="content"
            className="w-full"
            options={options}
            values={getValues()}
            translations={{
              selectAll: `Select all (${options.length})`,
              selected: getSelectedTranslation(
                selectedValues,
                options,
                dimensionName,
              ),
            }}
            onSearch={(value) => {
              if (!value) {
                setOptions(
                  baseOptions.map((option) => ({
                    ...option,
                    isDisabled: selectedSiblingNodesValues.includes(option.key),
                  })),
                );
                return;
              }
              setOptions(
                baseOptions
                  .filter((option) =>
                    option.label.toLowerCase().includes(value.toLowerCase()),
                  )
                  .map((option) => ({
                    ...option,
                    isDisabled: selectedSiblingNodesValues.includes(option.key),
                  })),
              );
            }}
            onSelect={(values) => {
              onSelect(values.map((value) => value.key));
            }}
            placeholder={placeholder}
          />
        </FormField>
      )}
      {isApplyToAllSwitchDisplayed && (
        <ApplyToAllSwitchField
          onChange={handleApplyToAllChange}
          isChecked={isApplyToAllChecked}
          dimensionName={dimensionName}
        />
      )}
    </>
  );
};

const getSelectedTranslation = (
  selectedValues: string[] | undefined | null,
  options: Option[],
  dimensionName: Dimension['type'],
) => {
  if (selectedValues === null) {
    return dimensionName === 'costCenter'
      ? 'All future cost centers'
      : 'All future spend types';
  }
  if (selectedValues && selectedValues.length > 0) {
    return selectedValues
      .map((value) => options.find((option) => option.key === value)?.label)
      .join(', ');
  }
  return '';
};
