import { z } from 'zod';

export const publicApiScopeListEntry = z.object({
  name: z.string(),
  translations: z.object({
    title: z.string(),
    description: z.string(),
  }),
  experimental: z.boolean().optional(),
});

export type PublicApiScopeListEntry = z.infer<typeof publicApiScopeListEntry>;

const getTranslationKeysByScopeName = (
  scopeEntries: PublicApiScopeListEntry[],
): Map<string, { title: string; description: string }> =>
  new Map(scopeEntries.map(({ name, translations }) => [name, translations]));

export const getPublicApiScopeTranslation = ({
  scopeEntries,
  type,
}: {
  scopeEntries: PublicApiScopeListEntry[];
  type: 'title' | 'description';
}) => {
  const scopeTranslationKeysByScopeName =
    getTranslationKeysByScopeName(scopeEntries);

  return (scopeName: string): string => {
    const translations = scopeTranslationKeysByScopeName.get(scopeName);

    if (!translations) {
      // TODO decide what to do in case of scope not found
      return '';
    }

    return translations[type];
  };
};
