import { Button, Modal, RadioGroup, RadioField } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { IconButtonText } from 'common/components/IconButtonText';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { NotificationType, useNotifications } from 'modules/app/notifications';

import { type CardStatus } from '../../card';
import { doCardAction } from '../../utils/card';

type ReportType = 'lost' | 'damaged' | 'stolen' | 'cancelled';

type Props = {
  card: {
    id: string;
    status: CardStatus;
  };
  onCardReported?: () => void; // call when the card has been reported successfully
};

export const CardReport = ({ card, onCardReported = () => {} }: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const company = useCompany();

  const [selectedReportType, setSelectedReportType] =
    useState<ReportType | null>(null);
  const [reportLoading, setReportLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const proceedToReport = () => {
    const allowedReportingTypes = ['lost', 'damaged', 'stolen', 'cancelled'];

    if (
      selectedReportType &&
      !reportLoading &&
      allowedReportingTypes.includes(selectedReportType)
    ) {
      setReportLoading(true);
      doCardAction(company.id, card.id, selectedReportType, null, (error) => {
        stopReportCard();

        if (!error) {
          pushNotif({
            message: t('cards.actions.successReport'),
          });
          onCardReported();
        } else {
          pushNotif({
            message: t('misc.somethingWrong'),
            type: NotificationType.Danger,
          });
        }
      });
    }
  };

  const reportCard = () => {
    setReportLoading(false);
    setIsModalOpen(true);
  };

  const stopReportCard = () => {
    setSelectedReportType(null);
    setReportLoading(false);
    setIsModalOpen(false);
  };

  const renderReportButton = () => {
    return (
      <IconButtonText
        text={t('cards.reportTitles.reportAndDelete')}
        iconName="circle-strikeout"
        onClick={reportCard}
      />
    );
  };

  const renderConfirmReportModal = () => {
    const reportTypes = [
      { value: 'lost', label: t('cards.reportDescriptions.lost') },
      { value: 'stolen', label: t('cards.reportDescriptions.stolen') },
      { value: 'damaged', label: t('cards.reportDescriptions.damaged') },
      { value: 'cancelled', label: t('cards.reportDescriptions.cancelled') },
    ];

    return (
      <Modal
        isOpen
        iconName="triangle-warning"
        iconVariant="alert"
        title={t('cards.reportTitles.report')}
        onClose={stopReportCard}
        actions={[
          <Button
            key="cancel"
            variant="secondaryNeutral"
            text={t('misc.cancel')}
            onClick={stopReportCard}
          />,
          <Button
            key="report"
            text={
              reportLoading
                ? t('cards.loadingReport')
                : t('cards.actions.report')
            }
            variant="primaryAlert"
            isLoading={reportLoading}
            isDisabled={!selectedReportType}
            onClick={proceedToReport}
          />,
        ]}
      >
        {t('cards.reportDescriptions.explanation')}{' '}
        {t('cards.reportDescriptions.warning')}
        <RadioGroup
          name="myRadioGroup"
          value={selectedReportType}
          className="grid grid-cols-2 gap-4"
          onChange={(event) =>
            setSelectedReportType(event.target.value as ReportType)
          }
          direction="row"
        >
          {reportTypes.map((props) => (
            <RadioField {...props} key={props.value} />
          ))}
        </RadioGroup>
      </Modal>
    );
  };

  return (
    <>
      {renderReportButton()}
      {isModalOpen && renderConfirmReportModal()}
    </>
  );
};
