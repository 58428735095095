import { DATE_FORMAT, Table, Tag } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PageNoResult } from 'common/components/PageNoResult/PageNoResultContainer';
import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import { type MileageScheme } from 'modules/requests/models/mileageScheme';
import type { RequestAPI } from 'modules/requests/types';
import { SubnavigationItem } from 'modules/requests/utils/navigation';
import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';
import { SupplierLogo } from 'src/core/common/components/SupplierLogo';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { getAmountToDisplay } from 'src/core/utils/entities/request';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';
import { formatMoney } from 'src/core/utils/money';

import { RequestsTableActions } from './RequestsTableActions';
import { getSection, sectionGroupByRequestType } from './utils';
import { RequestsListLoader } from '../RequestsListsLoader';
import type { RequestStats, Sections } from '../requestsSectionsProps';

export type Request = RequestAPI & {
  mileage_scheme: MileageScheme;
};

type DraftRequestsTableProps = {
  requests: Request[];
  sectionsStats: Sections<RequestStats>;
  type: SubnavigationItem | 'request';
  hasFiltersApplied?: boolean;
  isLoading?: boolean;
};

export const DraftRequestsTable = ({
  hasFiltersApplied,
  isLoading,
  requests,
  sectionsStats,
  type,
}: DraftRequestsTableProps) => {
  const user = useUser();
  const companyId = useCompanyId();
  const history = useHistory();
  const { localeFormat, t } = useTranslation('global');
  const [selectedRowIds, setSelectedRowsIds] = useState<string[]>([]);
  const [activeRequest, setActiveRequest] = useState('');

  if (isLoading) {
    return <RequestsListLoader />;
  }

  if (requests.length === 0) {
    return (
      <PageNoResult mode="draftRequest" hasFiltersApplied={hasFiltersApplied} />
    );
  }

  return (
    <ErrorBoundary
      context={{
        team: 'travel-and-expenses',
        scope: 'requests::list::drafts',
      }}
    >
      <Table
        className="my-24"
        columns={[
          {
            id: 'supplier',
            renderCell: (request) => (
              <div className="flex gap-8">
                {request?.supplier?.name ? (
                  <div className="flex items-center gap-8 truncate">
                    <SupplierLogo name={request.supplier.name} size={32} />
                    <EllipsisTooltip text={request.supplier.name} />
                  </div>
                ) : (
                  t('misc.na')
                )}
              </div>
            ),
            header: t('requests.toApproveList.supplier'),
          },
          {
            id: 'amount',
            width: '144px',
            renderCell: (request) => {
              return formatMoney(
                getAmountToDisplay(request),
                request.currency_declared,
              );
            },
            header: t('requests.toApproveList.amount'),
          },
          {
            id: 'description',
            renderCell: (request) => (
              <div className="truncate">
                <EllipsisTooltip text={request.description} />
              </div>
            ),
            header: t('requests.toApproveList.description'),
          },
          {
            id: 'status',
            width: '272px',
            renderCell: () => (
              <div className="text-wrap">
                <Tag variant="info" iconName="circle-check">
                  {t('requests.filters.draft')}
                </Tag>
              </div>
            ),
            header: t('misc.status'),
          },
          {
            id: 'date',
            width: '144px',
            renderCell: (request) =>
              localeFormat(new Date(request.created_at), DATE_FORMAT.MEDIUM),
            header: t('requests.toApproveList.date'),
          },
        ]}
        data={requests}
        groupBy={(request) => sectionGroupByRequestType[request.type]}
        getRowId={(request) => request.id}
        getIsRowActive={(request) => activeRequest === request.id}
        selectedRowIds={selectedRowIds}
        onRowClick={(request) => {
          // Open the request panel
          setActiveRequest(request.id);
          history.push(
            routeFor(routes.REQUESTS.path, {
              company: companyId,
              id: request.id,
              type,
            }),
          );
        }}
        onRowSelectionChange={(_, id, checked) => {
          setSelectedRowsIds((options) => {
            if (checked) {
              return options.concat(id);
            }
            return options.filter((optionId) => optionId !== id);
          });
        }}
        onAllRowsSelectionChange={(_, ids, checked) => {
          setSelectedRowsIds(checked ? ids : []);
        }}
        renderGroupedRowHeader={(value) => {
          const section = getSection(type, user)[value];

          if (!section) {
            return null;
          }
          const translationKey = section.translationKey;
          const sectionStats = sectionsStats[section.sectionStatsKey];
          return (
            <div className="flex items-center py-8 uppercase text-secondary-bg-secondary body-s">
              <span className="">{t(translationKey)}</span>
              <div className="ml-auto flex items-center gap-8">
                <span>
                  {t('misc.requestWithCount', {
                    count: sectionStats?.count,
                  })}
                </span>
                {sectionStats?.totalAmount && (
                  <>
                    <span
                      className="rounded-full h-4 w-4"
                      style={{ background: 'var(--color-border-default)' }}
                    />
                    <span>{formatMonetaryValue(sectionStats.totalAmount)}</span>
                  </>
                )}
              </div>
            </div>
          );
        }}
      />
      <RequestsTableActions
        selectedRowIds={selectedRowIds}
        type={SubnavigationItem.Drafts}
        unselectRequests={() => setSelectedRowsIds([])}
        requests={requests}
      />
    </ErrorBoundary>
  );
};
