import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useIntegrationStatusQuery } from 'src/core/modules/bookkeep';
import { isIntegrationStatusWithIntegration } from 'src/core/modules/bookkeep/integration/status';
import { useOrganisationLinks } from 'src/core/pages/SettingsOrganisationPage/useOrganisationLinks';

import { DisclosureItem } from '../DisclosureItem/DisclosureItem';
import { SubNavLink } from '../SubNavItem/SubNavItem';

type Props = {
  company: {
    id: string;
    churning_at: string | null;
  };
  user: {
    is_account_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
    is_organisation_owner: boolean;
  };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const SettingsLink = ({ company, user }: Props) => {
  const { t } = useTranslation('global');
  const isAccountingSettingsEnabled = useFeature(FEATURES.SETTINGS_ACCOUNTING);
  const isAccountingExportsSettingsEnabled = useFeature(
    FEATURES.SETTINGS_ACCOUNTING_EXPORTS,
  );
  const hasAccessToWallet = useFeature(FEATURES.WALLET_ACCESS);
  const isCustomFieldsSettingsEnabled = useFeature(FEATURES.CUSTOM_FIELDS);
  const isAccountsPayableSettingsEnabled = useFeature(
    FEATURES.SETTINGS_ACCOUNTS_PAYABLE,
  );
  const organisationLinks = useOrganisationLinks();
  const isAOorController = user.is_account_owner || user.is_controller;
  const isAOorAdmin = user.is_account_owner || user.is_admin;

  const canAccessSpendeskPlan =
    user.is_organisation_owner || isAOorController || user.is_admin;

  const canAccessChartOfAccounts =
    isAccountingSettingsEnabled && !company.churning_at && isAOorController;

  const canAccessAccountingCustomFields =
    isCustomFieldsSettingsEnabled && (isAOorController || user.is_admin);

  const canAccessAccounting =
    (isAccountingSettingsEnabled || isAccountingExportsSettingsEnabled) &&
    !company.churning_at &&
    isAOorController;

  const canAccessWallet = hasAccessToWallet && isAOorController;
  const canAccessIntegrations = !company.churning_at && isAOorAdmin;
  const accountingIntegrationStatusQueryResult = useIntegrationStatusQuery(
    false,
    {
      isEnabled: canAccessChartOfAccounts,
    },
  );

  let hasActiveIntegration = false;
  if (accountingIntegrationStatusQueryResult.status === 'success') {
    hasActiveIntegration = isIntegrationStatusWithIntegration(
      accountingIntegrationStatusQueryResult.data,
    );
  }

  const status =
    accountingIntegrationStatusQueryResult.status === 'success'
      ? accountingIntegrationStatusQueryResult.data
      : null;

  const canAccessAccountsPayable =
    isAccountsPayableSettingsEnabled &&
    user.is_controller &&
    status &&
    isIntegrationStatusWithIntegration(status);

  return (
    <DisclosureItem summary={t('misc.setting_plural')} iconName="gear">
      {organisationLinks.length > 0 && (
        <li>
          <SubNavLink
            activePaths={[
              routes.COMPANY_MEMBERS.path,
              routes.COMPANY_TEAMS.path,
              routes.COST_CENTERS.path,
              routes.COMPANY_CATEGORIES.path,
            ]}
            href={routeFor(organisationLinks[0].path, {
              company: company.id,
            })}
            label={t('navigation.organisation')}
            hasEllipsisTooltip
          />
        </li>
      )}
      {!company.churning_at && isAOorAdmin && (
        <li>
          <SubNavLink
            href={routeFor(routes.COMPANY_POLICIES.path, {
              company: company.id,
            })}
            activePaths={[
              routes.COMPANY_POLICIES.path,
              routes.COMPANY_CONTROL_RULES.path,
              routes.SPEND_LIMITS.path,
              routes.APPROVAL_WORKFLOWS.path,
              routes.COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS.path,
              routes.COMPANY_GENERAL_SETTINGS_NOTIFICATIONS.path,
            ]}
            label={t('navigation.companyRules')}
            hasEllipsisTooltip
          />
        </li>
      )}
      {!company.churning_at &&
        (canAccessAccounting || canAccessAccountingCustomFields) && (
          <li>
            <SubNavLink
              activePaths={[
                routes.COMPANY_ACCOUNTING_INTEGRATION.path,
                routes.COMPANY_EXPORTS.path,
                routes.COMPANY_ACCOUNTING_BOOKKEEPING_SETUP.path,
                routes.COMPANY_ACCOUNTING_ANALYTICAL_FIELDS.path,
              ]}
              href={routeFor(routes.COMPANY_ACCOUNTING_INTEGRATION.path, {
                company: company.id,
              })}
              label={t('navigation.accounting')}
              hasEllipsisTooltip
            />
          </li>
        )}
      {canAccessChartOfAccounts && hasActiveIntegration && (
        <li>
          <SubNavLink
            activePaths={[
              routes.COMPANY_ACCOUNTING_BANK_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTING_TAX_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTING_SUPPLIER_ACCOUNTS.path,
            ]}
            href={routeFor(routes.COMPANY_ACCOUNTING_BANK_ACCOUNTS.path, {
              company: company.id,
            })}
            label={t('navigation.chartOfAccounts')}
            hasEllipsisTooltip
          />
        </li>
      )}
      {canAccessAccountsPayable && (
        <li>
          <SubNavLink
            activePaths={[
              routes.COMPANY_ACCOUNTS_PAYABLE.path,
              routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path,
              routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS_DETAIL.path,
              routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIER_DETAIL.path,
            ]}
            href={routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
              company: company.id,
            })}
            label={t('settings.links.accountsPayable')}
            hasEllipsisTooltip
          />
        </li>
      )}
      {canAccessWallet && (
        <li>
          <SubNavLink
            href={routeFor(routes.COMPANY_BANK.path, {
              company: company.id,
            })}
            label={t('navigation.companyWallet')}
            hasEllipsisTooltip
          />
        </li>
      )}
      {canAccessSpendeskPlan && (
        <li>
          <SubNavLink
            href={routeFor(
              routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path,
              {
                company: company.id,
              },
            )}
            activePaths={[
              routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path,
              routes.COMPANY_BILLING_PLAN.path,
              routes.COMPANY_BILLING_INFORMATION.path,
            ]}
            label={t('navigation.mySpendeskPlan')}
            hasEllipsisTooltip
          />
        </li>
      )}
      {canAccessIntegrations && (
        <li>
          <SubNavLink
            href={routeFor(routes.COMPANY_INTEGRATIONS.path, {
              company: company.id,
            })}
            label={t('navigation.integrations')}
            hasEllipsisTooltip
          />
        </li>
      )}
    </DisclosureItem>
  );
};
