import { Button } from '@dev-spendesk/grapes';
import React, {
  type ReactNode,
  type MouseEventHandler,
  useRef,
  useEffect,
  type PropsWithChildren,
} from 'react';

import './DropdownSwitcherContent.css';

type Props = {
  activeItem: {
    left: ReactNode;
    right?: ReactNode;
    details?: ReactNode;
  };
  itemHeader?: ReactNode;
  items: {
    id: string;
    left: ReactNode;
    right?: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
  }[];
  button?: {
    label: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
  };
} & PropsWithChildren;

export const DropdownSwitcherContent = ({
  activeItem,
  itemHeader,
  items,
  button,
  children,
}: Props) => {
  const contentReference = useRef<HTMLDivElement>(null);

  // Make sure the dropdown cannot go offscreen
  useEffect(() => {
    const dropdown = contentReference.current;
    if (dropdown) {
      const top = dropdown.getBoundingClientRect().top;

      if (top > 0) {
        const additionalMargin = 36; // just so it's not too close to the bottom of the screen
        dropdown.style.maxHeight = `calc(100vh - ${top + additionalMargin}px)`;
      }
    }
  }, [contentReference.current]);

  return (
    <div className="w-[376px] overflow-auto" ref={contentReference}>
      <div className="p-16 pb-0">
        <div className="DropdownSwitcherContent__active__header title-m">
          <span className="DropdownSwitcherContent__active__header__left">
            {activeItem.left}
          </span>
          {activeItem.right && <span>{activeItem.right}</span>}
        </div>
        {activeItem.details && (
          <div className="DropdownSwitcherContent__active__details">
            <span>{activeItem.details}</span>
          </div>
        )}
      </div>
      {(items.length > 0 || itemHeader) && (
        <div className="DropdownSwitcherContent__items">
          {itemHeader}
          {items.map((item) => {
            const inside = (
              <>
                <span className="DropdownSwitcherContent__items__button__left">
                  {item.left}
                </span>
                {item.right && (
                  <span className="DropdownSwitcherContent__items__button__right body-m">
                    {item.right}
                  </span>
                )}
              </>
            );

            if (item.onClick) {
              return (
                <button
                  key={item.id}
                  type="button"
                  className="DropdownSwitcherContent__items__button title-m"
                  onClick={item.onClick}
                >
                  {inside}
                </button>
              );
            }

            return (
              <span
                key={item.id}
                className="flex items-center justify-between p-8 text-primary title-m"
              >
                {inside}
              </span>
            );
          })}
        </div>
      )}
      {(button || children) && (
        <div className="flex flex-col content-stretch gap-16 px-8 pb-8 pt-0">
          {children}
          {button && (
            <Button
              variant="tertiaryBrand"
              text={button.label}
              onClick={button.onClick}
            />
          )}
        </div>
      )}
    </div>
  );
};
