import {
  DropdownMenu,
  type DropdownMenuProps,
  Input,
  type InputProps,
} from '@dev-spendesk/grapes';
import { useState } from 'react';

import { normalizeString } from 'src/core/common/utils/normalizeString';

type Option = { key: string; label: string };

type SelectSearchProps<T extends Option> = {
  inputProps: Partial<InputProps>;
  value: T | undefined;
} & Omit<DropdownMenuProps<T>, 'renderButton'>;

export const SelectSearch = <T extends Option>({
  inputProps,
  value,
  ...props
}: SelectSearchProps<T>) => {
  const [search, setSearch] = useState<string>('');

  const options = !search
    ? props.options
    : props.options.flatMap((option) => {
        if ('options' in option) {
          return {
            ...option,
            options: option.options.filter(({ label }) =>
              normalizeString(label).includes(search),
            ),
          };
        }

        return normalizeString(option.label).includes(search) ? option : [];
      });

  const onSelect = (option: T) => {
    setSearch('');
    props.onSelect?.(option);
  };

  return (
    <DropdownMenu
      {...props}
      onSelect={onSelect}
      options={options}
      renderButton={(getToggleButtonProps, isOpen) => {
        const { isDropdown, ...toggleButtonProps } = getToggleButtonProps();

        // Reset search when the dropdown is closed
        if (!isOpen && search) {
          try {
            setSearch('');
          } catch {
            // Do nothing
          }
        }

        return (
          <Input
            {...(toggleButtonProps as object)}
            {...inputProps}
            autoComplete="off"
            type="text"
            value={isOpen ? search : (value?.label ?? '')}
            onChange={(event) => {
              const newSearch = normalizeString(event.target.value);
              setSearch(newSearch);
            }}
          />
        );
      }}
    />
  );
};
