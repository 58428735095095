import { Redirect, Switch } from 'react-router';

import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import { ProtectedRoute } from 'src/core/common/components/ProtectedRoute';
import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routes } from 'src/core/constants/routes';
import {
  CostCentersActivationPageContainer,
  CostCentersPageContainer,
} from 'src/core/modules/company/cost-centers';
import { CustomFieldsContainer } from 'src/core/modules/custom-fields';

import { SettingsOrganisationMembersPage } from './SettingsOrganisationMembersPage/SettingsOrganisationMembersPage';
import { SettingsOrganisationTeamsPage } from './SettingsOrganisationTeamsPage/SettingsOrganisationTeamsPage';
import {
  useOrganisationLinks,
  MembersKey,
  CostCenterKey,
  CompanyTeamsKey,
  CustomFieldsKey,
} from './useOrganisationLinks';

export const SettingsOrganisationPage = () => {
  const { t } = useTranslation('global');
  const hasCostCentersActivated = useFeature(FEATURES.COST_CENTERS_ACTIVATED);
  const organisationLinks = useOrganisationLinks();
  const hasMemberAccess = organisationLinks.some(
    (link) => link.key === MembersKey,
  );
  const hasCostCenterAccess = organisationLinks.some(
    (link) => link.key === CostCenterKey,
  );
  const hasTeamsAccess = organisationLinks.some(
    (link) => link.key === CompanyTeamsKey,
  );
  const hasCustomFieldsAccess = organisationLinks.some(
    (link) => link.key === CustomFieldsKey,
  );

  return (
    <ErrorBoundary
      context={{ scope: 'members-settings-page', team: 'control' }}
    >
      <div className="page__container">
        <HeaderWithNavigation links={organisationLinks}>
          {t('navigation.organisation')}
        </HeaderWithNavigation>
        <div className="flex w-full max-w-lg grow flex-col self-center py-40">
          <Switch>
            <ProtectedRoute
              isAccessAllowed={hasMemberAccess}
              path={routes.COMPANY_MEMBERS.path}
            >
              <SettingsOrganisationMembersPage />
            </ProtectedRoute>
            <ProtectedRoute
              isAccessAllowed={hasTeamsAccess}
              path={routes.COMPANY_TEAMS.path}
            >
              <SettingsOrganisationTeamsPage />
            </ProtectedRoute>

            <ProtectedRoute
              path={routes.COST_CENTERS.path}
              isAccessAllowed={hasCostCenterAccess}
            >
              {hasCostCentersActivated ? (
                <CostCentersPageContainer />
              ) : (
                <CostCentersActivationPageContainer />
              )}
            </ProtectedRoute>

            <ProtectedRoute
              path={routes.COMPANY_CATEGORIES.path}
              isAccessAllowed={hasCustomFieldsAccess}
            >
              <CustomFieldsContainer />
            </ProtectedRoute>

            <Redirect to={routes.HOMEPAGE.path} />
          </Switch>
        </div>
      </div>
    </ErrorBoundary>
  );
};
