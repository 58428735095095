import { Button, Modal, TextArea } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  isOpen: boolean;
  requestTypeHasEdition?: boolean;
  onCancel(): void;
  onProcessed(reason: string): Promise<void>;
};

export const DenyRequestModal = ({
  isOpen,
  onCancel,
  onProcessed,
  requestTypeHasEdition = false,
}: Props) => {
  const { t } = useTranslation('global');
  const [isLoading, setIsLoading] = useState(false);
  const [refusalReason, setRefusalReason] = useState('');

  const handleDenyRequest = async () => {
    setIsLoading(true);
    try {
      await onProcessed(refusalReason);
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onCancel}
          text={t('misc.cancel')}
          variant="secondaryNeutral"
          isDisabled={isLoading}
        />,
        <Button
          key="confirm"
          text={t('requests.actions.deny')}
          variant="primaryWarning"
          onClick={handleDenyRequest}
          isDisabled={isLoading || !refusalReason}
          isLoading={isLoading}
        />,
      ]}
      iconName="triangle-warning"
      title={t('requests.actions.denyThis')}
      subtitle={
        requestTypeHasEdition
          ? t('requests.actions.denySubtitleWithEdition')
          : t('requests.actions.denySubtitle')
      }
      iconVariant="warning"
      isOpen={isOpen}
    >
      <TextArea
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
          setRefusalReason(event.target.value)
        }
        name="deny_motif"
        placeholder={t('requests.denyPlaceholder')}
        maxLength={255}
        value={refusalReason}
      />
    </Modal>
  );
};
