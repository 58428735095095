import {
  useLoadSuppliersByIds,
  useSearchSuppliers,
} from 'modules/suppliers/hooks';
import {
  AutocompleteAsyncMultiple,
  type Option as BaseOption,
} from 'src/core/common/components/AutocompleteAsyncMultiple';
import { type AutocompleteAsyncMultipleProps } from 'src/core/common/components/AutocompleteAsyncMultiple/AutocompleteAsyncMultiple';

/**
 * @public
 */
export type Props<T extends BaseOption> = {
  value: string[];
  onChange: (value: string[]) => void;
} & Omit<
  AutocompleteAsyncMultipleProps<T>,
  'onSearch' | 'onGetByKeys' | 'selectedKeys' | 'onSelect'
>;

export function SupplierFilterField<T extends BaseOption>({
  value,
  onChange,
  ...props
}: Props<T>) {
  const onSearchSuppliers = useSearchSuppliers();
  const onSearchSuppliersOptions = async (search: string) => {
    const suppliers = await onSearchSuppliers(search, 25);
    return suppliers.map(({ id, name }) => ({ key: id, label: name }) as T);
  };

  const onGetSuppliersByKeys = useLoadSuppliersByIds();
  const onGetSuppliersOptionsByKeys = async (ids: string[]) => {
    const suppliers = await onGetSuppliersByKeys(ids);
    return suppliers.map(({ id, name }) => ({ key: id, label: name }) as T);
  };

  return (
    <AutocompleteAsyncMultiple
      {...props}
      selectedKeys={value}
      onSelect={(values) => {
        const newValue = values.map(({ key }) => key);
        onChange(newValue);
      }}
      onGetByKeys={onGetSuppliersOptionsByKeys}
      onSearch={onSearchSuppliersOptions}
    />
  );
}
