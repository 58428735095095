import { gql } from 'graphql-tag';

const CUSTOM_FIELD_WITH_FIRST_VALUES_FRAGMENT = gql`
  fragment customFieldWithFirstValues on CustomField {
    __typename
    id
    name
    optional
    scopes {
      team {
        id
      }
    }
    eligibleEntities
    archiveDate
    ... on CustomFieldBoolean {
      booleanValues: values {
        id
        name: value
      }
    }
    ... on CustomFieldList {
      canCreateValue
      values(first: 20, filter: { includeArchived: false }) {
        totalCount
        edges {
          node {
            id
            name: value
            archiveDate
          }
        }
      }
    }
  }
`;

export const LOAD_CUSTOM_FIELD_VALUES_BY_IDS = gql`
  query GetCustomFieldValues(
    $companyId: ID!
    $customFieldId: ID!
    $customFieldValueIds: [ID!]!
  ) {
    company(id: $companyId) {
      customFields(filter: { ids: [$customFieldId], includeArchived: false }) {
        ... on CustomFieldList {
          values(
            filter: { ids: $customFieldValueIds, includeArchived: false }
          ) {
            totalCount
            edges {
              node {
                id
                name: value
                archiveDate
              }
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_CUSTOM_FIELD_VALUES = gql`
  query SearchCustomFieldValues(
    $companyId: ID!
    $customFieldId: ID!
    $search: String!
  ) {
    company(id: $companyId) {
      customFields(filter: { ids: [$customFieldId], includeArchived: false }) {
        ... on CustomFieldList {
          values(
            first: 20
            filter: { search: $search, includeArchived: false }
          ) {
            totalCount
            edges {
              node {
                id
                name: value
                archiveDate
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FILTERS_VALUES = gql`
  query FiltersValues($companyId: ID!) {
    company(id: $companyId) {
      id
      teams(first: 20) {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
      members(first: 40, filter: { statuses: [user, suspended_user] }) {
        totalCount
        edges {
          node {
            id
            ... on User {
              givenName
              familyName
              email
            }
            ... on SuspendedUser {
              givenName
              familyName
            }
          }
        }
      }
      # First 20 only since there is an async search on this field
      suppliers(first: 20) {
        totalCount
        edges {
          node {
            id
            name
            thumbnailUrl
          }
        }
      }
      chartOfAccounts {
        expenseAccounts(first: 20) {
          totalCount
          edges {
            node {
              id
              name
              code
            }
          }
        }
      }
      customFields(filter: { includeArchived: false }) {
        ...customFieldWithFirstValues
      }
    }
    payableTypes: __type(name: "PayableType") {
      values: enumValues {
        id: name
      }
    }
  }
  ${CUSTOM_FIELD_WITH_FIRST_VALUES_FRAGMENT}
`;

export const GET_BY_IDS_EXPENSE_ACCOUNTS = gql`
  query GetByIdsExpenseAccounts($companyId: ID!, $ids: [ID!]!) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        expenseAccounts(filter: { ids: $ids }) {
          totalCount
          edges {
            node {
              id
              name
              isArchived
              ... on ExpenseAccount {
                isDefault
                code
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_BY_IDS_TAX_ACCOUNTS = gql`
  query GetByIdsTaxAccounts($companyId: ID!, $ids: [ID!]!) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        taxAccounts(filter: { ids: $ids }) {
          totalCount
          edges {
            node {
              id
              name
              isArchived
              ... on VatAccount {
                isDefault
                code
                rate
              }
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_MEMBERS = gql`
  query SearchMembers($companyId: ID!, $search: String!) {
    company(id: $companyId) {
      id
      members(
        first: 20
        filter: { search: $search, statuses: [user, suspended_user] }
      ) {
        totalCount
        edges {
          node {
            id
            ... on User {
              givenName
              familyName
              email
            }
            ... on SuspendedUser {
              givenName
              familyName
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_PAYABLE_ACCOUNTS = gql`
  query SearchPayableAccounts($companyId: ID!, $search: String!) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        employeeAccounts(first: 20, filter: { search: $search }) {
          totalCount
          edges {
            node {
              id
              generalAccountCode
              auxiliaryAccountCode
              isDefault
              isArchived
            }
          }
        }
        supplierAccounts(first: 20, filter: { search: $search }) {
          totalCount
          edges {
            node {
              id
              generalAccountCode
              auxiliaryAccountCode
              isDefault
              isArchived
            }
          }
        }
      }
    }
  }
`;

export const GET_BY_IDS_PAYABLE_ACCOUNTS = gql`
  query GetByIdsPayableAccounts($companyId: ID!, $ids: [String]) {
    company(id: $companyId) {
      id
      chartOfAccounts {
        employeeAccounts(filter: { ids: $ids }) {
          edges {
            node {
              id
              generalAccountCode
              auxiliaryAccountCode
              isDefault
              isArchived
            }
          }
        }
        supplierAccounts(filter: { ids: $ids }) {
          edges {
            node {
              id
              generalAccountCode
              auxiliaryAccountCode
              isDefault
              isArchived
            }
          }
        }
      }
    }
  }
`;

const PARTIAL_TEAM_FRAGMENT = gql`
  fragment PartialTeam on Team {
    id
    name
  }
`;

export const SEARCH_TEAMS = gql`
  query SearchTeams($companyId: ID!, $search: String!) {
    company(id: $companyId) {
      id
      teams(first: 20, filter: { search: $search }) {
        edges {
          node {
            ...PartialTeam
          }
        }
      }
    }
  }
  ${PARTIAL_TEAM_FRAGMENT}
`;

export const GET_TEAMS = gql`
  query GetTeams($companyId: ID!, $ids: [ID!]!) {
    company(id: $companyId) {
      id
      teams(filter: { ids: $ids }) {
        edges {
          node {
            ...PartialTeam
          }
        }
      }
    }
  }
  ${PARTIAL_TEAM_FRAGMENT}
`;
