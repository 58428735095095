import { Button, Table, type TableColumn } from '@dev-spendesk/grapes';
import { isValid } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { PageNoResult as PageNoResultContainer } from 'common/components/PageNoResult/PageNoResultContainer';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routeFor, routes } from 'src/core/constants/routes';
import { useUserRoles } from 'src/core/modules/app/hooks/useUserRoles';
import { type MonthStats } from 'src/core/modules/payments/graphql/allPayments/stats';

import { PaymentsFloatingActionBar } from './PaymentsFloatingActionBar';
import {
  AmountCell,
  DateCell,
  DescriptionWithPaymentIconCell,
  EmployeeCell,
  ReceiptStatusCell,
  SupplierCell,
} from './PaymentsTableCell';
import { PaymentTableGroup } from './PaymentsTableGroup';
import { PaymentsTableSkeleton } from './PaymentsTableSkeleton';
import { type Selection } from './types';
import { type Payment } from '../all/paymentType';

const BATCH_SIZE = 60;

export type Props = {
  isLoading?: boolean;
  payments?: Payment[];
  bulkEditPayments?: Payment[];
  activePayment?: string;
  paymentStats: MonthStats[];
  pageInfo?: {
    hasNextPage: boolean;
    endCursor: string;
  };
  selection: Selection;
  counters?: {
    remindable_for_invoice: number;
    downloadable: number;
    editable: number;
  };
  bulkActions?: {
    download?: { processing: boolean };
    edit?: { processing: boolean };
    remindInvoices?: { processing: boolean };
    markAsMissing?: { processing: boolean };
  };
  isSupervisionActive: boolean;
  filters?: Record<string, unknown>;
  fetchPayments: (options: {
    first?: number;
    after?: string;
    filters?: Record<string, unknown>;
  }) => void;
  bulkEdit: (arguments_: {
    selection: Selection;
    filters: Record<string, string | string[]>;
  }) => void;
  download: (arguments_: {
    selection: Selection;
    withReceipts: boolean;
    filters: Record<string, string | string[]>;
  }) => void;
  remindInvoices: (arguments_: {
    selection: Selection;
    filters: Record<string, string | string[]>;
  }) => void;
  bulkMarkAsMissing: (arguments_: {
    selection: Selection;
    filters: Record<string, string | string[]>;
  }) => Promise<{ nbPaymentsMarked: number }>;
  updateSelection: (ids: {
    all?: boolean;
    include?: string[];
    exclude?: string[];
  }) => void;
};

export const PaymentsTable = ({
  isLoading,
  payments,
  bulkEditPayments,
  activePayment,
  paymentStats,
  pageInfo,
  selection,
  counters,
  bulkActions,
  isSupervisionActive,
  filters,
  fetchPayments,
  bulkEdit,
  download,
  remindInvoices,
  bulkMarkAsMissing,
  updateSelection,
}: Props) => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const companyId = useCompanyId();
  const roles = useUserRoles();

  const hasEmployeeColumn =
    roles.isAccountOwner || roles.isController || roles.isAdmin;

  const columns: TableColumn<Payment>[] = [
    {
      id: 'supplier',
      header: t('payments.table.supplier'),
      renderCell: (payment) => <SupplierCell payment={payment} />,
      width: hasEmployeeColumn ? 'minmax(160px, 20%)' : '240px',
    },
    ...(hasEmployeeColumn
      ? [
          {
            id: 'employee',
            header: t('payments.table.employee'),
            renderCell: (payment: Payment) => (
              <EmployeeCell payment={payment} />
            ),
            width: 'minmax(160px, 20%)',
          },
        ]
      : []),
    {
      id: 'amount',
      header: t('payments.table.amount'),
      renderCell: (payment) => <AmountCell payment={payment} />,
      width: 160,
    },
    {
      id: 'description',
      header: t('payments.table.description'),
      renderCell: (payment) => (
        <DescriptionWithPaymentIconCell payment={payment} />
      ),
      width: '33%',
    },
    {
      id: 'receipt',
      header: t('payments.table.receipt'),
      renderCell: (payment) => <ReceiptStatusCell payment={payment} />,
      width: 190,
    },
    {
      id: 'date',
      header: t('payments.table.date'),
      renderCell: (payment) => <DateCell payment={payment} />,
      width: 160,
    },
  ];

  if (isLoading && (!payments || payments.length === 0)) {
    return <PaymentsTableSkeleton columns={columns} />;
  }

  if (!payments || payments.length === 0) {
    return (
      <PageNoResultContainer
        mode="payment"
        hasFiltersApplied={Boolean(Object.keys(filters ?? {}).length)}
      />
    );
  }

  const selectedRowIds = selection.all
    ? payments
        .map(({ databaseId }) => databaseId)
        .filter((databaseId) => !selection.exclude.includes(databaseId))
    : selection.include;

  return (
    <>
      <Table
        data={payments}
        columns={columns}
        groupBy={(option) => {
          const paidAt = option.paid_at ? new Date(option.paid_at) : null;
          return paidAt && isValid(paidAt)
            ? `${paidAt.getUTCFullYear()}-${paidAt.getUTCMonth()}`
            : 'none';
        }}
        getIsRowDisabled={() => !!bulkEditPayments}
        getIsRowActive={(payment) => payment.databaseId === activePayment}
        getRowId={(payment) => payment.databaseId}
        selectedRowIds={selectedRowIds}
        onRowClick={(payment) => {
          history.push({
            pathname: routeFor(routes.PAYMENTS_ALL.path, {
              company: companyId,
              id: payment.databaseId,
            }),
            search: history.location?.search,
          });
        }}
        onRowSelectionChange={(_payment, id, isChecked) => {
          if (bulkEditPayments) {
            return;
          }
          const changes = isChecked ? { include: [id] } : { exclude: [id] };
          return updateSelection(changes);
        }}
        onAllRowsSelectionChange={(_payment, _ids, isChecked) => {
          if (bulkEditPayments) {
            return;
          }
          updateSelection({ all: isChecked });
        }}
        renderGroupedRowHeader={(value) => {
          const paymentStat = paymentStats.find((stat) => {
            if (value === 'none') {
              return !stat.year;
            }
            return `${stat.year}-${stat.month}` === value;
          });

          if (!paymentStat) {
            return null;
          }

          return <PaymentTableGroup paymentStat={paymentStat} />;
        }}
        footer={
          pageInfo?.hasNextPage && (
            <div className="mx-auto">
              <Button
                variant="secondaryNeutral"
                text={t('misc.loadMore')}
                isLoading={isLoading}
                onClick={async () => {
                  await fetchPayments({
                    first: BATCH_SIZE,
                    after: pageInfo.endCursor,
                    filters,
                  });
                  // Fake loading as `fetchPayments` is not async
                  await new Promise((resolve) => setTimeout(resolve, 1000));
                }}
              />
            </div>
          )
        }
      />

      <PaymentsFloatingActionBar
        selectedRowIds={selectedRowIds}
        counters={counters}
        selection={selection}
        bulkEdit={bulkEdit}
        download={download}
        bulkActions={bulkActions}
        remindInvoices={remindInvoices}
        bulkMarkAsMissing={bulkMarkAsMissing}
        isSupervisionActive={isSupervisionActive}
      />
    </>
  );
};
