import { Button, Callout, FormField, SwitchField } from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';

import { toApiFormat as toApiApprovalRules } from 'modules/company/structure/approval-flows/models';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import {
  NotificationType,
  useNotifications,
} from 'src/core/modules/app/notifications';
import { createApprovalScheme } from 'src/core/modules/company/cost-centers/containers/CostCenterFormModalContainer/hooks/createApprovalScheme';
// import { deleteApprovalScheme } from 'src/core/modules/company/cost-centers/containers/CostCenterFormModalContainer/hooks/deleteApprovalSchemeQuery';
import { editApprovalScheme } from 'src/core/modules/company/cost-centers/containers/CostCenterFormModalContainer/hooks/editApprovalSchemeQuery';
import { type ApprovalRule } from 'src/core/modules/company/structure/approval-flows';

import { useWorkflowContext } from '../../WorkflowContext';
import { defaultApprovalRules } from '../../approvalScheme';
import { type Dimension } from '../../approvalWorkflow';
import { type CustomNode } from '../../node';
import { ApprovalScheme } from '../ApprovalScheme';
import { DimensionSelect, getDimensionOptions } from '../DimensionSelect';
import { DimensionValuesSelect } from '../DimensionValuesSelect';

type Props = {
  approvalRules?: ApprovalRule[];
  dimension?: Dimension;
  selectedValues?: string[] | null | undefined;
  approvalSchemeId: string | undefined;
  onCancel: () => void;
  onSubmit: () => void;
};

type FormProps = {
  dimension: Dimension | undefined;
  values: string[] | null | undefined;
  approvalRules: ApprovalRule[] | undefined;
};

export const DimensionApprovalFlowPanelContent = ({
  onCancel,
  onSubmit,
  approvalRules,
  approvalSchemeId,
  dimension,
  selectedValues,
}: Props) => {
  const companyId = useCompanyId();
  const panelReference = useRef<HTMLDivElement>(null);
  const { pushNotif } = useNotifications();
  const {
    nodes,
    edges,
    setNodes,
    selectedNodeId,
    selectedNodeLevel,
    handleSubmitApprovalFlow,
  } = useWorkflowContext();
  const [isApprovalSchemeSetupOpened, setIsApprovalSchemeSetupOpened] =
    useState(Boolean(approvalRules && approvalRules.length > 0));

  useEffect(() => {
    setIsApprovalSchemeSetupOpened(
      Boolean(approvalRules && approvalRules.length > 0),
    );
  }, [approvalRules]);

  const {
    values: formValues,
    errors,
    setFieldValue,
    handleSubmit,
  } = useFormik<FormProps>({
    enableReinitialize: true,
    initialValues: {
      dimension: getInitialDimension(dimension, selectedNodeLevel),
      values: selectedValues,
      approvalRules,
    },
    validateOnChange: false,
    validate: (values) => {
      const validationErrors: FormikErrors<FormProps> = {};
      if (!values.approvalRules && isApprovalSchemeSetupOpened) {
        validationErrors.approvalRules = 'Please set up an approval flow';
        // Scroll the panel down to see the validation error
        if (panelReference.current) {
          panelReference.current.scrollTo({
            top: panelReference.current.scrollHeight + 100,
            behavior: 'smooth',
          });
        }
      }
      if (values.values === undefined || values.values?.length === 0) {
        validationErrors.values = 'Please select at least one value';
      }
      return validationErrors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      let updatedNodes: CustomNode[] = nodes.map((node) => {
        if (node.id === selectedNodeId && node.type === 'baseNode') {
          return {
            ...node,
            data: {
              ...node.data,
              dimension: values.dimension,
              values: values.values,
            },
          };
        }
        return node;
      });

      // case when the user toggles off the approval scheme setup
      if (
        !values.approvalRules &&
        !isApprovalSchemeSetupOpened &&
        approvalSchemeId
      ) {
        updatedNodes = updatedNodes.map(
          (node) => {
            if (node.id === selectedNodeId && node.type === 'baseNode') {
              return {
                ...node,
                data: {
                  ...node.data,
                  schemeId: undefined,
                },
              };
            }
            return node;
          },
        );
        // The endpoint is not yet implemented
        // deleteApprovalScheme({
        //   approvalSchemeId,
        //   companyId,
        // });
      }

      setNodes(updatedNodes);
      handleSubmitApprovalFlow(updatedNodes, edges);
      onSubmit();

      if (!values.approvalRules) {
        return;
      }

      try {
        if (approvalSchemeId) {
          await editApprovalScheme({
            companyId,
            payload: {
              rules: toApiApprovalRules(values.approvalRules),
            },
            approvalSchemeId,
          });
        } else {
          const response = await createApprovalScheme(
            {
              owner: null,
              rules: toApiApprovalRules(values.approvalRules),
            },
            companyId,
          );

          // Assign new approval scheme id to this node
          const updatedNodesWithSchemeId: CustomNode[] = updatedNodes.map(
            (node) => {
              if (node.id === selectedNodeId && node.type === 'baseNode') {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    schemeId: response.id,
                  },
                };
              }
              return node;
            },
          );
          setNodes(updatedNodesWithSchemeId);
          handleSubmitApprovalFlow(updatedNodesWithSchemeId, edges);
        }
      } catch (error) {
        pushNotif({
          type: NotificationType.Danger,
          message: 'Failed to update approval flow',
        });
        throw new Error('Failed to update approval flow', error);
      }
      setSubmitting(false);
      pushNotif({
        type: NotificationType.Success,
        message: 'Approval flow updated',
      });
    },
  });

  const handleIsApprovalSchemeSetupOpenedChange = (isOpen: boolean) => {
    setIsApprovalSchemeSetupOpened(isOpen);
    if (!isOpen) {
      setFieldValue('approvalRules', undefined);
    }
  };

  return (
    <>
      <div className="grow overflow-y-auto" ref={panelReference}>
        <div className="p-32">
          <FormField className="text-left" label="When">
            <DimensionSelect
              value={getDimensionOptions().find(
                (dimensionOption) =>
                  dimensionOption.key === formValues.dimension?.type,
              )}
              onChange={(value) =>
                setFieldValue('dimension', { type: value.key })
              }
            />
          </FormField>
          {formValues.dimension !== undefined && (
            <div className="mt-16">
              <DimensionValuesSelect
                dimension={formValues.dimension}
                selectedValues={formValues.values}
                onSelect={(values) => setFieldValue('values', values)}
              />
            </div>
          )}
          {errors.values && (
            <Callout title={errors.values} variant="alert" className="mt-16" />
          )}
          <div className="my-16 h-[1px] w-full bg-primary-pressed" />
          <SwitchField
            fit="parent"
            label="Set up an approval flow"
            isChecked={isApprovalSchemeSetupOpened}
            onChange={(event) => {
              handleIsApprovalSchemeSetupOpenedChange(event.target.checked);
            }}
          />
        </div>
        {isApprovalSchemeSetupOpened && (
          <div className="p-32 pb-[90px] pt-0">
            <ApprovalScheme
              error={
                errors.approvalRules
                  ? (errors.approvalRules as string)
                  : undefined
              }
              onChange={(rules) => setFieldValue('approvalRules', rules)}
              rules={formValues.approvalRules ?? defaultApprovalRules}
            />
          </div>
        )}
      </div>
      <div className="absolute bottom-0 z-10 flex w-full justify-between gap-16 rounded-bl-12 rounded-br-12 border-0 border-t border-solid border-t-default bg-primary-default px-32 py-16">
        <Button
          text="Cancel"
          className="flex-grow"
          variant="secondaryNeutral"
          onClick={() => {
            onCancel();
          }}
        />
        <Button
          text="Save"
          className="flex-grow"
          variant="primaryBrand"
          onClick={() => {
            handleSubmit();
          }}
        />
      </div>
    </>
  );
};

const getInitialDimension = (
  dimension: Dimension | undefined,
  selectedNodeLevel: number | null,
): Dimension | undefined => {
  // By spec the first level of the tree should always be Spend type
  if (!dimension && selectedNodeLevel === 1) {
    return { type: 'spendType' };
  }

  // This is temporary as we have only two dimensions and we assume that the second level is always cost center
  if (selectedNodeLevel === 2) {
    return { type: 'costCenter' };
  }

  return dimension;
};
