import { IconButton } from '@dev-spendesk/grapes';
import { Handle, type NodeProps, Position } from '@xyflow/react';

import { useWorkflowContext } from '../../WorkflowContext';

export type LabelNodeData = {
  nodeType: 'labelNode';
};

const handleStyle = {
  background: 'transparent',
  border: 'none',
  width: 0,
  height: 0,
};

export const LabelNode = ({ id }: NodeProps) => {
  const { createAndConnectNode } = useWorkflowContext();

  return (
    <div className="flex h-[88px] items-center">
      <div className="rounded bg-primary-default px-8 py-4 text-primary title-m">
        When a request is made
      </div>
      <IconButton
        variant="tertiaryNeutral"
        onClick={() => {
          createAndConnectNode({ sourceNodeId: id });
        }}
        iconName="circle-plus"
        aria-label="plus"
      />
      <Handle
        style={handleStyle}
        type="source"
        id="rightHandle"
        position={Position.Right}
      />
      <Handle
        style={handleStyle}
        type="source"
        id="bottomHandle"
        position={Position.Bottom}
      />
    </div>
  );
};
