import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  title: string | React.ReactNode;
  subtitle?: string;
  rightAddon: React.ReactNode;
};

export const CustomFieldsPageHeader = ({
  className,
  title,
  subtitle,
  rightAddon,
}: Props) => {
  return (
    <div className={classNames('flex items-center justify-between', className)}>
      <div className="mr-24 text-primary">
        <div className="text-primary heading-l">{title}</div>
        {subtitle && (
          <div className="mt-8 text-secondary-bg-primary body-m">
            {subtitle}
          </div>
        )}
      </div>
      <div className="shrink-0">{rightAddon}</div>
    </div>
  );
};
