import React from 'react';

import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { PurchaseOrdersTableSkeleton } from './PurchaseOrdersTable/PurchaseOrdersTableSkeleton/PurchaseOrdersTableSkeleton';

export const PurchaseOrdersPageSkeleton = () => {
  const { t } = useTranslation('global');
  return (
    <div className="page__container">
      <HeaderWithNavigation links={[]}>
        {t('misc.purchaseOrders')}
      </HeaderWithNavigation>

      <main className="m-24">
        <PurchaseOrdersTableSkeleton />
      </main>
    </div>
  );
};
