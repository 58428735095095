import { Panel } from '@dev-spendesk/grapes';
import classnames from 'classnames';
import { type PropsWithChildren } from 'react';

import { type getEntityStatus } from './EntityListItem';

import './EntityItemLayoutWrapper.css';

export const EntityItemLayoutWrapper = ({
  entityStatus,
  children,
}: PropsWithChildren<{
  entityStatus: ReturnType<typeof getEntityStatus>;
}>) => (
  <Panel
    className={classnames(
      'z-[8] box-border w-full pt-24 elevation-xs',
      entityStatus === 'kycInProgress' && 'border-transparent',
    )}
  >
    <div className="EntityItem__LayoutWrapper">{children}</div>
  </Panel>
);
