import { colors, Icon, Tooltip } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { NewBranch } from 'src/core/modules/app/layout/components/NewBranch';
import { isNonNegativeNumber } from 'src/core/utils/isNonNegativeNumber';

import styles from './CompanyPlanNewWallet.module.css';
import { useGetOrganisationEntities } from '../../hooks/useGetOrganisationEntities';

export const CompanyPlanNewWallet = () => {
  const { t } = useTranslation('global');

  const [isNewWalletOpen, setIsNewWalletOpen] = useState(false);

  const { activeEntities, availableEntities, isAllowedToOpenEntity } =
    useGetOrganisationEntities();
  const displayEntityUsage =
    isNonNegativeNumber(activeEntities) &&
    isNonNegativeNumber(availableEntities);
  const remainingEntities =
    displayEntityUsage && Math.max(availableEntities - activeEntities, 0);

  return (
    <>
      <Tooltip
        content={
          isAllowedToOpenEntity
            ? ''
            : t('billing.companyPlan.notAllowedToCreateEntityTooltip')
        }
      >
        <button
          className={cx(styles.CompanyPlanNewWallet, {
            [styles.isDisabled]: !isAllowedToOpenEntity,
          })}
          type="button"
          disabled={!isAllowedToOpenEntity}
          onClick={() => setIsNewWalletOpen(true)}
        >
          <Icon
            name="circle-plus"
            color={
              isAllowedToOpenEntity
                ? colors.contentBrandDefault
                : colors.contentDecorativeIcon
            }
            size="l"
          />
          <div
            className={cx('title-m', {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'text-brand-default': isAllowedToOpenEntity,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'text-secondary-bg-primary': !isAllowedToOpenEntity,
            })}
          >
            {t('billing.companyPlan.addNewWallet')}
          </div>
          {displayEntityUsage ? (
            <div
              className={cx('mt-8 body-m', {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'text-brand-default': isAllowedToOpenEntity,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'text-secondary-bg-primary': !isAllowedToOpenEntity,
              })}
            >
              {t('billing.companyPlan.entityUsage', {
                remainingEntities,
                availableEntities,
              })}
            </div>
          ) : null}
        </button>
      </Tooltip>
      {isNewWalletOpen && (
        <NewBranch onClose={() => setIsNewWalletOpen(false)} />
      )}
    </>
  );
};
