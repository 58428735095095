import { useState } from 'react';

import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import withErrorBoundary from 'src/core/common/components/withErrorBoundary';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useNotifications } from 'src/core/modules/app/notifications';
import { useGetIntegrationsWithPolling } from 'src/core/modules/integrations/hooks/useGetIntegrationsWithPolling';
import { useIntegrationDetailsDataQuery } from 'src/core/modules/integrations/hooks/useIntegrationDetailsDataQuery';
import {
  type Integration,
  type IntegrationStatus,
} from 'src/core/modules/integrations/types';

import { IntegrationConfigurationSkeleton } from './components/IntegrationConfigurationSkeleton';
import { KomboIntegrationConfiguration } from './components/KomboIntegrationConfiguration';
import { StatusCallout } from './components/StatusCallout';
import { SyncInProgressModal } from './components/SyncInProgressModal';
import { IntegrationBenefitsBox } from '../../../IntegrationBenefitsBox';

type Props = {
  integration: Integration;
};

export const KomboIntegrationDetailsContainer = withErrorBoundary({
  scope: 'kombo-integration-details',
  team: 'control',
})(({ integration }: Props) => {
  const { t } = useTranslation('global');
  const { successNotif } = useNotifications();

  const updateIsSyncInProgress = (data: IntegrationStatus) => {
    const updatedError = data.active.find(
      (activeIntegration) => integration.id === activeIntegration.id,
    )?.error;

    // displays a notification once the sync is done
    if (isSyncInProgress && updatedError === 'pendingAction') {
      successNotif(
        t('integration.kombo.settings.notifications.integrationReady'),
      );
    }
    setIsSyncInProgress(updatedError === 'pendingSync');
  };

  useGetIntegrationsWithPolling(
    integration.error === 'pendingSync',
    updateIsSyncInProgress,
  );

  const [isSyncInProgress, setIsSyncInProgress] = useState<boolean>(
    integration.error === 'pendingSync',
  );
  const queryDetailsDataResult =
    useIntegrationDetailsDataQuery(!isSyncInProgress);

  return (
    <>
      <StatusCallout integrationError={integration.error} />
      <IntegrationBenefitsBox integration={integration} noSeparator />
      <SyncInProgressModal
        integrationId={integration.id}
        isOpen={isSyncInProgress}
      />
      {isSyncInProgress && <IntegrationConfigurationSkeleton />}
      {!isSyncInProgress && (
        <QuerySuspense
          queryState={queryDetailsDataResult}
          loading={<IntegrationConfigurationSkeleton />}
          fallback={() => null}
        >
          {({ companyConfiguration, komboAvailableFilters }) => (
            <KomboIntegrationConfiguration
              integration={integration}
              companyConfiguration={companyConfiguration}
              availableFiltersByDimension={komboAvailableFilters}
            />
          )}
        </QuerySuspense>
      )}
    </>
  );
});
