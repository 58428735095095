import {
  Button,
  Callout,
  type CalloutVariant,
  EmptyState,
  IconButton,
  SkeletonTable,
  SkeletonText,
} from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useFeature } from 'common/hooks/useFeature';
import { useModal } from 'common/hooks/useModalGrapes';
import { useTranslation } from 'common/hooks/useTranslation';
import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split/useAnalyticalSplitStatus';
import { useCostCentersQuery } from 'modules/budgets/apis';
import type { CostCenter } from 'modules/budgets/models/costCenter';
import { CustomFieldsPageHeader } from 'modules/custom-fields/components/CustomFieldsPageHeader';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import FEATURES from 'src/core/constants/features';

import { ExpenseCategoryTable } from './ExpenseCategoryTable';
import { useExpenseCategoriesQuery } from './hooks/useExpenseCategoriesQuery';
import { formatList } from '../../../../../common/utils/intl';
import { type CustomField } from '../../../../custom-fields/models/customField';
import { listCostCentersWithoutExpenseCategories } from '../../components/EcFormModal/listCostCentersWithoutExpenseCategories';
import { type ExpenseCategory } from '../../expenseCategory';
import { useGetDataForExpenseCategoryExpenseAccountRules } from '../../hooks/useGetDataForExpenseCategoryExpenseAccountRules';
import { useIsExpenseCategoryExpenseAccountRuleEnabled } from '../../hooks/useIsExpenseCategoryExpenseAccountRuleEnabled';
import { EcDeletionModalContainer } from '../EcDeletionModalContainer';
import {
  EcCreationFormModalContainer,
  EcEditFormModalContainer,
} from '../EcFormModalContainer';
import { ExpenseCategoriesEditSettingsContainer } from '../ExpenseCategoriesEditSettingsContainer';

type Props = {
  expenseCategoryCustomField?: CustomField;
};

export const EcContainer = ({ expenseCategoryCustomField }: Props) => {
  const { t, activeLanguage } = useTranslation('global');
  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();

  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );
  const isExpenseCategoryExpenseAccountRuleActivated =
    useIsExpenseCategoryExpenseAccountRuleEnabled();

  const queryStates = useQueryStates({
    states: {
      expenseCategories: useExpenseCategoriesQuery(),
      costCenters: useCostCentersQuery(),
      dataForExpenseCategoryExpenseAccountRules:
        useGetDataForExpenseCategoryExpenseAccountRules(),
    },
    reshapeData: ({
      expenseCategories,
      costCenters,
      dataForExpenseCategoryExpenseAccountRules,
    }) => ({
      expenseCategories,
      costCenters,
      ...dataForExpenseCategoryExpenseAccountRules,
    }),
  });

  const renderExpenseCategoriesCallout = (
    expenseCategories: ExpenseCategory[],
    costCenters: CostCenter[],
  ) => {
    const standaloneCostCenters = listCostCentersWithoutExpenseCategories(
      costCenters,
      expenseCategories,
    );

    if (expenseCategories.length === 0 || standaloneCostCenters.length === 0) {
      return null;
    }

    const isExpenseCategoryOptional = !expenseCategoryCustomField?.isRequired;

    return renderExpenseCategoriesCalloutContent(standaloneCostCenters, {
      title: t('expenseCategories.standaloneCostCenters.title'),
      description: isExpenseCategoryOptional
        ? t('expenseCategories.standaloneCostCenters.description.optionalEcs')
        : t('expenseCategories.standaloneCostCenters.description.mandatoryEcs'),
      variant: 'info',
    });
  };

  const renderExpenseCategoriesCalloutContent = (
    standaloneCostCenters: CostCenter[],
    content: { title: string; variant: CalloutVariant; description: string },
  ) => {
    const { title, variant, description } = content;

    return (
      <Callout className="mx-16 mb-16" title={title} variant={variant}>
        <div>
          <Trans i18nKey={description} />
          <div className="mt-16">
            {standaloneCostCenters.map(({ name }) => name).join(', ')}
          </div>
        </div>
      </Callout>
    );
  };

  const [createExpenseCategoryModal, showCreateExpenseCategoryModal] =
    useModal<{ expenseCategory: ExpenseCategory }>(({ isOpen, onClose }) => (
      <EcCreationFormModalContainer
        isOpen={isOpen}
        onComplete={onClose}
        onCancel={onClose}
      />
    ));

  const [editExpenseCategoryModal, showEditExpenseCategoryModal] = useModal<{
    expenseCategory: ExpenseCategory;
  }>(
    ({ isOpen, onClose, expenseCategory }) =>
      expenseCategory && (
        <EcEditFormModalContainer
          expenseCategory={expenseCategory}
          isOpen={isOpen}
          onComplete={onClose}
          onCancel={onClose}
        />
      ),
  );

  const [deletionModal, showDeletionModal] = useModal<{
    expenseCategory: ExpenseCategory;
  }>(({ isOpen, onClose, expenseCategory }) => {
    if (expenseCategory === undefined) {
      return <div />;
    }
    return (
      <EcDeletionModalContainer
        expenseCategory={expenseCategory}
        isOpen={isOpen}
        onComplete={onClose}
        onClose={onClose}
      />
    );
  });

  const [
    expenseCategoriesEditSettingsModal,
    showExpenseCategoriesEditSettingsModal,
  ] = useModal(({ isOpen, onClose }) => {
    if (expenseCategoryCustomField === undefined) {
      return <></>;
    }

    return (
      <ExpenseCategoriesEditSettingsContainer
        isOpen={isOpen}
        expenseCategoryCustomField={expenseCategoryCustomField}
        onClose={onClose}
        onComplete={onClose}
      />
    );
  });

  return (
    <QuerySuspense
      queryState={queryStates}
      fallback={() => (
        <EmptyState
          title={t('misc.errors.networkError')}
          subtitle=""
          iconVariant="alert"
          iconName="hexagone-cross"
        />
      )}
      loading={
        <SkeletonTable
          columns={[
            {
              cell: <SkeletonText width="80%" />,
              header: <SkeletonText width="100%" />,
              width: '33%',
            },
            {
              cell: <SkeletonText width="80%" />,
              header: <SkeletonText width="100%" />,
              width: '34%',
            },
            {
              cell: <SkeletonText width="80%" />,
              header: <SkeletonText width="100%" />,
              width: '33%',
            },
          ]}
          withHeader
          numberOfRows={5}
          rowHeight="compact"
        />
      }
    >
      {({
        costCenters,
        expenseCategories,
        expenseAccounts,
        expenseCategoryExpenseAccountRules,
      }) => {
        const tags: string[] = [];

        if (
          expenseCategoryCustomField?.type === 'list' &&
          isAnalyticalSplitActivated
        ) {
          tags.push(
            expenseCategoryCustomField.isSplittable
              ? t('customFields.settings.splittable')
              : t('customFields.settings.nonSplittable'),
          );
        }

        if (!expenseCategoryCustomField?.isRequired) {
          tags.push(t('misc.optional').toLocaleLowerCase());
        }

        const formattedTags = formatList(activeLanguage, tags, t);

        return (
          <div>
            <CustomFieldsPageHeader
              title={
                <>
                  {t('expenseCategories.setup.title')}{' '}
                  {isAnalyticalSplitActivated ? (
                    <span className="text-primary body-l">
                      ({formattedTags})
                    </span>
                  ) : null}
                </>
              }
              subtitle={t('expenseCategories.expenseCategoriesDescription')}
              className="mb-24"
              rightAddon={
                <div className="flex items-center gap-8">
                  <Button
                    className="flex-2 whitespace-nowrap"
                    variant="secondaryNeutral"
                    text={t('expenseCategories.addExpenseCategory')}
                    onClick={() => showCreateExpenseCategoryModal()}
                  />
                  {expenseCategoryCustomField && (
                    <IconButton
                      iconName="gear"
                      variant="secondaryNeutral"
                      onClick={() => showExpenseCategoriesEditSettingsModal()}
                      aria-label={t('misc.edit')}
                    />
                  )}
                </div>
              }
            />
            {renderExpenseCategoriesCallout(expenseCategories, costCenters)}
            <ExpenseCategoryTable
              costCenters={costCenters}
              expenseAccounts={expenseAccounts}
              expenseCategories={expenseCategories}
              expenseCategoryExpenseAccountRules={
                expenseCategoryExpenseAccountRules
              }
              isCostCentersFeatureEnabled={isCostCentersFeatureEnabled}
              isExpenseCategoryExpenseAccountRuleActivated={
                isExpenseCategoryExpenseAccountRuleActivated
              }
              maxHeight={330}
              onDelete={(expenseCategory: ExpenseCategory) =>
                showDeletionModal({ expenseCategory })
              }
              onEdit={(expenseCategory: ExpenseCategory) =>
                showEditExpenseCategoryModal({ expenseCategory })
              }
            />
            {createExpenseCategoryModal}
            {editExpenseCategoryModal}
            {deletionModal}
            {expenseCategoriesEditSettingsModal}
          </div>
        );
      }}
    </QuerySuspense>
  );
};
