import { type IconName } from '@dev-spendesk/grapes';
import type { MonetaryValue } from 'ezmoney';

import type { RequestAPI } from 'modules/requests/types';

type RawRequest = {
  amount?: MonetaryValue;
  amount_declared: RequestAPI['amount_declared'];
  amount_to_refund?: RequestAPI['amount_to_refund'];
  currency_declared: RequestAPI['currency_declared'];
  state: RequestAPI['state'];
  type: RequestAPI['type'];
  user_id: RequestAPI['user_id'];
  balance: boolean;
  balanceOnlyWarn?: boolean;
  deleted_at: RequestAPI['deleted_at'];
  custom_fields: { field: { id: string }; value: { id: string } }[];
};

const isApproved = (request: Pick<RawRequest, 'state'>) =>
  request.state === 'approved';

const isCardLoadRequest = (request: Pick<RawRequest, 'type'>) =>
  request.type === 'card_load';

export const isLoadCardAllowed = (
  request: Pick<RawRequest, 'type' | 'state' | 'user_id' | 'balance'>,
  user: { id: string },
) =>
  isCardLoadRequest(request) &&
  isApproved(request) &&
  request.user_id === user.id &&
  request.balance;

export const isGetCardAllowed = (
  request: Pick<
    RawRequest,
    'type' | 'state' | 'user_id' | 'balance' | 'balanceOnlyWarn' | 'deleted_at'
  >,
  user: { id: string },
) =>
  request.type !== 'subscription_increase' &&
  !isCardLoadRequest(request) &&
  isApproved(request) &&
  request.user_id === user.id &&
  (request.balance || request.balanceOnlyWarn) &&
  !request.deleted_at;

const getExpenseCategoryValueFromCustomFields = (
  customFields: { field: { id: string }; value: { id: string } }[],
  expenseCategoryCustomFieldId: string,
) => {
  const expenseCategory = customFields?.find((customField) => {
    return customField.field.id === expenseCategoryCustomFieldId;
  });
  if (expenseCategory) {
    return expenseCategory.value;
  }
};

export const enrichRequestWithExpenseCategory = <
  TRequest extends {
    custom_fields?: { field: { id: string }; value: { id: string } }[];
  },
>(
  request: TRequest,
  expenseCategoryCustomFieldId: string,
): TRequest & { expenseCategory?: { id: string } } => {
  return {
    ...request,
    expenseCategory: getExpenseCategoryValueFromCustomFields(
      request.custom_fields ?? [],
      expenseCategoryCustomFieldId,
    ),
  };
};

export const paymentTypeIconFromRequest = ({
  request,
  card_id,
}: {
  card_id?: string;
  request?: {
    type: RequestAPI['type'] | 'plastic_card';
    atm_withdrawal?: boolean;
  };
}): IconName | undefined => {
  // spendesk billing invoices
  if (!request && !card_id) {
    return 'invoice';
  }

  if (!request) {
    return;
  }

  if (request.type === 'mileage_allowance') {
    return 'car';
  }
  if (request.type === 'per_diem_allowance') {
    return 'globe-eu-africa';
  }
  if (request.type === 'expense') {
    return 'receipt';
  }
  if (request.type === 'single_purchase') {
    return 'card-lightning';
  }
  if (request.type === 'subscription') {
    return 'card-rounded-arrows';
  }
  if (['invoice'].includes(request.type)) {
    return 'receipt-percent';
  }
  if (['credit_note'].includes(request.type)) {
    return 'receipt-uturn-left-top';
  }
  if (['card_load', 'subscription_increase'].includes(request.type)) {
    return 'card-arrow-up';
  }
  if (request.type === 'plastic_card' && request.atm_withdrawal) {
    return 'banknote';
  }
};

export const getLocationsDescriptionsFromMileageAllowanceRequest =
  (mileageAllowanceRequest: {
    mileage_allowance_request_journeys: {
      mileage_allowance_request_journey_locations: { description: string }[];
    }[];
  }) => {
    return mileageAllowanceRequest.mileage_allowance_request_journeys[0].mileage_allowance_request_journey_locations.map(
      ({ description }) => description,
    );
  };

export const getAmountToDisplay = (
  request: Pick<RawRequest, 'amount_declared' | 'amount_to_refund' | 'type'>,
) => {
  const {
    amount_declared: amountDeclared,
    amount_to_refund: amountToRefund,
    type,
  } = request;

  let amountToDisplay;

  if (type === 'mileage_allowance') {
    amountToDisplay =
      typeof amountToRefund !== 'number' ? amountDeclared : amountToRefund;
  } else {
    amountToDisplay = amountDeclared;
  }

  return amountToDisplay;
};
