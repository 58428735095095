import { type ReactNode } from 'react';

import styles from './TopBanner.module.css';

type TopBannerProp = {
  /**
   * Title for the TopBanner
   */
  title: string;
  /**
   * Variant for the TopBanner
   * @default alert
   */
  variant: 'alert' | 'info' | 'complementary';
  children?: ReactNode;
  testId?: string;
};

export const TopBanner = ({
  children,
  variant = 'alert',
  title,
  testId,
}: TopBannerProp) => {
  return (
    <div className={styles.topBanner} data-variant={variant} data-testid={testId}>
      <p className={styles.title}>{title}</p>
      {children}
    </div>
  );
};
