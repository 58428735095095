import {
  colors,
  IconButton,
  Table,
  type TableProps,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';
import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'common/hooks/useTranslation';
import type { CostCenter } from 'modules/budgets/models/costCenter';
import { type ExpenseAccount } from 'src/core/modules/bookkeep/accounts-payable/types';

import { type ExpenseCategory } from '../../expenseCategory';
import { type ExpenseCategoryExpenseAccountRule } from '../../hooks/useGetExpenseCategoryExpenseAccountRulesLazyQuery';

const getCostCentersString = (
  costCenterIds: string[],
  costCenters: CostCenter[],
  t: TGlobalFunctionTyped,
) => {
  const costCentersList = costCenterIds
    .map((costCenterId) => {
      return (
        // Case where the cost center list does not have one of the cost center defined in the expense category associated list
        costCenters.find((costCenter) => costCenter.id === costCenterId)
          ?.name ?? undefined
      );
    })
    .filter((name): name is string => name !== undefined);
  if (costCentersList.length > 0) {
    return <EllipsisTooltip text={costCentersList.join(', ')} />;
  }

  return t('expenseCategories.appliedOnAllCostCenters');
};

const getExpenseAccountsString = (
  expenseAccountIds: string[],
  expenseAccounts: ExpenseAccount[],
  t: TGlobalFunctionTyped,
) => {
  const names = expenseAccountIds
    .map((id) => {
      return (
        expenseAccounts.find((expenseAccount) => expenseAccount.id === id)
          ?.name ?? undefined
      );
    })
    .filter((name): name is string => name !== undefined);
  if (names.length > 0) {
    return <EllipsisTooltip text={names.join(', ')} />;
  }

  return t('expenseCategories.noLinkedExpenseAccount');
};

const getLinkedExpenseAccountsText = (
  rules: ExpenseCategoryExpenseAccountRule[],
  expenseCategoryId: string,
  expenseAccounts: ExpenseAccount[],
  t: TGlobalFunctionTyped,
) => {
  const rule = rules.find((r) => r.expenseCategoryId === expenseCategoryId);

  return getExpenseAccountsString(
    rule?.expenseAccountIds || [],
    expenseAccounts,
    t,
  );
};

type Props = {
  costCenters: CostCenter[];
  expenseAccounts?: ExpenseAccount[];
  expenseCategories: ExpenseCategory[];
  expenseCategoryExpenseAccountRules?: ExpenseCategoryExpenseAccountRule[];
  isCostCentersFeatureEnabled: boolean;
  isExpenseCategoryExpenseAccountRuleActivated: boolean;
  maxHeight?: number;
  onDelete: (expenseCategory: ExpenseCategory) => void;
  onEdit: (expenseCategory: ExpenseCategory) => void;
  rowHeight?: TableProps<ExpenseCategory>['rowHeight'];
  withBorder?: boolean;
};

export const ExpenseCategoryTable = ({
  costCenters,
  expenseAccounts = [],
  expenseCategories,
  expenseCategoryExpenseAccountRules = [],
  isCostCentersFeatureEnabled,
  isExpenseCategoryExpenseAccountRuleActivated,
  maxHeight,
  onDelete,
  onEdit,
  rowHeight = 'compact',
  withBorder = false,
}: Props) => {
  const { t } = useTranslation('global');

  const getRowActions = (expenseCategory: ExpenseCategory) => {
    return (
      <div className="EcContainer__row__actions flex shrink-0 items-center justify-end gap-4 opacity-0 transition-opacity group-hover:opacity-100">
        <IconButton
          variant="tertiaryNeutral"
          iconName="pen"
          iconColor={colors.contentPrimary}
          onClick={() => onEdit(expenseCategory)}
          aria-label={t('misc.edit')}
        />
        <IconButton
          variant="tertiaryNeutral"
          iconName="trash"
          iconColor={colors.contentPrimary}
          onClick={() => onDelete(expenseCategory)}
          aria-label={t('misc.delete')}
        />
      </div>
    );
  };

  const getColumns = () => {
    const columns = [
      {
        id: 'expenseCategory',
        header: t('expenseCategories.expenseCategory'),
        renderCell: (expenseCategory: ExpenseCategory) => (
          <div className="group flex grid-flow-col items-center justify-between">
            <div className="truncate">{expenseCategory.name}</div>
            {!isCostCentersFeatureEnabled &&
              !isExpenseCategoryExpenseAccountRuleActivated &&
              getRowActions(expenseCategory)}
          </div>
        ),
      },
    ];

    if (isCostCentersFeatureEnabled) {
      columns.push({
        id: 'associatedCostCenter',
        header: t('expenseCategories.associatedCostCenter'),
        renderCell: (expenseCategory: ExpenseCategory) => (
          <div className="group flex grid-flow-col items-center justify-between">
            <div className="truncate">
              {getCostCentersString(
                expenseCategory.costCenterIds,
                costCenters,
                t,
              )}
            </div>
            {!isExpenseCategoryExpenseAccountRuleActivated &&
              getRowActions(expenseCategory)}
          </div>
        ),
      });
    }

    if (isExpenseCategoryExpenseAccountRuleActivated) {
      columns.push({
        id: 'expenseCategoryExpenseAccountRules',
        header: t('expenseCategories.linkedExpenseAccount'),
        renderCell: (expenseCategory: ExpenseCategory) => (
          <div className="group">
            <div className="truncate">
              {getLinkedExpenseAccountsText(
                expenseCategoryExpenseAccountRules,
                expenseCategory.id,
                expenseAccounts,
                t,
              )}
            </div>
            {getRowActions(expenseCategory)}
          </div>
        ),
      });
    }

    return columns;
  };

  return (
    <Table
      className={cx('EcContainer__expenseCategoryTable', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'EcContainer__expenseCategoryTable--noBorder': !withBorder,
      })}
      columns={getColumns()}
      data={expenseCategories}
      rowHeight={rowHeight}
      maxHeight={maxHeight}
      emptyState={{
        title: t('expenseCategories.emptyState.title'),
        subtitle: t('expenseCategories.emptyState.subtitle'),
      }}
      getRowId={(row) => row.id}
    />
  );
};
