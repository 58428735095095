import {
  AmountInput,
  AutocompleteMultiple,
  Avatar,
  DatePicker,
  DropdownItem,
  Input,
  OptionGroup,
  Select,
  TextInput,
} from '@dev-spendesk/grapes';
import { type ReactElement, useMemo } from 'react';

import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  useLoadCustomFieldValuesByIds,
  useLoadExpenseAccountsByIds,
  useLoadMembersByIds,
  useLoadPayableAccountsByIds,
  useLoadTaxAccountsByIds,
  useLoadTeamsByIds,
  useSearchCustomFieldValues,
  useSearchExpenseAccounts,
  useSearchMembers,
  useSearchPayableAccounts,
  useSearchTaxAccounts,
  useSearchTeams,
} from 'modules/bookkeep/prepare-payables/components/PreparePayablesFiltersBar/components/PreparePayablesFiltersAdditionalFilters/hooks';
import {
  useCostCentersQuery,
  useCustomFieldsQuery,
} from 'modules/budgets/apis';
import {
  useIsCostCenterFeatureEnabled,
  useIsCustomFieldFeatureEnabled,
  useIsTeamFeatureEnabled,
} from 'modules/budgets/features';
import { type CustomFieldDefinition } from 'modules/budgets/models/customFieldDefinition';
import { sortCostCenters } from 'modules/company/cost-centers/utils';
import {
  useCreatePayableFilter,
  useDeletePayableFilter,
  usePayableFilter,
  useUpdatePayableFilter,
} from 'modules/payable/hooks';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import {
  AutocompleteAsyncMultiple,
  type Option as BaseOption,
} from 'src/core/common/components/AutocompleteAsyncMultiple';
import { type AutocompleteAsyncMultipleProps } from 'src/core/common/components/AutocompleteAsyncMultiple/AutocompleteAsyncMultiple';
import { DateRangeFormField } from 'src/core/common/components/DateRangeFormField';
import {
  type Field,
  FilterBuilder,
  getNonEmptyArraySubfilterValue,
  type SelectedFilter,
  type SubfilterValueWithId,
  toFilterUrlParameter,
  useActiveFilter,
} from 'src/core/common/components/FilterBuilder';
import { fallbackSupplierLogoSrc } from 'src/core/common/components/SupplierLogo';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { normalizeString } from 'src/core/common/utils/normalizeString';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useHasAccountingIntegrationCapability } from 'src/core/modules/accounting-integration/apis';
import { SupplierFilterField } from 'src/core/modules/suppliers';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { getCurrencyOptionByKey } from 'src/core/utils/money';

const filterIdUrlParameterName = 'payableFilterId' as const;

const filterUrlParameterName = 'payableFilter' as const;

/**
 * @public
 */
export type PayableFilterProps = {
  variant: 'prepare-payables' | 'all-payables';
};

export const PayableFilterBuilder = ({ variant }: PayableFilterProps) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  const fields = useGetFields({ variant });

  const [createFilter] = useCreatePayableFilter();
  const [updateFilter] = useUpdatePayableFilter();
  const [deleteFilter] = useDeletePayableFilter();

  let savedFiltersQuery = usePayableFilter();

  if (savedFiltersQuery.status === 'success') {
    savedFiltersQuery = {
      ...savedFiltersQuery,
      data: savedFiltersQuery.data.map((filter) => {
        const hasBookkeepingStatus = filter.filter.subfilters.some(
          (subfilter) =>
            subfilter.subfilters.some(
              ({ field }) => field === 'bookkeepingStatus',
            ),
        );

        if (hasBookkeepingStatus && variant === 'prepare-payables') {
          return { ...filter, disabledMessage: t('payables.disabledFilter') };
        }

        return filter;
      }),
    };
  }

  const resetPath =
    variant === 'prepare-payables'
      ? routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
          company: companyId,
        })
      : routeFor(routes.PAYABLES_ALL.path, {
          company: companyId,
        });

  // Members
  const onSearchMembers = useSearchMembers();
  const onSearchMembersOptions = async (search: string) => {
    const members = await onSearchMembers(search);
    return members.map(({ id, fullName }) => ({ key: id, label: fullName }));
  };

  const onGetMembersByKeys = useLoadMembersByIds();
  const onGetMembersOptionsByKeys = async (ids: string[]) => {
    const members = await onGetMembersByKeys(ids);
    return members.map(({ id, fullname }) => ({ key: id, label: fullname }));
  };

  // Expense accounts
  const onSearchExpenseAccounts = useSearchExpenseAccounts();
  const onSearchExpenseAccountsOptions = async (search: string) => {
    const accounts = await onSearchExpenseAccounts(search);
    return accounts.map(({ id, name }) => ({ key: id, label: name }));
  };
  const onGetExpenseAccountsByKeys = useLoadExpenseAccountsByIds();
  const onGetExpenseAccountsOptionsByKeys = async (ids: string[]) => {
    const accounts = await onGetExpenseAccountsByKeys(ids);
    return accounts.map(({ id, name }) => ({ key: id, label: name }));
  };

  // Tax accounts
  const onSearchTaxAccounts = useSearchTaxAccounts();
  const onSearchTaxAccountsOptions = async (search: string) => {
    const accounts = await onSearchTaxAccounts(search);
    return accounts.map(({ id, name }) => ({ key: id, label: name }));
  };
  const onGetTaxAccountsByKeys = useLoadTaxAccountsByIds();
  const onGetTaxAccountsOptionsByKeys = async (ids: string[]) => {
    const accounts = await onGetTaxAccountsByKeys(ids);
    return accounts.map(({ id, name }) => ({ key: id, label: name }));
  };

  // Supplier/Employee accounts
  const onSearchPayableAccounts = useSearchPayableAccounts();
  const onSearchPayableAccountsOptions = async (search: string) => {
    const accounts = await onSearchPayableAccounts(search);
    return [
      {
        key: 'supplier-account',
        label: t('payables.filterFields.supplierAccounts'),
        options: accounts.supplierAccounts.map(({ id, name }) => ({
          key: id,
          label: name,
        })),
      },
      {
        key: 'employee-account',
        label: t('payables.filterFields.employeeAccounts'),
        options: accounts.employeeAccounts.map(({ id, name }) => ({
          key: id,
          label: name,
        })),
      },
    ];
  };
  const onGetPayableAccountsByKeys = useLoadPayableAccountsByIds();
  const onGetPayableAccountsOptionsByKeys = async (ids: string[]) => {
    const accounts = await onGetPayableAccountsByKeys(ids);
    return [
      {
        key: 'supplier-account',
        label: t('payables.filterFields.supplierAccounts'),
        options: accounts.supplierAccounts.map(({ id, name }) => ({
          key: id,
          label: name,
        })),
      },
      {
        key: 'employee-account',
        label: t('payables.filterFields.employeeAccounts'),
        options: accounts.employeeAccounts.map(({ id, name }) => ({
          key: id,
          label: name,
        })),
      },
    ];
  };

  // Teams
  const onSearchTeams = useSearchTeams();
  const onSearchTeamsOptions = async (search: string) => {
    const teams = await onSearchTeams(search);
    return teams.map(({ id, name }) => ({ key: id, label: name }));
  };
  const onGetTeamsByKeys = useLoadTeamsByIds();
  const onGetTeamsOptionsByKeys = async (ids: string[]) => {
    const teams = await onGetTeamsByKeys(ids);
    return teams.map(({ id, name }) => ({ key: id, label: name }));
  };

  // Cost centers
  const costCenters = sortCostCenters(unwrapQuery(useCostCentersQuery()) ?? []);
  const onGetCostCentersOptionsByKeys = async (ids: string[]) => {
    return costCenters
      .filter(({ id }) => ids.includes(id))
      .map(({ id, name }) => ({ key: id, label: name }));
  };
  const onSearchCostCentersOptions = async (search: string) => {
    return costCenters
      .filter(({ name }) =>
        normalizeString(name).includes(normalizeString(search)),
      )
      .map(({ id, name }) => ({ key: id, label: name }));
  };

  const hasPayableFilterFeature = useFeature(FEATURES.TMP_PAYABLE_FILTERS);
  if (!hasPayableFilterFeature) {
    return null;
  }

  return (
    <FilterBuilder
      fields={fields}
      filterIdUrlParameterName={filterIdUrlParameterName}
      filterUrlParameterName={filterUrlParameterName}
      savedFiltersQuery={savedFiltersQuery}
      onFilterBuilderOpened={() => {
        track(AnalyticEventName.PAYABLES_FILTER_MODALE_OPENED, {
          pageUrl: window.location.href,
        });
      }}
      onDuplicateFilterClicked={() => {
        track(AnalyticEventName.PAYABLES_FILTER_DUPLICATE_CLICKED, {
          pageUrl: window.location.href,
        });
      }}
      onFilterReset={() => {
        track(AnalyticEventName.EXPENSE_INBOX_FILTERS_RESET, {
          type: 'empty_state',
          pageUrl: window.location.href,
        });
      }}
      createFilter={createFilter}
      updateFilter={updateFilter}
      deleteFilter={deleteFilter}
      resetPath={resetPath}
      renderSubfilterValue={({
        subfilterValue,
        updateSubfilterValue,
        subfilterOperatorName,
        subfilterValueName,
      }) => {
        const renderIdTypeWithPredefinedOptionsField = (
          options: React.ComponentProps<
            typeof IdTypeWithPredefinedOptionsField
          >['options'],
        ) => (
          <IdTypeWithPredefinedOptionsField
            options={options}
            value={subfilterValue}
            onChange={updateSubfilterValue}
            subfilterOperatorName={subfilterOperatorName}
            subfilterValueName={subfilterValueName}
          />
        );

        const [, customFieldId] =
          subfilterValue.field?.match(/customField-(.*)/) ?? [];
        if (customFieldId) {
          return (
            <CustomFieldIdTypeField
              customFieldId={customFieldId}
              value={subfilterValue}
              onChange={updateSubfilterValue}
              subfilterOperatorName={subfilterOperatorName}
              subfilterValueName={subfilterValueName}
            />
          );
        }

        switch (subfilterValue.field) {
          case 'payableType': {
            return renderIdTypeWithPredefinedOptionsField(
              getPayableTypeOptions(t),
            );
          }

          case 'paymentStatus': {
            return renderIdTypeWithPredefinedOptionsField(
              getPaymentStatusOptions(t),
            );
          }

          case 'bookkeepingStatus': {
            return renderIdTypeWithPredefinedOptionsField(
              getBookkeepingStatusOptions(t),
            );
          }

          case 'documentaryEvidenceStatus': {
            return renderIdTypeWithPredefinedOptionsField(
              getDocumentaryEvidenceStatusOptions(t),
            );
          }

          case 'requestor': {
            return (
              <IdTypeField
                value={subfilterValue}
                onChange={updateSubfilterValue}
                subfilterOperatorName={subfilterOperatorName}
                subfilterValueName={subfilterValueName}
                onSearch={onSearchMembersOptions}
                onGetByKeys={onGetMembersOptionsByKeys}
              />
            );
          }

          case 'supplier': {
            type Option = {
              key: string;
              label: string;
              thumbnailUrl?: string;
            };

            const getSupplierNamePrefix = (option: Option | undefined) => {
              const defaultUrl = option?.label
                ? `https://logo.clearbit.com/${option.label
                    .toLowerCase()
                    .replaceAll(' ', '')}.com`
                : undefined;

              return (
                <Avatar
                  variant="square"
                  src={option?.thumbnailUrl ?? defaultUrl}
                  fallbackSrc={fallbackSupplierLogoSrc}
                  text={option?.label ?? ''}
                  size={24}
                />
              );
            };

            let selectedKeys = [];
            if (typeof subfilterValue.value === 'string') {
              selectedKeys = [subfilterValue.value];
            } else if (Array.isArray(subfilterValue.value)) {
              selectedKeys = subfilterValue.value;
            }

            return (
              <IdTypeField<Option>
                value={subfilterValue}
                onChange={updateSubfilterValue}
                subfilterOperatorName={subfilterOperatorName}
                subfilterValueName={subfilterValueName}
                renderPrefix={getSupplierNamePrefix}
                field={
                  <SupplierFilterField
                    name={subfilterValueName}
                    value={selectedKeys}
                    onChange={(newValue) =>
                      updateSubfilterValue({
                        ...subfilterValue,
                        value: newValue.length ? newValue : undefined,
                      })
                    }
                  />
                }
              />
            );
          }

          case 'expenseAccount': {
            return (
              <IdTypeField
                value={subfilterValue}
                onChange={updateSubfilterValue}
                subfilterOperatorName={subfilterOperatorName}
                subfilterValueName={subfilterValueName}
                onSearch={onSearchExpenseAccountsOptions}
                onGetByKeys={onGetExpenseAccountsOptionsByKeys}
              />
            );
          }

          case 'vatAccount': {
            return (
              <IdTypeField
                value={subfilterValue}
                onChange={updateSubfilterValue}
                subfilterOperatorName={subfilterOperatorName}
                subfilterValueName={subfilterValueName}
                onSearch={onSearchTaxAccountsOptions}
                onGetByKeys={onGetTaxAccountsOptionsByKeys}
              />
            );
          }

          case 'accountPayable': {
            return (
              <IdTypeField
                value={subfilterValue}
                onChange={updateSubfilterValue}
                subfilterOperatorName={subfilterOperatorName}
                subfilterValueName={subfilterValueName}
                onSearch={onSearchPayableAccountsOptions}
                onGetByKeys={onGetPayableAccountsOptionsByKeys}
              />
            );
          }

          case 'team': {
            return (
              <IdTypeField
                value={subfilterValue}
                onChange={updateSubfilterValue}
                subfilterOperatorName={subfilterOperatorName}
                subfilterValueName={subfilterValueName}
                onSearch={onSearchTeamsOptions}
                onGetByKeys={onGetTeamsOptionsByKeys}
              />
            );
          }

          case 'costCenter': {
            return (
              <IdTypeField
                value={subfilterValue}
                onChange={updateSubfilterValue}
                subfilterOperatorName={subfilterOperatorName}
                subfilterValueName={subfilterValueName}
                onSearch={onSearchCostCentersOptions}
                onGetByKeys={onGetCostCentersOptionsByKeys}
                maxOptions={50}
              />
            );
          }

          case 'grossAmount':
          case 'vatAmount':
          case 'netAmount':
            return (
              <AmountTypeField
                value={subfilterValue}
                onChange={updateSubfilterValue}
                subfilterOperatorName={subfilterOperatorName}
                subfilterValueName={subfilterValueName}
              />
            );

          case 'description':
          case 'documentaryEvidenceNumber':
            return (
              <StringTypeField
                value={subfilterValue}
                onChange={updateSubfilterValue}
                subfilterOperatorName={subfilterOperatorName}
                subfilterValueName={subfilterValueName}
              />
            );

          case 'payableDate':
            return (
              <DateTypeField
                value={subfilterValue}
                onChange={updateSubfilterValue}
                subfilterOperatorName={subfilterOperatorName}
                subfilterValueName={subfilterValueName}
              />
            );

          default:
            return <div className="w-full" />;
        }
      }}
    />
  );
};

/**
 * Active filter hook
 */

export const useActivePayableFilter = ({
  variant,
}: {
  variant?: PayableFilterProps['variant'];
} = {}): SelectedFilter | undefined => {
  const fields = useGetFields({ variant });
  const savedFiltersQuery = usePayableFilter();
  const savedFilters = useMemo(
    () => unwrapQuery(savedFiltersQuery) ?? [],
    [
      savedFiltersQuery.status === 'success'
        ? savedFiltersQuery.data
            .map((f) => f.id + f.name + toFilterUrlParameter(f, fields))
            .join(',')
        : null,
    ],
  );

  const activefilter = useActiveFilter({
    filterIdUrlParameterName,
    filterUrlParameterName,
    savedFilters,
    fields,
  });

  if (!useFeature(FEATURES.TMP_PAYABLE_FILTERS)) {
    return undefined;
  }

  return activefilter;
};

/**
 * Opertor helpers
 */

const getIdOperatorOptions = (t: TGlobalFunctionTyped, isMultiple: boolean) => {
  return [
    {
      key: 'is',
      label: isMultiple
        ? t('payables.filterOperators.isAnyOf')
        : t('payables.filterOperators.is'),
    },
    {
      key: 'isNot',
      label: isMultiple
        ? t('payables.filterOperators.isNoneOf')
        : t('payables.filterOperators.isNot'),
    },
    { key: 'isEmpty', label: t('payables.filterOperators.isEmpty') },
    { key: 'isNotEmpty', label: t('payables.filterOperators.isNotEmpty') },
  ];
};

const getStringOperatorOptions = (t: TGlobalFunctionTyped) => {
  return [
    { key: 'is', label: t('payables.filterOperators.is') },
    { key: 'isNot', label: t('payables.filterOperators.isNot') },
    { key: 'contains', label: t('payables.filterOperators.contains') },
    {
      key: 'notContains',
      label: t('payables.filterOperators.notContains'),
    },
    { key: 'startsWith', label: t('payables.filterOperators.startsWith') },
    { key: 'endsWith', label: t('payables.filterOperators.endsWith') },
    { key: 'isEmpty', label: t('payables.filterOperators.isEmpty') },
    { key: 'isNotEmpty', label: t('payables.filterOperators.isNotEmpty') },
  ];
};

const getDateOperatorOptions = (t: TGlobalFunctionTyped) => {
  return [
    { key: 'inLast', label: t('payables.filterOperators.isInTheLast') },
    { key: 'is', label: t('payables.filterOperators.isExactDate') },
    {
      key: 'isBetween',
      label: t('payables.filterOperators.isWithinDateRange'),
    },
    { key: 'before', label: t('payables.filterOperators.isBefore') },
    { key: 'after', label: t('payables.filterOperators.isAfter') },
  ];
};

const getAmountOperatorOptions = (t: TGlobalFunctionTyped) => {
  return [
    { key: '=', label: t('payables.filterOperators.equal') },
    { key: '!=', label: t('payables.filterOperators.notEqual') },
    { key: '<', label: t('payables.filterOperators.less') },
    { key: '<=', label: t('payables.filterOperators.lessOrEqual') },
    { key: '>', label: t('payables.filterOperators.greater') },
    {
      key: '>=',
      label: t('payables.filterOperators.greaterOrEqual'),
    },
    { key: 'isBetween', label: t('payables.filterOperators.isBetween') },
    { key: 'isNotBetween', label: t('payables.filterOperators.isNotBetween') },
  ];
};

/**
 * Fields helpers
 */

const useGetFields = ({
  variant,
}: {
  variant?: PayableFilterProps['variant'];
} = {}): Field[] => {
  type Option = { key: string; label: string };

  const { t } = useTranslation('global');

  const isTeamFeatureEnabled = useIsTeamFeatureEnabled();
  const isCostCenterFeatureEnabled = useIsCostCenterFeatureEnabled();
  const isCustomFieldsEnabled = useIsCustomFieldFeatureEnabled();
  const hasTaxAccountsCapability =
    useHasAccountingIntegrationCapability('taxAccounts');
  const hasExpenseAccountsCapability =
    useHasAccountingIntegrationCapability('expenseAccounts');
  const hasEmployeeAccountsCapability =
    useHasAccountingIntegrationCapability('employeeAccounts');
  const hasSupplierAccountsCapability =
    useHasAccountingIntegrationCapability('supplierAccounts');

  const customFields = unwrapQuery(
    useCustomFieldsQuery({ includeArchived: false }),
  );

  const accountingDetailsOptions = [
    (hasEmployeeAccountsCapability || hasSupplierAccountsCapability) && {
      key: 'accountPayable',
      label: t('payables.filterFields.accountPayable'),
    },
    hasExpenseAccountsCapability && {
      key: 'expenseAccount',
      label: t('payables.filterFields.expenseAccount'),
    },
    hasTaxAccountsCapability && {
      key: 'vatAccount',
      label: t('payables.filterFields.vatAccount'),
    },
  ].filter(Boolean) as Option[];

  const fields: Field[] = [
    {
      key: 'type',
      label: t('payables.filterFields.type'),
      options: [
        { key: 'payableType', label: t('payables.filterFields.payableType') },
      ],
      kind: 'id',
    },

    {
      key: 'date',
      label: t('payables.filterFields.date'),
      options: [
        { key: 'payableDate', label: t('payables.filterFields.payableDate') },
      ],
      kind: 'date',
    },

    {
      key: 'actor',
      label: t('payables.filterFields.actor'),
      options: [
        { key: 'supplier', label: t('payables.filterFields.supplier') },
        { key: 'requestor', label: t('payables.filterFields.requestor') },
      ],
      kind: 'id',
    },

    {
      key: 'amount',
      label: t('payables.filterFields.amount'),
      options: [
        {
          key: 'grossAmount',
          label: t('payables.filterFields.grossAmount'),
        },
        {
          key: 'netAmount',
          label: t('payables.filterFields.netAmount'),
        },
        {
          key: 'vatAmount',
          label: t('payables.filterFields.vatAmount'),
        },
      ],
      kind: 'amount',
    },

    {
      key: 'documentaryEvidence',
      label: t('payables.filterFields.documentaryEvidence'),
      options: [
        {
          key: 'documentaryEvidenceNumber',
          label: t('payables.filterFields.documentaryEvidenceNumber'),
        },
      ],
      kind: 'string',
    },

    {
      key: 'status',
      label: t('payables.filterFields.status'),
      options: [
        {
          key: 'paymentStatus',
          label: t('payables.filterFields.paymentStatus'),
        },
        {
          key: 'documentaryEvidenceStatus',
          label: t('payables.filterFields.documentaryEvidenceStatus'),
        },
        variant === 'all-payables' && {
          key: 'bookkeepingStatus',
          label: t('payables.filterFields.bookkeepingStatus'),
        },
      ].filter(Boolean) as Option[],
      kind: 'id',
    },

    {
      key: 'context',
      label: t('payables.filterFields.context'),
      options: [
        {
          key: 'description',
          label: t('payables.filterFields.description'),
        },
      ],
      kind: 'string',
    },

    accountingDetailsOptions.length && {
      key: 'accounting-details',
      label: t('payables.filterFields.accountingDetails'),
      options: accountingDetailsOptions,
      kind: 'id',
    },

    {
      key: 'analytical-details',
      label: t('payables.filterFields.analyticalDetails'),
      options: [
        isTeamFeatureEnabled && {
          key: 'team',
          label: t('payables.filterFields.team'),
        },

        isCostCenterFeatureEnabled && {
          key: 'costCenter',
          label: t('payables.filterFields.costCenter'),
        },

        ...(isCustomFieldsEnabled
          ? (customFields?.expenseCategoryField
              ? [
                  {
                    key: `customField-${customFields?.expenseCategoryField.id}`,
                    label: t('misc.expenseCategory'),
                  },
                ]
              : []
            ).concat(
              (customFields?.customFields ?? []).map(({ id, name }) => ({
                key: `customField-${id}`,
                label: name,
              })),
            )
          : []),
      ].filter(Boolean) as Option[],
      kind: 'id',
    },
  ].filter(Boolean) as Field[];

  return useMemo(
    () => fields,
    [fields.flatMap((f) => f.options?.map((o) => o.key) ?? [f.key]).join(',')],
  );
};

const getPayableTypeOptions = (t: TGlobalFunctionTyped) => {
  return [
    {
      key: 'singleCardPurchase',
      label: t('payables.payableTypes.singleCardPurchase'),
    },
    {
      key: 'subscription',
      label: t('payables.payableTypes.subscription'),
    },
    {
      key: 'physicalCardPurchase',
      label: t('payables.payableTypes.physicalCardPurchase'),
    },
    {
      key: 'cardRefund',
      label: t('payables.payableTypes.cardRefund'),
    },
    {
      key: 'invoicePurchase',
      label: t('payables.payableTypes.invoicePurchase'),
    },
    {
      key: 'creditNote',
      label: t('payables.payableTypes.creditNote'),
    },
    {
      key: 'expenseClaim',
      label: t('payables.payableTypes.expenseClaim'),
    },
    {
      key: 'mileageAllowance',
      label: t('payables.payableTypes.mileageAllowance'),
    },
    {
      key: 'perDiemAllowance',
      label: t('payables.payableTypes.perDiemAllowance'),
    },
  ];
};

const getPaymentStatusOptions = (t: TGlobalFunctionTyped) => {
  return [
    {
      key: 'toPay',
      label: t('payables.paymentStatus.toPayOrToReimburse'),
    },
    {
      key: 'paid',
      label: t('payables.paymentStatus.paidOrReimbursed'),
    },
  ];
};

const getBookkeepingStatusOptions = (t: TGlobalFunctionTyped) => {
  return [
    {
      key: 'toPrepare',
      label: t('payables.bookkeepingStatus.toPrepare'),
    },
    {
      key: 'toExport',
      label: t('payables.bookkeepingStatus.toExport'),
    },
    {
      key: 'exported',
      label: t('payables.bookkeepingStatus.exported'),
    },
  ];
};

const getDocumentaryEvidenceStatusOptions = (t: TGlobalFunctionTyped) => {
  return [
    {
      key: 'provided',
      label: t('payables.documentaryEvidenceStatus.provided'),
    },
    {
      key: 'providedAndDpr',
      label: t('payables.documentaryEvidenceStatus.providedAndDpr'),
    },
    {
      key: 'missing',
      label: t('payables.documentaryEvidenceStatus.missing'),
    },
    {
      key: 'cannotBeProvided',
      label: t('payables.documentaryEvidenceStatus.cannotBeProvided'),
    },
    {
      key: 'declaredAsInvalid',
      label: t('payables.documentaryEvidenceStatus.declaredAsInvalid'),
    },
  ];
};

const getRelativeDateStatusOptions = (t: TGlobalFunctionTyped) => {
  return [
    {
      key: 'days',
      label: t('common.filterBuilder.days'),
    },
    {
      key: 'months',
      label: t('common.filterBuilder.months'),
    },
    {
      key: 'years',
      label: t('common.filterBuilder.years'),
    },
  ];
};

/**
 * Child components
 */

const IdTypeWithPredefinedOptionsField = ({
  options,
  value,
  onChange,
  subfilterOperatorName,
  subfilterValueName,
}: {
  options: { key: string; label: string }[];
  value: SubfilterValueWithId;
  onChange: (value: SubfilterValueWithId) => void;
  subfilterOperatorName: string;
  subfilterValueName: string;
}) => {
  const { t } = useTranslation('global');

  const rawValue = value.value ?? [];

  const keysOfValues: string[] = Array.isArray(rawValue)
    ? rawValue
    : [rawValue];

  const values = options.filter((option) => keysOfValues.includes(option.key));

  const idOperatorOptions = getIdOperatorOptions(t, values.length > 1);

  const hasSecondField = value.operator
    ? ['is', 'isNot'].includes(value.operator)
    : undefined;

  return (
    <>
      <Select
        onSelect={({ key }) => {
          onChange({
            ...value,
            operator: key,
            value: ['is', 'isNot'].includes(key) ? value.value : null,
          });
        }}
        options={idOperatorOptions}
        value={idOperatorOptions.find(
          (option) => option.key === value.operator,
        )}
        className={`[&_input]:w-full ${
          hasSecondField === false ? 'w-full' : 'w-[140px]'
        }`}
        fit={hasSecondField === false ? 'parent' : 'content'}
        renderOption={(option, optionState) => (
          <DropdownItem
            key={option.key}
            label={option.label}
            {...optionState}
            className="min-w-[240px]"
          />
        )}
        name={subfilterOperatorName}
        dropdownContentMaxHeight="250px"
      />

      {hasSecondField === true && (
        <AutocompleteMultiple
          onSelect={(newValues) => {
            onChange({
              ...value,
              value: getNonEmptyArraySubfilterValue(
                newValues.map((v) => v.key),
              ),
            });
          }}
          options={options}
          values={values}
          translations={{
            selectAll: t('misc.selectAll'),
            selected: values.map((v) => v.label).join(', '),
          }}
          onSearch={() => {
            // Do nothing
          }}
          fit="parent"
          name={subfilterValueName}
        />
      )}

      {hasSecondField === undefined && <div className="w-full" />}
    </>
  );
};

function IdTypeField<T extends BaseOption>({
  value,
  onChange,
  subfilterOperatorName,
  subfilterValueName,
  onSearch,
  onGetByKeys,
  renderPrefix,
  field,
  maxOptions,
}:
  | ({
      value: SubfilterValueWithId;
      onChange: (value: SubfilterValueWithId) => void;
      subfilterOperatorName: string;
      subfilterValueName: string;
      maxOptions?: number;
      field?: undefined;
    } & Pick<
      AutocompleteAsyncMultipleProps<T>,
      'onSearch' | 'onGetByKeys' | 'renderPrefix'
    >)
  | ({
      value: SubfilterValueWithId;
      onChange: (value: SubfilterValueWithId) => void;
      subfilterOperatorName: string;
      subfilterValueName: string;
      maxOptions?: number;
      field: ReactElement;
      onSearch?: undefined;
      onGetByKeys?: undefined;
    } & Pick<AutocompleteAsyncMultipleProps<T>, 'renderPrefix'>)) {
  const { t } = useTranslation('global');

  let selectedKeys = [];
  if (typeof value.value === 'string') {
    selectedKeys = [value.value];
  } else if (Array.isArray(value.value)) {
    selectedKeys = value.value;
  }

  const idOperatorOptions = getIdOperatorOptions(t, selectedKeys.length > 1);

  const hasSecondField = value.operator
    ? ['is', 'isNot'].includes(value.operator)
    : undefined;

  const valueField = field ?? (
    <AutocompleteAsyncMultiple
      selectedKeys={selectedKeys}
      onSelect={(values) => {
        const newValue = values.map(({ key }) => key);
        onChange({
          ...value,
          value: newValue.length ? newValue : undefined,
        });
      }}
      onGetByKeys={onGetByKeys}
      onSearch={onSearch}
      name={subfilterValueName}
      renderPrefix={renderPrefix}
      maxOptions={maxOptions}
    />
  );

  return (
    <>
      <Select
        onSelect={({ key }) => {
          onChange({
            ...value,
            operator: key,
            value: ['is', 'isNot'].includes(key) ? value.value : null,
          });
        }}
        options={idOperatorOptions}
        value={idOperatorOptions.find(
          (option) => option.key === value.operator,
        )}
        className={`[&_input]:w-full ${
          hasSecondField === false ? 'w-full' : 'w-[140px]'
        }`}
        fit={hasSecondField === false ? 'parent' : 'content'}
        renderOption={(option, optionState) => (
          <DropdownItem
            key={option.key}
            label={option.label}
            {...optionState}
            className="min-w-[240px]"
          />
        )}
        name={subfilterOperatorName}
        dropdownContentMaxHeight="250px"
      />

      {hasSecondField === true && valueField}

      {value.operator === undefined && <div className="w-full" />}
    </>
  );
}

const AmountTypeField = ({
  value,
  onChange,
  subfilterOperatorName,
  subfilterValueName,
}: {
  value: SubfilterValueWithId;
  onChange: (value: SubfilterValueWithId) => void;
  subfilterOperatorName: string;
  subfilterValueName: string;
}) => {
  type RangeValue = { from: number | null; to: number | null };

  const { t } = useTranslation('global');

  const singleValue = typeof value.value === 'number' ? value.value : null;

  const rangeValue =
    value.value && typeof value.value === 'object'
      ? (value.value as RangeValue)
      : null;

  const amountOperatorOptions = getAmountOperatorOptions(t);

  const hasRangeField = value.operator
    ? ['isBetween', 'isNotBetween'].includes(value.operator)
    : undefined;

  const companyCurrency = useCompanyCurrency();

  const selectedCurrencyOption = useMemo(
    () => getCurrencyOptionByKey(companyCurrency),
    [companyCurrency],
  );

  return (
    <>
      <Select
        onSelect={({ key }) => {
          onChange({
            ...value,
            operator: key,
            value: ['isBetween', 'isNotBetWeen'].includes(key)
              ? undefined
              : value.value,
          });
        }}
        options={amountOperatorOptions}
        value={amountOperatorOptions.find(
          (option) => option.key === value.operator,
        )}
        className="w-[140px] [&_input]:w-full"
        fit="content"
        renderOption={(option, optionState) => (
          <DropdownItem
            key={option.key}
            label={option.label}
            {...optionState}
            className="min-w-[240px]"
          />
        )}
        name={subfilterOperatorName}
        dropdownContentMaxHeight="250px"
      />

      {value.operator ? (
        <AmountInput
          hasNegativeValueAllowed
          currency={selectedCurrencyOption}
          value={hasRangeField ? (rangeValue?.from ?? null) : singleValue}
          onChange={(event) => {
            let newValue: number | null | RangeValue = Number.parseFloat(
              event.target.value,
            );
            newValue = Number.isNaN(newValue) ? null : newValue;
            if (hasRangeField) {
              newValue = { from: newValue, to: rangeValue?.to ?? null };
            }
            onChange({ ...value, value: newValue });
          }}
          fit={!hasRangeField ? 'parent' : 'content'}
          className={`${!hasRangeField ? 'w-full' : 'w-1/2'} [&_input]:w-full`}
          name={subfilterValueName}
        />
      ) : (
        <div className="w-full" />
      )}

      {hasRangeField && (
        <>
          {t('common.filterBuilder.and')}{' '}
          <AmountInput
            hasNegativeValueAllowed
            currency={selectedCurrencyOption}
            value={rangeValue?.to ?? null}
            onChange={(event) => {
              let newValue: number | null | RangeValue = Number.parseFloat(
                event.target.value,
              );
              newValue = Number.isNaN(newValue) ? null : newValue;
              newValue = { from: rangeValue?.from ?? null, to: newValue };
              onChange({ ...value, value: newValue });
            }}
            className="w-1/2 [&_input]:w-full"
            name={subfilterValueName}
          />
        </>
      )}
    </>
  );
};

const StringTypeField = ({
  value,
  onChange,
  subfilterOperatorName,
  subfilterValueName,
}: {
  value: SubfilterValueWithId;
  onChange: (value: SubfilterValueWithId) => void;
  subfilterOperatorName: string;
  subfilterValueName: string;
}) => {
  const { t } = useTranslation('global');

  const rawValue = value.value as string | number | undefined;

  const stringOperatorOptions = getStringOperatorOptions(t);

  const operatorsWithSecondField = [
    'is',
    'isNot',
    'contains',
    'notContains',
    'startsWith',
    'endsWith',
  ];

  const hasSecondField = value.operator
    ? operatorsWithSecondField.includes(value.operator)
    : undefined;

  return (
    <>
      <Select
        onSelect={({ key }) => {
          onChange({
            ...value,
            operator: key,
            value: operatorsWithSecondField.includes(key) ? value.value : null,
          });
        }}
        options={stringOperatorOptions}
        value={stringOperatorOptions.find(
          (option) => option.key === value.operator,
        )}
        className={`[&_input]:w-full ${
          hasSecondField === false ? 'w-full' : 'w-[140px]'
        }`}
        fit={hasSecondField === false ? 'parent' : 'content'}
        renderOption={(option, optionState) => (
          <DropdownItem
            key={option.key}
            label={option.label}
            {...optionState}
            className="min-w-[240px]"
          />
        )}
        name={subfilterOperatorName}
        dropdownContentMaxHeight="250px"
      />

      {hasSecondField === true && (
        <TextInput
          value={rawValue ?? ''}
          onChange={(event) => {
            const newValue = event.target.value;
            onChange({ ...value, value: newValue });
          }}
          fit="parent"
          name={subfilterValueName}
        />
      )}

      {hasSecondField === undefined && <div className="w-full" />}
    </>
  );
};

const DateTypeField = ({
  value,
  onChange,
  subfilterOperatorName,
  subfilterValueName,
}: {
  value: SubfilterValueWithId;
  onChange: (value: SubfilterValueWithId) => void;
  subfilterOperatorName: string;
  subfilterValueName: string;
}) => {
  type RangeValue = { from: Date | undefined; to: Date | undefined };

  const { t } = useTranslation('global');

  const rawValue =
    typeof value.value === 'string'
      ? new Date(value.value)
      : (value.value as Date | undefined);

  const rangeValue =
    value.value && typeof value.value === 'object' && 'from' in value.value
      ? (value.value as RangeValue)
      : null;

  const relativeValue =
    value.value && typeof value.value === 'object' && 'amount' in value.value
      ? (value.value as { amount: number; timeUnit: string })
      : null;

  const dateOperatorOptions = getDateOperatorOptions(t);

  const relativeDateOptions = getRelativeDateStatusOptions(t);

  return (
    <>
      <Select
        onSelect={({ key }) => {
          onChange({
            ...value,
            operator: key,
            value:
              key === 'inLast'
                ? { amount: undefined, timeUnit: 'days' }
                : undefined,
          });
        }}
        options={dateOperatorOptions}
        value={dateOperatorOptions.find(
          (option) => option.key === value.operator,
        )}
        className="w-[140px] [&_input]:w-full"
        fit="content"
        renderOption={(option, optionState) => (
          <DropdownItem
            key={option.key}
            label={option.label}
            {...optionState}
            className="min-w-[240px]"
          />
        )}
        name={subfilterOperatorName}
        dropdownContentMaxHeight="250px"
      />

      {value.operator === 'isBetween' && (
        <DateRangeFormField
          placement="bottom-end"
          calendarId={subfilterValueName}
          value={[rangeValue?.from, rangeValue?.to]}
          onChange={([from, to]) => {
            onChange({ ...value, value: { from, to } });
          }}
          name={subfilterValueName}
          withLabel={false}
          withSeparator
          inputClassName="w-1/2 [&_input]:w-full"
        />
      )}

      {value.operator === 'inLast' && (
        <div className="flex w-full flex-row items-center gap-8">
          <Input
            className="w-1/2"
            name={subfilterValueName}
            type="number"
            step="1"
            min={0}
            max={1000}
            value={relativeValue?.amount ?? ''}
            onChange={(event) => {
              if (event.target.checkValidity()) {
                const amount = Number.parseInt(event.target.value);
                onChange({
                  ...value,
                  value: { amount, timeUnit: relativeValue?.timeUnit },
                });
              }
            }}
          />
          <div className="w-1/2">
            <Select
              onSelect={({ key }) => {
                onChange({
                  ...value,
                  value: { amount: relativeValue?.amount, timeUnit: key },
                });
              }}
              options={relativeDateOptions}
              value={relativeDateOptions.find(
                (option) => option.key === relativeValue?.timeUnit,
              )}
              className="w-full [&_input]:w-full"
              fit="content"
              name={subfilterValueName}
            />
          </div>
        </div>
      )}

      {['is', 'before', 'after'].includes(value.operator ?? '') && (
        <div className="w-full">
          <DatePicker
            value={Array.isArray(rawValue) ? undefined : rawValue}
            onChange={(newValue) => {
              onChange({ ...value, value: newValue });
            }}
            className="w-full [&_input]:w-full"
            fit="parent"
            // @ts-expect-error DatePicker is missing `name` prop definition
            name={subfilterValueName}
          />
        </div>
      )}

      {value.operator === undefined && <div className="w-full" />}
    </>
  );
};

const CustomFieldIdTypeField = ({
  customFieldId,
  value,
  onChange,
  subfilterOperatorName,
  subfilterValueName,
}: {
  customFieldId: string;
  value: SubfilterValueWithId;
  onChange: (value: SubfilterValueWithId) => void;
  subfilterOperatorName: string;
  subfilterValueName: string;
}) => {
  const { t } = useTranslation('global');

  const customFields = unwrapQuery(
    useCustomFieldsQuery({ includeArchived: false }),
  );

  const customField = [
    customFields?.expenseCategoryField,
    ...(customFields?.customFields ?? []),
  ].find((field) => field?.id === customFieldId);

  const getBooleanValues = (cf: CustomFieldDefinition) => {
    const trueValue = cf.custom_fields_values.find((cfValue) =>
      ['true', '1'].includes(cfValue.value),
    );
    const falseValue = cf.custom_fields_values.find((cfValue) =>
      ['false', '0'].includes(cfValue.value),
    );

    if (!trueValue || !falseValue) {
      return;
    }

    return [
      {
        value: trueValue.id,
        label: t('misc.yes'),
      },
      {
        value: falseValue.id,
        label: t('misc.no'),
      },
    ];
  };

  const rawValue = value.value as string | null | undefined;

  const booleanValues = customField && getBooleanValues(customField);

  const onSearchCustomFieldValues = useSearchCustomFieldValues(customFieldId);
  const onSearchCustomFieldValuesOptions = async (search: string) => {
    const customFieldValues = await onSearchCustomFieldValues(search);
    return customFieldValues.map(({ id, name }) => ({ key: id, label: name }));
  };

  const onGetCustomFieldValuesByKeys =
    useLoadCustomFieldValuesByIds(customFieldId);
  const onGetCustomFieldValuesOptionsByKeys = async (ids: string[]) => {
    const customFieldValues = await onGetCustomFieldValuesByKeys(ids);
    return customFieldValues.map(({ id, name }) => ({ key: id, label: name }));
  };

  let selectedKeys = [];
  if (typeof value.value === 'string') {
    selectedKeys = [value.value];
  } else if (Array.isArray(value.value)) {
    selectedKeys = value.value;
  }

  const idOperatorOptions = getIdOperatorOptions(t, selectedKeys.length > 1);

  const hasSecondField = value.operator
    ? ['is', 'isNot'].includes(value.operator)
    : undefined;

  return (
    <>
      <Select
        onSelect={({ key }) => {
          onChange({
            ...value,
            operator: key,
            value: ['is', 'isNot'].includes(key) ? value.value : null,
          });
        }}
        options={idOperatorOptions}
        value={idOperatorOptions.find(
          (option) => option.key === value.operator,
        )}
        className={`[&_input]:w-full ${
          hasSecondField === false ? 'w-full' : 'w-[140px]'
        }`}
        fit={hasSecondField === false ? 'parent' : 'content'}
        renderOption={(option, optionState) => (
          <DropdownItem
            key={option.key}
            label={option.label}
            {...optionState}
            className="min-w-[240px]"
          />
        )}
        name={subfilterOperatorName}
        dropdownContentMaxHeight="250px"
      />

      {hasSecondField === true &&
        customField?.type === 'boolean' &&
        booleanValues && (
          <OptionGroup
            className="w-full"
            name={subfilterValueName}
            options={booleanValues}
            value={rawValue || null}
            onChange={(event) => {
              const newValue = event.target.value;
              onChange({
                ...value,
                value: newValue ?? undefined,
              });
            }}
          />
        )}

      {hasSecondField === true && customField?.type === 'list' && (
        <AutocompleteAsyncMultiple
          selectedKeys={selectedKeys}
          onSelect={(values) => {
            const newValue = values.map(({ key }) => key);
            onChange({
              ...value,
              value: newValue.length ? newValue : undefined,
            });
          }}
          onGetByKeys={onGetCustomFieldValuesOptionsByKeys}
          onSearch={onSearchCustomFieldValuesOptions}
          name={subfilterValueName}
          totalOptions={customField.total_values}
        />
      )}

      {value.operator === undefined && <div className="w-full" />}
    </>
  );
};
