import { HighlightIcon } from '@dev-spendesk/grapes';

import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

type Props = {
  t: TGlobalFunctionTyped;
};

type AccountSuspensionText = {
  iconName: 'lock-close' | 'envelope';
  title: string;
  description: string;
};

export const AccountSuspensionEffects = ({ t }: Props) => {
  const accountSuspensionText: AccountSuspensionText[] = [
    {
      iconName: 'lock-close',
      title: t(
        'overdueInvoice.closedDoor.suspensionEffect.membersAccessBlocked.title',
      ),
      description: t(
        'overdueInvoice.closedDoor.suspensionEffect.membersAccessBlocked.description',
      ),
    },
    {
      iconName: 'envelope',
      title: t(
        'overdueInvoice.closedDoor.suspensionEffect.usersNotified.title',
      ),
      description: t(
        'overdueInvoice.closedDoor.suspensionEffect.usersNotified.description',
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-24">
      <h2 className="title-xl">
        {t('overdueInvoice.closedDoor.suspensionEffect.title')}
      </h2>
      <div className="flex flex-row justify-evenly gap-40">
        {accountSuspensionText.map((info, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="flex w-[208px] flex-col items-center gap-16 text-center"
          >
            <HighlightIcon name={info.iconName} size={56} variant="alert" />
            <span className="title-m">{info.title}</span>
            <span className="body-m">{info.description}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
