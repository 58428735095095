import {
  IconButton,
  Avatar,
  type TableVariant,
  Table,
  type TableColumn,
} from '@dev-spendesk/grapes';
import { type ReactChild } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { type EmployeeAccountValidationFailedResult } from 'modules/accounting-integration/models';
import { useHasAuxiliaryAccountsEnabled } from 'modules/bookkeep/hooks';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';

import {
  hasFooter,
  TableFooter,
} from './EmployeeAccountsLocalOnlySection/TableFooter';
import styles from './EmployeeAccountsSection.module.css';
import { type EmployeeAccount } from '../../../../../accounting';
import { userToString } from '../../../../../accounting/utils';
import { CellWithButton } from '../../components/CellWithIconButton/CellWithIconButton';

type Props = {
  employeeAccounts: EmployeeAccount[];
  hasNextPage: boolean;
  fetchNextPage: () => Promise<void>;
  integrationStatus: IntegrationStatusWithIntegration;
  isLoading: boolean;
  onDelete: (accountPayable: EmployeeAccount) => void;
};

export const IndividualEmployeeAccountsTable = ({
  employeeAccounts,
  hasNextPage,
  fetchNextPage,
  isLoading,
  integrationStatus,
  onDelete,
}: Props) => {
  const { t } = useTranslation('global');

  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();

  const isLocalOnly =
    integrationStatus.capabilities.employeeAccounts !==
    'pullWithDefaultAccounts';

  const addCellWithTrashButton = (
    row: EmployeeAccount,
    isRowHovered: boolean,
    defaultText: JSX.Element,
    propertyName: 'generalAccountCode' | 'auxiliaryAccountCode',
  ): ReactChild => {
    return (
      <CellWithButton
        isButtonVisible={isRowHovered}
        button={
          <span className="CellWithButton__button">
            <IconButton
              iconName="trash"
              variant="tertiaryNeutral"
              onClick={() => onDelete(row)}
              aria-label={t('misc.delete')}
            />
          </span>
        }
      >
        {row[propertyName] || defaultText}
      </CellWithButton>
    );
  };

  const employeeTableColumns: TableColumn<EmployeeAccount>[] = [
    {
      id: 'name',
      header: t('bookkeep.integrations.settings.employeeAccountsTable.name'),
      width: '50%',
      renderCell(row) {
        return (
          <div className={styles.tableAvatarWrapper}>
            {row.user ? (
              <Avatar
                text={row.user.firstName}
                src={row.user.avatar ?? ''}
                size={32}
              />
            ) : null}
            <div>
              {row.user ? (
                userToString(row.user)
              ) : (
                <span className="italic">
                  {isLocalOnly
                    ? t(
                        'bookkeep.integrations.settings.employeeAccountsTable.deletedEmployeeAccount',
                      )
                    : t(
                        'bookkeep.integrations.settings.employeeAccountsTable.notAssignedEmployeeAccount',
                      )}
                </span>
              )}
            </div>
          </div>
        );
      },
    },
    {
      id: 'generalAccountCode',
      header: auxiliaryAccountsEnabled
        ? t(
            'bookkeep.integrations.settings.employeeAccountsTable.accountCodeLabel',
          )
        : t('bookkeep.integrations.settings.employeeAccountsTable.code'),
      width: '50%',
      getCellVariant: (row) =>
        getCellVariant(
          row,
          integrationStatus?.settingsValidation?.employeeAccounts ?? [],
        ),
      renderCell(row, { isRowHovered }) {
        return auxiliaryAccountsEnabled || (!isLocalOnly && !row.user)
          ? row.generalAccountCode || <em>{t('misc.none')}</em>
          : addCellWithTrashButton(
              row,
              isRowHovered,
              <em>{t('misc.none')}</em>,
              'generalAccountCode',
            );
      },
    },
  ];

  if (auxiliaryAccountsEnabled) {
    employeeTableColumns.push({
      id: 'auxiliaryAccountCode',
      header: t(
        'bookkeep.integrations.settings.employeeAccountsTable.auxiliaryAccountCode',
      ),
      width: '50%',
      getCellVariant: (row) =>
        getCellVariant(
          row,
          integrationStatus?.settingsValidation?.employeeAccounts ?? [],
        ),
      renderCell(row, { isRowHovered }) {
        return isLocalOnly || row.user ? (
          addCellWithTrashButton(
            row,
            isRowHovered,
            <></>,
            'auxiliaryAccountCode',
          )
        ) : (
          <>{row.auxiliaryAccountCode}</>
        );
      },
    });
  }

  return (
    <Table
      rowHeight="compact"
      columns={employeeTableColumns}
      data={employeeAccounts}
      footer={
        hasFooter(isLoading, employeeAccounts, hasNextPage) ? (
          <TableFooter
            isLoading={isLoading}
            employeeAccounts={employeeAccounts}
            fetchNextPage={fetchNextPage}
          />
        ) : undefined
      }
    />
  );
};

const getCellVariant = (
  row: EmployeeAccount,
  employeeAccountsValidation: EmployeeAccountValidationFailedResult[],
): TableVariant | undefined => {
  if (employeeAccountsValidation.length) {
    const errors = employeeAccountsValidation.filter(
      (error) =>
        error.error === 'invalidAccount' &&
        error.id &&
        row.id &&
        !row.isDefault &&
        error.id === row.id,
    );
    return errors.length === 1 ? 'alert' : undefined;
  }
};
