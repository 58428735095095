import { Button, Tag } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';

import { toApiFormat as toApiApprovalRules } from 'modules/company/structure/approval-flows/models';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import {
  NotificationType,
  useNotifications,
} from 'src/core/modules/app/notifications';
import { editApprovalScheme } from 'src/core/modules/company/cost-centers/containers/CostCenterFormModalContainer/hooks/editApprovalSchemeQuery';
import { type ApprovalRule } from 'src/core/modules/company/structure/approval-flows';

import { validateForm } from './validate';
import { ApprovalScheme } from '../ApprovalScheme';

export type FormProps = {
  approvalRules: ApprovalRule[];
};

export const ApprovalSchemeEdition = ({
  approvalRules,
  approvalSchemeId,
  onClose,
}: {
  approvalRules: ApprovalRule[];
  approvalSchemeId: string;
  onClose: () => void;
}) => {
  const companyId = useCompanyId();
  const { t } = useTranslation('global');
  const queryClient = useQueryClient();
  const { pushNotif } = useNotifications();

  const {
    values: formValues,
    errors,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = useFormik<FormProps>({
    enableReinitialize: true,
    initialValues: {
      approvalRules,
    },
    validate: (values) => validateForm(values, t),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await editApprovalScheme({
          companyId,
          payload: {
            rules: toApiApprovalRules(values.approvalRules),
          },
          approvalSchemeId,
        });
      } catch (error) {
        pushNotif({
          type: NotificationType.Danger,
          message: 'Failed to update approval flow',
        });
        throw new Error('Failed to update approval flow', error);
      }
      setSubmitting(false);
      onClose();
      queryClient.invalidateQueries(['approval-scheme', approvalSchemeId]);
      pushNotif({
        type: NotificationType.Success,
        message: 'Approval flow updated',
      });
    },
  });

  return (
    <>
      <div className="grow overflow-y-auto">
        <div className="p-32">
          <Tag variant="purple">Standard approval workflow</Tag>
          <div className="mt-16 text-primary body-s">
            This condition will be executed if no other workflow applies.
            <br />
            This ensures that any request has a reviewer.
          </div>
        </div>
        <div className="mb-8 px-32 text-primary title-m">
          Approval required from
        </div>
        <div className="p-32 pb-[90px] pt-0">
          <ApprovalScheme
            error={
              errors.approvalRules
                ? (errors.approvalRules as string)
                : undefined
            }
            rules={formValues.approvalRules}
            onChange={(rules) => {
              setFieldValue('approvalRules', rules);
            }}
          />
        </div>
      </div>
      <div className="absolute bottom-0 z-10 flex w-full justify-between gap-16 rounded-bl-12 rounded-br-12 border-0 border-t border-solid border-t-default bg-primary-default px-32 py-16">
        <Button
          text="Cancel"
          className="flex-grow"
          variant="secondaryNeutral"
          onClick={() => {
            onClose();
          }}
        />
        <Button
          text="Save"
          className="flex-grow"
          variant="primaryBrand"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          onClick={() => {
            handleSubmit();
          }}
        />
      </div>
    </>
  );
};
