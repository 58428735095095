import {
  Button,
  Callout,
  FormField,
  Modal,
  SkeletonText,
} from '@dev-spendesk/grapes';

import { AutocompleteNoOptions } from 'src/core/common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { type ForeignExchangeAccountDifferenceType } from 'src/core/modules/bookkeep/settings/accounting/foreignExchangeAccount';

import { type ForeignExchangeAccountsModalState } from './foreignExchangeAcounts';
import { useAllExpenseAccountsQuery } from '../../../../hooks/useAllExpenseAccountsQuery';
import { useUpdateForeignExchangeAccountsMutation } from '../../../../hooks/useUpdateForeignExchangeAccountsMutation';

type ForeignExchangeAccountModalProps = {
  setModalState: (state: ForeignExchangeAccountsModalState) => void;
  modalState: ForeignExchangeAccountsModalState;
};

export const ForeignExchangeAccountModal = (
  props: ForeignExchangeAccountModalProps,
) => {
  const { t } = useTranslation('global');

  const expenseAccountsQueryState = useAllExpenseAccountsQuery();

  const [
    updateForeignExchangeAccounts,
    updateForeignExchangeAccountsQueryState,
    clearErrors,
  ] = useUpdateForeignExchangeAccountsMutation();

  if (!props.modalState.editedAccount) {
    return;
  }

  const differenceType = props.modalState.editedAccount.differenceType;

  async function handleForeignExchangeAccountUpdate() {
    const { editedAccount } = props.modalState;

    if (!editedAccount?.code) {
      return;
    }

    const result = await updateForeignExchangeAccounts({
      id: editedAccount.id,
      capabilityKind: 'pullAndSelect',
      differenceType: editedAccount.differenceType,
      newCode: editedAccount.code,
    });

    if (result.outcome === 'set') {
      clearErrors();
    }
  }

  return (
    <Modal
      isOpen={!!props.modalState.editedAccount}
      title={t(differenceTypeToI18nKeyTitle[differenceType])}
      iconName="pen"
      actions={[
        <Button
          key="cancel"
          onClick={() => {
            props.setModalState({
              editedAccount: undefined,
            });
          }}
          variant="secondaryNeutral"
          text={t(
            'bookkeep.integrations.settings.sectionForeignExchangeAccounts.modal.cancelButton',
          )}
        />,
        <Button
          key="save"
          onClick={() => {
            handleForeignExchangeAccountUpdate();

            props.setModalState({
              editedAccount: undefined,
            });
          }}
          variant="primaryBrand"
          text={t(
            'bookkeep.integrations.settings.sectionForeignExchangeAccounts.modal.saveButton',
          )}
        />,
      ]}
    >
      {updateForeignExchangeAccountsQueryState.status === 'error' && (
        <Callout
          variant="alert"
          title={t(
            'bookkeep.integrations.settings.sectionForeignExchangeAccounts.modal.error',
          )}
          className="error"
        />
      )}
      <FormField
        label={t(differenceTypeToI18nKeyLabel[differenceType])}
        htmlFor="set-account-autocomplete-input"
      >
        <QuerySuspense
          queryState={expenseAccountsQueryState}
          loading={<SkeletonText key="loading_expense_accounts" />}
          fallback={() => (
            <Callout
              title={t(
                'bookkeep.integrations.settings.sectionForeignExchangeAccounts.modal.loadingExpenseAccountsError',
              )}
            />
          )}
        >
          {(expenseAccountsResult) => {
            const expenseAccounts = expenseAccountsResult.map((account) => ({
              key: account.code,
              label: `${account.code} - ${account.name}`,
            }));

            return (
              <AutocompleteSearch
                name="set-account-autocomplete-input"
                placeholder={t(
                  'bookkeep.integrations.settings.sectionForeignExchangeAccounts.modal.selectPlaceholder',
                )}
                fit="parent"
                options={expenseAccounts}
                value={expenseAccounts.find(
                  (account) =>
                    account.key === props.modalState.editedAccount?.code,
                )}
                renderNoOptions={(rawValue) => (
                  <AutocompleteNoOptions value={rawValue} />
                )}
                onSelect={(account) => {
                  if (!props.modalState.editedAccount) {
                    return;
                  }
                  props.setModalState({
                    ...props.modalState,
                    editedAccount: {
                      ...props.modalState.editedAccount,
                      code: account?.key,
                    },
                  });
                }}
              />
            );
          }}
        </QuerySuspense>
      </FormField>
    </Modal>
  );
};

const differenceTypeToI18nKeyTitle: Record<
  ForeignExchangeAccountDifferenceType,
  I18nKey
> = {
  loss: 'bookkeep.integrations.settings.sectionForeignExchangeAccounts.modal.loss.title',
  gain: 'bookkeep.integrations.settings.sectionForeignExchangeAccounts.modal.gain.title',
};

const differenceTypeToI18nKeyLabel: Record<
  ForeignExchangeAccountDifferenceType,
  I18nKey
> = {
  loss: 'bookkeep.integrations.settings.sectionForeignExchangeAccounts.modal.loss.selectLabel',
  gain: 'bookkeep.integrations.settings.sectionForeignExchangeAccounts.modal.gain.selectLabel',
};
