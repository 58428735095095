import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useRequestTypeEnablements } from '../../requests/hooks/useRequestTypeEnablements';
import { SPEND_TYPES, getSpendTypeTranslation } from '../spendType';

export const useSpendTypeOptions = () => {
  const { t } = useTranslation('global');
  const { requestTypeEnabled: requestTypeActivated } =
    useRequestTypeEnablements();
  return SPEND_TYPES.filter((spendType) => requestTypeActivated[spendType]).map(
    (spendType) => ({
      key: spendType,
      label: getSpendTypeTranslation(t, spendType),
    }),
  );
};
