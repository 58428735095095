import { useMutation } from 'react-query';

import { useCompany } from 'modules/app/hooks/useCompany';
import { apiV2Url } from 'src/core/utils/api';

export type RequestsDenials = {
  successIds: string[];
  errorIds: string[];
};

export const useRequestsDenialsMutation = () => {
  const company = useCompany();
  return useMutation(
    ['v2', 'requests', 'denials'],
    async ({
      requestIds,
      denialReason,
    }: {
      requestIds: string[];
      denialReason: string;
    }): Promise<{ data: RequestsDenials }> => {
      const response = await fetch(apiV2Url('/requests/denials', company.id), {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          requestIds,
          denialReason,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to deny requests');
      }
      return response.json();
    },
    {
      retry: false,
    },
  );
};
