import { Tag } from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type Addon, addonToDisplayTranslationKeys } from '../../../../models';

type Props = {
  addons: Addon[];
};

export const CompanyPlanAddons = ({ addons }: Props) => {
  const { t } = useTranslation('global');

  return (
    <ul className="flex list-none flex-wrap justify-start gap-8">
      {addons.map((addon) => {
        if (addon.slug === 'supplementaryWallets') {
          if (addon.quantity > 0) {
            return (
              <li key={addon.slug}>
                <Tag variant="lemon">
                  <Trans
                    i18nKey="billing.companyPlan.addons.supplementaryWallet"
                    values={{
                      count: addon.quantity,
                    }}
                    // eslint-disable-next-line react/jsx-key
                    components={[<strong className="text-primary">-</strong>]}
                  />
                </Tag>
              </li>
            );
          }
          return;
        }
        const key = addonToDisplayTranslationKeys[addon.slug];
        if (key) {
          return (
            <li key={addon.slug}>
              <Tag variant="lemon">{t(key)}</Tag>
            </li>
          );
        }
        return null;
      })}
    </ul>
  );
};
