import { Accordion } from '@dev-spendesk/grapes';
import React, { useEffect, useState } from 'react';

import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { type OperationalOnboardingData } from '../../../../../models';
import { OperationalOnboardingItem } from '../OperationalOnboardingItem';

type Props = {
  operationalOnboarding: OperationalOnboardingData;
};

export const OperationalOnboardingContent = ({
  operationalOnboarding,
}: Props) => {
  const [openItemIndex, setOpenItemIndex] = useState(
    operationalOnboarding.tasks.findIndex((task) => task.status === 'ongoing'),
  );

  const closeAccordion = () => setOpenItemIndex(-1);

  const closeAccordionAndOpenNext = (currentIndex: number) => {
    closeAccordion();
    const followingOngoingTasks = operationalOnboarding.tasks
      .slice(currentIndex + 1)
      .filter((task) => task.status === 'ongoing');

    if (followingOngoingTasks.length > 0) {
      const nextTaskIndex = operationalOnboarding.tasks.indexOf(
        followingOngoingTasks[0],
      );
      setOpenItemIndex(nextTaskIndex);
    }
  };

  useEffect(() => {
    track(AnalyticEventName.WELCOME_DASHBOARD_LOAD, {
      taskList: operationalOnboarding.tasks.map((task) => ({
        type: task.type,
        status: task.status,
      })),
    });
  }, []);

  return (
    <>
      <Accordion>
        {operationalOnboarding.tasks.map((task, index) => {
          const open = openItemIndex === index;
          return (
            <OperationalOnboardingItem
              key={task.type}
              task={task}
              open={open}
              closeAccordion={closeAccordion}
              closeAccordionAndOpenNext={() => closeAccordionAndOpenNext(index)}
            />
          );
        })}
      </Accordion>
    </>
  );
};
