import { Avatar, Icon, colors } from '@dev-spendesk/grapes';

import { type SupplierDetailsChange } from '../../models';

type Props = {
  supplierChange: SupplierDetailsChange;
};

export const UpdaterAvatar = ({ supplierChange }: Props) => {
  return supplierChange.updater ? (
    <Avatar
      size={16}
      className="mr-4"
      src={supplierChange.updater.avatar}
      text={supplierChange.updater.name}
    />
  ) : (
    <div className="pl-4">
      <Icon
        name="building-storefront"
        color={colors.contentDecorativeIcon}
        size="l"
        className="mr-4"
      />
    </div>
  );
};
