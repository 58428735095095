import {
  Box,
  PanelSection,
  type PanelSectionProps,
} from '@dev-spendesk/grapes';
import React, { type ReactElement, type ReactNode } from 'react';

import styles from './PanelPreviewsSection.module.css';

type Props = DistributiveOmit<PanelSectionProps, 'children'> & {
  previews: ReactElement[];
  noPreviewContent: string;
  children?: ReactNode;
  beforePreviews?: ReactNode;
};

export function PanelPreviewsSection({
  previews,
  noPreviewContent,
  children,
  beforePreviews,
  ...panelSectionProps
}: Props) {
  return (
    <PanelSection {...panelSectionProps}>
      <>
        {beforePreviews ? <div className="mb-8">{beforePreviews}</div> : null}

        {previews.length === 0 ? (
          <Box
            variant="secondary"
            className="rounded-12 border border-dashed border-default text-center text-secondary-bg-secondary body-m"
          >
            {noPreviewContent}
          </Box>
        ) : (
          <div className={styles.panelPreviewsSectionContent}>{previews}</div>
        )}
        {children}
      </>
    </PanelSection>
  );
}
