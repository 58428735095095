import { type ApiSpendingType } from 'src/core/modules/members/models/policy';

import type { ApprovalNeeded, ApprovalPolicy } from './approvalPolicy';
import type { Member } from './member';
import type { SpendingType } from './spendingTypes';

class TypeGuardError extends Error {
  constructor(_switchCase: never, message: string) {
    super(message);
  }
}

export type ApiApprovalPolicy = {
  id: string;
  name: string;
  is_default: boolean;
  is_user_custom: boolean;
  nb_users?: number;
  params: {
    approval_needed: ApprovalNeeded;
    spending_types: ApiSpendingType[] | string;
    spending_limit: number;
    transaction_max: number;
  };
};

export const reshapeToApprovalPolicy = (
  policy: ApiApprovalPolicy,
): ApprovalPolicy => {
  return {
    id: policy.id,
    name: policy.name,
    isDefault: policy.is_default,
    isCustom: policy.is_user_custom,
    params: {
      spendingTypes: reshapeToSpendingTypes(policy.params.spending_types),
      approvalNeeded: policy.params.approval_needed,
      amountPerMonth: policy.params.spending_limit,
      amountPerTransaction: policy.params.transaction_max,
    },
  };
};

export const reshapeToSpendingTypes = (
  spendingTypesString: string | ApiSpendingType[],
): SpendingType[] => {
  let types: ApiSpendingType[] = [];

  if (typeof spendingTypesString === 'string') {
    types = spendingTypesString.split('|').filter(Boolean) as ApiSpendingType[];
  } else {
    types = spendingTypesString;
  }
  if (!types) {
    return [];
  }
  return types.map((spendingType: ApiSpendingType) => {
    switch (spendingType) {
      case 'single_purchase':
        return 'singlePurchase';
      case 'card_load':
        return 'cardLoad';
      case 'expense':
        return 'expenseClaim';
      case 'invoice':
        return 'invoice';
      case 'subscription':
        return 'subscription';
      case 'purchase_order':
        return 'purchaseOrder';
      default:
        throw new TypeGuardError(
          spendingType,
          `Unknown spending type ${spendingType}`,
        );
    }
  });
};

export const reshapeFromSpendingTypes = (
  spendingTypes: SpendingType[],
): string => {
  const spendingTypesApi = spendingTypes.map((spendingType) => {
    switch (spendingType) {
      case 'singlePurchase':
        return 'single_purchase';
      case 'cardLoad':
        return 'card_load';
      case 'expenseClaim':
        return 'expense';
      case 'invoice':
        return 'invoice';
      case 'subscription':
        return 'subscription';
      case 'purchaseOrder':
        return 'purchase_order';
      default:
        throw new TypeGuardError(
          spendingType,
          `Unknown the spending type ${spendingType}`,
        );
    }
  });
  return spendingTypesApi.join('|');
};

export type ApiMember = {
  id: string;
  email: string;
  avatar: string;
  gender: string;
  fullname: string;
  is_pending: boolean;
  is_account_owner: boolean;
};

export const reshapeToMember = (member: ApiMember): Member => ({
  id: member.id,
  email: member.email,
  avatar: member.avatar,
  gender: member.gender,
  fullname: member.fullname,
  isPending: member.is_pending,
  isAccountOwner: member.is_account_owner,
});
