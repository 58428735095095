import { Button, DATE_FORMAT } from '@dev-spendesk/grapes';
import { toNumber } from 'ezmoney';
import React from 'react';

import {
  type LocaleFormat,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';
import { formatMoney } from 'src/core/utils/money';
import { openSupportChat } from 'src/core/utils/supportChat';

import { type InvoiceDetail } from '../types';
import { getTotalAmountFromInvoices } from '../utils';

type Props = {
  delinquentStartDate: Date;
  overdueInvoices: InvoiceDetail[];
  localeFormat: LocaleFormat;
  t: TGlobalFunctionTyped;
  companyCurrency: string;
  companyName: string;
};

export const HeaderText = ({
  delinquentStartDate,
  overdueInvoices,
  localeFormat,
  companyCurrency,
  companyName,
  t,
}: Props) => {
  const formattedDeliquentStartDate = localeFormat(
    new Date(delinquentStartDate),
    DATE_FORMAT.SHORT,
  );

  const totalAmount = getTotalAmountFromInvoices(
    overdueInvoices,
    companyCurrency,
  );

  const formattedTotalAmountDue = formatMoney(
    toNumber(totalAmount),
    overdueInvoices[0].functionalAmountToCollect.currency,
  );

  return (
    <div className="flex flex-col gap-16">
      <div className="flex flex-col gap-24">
        <h1 className="title-xxl">
          {t('overdueInvoice.closedDoor.accountSuspension.title')}
        </h1>
        <p className="body-xl">
          {t('overdueInvoice.closedDoor.accountSuspension.text', {
            delinquentStartDate: formattedDeliquentStartDate,
            totalAmountDue: formattedTotalAmountDue,
            numberOfOverdueInvoices: overdueInvoices.length,
            companyName,
          })}
        </p>
      </div>
      <div className="flex gap-[21px]">
        <Button
          onClick={() => openSupportChat()}
          variant="secondaryNeutral"
          text={t('overdueInvoice.closedDoor.buttons.contactSupport')}
        />
      </div>
    </div>
  );
};
