import {
  Avatar,
  colors,
  DATE_FORMAT,
  HighlightIcon,
  Icon,
  Tag,
  Tooltip,
} from '@dev-spendesk/grapes';

import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';
import {
  fallbackSupplierLogoSrc,
  getLogoUrlFromName,
} from 'src/core/common/components/SupplierLogo';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { metersToDistanceUnit } from 'src/core/utils/geo';
import { formatMoney } from 'src/core/utils/money';
import { formatNumberToDistance } from 'src/core/utils/number';

import {
  getInvoiceIconFromPayment,
  getPaymentTypeIcon,
} from '../../utils/paymentUtils';
import { type Payment } from '../all/paymentType';

export const AmountCell = ({ payment }: { payment: Payment }) => {
  const company = useCompany();

  const {
    amount_declared: amountDeclared,
    currency_declared: currencyDeclared,
    amount_billed: amountBilled,
    fx_fee_amount: fxFeesAmount,
  } = payment;

  const total =
    Number.parseFloat(amountBilled) + Number.parseFloat(fxFeesAmount);

  const totalFormatted =
    total < 0
      ? // We want to show refunds with a +
        formatMoney(Math.abs(total), company.currency, true)
      : formatMoney(total, company.currency);

  if (company.currency !== currencyDeclared) {
    const formattedAmountDeclared =
      Number.parseFloat(amountDeclared) < 0
        ? formatMoney(
            Math.abs(Number.parseFloat(amountDeclared)),
            currencyDeclared,
            true,
          )
        : formatMoney(amountDeclared, currencyDeclared);

    return (
      <span
        className={`${payment.state === 'authorised' ? 'text-secondary-bg-primary' : ''} flex flex-col`}
      >
        <span>{totalFormatted}</span>
        <span className="text-secondary-bg-primary">
          {formattedAmountDeclared}
        </span>
      </span>
    );
  }

  return (
    <span
      className={
        payment.state === 'authorised' ? 'text-secondary-bg-primary' : ''
      }
    >
      {totalFormatted}
    </span>
  );
};

export const DateCell = ({ payment }: { payment: Payment }) => {
  const { localeFormat } = useTranslation('global');

  return (
    <span
      className={`block text-right ${
        payment.state === 'authorised' ? 'text-secondary-bg-primary' : ''
      }`}
    >
      {localeFormat(
        new Date(payment.paid_at || payment.created_at),
        DATE_FORMAT.CUSTOM,
        { dateStyle: 'medium', timeZone: 'UTC' },
      )}
    </span>
  );
};

const DescriptionCell = ({ payment }: { payment: Payment }) => {
  const company = useCompany();
  const { t } = useTranslation('global');

  const { description, is_atm_withdrawal: isWithdrawal, transaction } = payment;

  const paymentDescription =
    description || (transaction ? transaction.clean_description : '');

  const requestType = payment.request?.type;

  let origin;
  if (requestType === 'mileage_allowance') {
    const distanceMeters =
      payment.request?.mileage_allowance_request?.distance ?? 0;
    const distanceUnit = company.mileage_scheme?.distanceUnit ?? 'km';
    origin = (
      <span>
        {formatNumberToDistance({
          number: metersToDistanceUnit({
            distanceMeters,
            distanceUnit,
          }),
          distanceUnit,
        })}
      </span>
    );
  } else if (requestType === 'per_diem_allowance') {
    /* TODO: @CORE-5111 add number of trip days, impossible for now with fetch from grahql v1 */
    origin = null;
  } else if (isWithdrawal) {
    origin = t('misc.atmWithdrawal');
  }

  return (
    <span title={paymentDescription}>
      {origin && (
        <>
          {origin}
          <span className="mx-4">•</span>
        </>
      )}
      {paymentDescription}
      {payment.costCenter && (
        <>
          <span className="mx-4">•</span>
          {payment.costCenter.name}
        </>
      )}
    </span>
  );
};

const PendingPaymentStatus = ({ payment }: { payment: Payment }) => {
  const { t } = useTranslation('global');

  if (payment.state !== 'authorised') {
    return;
  }

  let tooltipMessage = t('payments.awaitingMerchantConfirmation');
  if (
    payment.request &&
    ['expense', 'invoice'].includes(payment.request.type)
  ) {
    tooltipMessage = t('payments.awaitingWireTransfer');
  }

  return (
    <Tooltip content={tooltipMessage} placement="right" triggerAsChild>
      <Icon
        className="pending-payment-clock shrink-0"
        name="clock-outline"
        color={colors.contentDecorativeIcon}
      />
    </Tooltip>
  );
};

export const DescriptionWithPaymentIconCell = ({
  payment,
}: {
  payment: Payment;
}) => {
  const { t } = useTranslation('global');
  const { icon, color, tooltip } = getPaymentTypeIcon(payment, t);

  return (
    <div
      className={`flex items-center gap-8 ${
        payment.state === 'authorised' ? 'text-secondary-bg-primary' : ''
      }`}
    >
      <Tooltip content={tooltip} triggerAsChild>
        <Icon name={icon} color={color} className="shrink-0" />
      </Tooltip>
      <EllipsisTooltip text={<DescriptionCell payment={payment} />} />
      <PendingPaymentStatus payment={payment} />
    </div>
  );
};

export const ReceiptStatusCell = ({ payment }: { payment: Payment }) => {
  const { t } = useTranslation('global');
  const { icon, variant, tooltip, content } = getInvoiceIconFromPayment(
    payment,
    t,
  );

  return (
    <Tooltip content={tooltip} triggerAsChild>
      <Tag iconName={icon} variant={variant}>
        {content}
      </Tag>
    </Tooltip>
  );
};

export const SupplierCell = ({ payment }: { payment: Payment }) => {
  const { t } = useTranslation('global');

  let supplierName = payment.supplier?.name ?? '';
  if (payment.supplier?.id === 'unknown') {
    supplierName = '';
  }

  let content = (
    <>
      <Avatar
        variant="square"
        text={supplierName}
        src={getLogoUrlFromName(supplierName)}
        fallbackSrc={fallbackSupplierLogoSrc}
        className="shrink-0 rounded-8 border border-default"
        lazyLoad
      />
      <EllipsisTooltip text={payment.supplier?.name ?? t('misc.na')} />
    </>
  );

  if (payment.request && ['mileage_allowance'].includes(payment.request.type)) {
    content = (
      <>
        <HighlightIcon
          variant="peach"
          name="car"
          size={32}
          className="shrink-0"
        />
        <EllipsisTooltip text={t('payables.panel.types.mileage')} />
      </>
    );
  }

  if (
    payment.request &&
    ['per_diem_allowance'].includes(payment.request.type)
  ) {
    content = (
      <>
        <HighlightIcon
          variant="lemon"
          name="globe-eu-africa"
          size={32}
          className="shrink-0"
        />
        <EllipsisTooltip text={t('payables.panel.types.perDiem')} />
      </>
    );
  }

  return (
    <span
      className={`flex items-center gap-8 ${
        payment.state === 'authorised' ? 'text-secondary-bg-primary' : ''
      }`}
    >
      {content}
    </span>
  );
};

export const EmployeeCell = ({ payment }: { payment: Payment }) => {
  const { t } = useTranslation('global');

  return (
    <span
      className={`flex items-center gap-8 ${
        payment.state === 'authorised' ? 'text-secondary-bg-primary' : ''
      }`}
    >
      <Avatar
        variant="circle"
        text={payment.user?.full_name ?? ''}
        src={payment.user?.avatar ?? ''}
        className="shrink-0 rounded-8 border border-default"
        lazyLoad
      />
      <div className="w-[calc(100%-40px)]">
        <EllipsisTooltip text={payment.user?.full_name ?? t('misc.na')} />
        {payment.team && (
          <EllipsisTooltip
            text={payment.team.name}
            className="text-secondary-bg-primary"
          />
        )}
      </div>
    </span>
  );
};
