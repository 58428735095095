import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { type AppDispatch } from 'src/core/modules/app/redux/store';
import { type AppState } from 'src/core/reducers';
import {
  getIsMobileNumber,
  getPhoneFactor,
  getUserActiveFactorPhoneNumber,
  getUserPhoneNumber,
} from 'src/core/selectors/users';
import { logger } from 'src/utils/datadog-log-wrapper';

import { ModalMFA, type PagesType } from './ModalMFA';
import { fetchPhoneFactor, requestMfaProcedure } from './actions';
import { type Factor } from './models';
import { isRequestingMfaProcedure } from './selectors';

const determinePageFromFactor = (factor: Factor) => {
  if (!factor) {
    return 'phone';
  }

  if (factor.state === 'active') {
    return 'finish';
  }

  if (factor.state === 'pending') {
    return 'code';
  }

  return 'phone';
};

type Props = {
  onFactorActivated: () => void;
  onModalClose: () => void;
  isOpen: boolean;
  isReset?: boolean;
};

export const ModalMFAContainer = ({
  onFactorActivated,
  onModalClose,
  isOpen,
  isReset = false,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const queryClient = useQueryClient();

  const userPhoneFactor = useSelector((state: AppState) =>
    getPhoneFactor(state),
  );
  const userActiveFactorPhoneNumber = useSelector((state: AppState) =>
    getUserActiveFactorPhoneNumber(state),
  );
  const userPhoneNumber = useSelector((state: AppState) =>
    getUserPhoneNumber(state),
  );
  const isMobileNumber = useSelector((state: AppState) =>
    getIsMobileNumber(state),
  );
  const isVerifyPhoneLoading = useSelector((state: AppState) =>
    isRequestingMfaProcedure(state),
  );

  const initialPhoneNumber: string =
    userActiveFactorPhoneNumber || userPhoneNumber;

  const [currentPage, setCurrentPage] = useState<PagesType>(
    !isReset ? determinePageFromFactor(userPhoneFactor) : 'phone',
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(initialPhoneNumber);

  const { search } = useLocation();
  const parsedParams = queryString.parse(search);
  const token = (parsedParams.token as string) || undefined;

  const isPhoneNumberValid = useMemo(() => {
    let isValid = false;
    if (!phoneNumber) {
      return isValid;
    }
    try {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
      isValid = isValidPhoneNumber(parsedPhoneNumber.number);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return isValid;
  }, [phoneNumber]);

  useEffect(() => {
    dispatch(fetchPhoneFactor());
  }, []);

  useEffect(() => {
    if (userActiveFactorPhoneNumber || userPhoneNumber) {
      setPhoneNumber(userActiveFactorPhoneNumber || userPhoneNumber);
    }
  }, [userActiveFactorPhoneNumber, userPhoneNumber]);

  useEffect(() => {
    if (isReset || !isOpen || !userPhoneFactor) {
      return;
    }

    if (userPhoneFactor.state === 'active') {
      onFactorActivated();
    }
  }, [isOpen, userPhoneFactor]);

  const handleVerifyPhoneClick = async () => {
    if (!token) {
      logger.error('token is undefined in phone verification', {
        team: 'banking-gateway',
        scope: 'mfa',
        data: { search, wholeUrl: window.location.href },
      });
    }

    await dispatch(requestMfaProcedure(phoneNumber, token));
    setCurrentPage('code');
  };

  const handleMfaTokenValidated = async () => {
    setCurrentPage('finish');
    dispatch(fetchPhoneFactor());
    await queryClient.invalidateQueries(['phoneFactor']);
    await queryClient.invalidateQueries(['sca', 'users', 'enrol']);
    await onFactorActivated();
  };

  const handleModalClose = () => {
    onModalClose();
  };

  return (
    <ModalMFA
      isOpen={isOpen}
      currentPage={currentPage}
      onClose={handleModalClose}
      phoneNumber={phoneNumber}
      onPhoneNumberChange={setPhoneNumber}
      isPhoneNumberValid={isPhoneNumberValid}
      isMobileNumber={isMobileNumber}
      onVerifyPhone={handleVerifyPhoneClick}
      isVerifyPhoneLoading={isVerifyPhoneLoading}
      onMfaTokenValidated={handleMfaTokenValidated}
    />
  );
};
