import {
  type Filter,
  type SelectedFilter,
  withoutOptionalFields,
} from 'src/core/common/components/FilterBuilder';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

export const trackFiltersV2 = (
  filtersV2: SelectedFilter | undefined,
  variant: 'all-payables' | 'prepare',
) => {
  if (!filtersV2) {
    return;
  }

  const trackFilterParams = computeFiltersV2Params(
    withoutOptionalFields(filtersV2.filter),
  );

  track(AnalyticEventName.EXPENSE_INBOX_FILTER_CHANGED, {
    initial: true,
    pageUrl: window.location.href,
    pageName: variant,
    savedFilterId: filtersV2.id,
    ...trackFilterParams,
  });
};

export const computeFiltersV2Params = (
  filterV2: Filter,
): {
  filterGroupsCount: number;
  filterGroupsList: string[][];
  filterCount: number;
  filterList: string[];
  filterQuery: string;
} => {
  const filterGroupsList = filterV2.subfilters.map((filterGroup) => {
    return filterGroup.subfilters
      .map((filter) => {
        return filter.field;
      })
      .sort();
  });

  const filterList = filterGroupsList.flat().sort();

  return {
    filterGroupsCount: filterGroupsList.length,
    filterGroupsList,
    filterCount: filterList.length,
    filterList,
    filterQuery: JSON.stringify(filterV2),
  };
};
