import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useCompany } from 'modules/app/hooks/useCompany';
import { routeFor, routes } from 'src/core/constants/routes';
import { useSupervisor } from 'src/core/modules/app/hooks/useSupervisor';
import { useHasAccessToMultiEntityHub } from 'src/core/modules/organisation-reporting/hooks/useHasAccessToMultiEntityHub';

export const RedirectToHome = () => {
  const history = useHistory();

  const company = useCompany();
  const supervisor = useSupervisor();
  const hasAccessToMultiEntityHub = useHasAccessToMultiEntityHub();

  // For some unexplained and mysterious reasons,
  // there were cases when react-router's Redirect did not work in PaidLayoutContainer.
  // Replacing it with an useEffect here
  useEffect(() => {
    if (hasAccessToMultiEntityHub && !supervisor) {
      history.replace({
        pathname: routeFor(routes.ORGANISATION_REPORTING.path, {
          company: company.id,
        }),
      });

      return () => {};
    }

    history.replace({
      pathname: routeFor(routes.HOMEPAGE.path, {
        company: company.id,
      }),
      search: history.location.search,
    });
  }, []);

  return null;
};
