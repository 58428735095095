import { Box, Button, HighlightIcon } from '@dev-spendesk/grapes';
import { isBefore } from 'date-fns';
import React, { useId } from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';

import {
  useFetchPurchaseOrdersQuery,
  useGoToPurchaseOrders,
} from '../../hooks';
import { type PurchaseOrder } from '../../models';

export const PurchaseOrderDashboardSummary = () => {
  const { t } = useTranslation('global');
  const titleId = useId();
  const goToPurchaseOrders = useGoToPurchaseOrders();
  const fetchPurchaseOrdersQueryState = useFetchPurchaseOrdersQuery(
    {
      status: 'open',
    },
    1000,
  );

  return (
    <QuerySuspense
      queryState={fetchPurchaseOrdersQueryState}
      loading={null}
      fallback={() => null}
    >
      {(purchaseOrders) => {
        const latePurchaseOrders = getLatePurchaseOrders(purchaseOrders);
        return latePurchaseOrders.length > 0 ? (
          <Box
            className="col-span-2 flex items-center gap-16"
            role="region"
            aria-labelledby={titleId}
          >
            <HighlightIcon
              name="shopping-cart"
              size={32}
              variant="blue"
              aria-hidden="true"
            />
            <div className="flex grow flex-col">
              <h2 className="text-primary title-l" id={titleId}>
                {t('purchaseOrders.dashboard.title', {
                  count: latePurchaseOrders.length,
                })}
              </h2>
              <p className="text-secondary-bg-primary body-m">
                {t('purchaseOrders.dashboard.description')}
              </p>
            </div>
            <Button
              onClick={() => goToPurchaseOrders()}
              text={t('purchaseOrders.dashboard.cta')}
              variant="tertiaryNeutral"
            />
          </Box>
        ) : null;
      }}
    </QuerySuspense>
  );
};

// TODO@Request&Pay: remove this logic once the back end provides filters
const getLatePurchaseOrders = (purchaseOrders: PurchaseOrder[]) => {
  const now = new Date();
  return purchaseOrders.filter((purchaseOrder) =>
    isBefore(new Date(purchaseOrder.endDate), now),
  );
};
