import React from 'react';
import { useSelector } from 'react-redux';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import withErrorBoundary from 'common/components/withErrorBoundary';
import { useFeature } from 'common/hooks/useFeature';
import { useCompany } from 'modules/app/hooks/useCompany';
import { LatePaymentsSection } from 'modules/control-rules/components/LatePaymentsSection';
import { PurchaseOrderDashboardSummary } from 'modules/purchase-orders/components/';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import FEATURES from 'src/core/constants/features';
import { type AppState } from 'src/core/reducers';
import { getUserPaymentCount } from 'src/core/selectors/users';

import { PaymentsListSection } from './components/PaymentsListSection';
import { PaymentsListSectionLoader } from './components/PaymentsListSectionLoader';
import { RestrictedAccessPanel } from './components/RestrictedAccessPanel';
import { SpendingRules } from './components/SpendingRules/SpendingRules';
import { usePaymentsToCompleteQuery } from '../../control-rules/hooks/usePaymentsToCompleteQuery';
import { useUserControlRuleQuery } from '../../control-rules/hooks/userControlRuleQuery';
import { useCardsAccessQuery } from '../Homepage/hooks';

const RequesterHomepage = () => {
  const company = useCompany();
  const userPaymentCount = useSelector((state: AppState) =>
    getUserPaymentCount(state, company.id),
  );
  const hasPurchaseOrderFeatureActivated = useFeature(
    FEATURES.PURCHASE_ORDERS_ACTIVATED,
  );
  const hasPlayByTheRulesReceiptAndFieldsFeature = useFeature(
    FEATURES.PLAY_BY_THE_RULES_RECEIPT_AND_FIELDS,
  );

  const controlRuleQueryState = useUserControlRuleQuery();
  const paymentsListQueryState = useQueryStates({
    states: {
      incompletePayments: usePaymentsToCompleteQuery({
        companyCurrency: company.currency,
      }),
      controlRule: controlRuleQueryState,
    },
    reshapeData({ incompletePayments, controlRule }) {
      return {
        incompletePayments,
        controlRule,
      };
    },
  });
  const rulesPanelQueryState = useQueryStates({
    states: {
      cardsAccess: useCardsAccessQuery(),
      controlRule: controlRuleQueryState,
    },
  });

  return (
    <div className="grid grid-cols-2 gap-24">
      {hasPlayByTheRulesReceiptAndFieldsFeature ? (
        <LatePaymentsSection />
      ) : (
        <div className="box col-span-2">
          <QuerySuspense
            queryState={paymentsListQueryState}
            loading={<PaymentsListSectionLoader />}
            fallback={(error) => (
              <QueryError
                queryError={error}
                componentType="Text"
                className="mb-24 block text-alert-default"
              />
            )}
          >
            {({ incompletePayments, controlRule }) => (
              <PaymentsListSection
                context={{
                  companyId: company.id,
                  userHasMadePayments: userPaymentCount > 0,
                }}
                incompletePayments={incompletePayments}
                controlRule={controlRule}
              />
            )}
          </QuerySuspense>
        </div>
      )}
      {hasPurchaseOrderFeatureActivated && <PurchaseOrderDashboardSummary />}
      <QuerySuspense
        queryState={rulesPanelQueryState}
        loading={null}
        fallback={() => null}
      >
        {({ cardsAccess, controlRule }) => {
          if (!cardsAccess.hasAccess && cardsAccess.initiator === 'user') {
            return <RestrictedAccessPanel />;
          }

          return (
            <SpendingRules
              incompletePaymentsLimit={controlRule.incompletePaymentsLimit}
              completionDeadline={controlRule.completionDeadline}
            />
          );
        }}
      </QuerySuspense>
    </div>
  );
};

const ConnectedRequesterHomepage = withErrorBoundary({
  team: 'travel-and-expenses',
  scope: 'requester-home-page',
})(RequesterHomepage);

export { ConnectedRequesterHomepage as RequesterHomepage };
