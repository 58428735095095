import { Callout } from '@dev-spendesk/grapes';

import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { formatMoney } from 'src/core/utils/money';

import { useSpendLimitStatusByRequestQuery } from '../../hooks/useSpendLimitStatusByRequestQuery';

export const StatusByRequestLimitCallout = ({
  requestId,
  className,
}: {
  requestId: string;
  className?: string;
}) => {
  const { t } = useTranslation('global');
  const spendLimitStatusByRequestQueryState = useSpendLimitStatusByRequestQuery(
    {
      requestId,
      enabled: true,
    },
  );

  // TODO: handle 404 specifically
  if (spendLimitStatusByRequestQueryState.status === 'error') {
    return null;
  }

  return (
    <QuerySuspense
      loading={null}
      queryState={spendLimitStatusByRequestQueryState}
      fallback={(error) => (
        <QueryError queryError={error} componentType="Callout" />
      )}
    >
      {(spendLimitStatus) => {
        const isUnderLimit =
          spendLimitStatus.usedAmount <= spendLimitStatus.limitAmount;
        const isOverLimit =
          spendLimitStatus.usedAmount > spendLimitStatus.limitAmount;
        const overLimitAmount =
          spendLimitStatus.usedAmount - spendLimitStatus.limitAmount;

        const calloutTitleKey =
          spendLimitStatus.period === 'day'
            ? 'spendLimits.modal.request.preRequestCallout.titleDay'
            : 'spendLimits.modal.request.preRequestCallout.titleMonth';
        if (isUnderLimit) {
          return (
            <Callout
              className={className}
              variant="info"
              title={t(calloutTitleKey, {
                amount: formatMoney(
                  spendLimitStatus.limitAmount,
                  spendLimitStatus.currency,
                ),
              })}
            />
          );
        }
        if (isOverLimit) {
          const calloutDescriptionKey =
            spendLimitStatus.period === 'day'
              ? 'spendLimits.modal.request.calloutWarning.descriptionDay'
              : 'spendLimits.modal.request.calloutWarning.descriptionMonth';
          return (
            <Callout
              className={className}
              variant="warning"
              title={t('spendLimits.modal.request.calloutWarning.title', {
                amount: formatMoney(overLimitAmount, spendLimitStatus.currency),
              })}
            >
              {t(calloutDescriptionKey, {
                amount: formatMoney(
                  spendLimitStatus.limitAmount,
                  spendLimitStatus.currency,
                ),
              })}
            </Callout>
          );
        }

        return null;
      }}
    </QuerySuspense>
  );
};
