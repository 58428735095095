import { Button, PanelSection, SidePanel } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SearchFilter } from 'common/components/SearchFilter';
import { useTranslation } from 'common/hooks/useTranslation';
import type { CostCenter } from 'modules/budgets/models/costCenter';
import type { Team } from 'modules/budgets/models/team';
import { CustomFieldFilters } from 'modules/custom-fields';
import { resetRequestFilters } from 'src/core/modules/requests/redux/legacy/actions';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { getUrlParams } from 'src/core/utils/urlParser';

import { RequestFiltersForm } from './RequestFiltersForm';
import { type Filters, convertFiltersToPayload } from './types';
import { convertFiltersFromUrl } from '../../../utils/convertFiltersForApi';
import { trackRequestsSearchText } from '../../../utils/trackFilters';

type Props = {
  filtersOpen: boolean;
  setFiltersOpen: (open: boolean) => void;
  costCenters: CostCenter[];
  teams: Team[];
  closeRequestsPanel: () => void;
  setTextFilter: (query: string) => void;
  setRequestFilters: (query: {
    requester: string | undefined;
    supplier: string | undefined;
    status: string[] | undefined;
    type: string[] | undefined;
    costCenter: string[] | undefined;
    team: string[] | undefined;
    period?: string;
    amount?: string;
    custom_fields?: { [key in string]: string | null };
  }) => void;
};

export const RequestFilters = ({
  filtersOpen,
  setFiltersOpen,
  costCenters,
  teams,
  setTextFilter,
  setRequestFilters,
  closeRequestsPanel,
}: Props) => {
  const { t } = useTranslation('global');
  const { type } = useParams();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<Filters>({
    fromPeriod: undefined,
    toPeriod: undefined,
  });

  const [countFilters, setCountFilters] = useState(0);

  const applyFilters = (filtersToApply: Filters) => {
    const { customFields, ...filtersWithoutCustomFields } = filtersToApply;

    setCountFilters(
      Object.keys(customFields ?? {}).length +
        Object.values(filtersWithoutCustomFields).filter(
          (value) => value !== undefined,
        ).length,
    );
    const payload = convertFiltersToPayload(filtersToApply);

    if (Object.keys(filtersToApply).length === 0) {
      track(AnalyticEventName.REQUESTS_LIST_FILTERS_RESET);
    } else {
      track(AnalyticEventName.REQUEST_LIST_FILTERING_CHANGED_NEW, {
        filters: Object.keys(filtersToApply),
      });
    }
    setRequestFilters(payload);
  };

  useEffect(() => {
    const statusesFromURL = readStatusesFromURL();
    setCountFilters(statusesFromURL?.length ?? 0);
    dispatch(resetRequestFilters());
    setFilters({
      fromPeriod: undefined,
      toPeriod: undefined,
      statuses: statusesFromURL,
    });
  }, [type]);

  return (
    <div className="flex items-center gap-8 pt-24 [&_nav]:mb-0">
      <SearchFilter
        search={search}
        setSearch={(newSearch) => {
          trackRequestsSearchText(newSearch);
          setSearch(newSearch);
          setTextFilter(newSearch);
        }}
      />
      <Button
        variant={countFilters > 0 ? 'secondaryBrand' : 'secondaryNeutral'}
        iconName="adjustments-horizontal"
        text={
          t('misc.filters') + (countFilters > 0 ? ` (${countFilters})` : '')
        }
        onClick={() => {
          setFiltersOpen(true);
          closeRequestsPanel();
        }}
      />
      {filtersOpen && (
        <SidePanel
          title={t('misc.selectAndApplyFilters')}
          onClose={() => {
            setFiltersOpen(false);
          }}
          footer={
            <div className="grid w-full grid-cols-2 gap-8">
              <Button
                variant="secondaryNeutral"
                text={t('misc.resetFilters')}
                onClick={() => {
                  setFilters({});
                  applyFilters({});
                  setFiltersOpen(false);
                }}
              />
              <Button
                text={t('misc.apply')}
                onClick={() => {
                  applyFilters(filters);
                  setFiltersOpen(false);
                }}
              />
            </div>
          }
        >
          <PanelSection>
            <RequestFiltersForm
              costCenters={costCenters}
              teams={teams}
              filters={filters}
              setFilters={setFilters}
            />
            <CustomFieldFilters
              eligibleTypes={['request', 'expense']}
              filters={filters.customFields ?? {}}
              setFilters={(customFields) => {
                const nonNullCustomFields = Object.fromEntries(
                  Object.entries(customFields).filter(
                    ([_, value]) => value !== null,
                  ),
                );
                if (Object.keys(nonNullCustomFields).length === 0) {
                  const { customFields: customFieldsUnused, ...rest } = filters;
                  setFilters(rest);
                } else {
                  setFilters({
                    ...filters,
                    customFields: nonNullCustomFields,
                  });
                }
              }}
            />
          </PanelSection>
        </SidePanel>
      )}
    </div>
  );
};

const readStatusesFromURL = () => {
  const statuses = convertFiltersFromUrl(
    getUrlParams(window.location.search),
  )?.status;
  if (!Array.isArray(statuses) || statuses.length === 0) {
    return undefined;
  }
  return statuses.map((status) => ({ key: status, label: '' }));
};
