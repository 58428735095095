import { type ApprovalRule } from 'src/core/modules/company/structure/approval-flows';

import { ApprovalSchemeCreation } from './ApprovalSchemeCreation';
import { ApprovalSchemeEdition } from './ApprovalSchemeEdition';

type Props = {
  onClose: () => void;
  approvalRules: ApprovalRule[];
  approvalSchemeId?: string;
};

export const StandardApprovalFlowPanelContent = ({
  onClose,
  approvalRules,
  approvalSchemeId,
}: Props) => {
  // TODO: refactor this component as like in DimensionApprovalFlowPanelContent. Here it shouldn't use two components for creation and edition.
  if (approvalSchemeId === undefined) {
    return <ApprovalSchemeCreation onClose={onClose} />;
  }
  return (
    <ApprovalSchemeEdition
      approvalRules={approvalRules}
      approvalSchemeId={approvalSchemeId}
      onClose={onClose}
    />
  );
};
