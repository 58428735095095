import { compose } from '@reduxjs/toolkit';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { thunks as approvalThunks } from 'modules/company/structure/approval-flows';
import {
  selectors as teamSelectors,
  thunks as teamThunks,
} from 'modules/company/structure/teams';
import { addUsersToGroup, removeUsersFromGroup } from 'src/core/actions/groups';
import { type AppState } from 'src/core/reducers';
import { getUsers, getUsersIsLoading } from 'src/core/selectors/users';

import { createCustomControlRule } from './components/ControlRules/redux/thunks';
import {
  TeamsTabContainer,
  type TeamsTabContainerProps,
} from './components/TeamsTabContainer';
import { updateUsersPolicy, fetchApprovalSchemes } from './redux/actions';

type Props = {
  user: {
    is_account_owner: boolean;
    is_admin: boolean;
  };
  company: {
    id: string;
  };
} & TeamsTabContainerProps;

const TeamsWrapper = (props: Props) => {
  return (
    <div className="page__container w-full body-m">
      <TeamsTabContainer {...props} />
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const {
    global: { company, user },
    members: { approvers, approvalSchemes },
  } = state;

  return {
    users: getUsers(state),
    groups: teamSelectors.getAll(state),
    approvers,
    approvalSchemes,
    company,
    user,
    isLoadingUsers: getUsersIsLoading(state),
  };
};

const mapDispatchToProps = {
  fetchGroup: teamThunks.fetchOne,
  createGroup: teamThunks.create,
  updateGroup: teamThunks.update,
  removeGroup: teamThunks.destroy,
  patchGroup: teamThunks.patch,
  addUsersToGroup,
  removeUsersFromGroup,
  updateUsersPolicy,
  fetchApprovalSchemes,
  fetchApprovalScheme: approvalThunks.fetchSchema,
  createCustomControlRule,
  saveApprovalScheme: approvalThunks.saveSchema,
};

export const TeamsContainer = compose<() => JSX.Element>(
  withErrorBoundary({ scope: 'members-teams', team: 'control' }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(TeamsWrapper);
