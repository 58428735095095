import { Callout } from '@dev-spendesk/grapes';
import { type MonetaryValue } from 'ezmoney';
import React from 'react';

import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { ReactComponent as CommittedIcon } from '../../assets/committed.svg';
import { spendingTypes } from '../../breakdownSpendings';
import { BreakdownSpendingsSectionContainer } from '../../containers/BreakdownSpendingsSectionContainer';
import { type BreakdownPart } from '../../models/breakdown';
import { type ExpenseCategoryBreakdown } from '../../models/expenseCategoryBreakdown';
import { type PeriodRangePayload } from '../../models/period';

type Props = {
  budgetId: string;
  title: string;
  breakdownPart: BreakdownPart;
  breakdownComputedAt: Date;
  periodRange: PeriodRangePayload;
  budgetTotalAmount: MonetaryValue;
  expenseCategory?: ExpenseCategoryBreakdown['expenseCategory'];
};

export const BreakdownSpendingsPageDrawerContent = ({
  budgetId,
  title,
  breakdownPart,
  breakdownComputedAt,
  periodRange,
  budgetTotalAmount,
  expenseCategory,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className="mx-auto w-full max-w-lg">
      <h1 className="mb-24 text-primary title-xl">
        {t('budget.breakdownSpendings.title', { name: title })}
      </h1>
      {renderAmountPanel(
        formatMonetaryValue(budgetTotalAmount),
        getBreakdownPartName(breakdownPart, t),
        getBreakdownPartIcon(breakdownPart),
      )}
      <Callout
        className="mt-24"
        title={t('budget.breakdownSpendings.netAmounts.callout.title')}
      >
        {t('budget.breakdownSpendings.netAmounts.callout.description')}
      </Callout>
      <div className="separator my-24" />
      <div>
        {breakdownPart &&
          spendingTypes.map((spendingType) => {
            if (
              // FIXME: this case shouldn't not happen, to fix in the backend
              spendingType === 'physicalCard' &&
              breakdownPart === 'committed'
            ) {
              return null;
            }
            return (
              <BreakdownSpendingsSectionContainer
                key={spendingType}
                spendingType={spendingType}
                breakdownPart={breakdownPart}
                budgetId={budgetId}
                periodRange={periodRange}
                breakdownComputedAt={breakdownComputedAt}
                expenseCategory={expenseCategory}
              />
            );
          })}
      </div>
    </div>
  );
};

const getBreakdownPartName = (
  breakdownPart: BreakdownPart,
  t: TGlobalFunctionTyped,
): string | undefined => {
  if (breakdownPart === 'committed') {
    return t('budget.breakdownSpendings.netAmounts.breakdownPartCommitted');
  }
  if (breakdownPart === 'used') {
    return t('budget.breakdownSpendings.netAmounts.breakdownPartUsed');
  }

  return undefined;
};

const getBreakdownPartIcon = (breakdownPart: BreakdownPart) => {
  if (breakdownPart === 'committed') {
    return <CommittedIcon />;
  }
  if (breakdownPart === 'used') {
    return (
      <div className="inline-block h-12 w-12 rounded-[100%] bg-primary-brand-default" />
    );
  }

  return undefined;
};

const renderAmountPanel = (
  amount: string,
  label: string | undefined,
  icon: JSX.Element | undefined,
) => {
  return (
    <div className="flex justify-between rounded bg-secondary-default px-16 py-8">
      <div className="flex items-center gap-8">
        {icon && <div className="relative top-[1px]">{icon}</div>}
        <div className="body-m">{label}</div>
      </div>
      <div className="title-m">{amount}</div>
    </div>
  );
};
