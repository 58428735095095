import { IconButton, type IconButtonProps } from '@dev-spendesk/grapes';
import React from 'react';

type Props = {
  isDisabled?: boolean;
  text: string;
} & Pick<IconButtonProps, 'iconName' | 'onClick'>;

export const IconButtonText = ({
  isDisabled,
  onClick,
  text,
  iconName,
}: Props) => {
  return (
    <div className="max-w-[90px] flex-auto justify-items-center">
      <IconButton
        variant="secondaryNeutral"
        iconName={iconName}
        disabled={isDisabled}
        onClick={onClick}
        aria-label={text}
      />
      <p className="mt-8 text-center text-secondary-bg-secondary body-s">
        {text}
      </p>
    </div>
  );
};
