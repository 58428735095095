import { PanelSectionListItem } from 'src/core/common/components/Panel';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

import { type MileageRequest } from '../../models/mileageRequest';

type Props = {
  vehicle: {
    type: MileageRequest['mileage_allowance_request']['vehicle']['type'];
    ownership?: MileageRequest['mileage_allowance_request']['vehicle']['ownership'];
    fuelType: MileageRequest['mileage_allowance_request']['vehicle']['fuelType'];
    engineSize: MileageRequest['mileage_allowance_request']['vehicle']['horsepower'];
  };
};

export const MileageRequestUKVehicleDetailsLine = ({ vehicle }: Props) => {
  const { t } = useTranslation('global');
  return (
    <PanelSectionListItem
      label={t('forms.mileageAllowance.vehicleLabel')}
      value={displayVehicle({
        vehicle,
        t,
      })}
    />
  );
};

export const displayVehicle = ({
  vehicle,
  t,
}: {
  vehicle: Props['vehicle'];
  t: TGlobalFunctionTyped;
}) => {
  return [
    displayVehicleType({
      type: vehicle.type,
      ownership: vehicle.ownership,
      t,
    }),
    displayFuelType({
      type: vehicle.type,
      fuelType: vehicle.fuelType,
      t,
    }),
    displayEngineSize({
      type: vehicle.type,
      engineSize: vehicle.engineSize,
      t,
    }),
  ]
    .filter(Boolean)
    .join(', ');
};

const displayVehicleType = ({
  type,
  ownership,
  t,
}: {
  type: MileageRequest['mileage_allowance_request']['vehicle']['type'];
  ownership: MileageRequest['mileage_allowance_request']['vehicle']['ownership'];
  t: TGlobalFunctionTyped;
}) => {
  if (type === 'car') {
    return ownership === 'company'
      ? t('forms.mileageAllowance.companyCar')
      : t('forms.mileageAllowance.personalCar');
  }
  if (type === 'motorcycle') {
    return t('forms.mileageAllowance.motorcycle');
  }
  if (type === 'bike') {
    return t('forms.mileageAllowance.bike');
  }
};

const displayFuelType = ({
  type,
  fuelType,
  t,
}: {
  type: MileageRequest['mileage_allowance_request']['vehicle']['type'];
  fuelType: MileageRequest['mileage_allowance_request']['vehicle']['fuelType'];
  t: TGlobalFunctionTyped;
}) => {
  if (type === 'car' && fuelType) {
    // eslint-disable-next-line default-case
    switch (fuelType) {
      case 'diesel':
        return t(`forms.mileageAllowance.dieselFuelType`);
      case 'electric':
        return t(`forms.mileageAllowance.electricFuelType`);
      case 'lpg':
        return t(`forms.mileageAllowance.lpgFuelType`);
      case 'petrol':
        return t(`forms.mileageAllowance.petrolFuelType`);
    }
  }
};

const displayEngineSize = ({
  type,
  engineSize,
  t,
}: {
  type: MileageRequest['mileage_allowance_request']['vehicle']['type'];
  engineSize: MileageRequest['mileage_allowance_request']['vehicle']['horsepower'];
  t: TGlobalFunctionTyped;
}) => {
  if (type === 'car' && engineSize) {
    // eslint-disable-next-line default-case
    switch (engineSize) {
      case '0-1400cc':
        return t(`forms.mileageAllowance.engineSize<1400cc`);
      case '1401-2000cc':
        return t(`forms.mileageAllowance.engineSize1401-2000cc`);
      case '0-1600cc':
        return t(`forms.mileageAllowance.engineSize<1600cc`);
      case '1601-2000cc':
        return t(`forms.mileageAllowance.engineSize1601-2000cc`);
      case '>2000cc':
        return t(`forms.mileageAllowance.engineSize>2000cc`);
    }
  }
};
