import { Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { useMemo } from 'react';

import { getNewReleaseBaseUrl } from 'common/components/AvatarMenu/utils';
import { useTranslation } from 'common/hooks/useTranslation';
import { READY_TO_SPEND_TASKS } from 'modules/onboarding/setup-hub/constants/taskSections';
import { useGetOnboardingUserTasksQuery } from 'modules/onboarding/setup-hub/hooks/useGetOnboardingUserTasksQuery';
import { useSetupHubTasksQuery } from 'modules/onboarding/setup-hub/pages/SetupHubPage/hooks/useSetupHubTasksQuery';

import styles from './ReleaseDetailsLink.module.css';

export const ReleaseDetailsLink = () => {
  const { t, activeLanguage } = useTranslation('global');
  const onboardingUserTasksQueryState = useGetOnboardingUserTasksQuery();
  const setupHubQueryState = useSetupHubTasksQuery();

  const hasSetupHub = useMemo(() => {
    if (
      setupHubQueryState.status !== 'success' ||
      onboardingUserTasksQueryState.status !== 'success'
    ) {
      return false;
    }
    if (setupHubQueryState.data.length > 0) {
      return true;
    }
    const readyToSpendTasks = onboardingUserTasksQueryState.data.filter(
      (task) => {
        if (!READY_TO_SPEND_TASKS.includes(task.type)) {
          return false;
        }
        return task;
      },
    );
    return readyToSpendTasks.length > 0;
  }, [setupHubQueryState, onboardingUserTasksQueryState]);

  if (!hasSetupHub) {
    return (
      <li>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          aria-hidden="true"
          href={getNewReleaseBaseUrl(activeLanguage)}
          target="_blank"
          className={styles.releaseNavigationItem}
        >
          <span className="whitespace-nowrap">
            <Icon name="megaphone" size="m" className="mr-12 shrink-0" />
            {t('navigation.release.title')}
          </span>
          <div className={cx('flex flex-col gap-12', styles.releaseContent)}>
            <span className="text-secondary-bg-primary">
              {t('navigation.release.description')}
            </span>
            <span className="text-info-default">
              {t('navigation.release.cta')}
              <Icon name="arrow-right" size="m" className="ml-4 align-middle" />
            </span>
          </div>
        </a>
      </li>
    );
  }
};
