import React from 'react';

import { PaymentsToScheduleListSkeleton } from 'modules/invoices/components/PaymentsToScheduleList/PaymentsToScheduleListSkeleton';
import { SchedulePaymentsBlockLoader } from 'modules/invoices/components/SchedulePaymentsBlock/SchedulePaymentsBlockLoader';

export const InvoicesPayPageSkeleton = () => {
  return (
    <div className="h-full">
      <div className="mx-auto mt-48 grid max-h-full w-full max-w-lg grid-cols-2 gap-32">
        <PaymentsToScheduleListSkeleton numberOfRows={5} />
        <div className="box">
          <SchedulePaymentsBlockLoader />
        </div>
      </div>
    </div>
  );
};
