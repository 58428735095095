import { Navigation } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { type ReactNode } from 'react';

import {
  NavigationLink,
  type NavigationLinkProps,
} from 'src/core/common/components/NavigationLink';

type HeaderWithNavigationProps = {
  children: ReactNode;
  links: NavigationLinkProps[];
  navigationLabel?: string;
  withSmallPagePadding?: boolean; // On pages where we want to maximise the space available for the content (like bookkeep & reviews), we can set this to true
};

export const HeaderWithNavigation = ({
  children,
  links,
  navigationLabel,
  withSmallPagePadding,
}: HeaderWithNavigationProps) => {
  return (
    <header
      className={classNames(
        'sticky top-0 z-10 bg-primary-default',
        withSmallPagePadding ? 'px-24' : 'px-40',
      )}
    >
      <h1
        className={classNames(
          'pb-24 text-primary heading-xl',
          withSmallPagePadding ? 'pt-24' : 'pt-40',
        )}
      >
        {children}
      </h1>
      <Navigation aria-label={navigationLabel}>
        {links.map((link) => (
          <NavigationLink {...link} key={link.key} />
        ))}
      </Navigation>
    </header>
  );
};
