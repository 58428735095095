import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';
import { getCodeWithAuxiliaryAccounts } from 'src/core/utils/accountPayable';

import { SEARCH_PAYABLE_ACCOUNTS } from './queries';

type RawPayableAccount = {
  id: string;
  generalAccountCode: string;
  auxiliaryAccountCode: string | undefined;
  isArchived: boolean;
  isDefault: boolean;
};

type PayableAccountKind = 'employee' | 'supplier';

type PayableAccount = {
  id: string;
  name: string;
  isArchived: boolean;
  isDefault: boolean;
  kind: PayableAccountKind;
};

export type SearchPayableAccountsRawResponse = {
  company: {
    chartOfAccounts: {
      employeeAccounts: {
        edges: {
          node: RawPayableAccount;
        }[];
      };
      supplierAccounts: {
        edges: {
          node: RawPayableAccount;
        }[];
      };
    };
  };
};

export type SearchPayableAccountsVariables = {
  search: string;
};

export const useSearchPayableAccounts = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  return async (
    search: string,
  ): Promise<{
    supplierAccounts: PayableAccount[];
    employeeAccounts: PayableAccount[];
  }> => {
    const data = await queryClient.fetchQuery(
      ['useSearchPayableAccounts', companyId, search],
      getFetcher<SearchPayableAccountsRawResponse>({
        companyId,
        getRequest: () => ({
          type: 'graphQL',
          target: 'v2',
          query: SEARCH_PAYABLE_ACCOUNTS,
          variables: {
            search,
          },
          method: 'get',
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return {
      supplierAccounts: reshapePayableAccountValues(
        data.company.chartOfAccounts.supplierAccounts.edges,
        'supplier',
      ),
      employeeAccounts: reshapePayableAccountValues(
        data.company.chartOfAccounts.employeeAccounts.edges,
        'employee',
      ),
    };
  };
};

/**
 * Reshapers
 */

const reshapePayableAccountValues = (
  nodes: { node: RawPayableAccount }[],
  kind: PayableAccountKind,
): PayableAccount[] => {
  return nodes.map(({ node }) => ({
    id: node.id,
    name: getCodeWithAuxiliaryAccounts(node),
    isArchived: node.isArchived,
    isDefault: node.isDefault,
    kind,
  }));
};
