import { Avatar, type AvatarSize } from '@dev-spendesk/grapes';
import classNames from 'classnames';

export const fallbackSupplierLogoSrc = '/static/img/avatars/supplier.svg';

type SupplierLogoProps = {
  /**
   * Text to use to serach for the logo using clearbit.
   * If no logo is found, fallback on fallbackSupplierLogoSrc.
   * Used as alt attribute.
   */
  name: string | undefined;
  /**
   * The image URL.
   * If provided, the logo won't be fetched using clearbit
   */
  src?: string;
  /**
   * Specifies the size of the element.
   * @default 'm'
   */
  size?: AvatarSize;
  /**
   * className for the element
   */
  className?: string;
  /**
   * Whether the Avatar src should be lazy loaded
   * @default false
   */
  lazyLoad?: boolean;
};

const logoProviderUrl = '//logo.clearbit.com';
const knownDomainExtensions = [
  '.fr',
  '.uk',
  '.de',
  '.us',
  '.io',
  '.net',
  '.com',
  '.org',
  '.aero',
  '.biz',
  '.edu',
  '.gov',
  '.info',
  '.pro',
];
const endsWithKnownDomainExtensionRegex = new RegExp(
  `(${knownDomainExtensions.join('|')})$`,
  'i',
);

export const getLogoUrlFromName = (name: string) =>
  endsWithKnownDomainExtensionRegex.test(name)
    ? `${logoProviderUrl}/${name.toLowerCase()}`
    : `${logoProviderUrl}/${name.toLowerCase()}.com`;

export const SupplierLogo = ({
  name,
  src,
  size = 32,
  className,
  lazyLoad = false,
}: SupplierLogoProps) => {
  const imgSource = name ? (src ?? getLogoUrlFromName(name)) : undefined;

  return (
    <Avatar
      src={imgSource}
      text={name || ''}
      size={size}
      variant="square"
      fallbackSrc={fallbackSupplierLogoSrc}
      className={classNames('shrink-0', className)}
      lazyLoad={lazyLoad}
    />
  );
};
