import { HighlightIcon, Modal } from '@dev-spendesk/grapes';
import { isBefore } from 'date-fns';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';

import { InputCopy } from 'common/components/InputCopy';
import { useTranslation } from 'common/hooks/useTranslation';
import { upperFirst } from 'common/utils/string';
import { useCompany } from 'modules/app/hooks/useCompany';
import CONFIG from 'src/core/config';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import VCard from 'src/core/utils/vcard';

import './ForwardInfos.css';

type Props =
  | {
      inModal: true;
      onExit: () => void;
    }
  | {
      inModal: false;
      onExit?: never;
    };

export const ForwardInfos = ({ inModal, onExit }: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();

  useEffect(() => {
    track(AnalyticEventName.CLICKED_GET_FORWARD_INFO);
  }, []);

  const downloadMarvinContactFile = () => {
    let phone = CONFIG.phoneNumbers.uk;
    if (company.country === 'FR') {
      phone = CONFIG.phoneNumbers.fr;
    } else if (company.country === 'DE') {
      phone = CONFIG.phoneNumbers.de;
    }

    const vcard = new VCard(`Spendesk ${t('payments.invoices')}.vcf`, {
      firstName: 'Spendesk',
      lastName: upperFirst(t('payments.invoices')),
      nickname: 'Marvin',
      email: `invoices+${company.id}@spendesk.com`,
      phone,
      title: t('payments.automationRobot'),
      organisation: 'Spendesk',
      url: 'https://www.spendesk.com',
    });

    track(AnalyticEventName.GOT_FORWARD_INFO, { type: 'contact_details' });

    return vcard.download();
  };

  const renderContent = () => {
    // for companies created before June 15 we also display the old invoice forward email
    const isOldMarvinEmailDisplayed = isBefore(
      new Date(company.created_at),
      new Date('2020-06-15'),
    );

    return (
      <div className="ForwardInfos__content pb-24">
        <div className="ForwardInfos__content-description">
          {t('payments.autoInvoiceMatchingDescription')}
          <br />
          <Trans i18nKey="payments.autoInvoiceMatchingDescriptionLink">
            -
            <button
              className="ForwardInfos__content-description__link resetButtonStyle body-m"
              type="button"
              onClick={downloadMarvinContactFile}
            >
              Marvin
            </button>
            -
          </Trans>
        </div>
        <InputCopy
          className="mt-24"
          withPurpleShadow
          value={`receipts+${company.id}@spendesk.com`}
          onCopied={(copyMode) =>
            track(AnalyticEventName.GOT_FORWARD_INFO, {
              type: copyMode === 'button' ? 'copy_button' : 'copy_clipboard',
            })
          }
        />
        {isOldMarvinEmailDisplayed && (
          <div className="ForwardInfos__content__small body-s">
            {t('payments.autoInvoiceMatchingDescriptionOldEmail', {
              email: `invoices+${company.id}@spendesk.com`,
            })}
          </div>
        )}
      </div>
    );
  };

  if (inModal) {
    return (
      <Modal
        isOpen
        onClose={onExit}
        iconName="robot"
        title={t('payments.autoInvoiceMatchingTitle')}
      >
        {renderContent()}
      </Modal>
    );
  }

  return (
    <>
      <HighlightIcon variant="info" size={56} name="robot" />
      {renderContent()}
    </>
  );
};
