import { useParams } from 'react-router-dom';
import { array } from 'zod';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useUser } from 'src/core/modules/app/hooks/useUser';

import {
  type PublicApiScopeListEntry,
  publicApiScopeListEntry,
} from '../domain/public-api/scopes';

export const useGetPublicApiScopes = (): QueryState<
  PublicApiScopeListEntry[]
> => {
  const user = useUser();
  const { company: companyId } = useParams();
  return useQuery<PublicApiScopeListEntry[], PublicApiScopeListEntry[]>({
    key: ['publicApiScopesList', companyId, user.lang],
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/${companyId}/public-api-configuration/scopes?language=${user.lang}`,
    },
    options: {
      staleTime: 1000,
      cacheTime: 1000,
    },
    reshapeData(rawItems: unknown[]): PublicApiScopeListEntry[] {
      return array(publicApiScopeListEntry).parse(rawItems);
    },
  });
};
