import { startOfDay, endOfDay, format } from 'date-fns';

import { type CustomFieldFiltersType } from 'src/core/modules/custom-fields';

export type Filters = {
  minAmount?: number | null;
  maxAmount?: number | null;
  fromPeriod?: Date;
  toPeriod?: Date;
  supplier?: string[];
  statuses?: { key: string; label: string }[];
  types?: { key: string; label: string }[];
  teams?: { key: string; label: string }[];
  costCenters?: { key: string; label: string }[];
  requesters?: { key: string; label: string }[];
  documentaryEvidences?: { key: string; label: string }[];
  customFields?: CustomFieldFiltersType;
  textSearch?: string;
};

export type FiltersPayload = {
  type: string;
  value: string | string[];
  label?: string;
}[];

const CUSTOM_FIELD_PREFIX = 'cf_';

export function convertFiltersToPayload(filters: Filters): FiltersPayload {
  const payload: FiltersPayload = [];

  if (filters.textSearch !== undefined) {
    payload.push({
      type: 'textSearch',
      value: filters.textSearch,
    });
  }

  if (filters.supplier) {
    payload.push({
      type: 'supplier',
      value: filters.supplier,
    });
  }

  if (filters.types) {
    payload.push({
      type: 'type',
      value: filters.types.map(({ key }) => key),
    });
  }

  if (filters.statuses) {
    payload.push({
      type: 'status',
      value: filters.statuses.map(({ key }) => key),
    });
  }

  if (filters.teams) {
    payload.push({
      type: 'team',
      value: filters.teams.map(({ key }) => key),
    });
  }

  if (filters.costCenters) {
    payload.push({
      type: 'cost_center',
      value: filters.costCenters.map(({ key }) => key),
    });
  }

  if (filters.requesters) {
    payload.push({
      type: 'payer',
      value: filters.requesters.map(({ key }) => key),
    });
  }

  if (filters.documentaryEvidences) {
    payload.push({
      type: 'invoice',
      value: filters.documentaryEvidences.map(({ key }) => key),
    });
  }

  if (filters.customFields) {
    Object.entries(filters.customFields).forEach(([key, value]) => {
      if (value) {
        payload.push({
          type: `${CUSTOM_FIELD_PREFIX}${key}`,
          value: value.key,
          label: value.label,
        });
      }
    });
  }

  if (filters.fromPeriod && filters.toPeriod) {
    payload.push({
      type: 'period',
      value: `${format(
        startOfDay(filters.fromPeriod),
        "yyyy-MM-dd'T'HH:mm:ss",
      )}/${format(endOfDay(filters.toPeriod), "yyyy-MM-dd'T'HH:mm:ss")}`,
    });
  }

  if (
    typeof filters.minAmount === 'number' ||
    typeof filters.maxAmount === 'number'
  ) {
    payload.push({
      type: 'amount',
      value: `${filters.minAmount ?? Number.NaN}/${filters.maxAmount ?? Number.NaN}`,
    });
  }

  return payload;
}

export const convertPayloadToFilters = (payload: FiltersPayload): Filters => {
  const filters: Filters = {};

  const getMultipleValues = (
    filter: { key: string; label: string }[] | undefined,
    value: string | string[],
  ) =>
    (filter ?? []).concat(
      Array.isArray(value)
        ? value.map((key) => ({ key, label: '' }))
        : [{ key: value, label: '' }],
    );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  payload.forEach(({ type, value }) => {
    switch (type) {
      case 'textSearch':
        filters.textSearch = value as string;
        break;
      case 'supplier': {
        filters.supplier = (filters.supplier ?? []).concat(value);
        break;
      }
      case 'type':
        filters.types = getMultipleValues(filters.types, value);
        break;
      case 'status':
        filters.statuses = getMultipleValues(filters.statuses, value);
        break;
      case 'team':
        filters.teams = getMultipleValues(filters.teams, value);
        break;
      case 'cost_center':
        filters.costCenters = getMultipleValues(filters.costCenters, value);
        break;
      case 'payer':
        filters.requesters = getMultipleValues(filters.requesters, value);
        break;
      case 'invoice':
        filters.documentaryEvidences = getMultipleValues(
          filters.documentaryEvidences,
          value,
        );
        break;
      case 'amount': {
        const [minAmount, maxAmount] = (value as string).split('/').map(Number);
        filters.minAmount = !Number.isNaN(minAmount) ? minAmount : undefined;
        filters.maxAmount = !Number.isNaN(maxAmount) ? maxAmount : undefined;
        break;
      }
      case 'period': {
        const [from, to] = (value as string).split('/');
        filters.fromPeriod = from ? new Date(from) : undefined;
        filters.toPeriod = to ? new Date(to) : undefined;
        break;
      }
      default:
        if (value && type.startsWith(CUSTOM_FIELD_PREFIX)) {
          const [key, label] = (value as string).split('/');
          filters.customFields = {
            ...filters.customFields,
            /* Remove custom field prefix */
            [type.slice(CUSTOM_FIELD_PREFIX.length)]: {
              key,
              label,
            },
          };
        }
        break;
    }
  });

  return filters;
};

export const getActiveFiltersCount = (filters: Filters) => {
  const {
    customFields: filtersWithCustomFields = {},
    textSearch: _textSearch,
    minAmount,
    maxAmount,
    fromPeriod,
    toPeriod,
    ...filtersWithoutCustomFields
  } = filters;

  return (
    Object.keys(filtersWithCustomFields).length +
    Object.keys(filtersWithoutCustomFields).length +
    (typeof minAmount === 'number' || typeof maxAmount === 'number' ? 1 : 0) +
    (fromPeriod || toPeriod ? 1 : 0)
  );
};

export const getLegacyFiltersFromUrl = (search: string) => {
  return Array.from(new URLSearchParams(search).entries()).reduce<{
    [key: string]: string | string[];
  }>(
    (filters, [type, value]) =>
      filters[type]
        ? {
            ...filters,
            [type]: [
              ...(Array.isArray(filters[type])
                ? filters[type]
                : [filters[type]]),
              value,
            ],
          }
        : {
            ...filters,
            [type]: ['period', 'amount'].includes(type)
              ? value
              : value.split('/')[0],
          },
    {},
  );
};
