import {
  Skeleton,
  SkeletonAvatar,
  Table,
  type TableColumn,
} from '@dev-spendesk/grapes';

import { type Payment } from '../all/paymentType';

export const PaymentsTableSkeleton = ({
  columns,
}: {
  columns: TableColumn<Payment>[];
}) => {
  return (
    <Table
      data-testid="payment-list-loading"
      data={Array(14).fill({})}
      columns={columns.map((column, index) => ({
        id: column.id,
        header: column.header,
        renderCell: () => {
          if (index === 0) {
            return (
              <span className="flex items-center gap-8">
                <SkeletonAvatar
                  size="m"
                  variant="square"
                  className="shrink-0"
                />
                <Skeleton height="24px" width="100%" />
              </span>
            );
          }

          if (index === 1) {
            return (
              <span className="flex items-center gap-8">
                <SkeletonAvatar
                  size="m"
                  variant="circle"
                  className="shrink-0"
                />
                <Skeleton height="24px" width="100%" />
              </span>
            );
          }

          return <Skeleton height="24px" width="100%" />;
        },
        width: column.width,
      }))}
      groupBy={() => 'none'}
      renderGroupedRowHeader={() => (
        <div className="flex flex-row justify-between py-8">
          <Skeleton height="16px" width="120px" />
          <Skeleton height="16px" width="160px" />
        </div>
      )}
      getRowId={() => ''}
      onAllRowsSelectionChange={() => {}}
      onRowSelectionChange={() => {}}
      selectedRowIds={[]}
    />
  );
};
