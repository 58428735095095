import { Avatar, Button, Link, Tag, Tooltip } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { routes, routeFor } from 'src/core/constants/routes';

import {
  useDisconnectKomboIntegrationMutation,
  useDisconnectTravelperkMutation,
} from '../../../hooks';
import { useKomboForceSyncMutation } from '../../../hooks/useKomboForceSyncMutation';
import { type Integration } from '../../../types';
import {
  canRefreshSettings,
  getIntegrationDetailsDescription,
  getIntegrationLogo,
  getIntegrationName,
  getIntegrationUrl,
  isKomboIntegration,
} from '../../../utils';
import { ConnectionModal } from '../ConnectionModal';
import { IntegrationBenefitsBox } from '../IntegrationBenefitsBox';
import { IntegrationErrorCallout } from '../IntegrationErrorCallout';
import { KomboIntegrationDetailsContainer } from '../IntegrationPage/custom-page-content/kombo/KomboIntegrationDetailsContainer';
import OAuth2TokenContent from '../IntegrationPage/custom-page-content/oauth2-token/OAuth2TokenContent';

type Props = {
  integration: Integration;
};

type ModalState =
  | {
      isOpen: false;
    }
  | {
      isOpen: true;
      integration: Integration;
    };

const IntegrationDetails = (props: Props) => {
  const { integration } = props;
  const { error } = integration;
  const company = useCompany();
  const history = useHistory();
  const { t } = useTranslation('global');
  const name = getIntegrationName(t, integration.id);
  const logo = getIntegrationLogo(integration.id);
  const url = getIntegrationUrl(t, integration.id);

  const navigateToList = () => {
    history.push(
      routeFor(routes.COMPANY_INTEGRATIONS_ALL.path, {
        company: company.id,
      }),
    );
  };

  const komboIntegration = isKomboIntegration(integration.id);

  const canRefresh = canRefreshSettings(integration.id);

  const [komboForceSync] = useKomboForceSyncMutation();

  const [disconnectTravelperk] = useDisconnectTravelperkMutation(
    integration.id,
  );
  const [disconnectKombo] = useDisconnectKomboIntegrationMutation(
    integration.id,
  );
  const disconnect = async ({ silent } = { silent: false }) => {
    if (integration.id === 'travelperk') {
      await disconnectTravelperk({ silent });
    }
    if (isKomboIntegration(integration.id)) {
      await disconnectKombo({ silent });
    }
  };

  const renderStatusTag = () => {
    if (!error) {
      return (
        <Tag variant="success" className="flex self-center">
          {t('integration.tags.connected')}
        </Tag>
      );
    }

    switch (error) {
      case 'pendingAction':
        return (
          <Tag variant="warning" className="flex self-center">
            {t('integration.tags.pendingAction')}
          </Tag>
        );
      case 'pendingSync':
        return (
          <Tag variant="info" className="flex self-center">
            {t('integration.tags.pendingSync')}
          </Tag>
        );
      default:
        return (
          <Tag variant="alert" className="flex self-center">
            {t('integration.tags.notConnected')}
          </Tag>
        );
    }
  };

  return (
    <div className="flex flex-col items-stretch gap-24 p-24">
      <Button
        className="self-start"
        fit="content"
        iconName="chevron-left"
        iconPosition="left"
        variant="secondaryNeutral"
        text={t('integration.backToList')}
        onClick={navigateToList}
      />
      <div className="flex flex-row items-center gap-16">
        <Avatar src={logo} text={name} variant="square" size={56} />
        <div className="flex flex-col gap-8">
          <div className="flex gap-16">
            <span className="text-primary title-xl">{name}</span>
            {renderStatusTag()}
          </div>
          <div>
            {url ? (
              <Link
                className="flex items-center text-primary no-underline hover:text-secondary-bg-secondary"
                href={url}
                isExternal
              >
                {url}
              </Link>
            ) : null}
          </div>
        </div>

        {canRefresh && (
          <Tooltip
            content={t('integration.kombo.refresh.tooltipMessage')}
            placement="top"
            maxWidth={430}
          >
            <Button
              variant="secondaryNeutral"
              text={t('integration.refresh')}
              onClick={async () => {
                await komboForceSync({ full: false });
              }}
              isDisabled={!!error}
            />
          </Tooltip>
        )}

        {komboIntegration ? (
          <div>
            <Button
              variant="primaryAlert"
              className="self-start"
              text={t('integration.disconnect')}
              onClick={async () => {
                await disconnect();
                navigateToList();
              }}
            />
          </div>
        ) : null}
      </div>
      <IntegrationDetailsContent
        {...props}
        navigateToList={navigateToList}
        disconnect={disconnect}
      />
    </div>
  );
};

export default withErrorBoundary({
  scope: 'integration-details',
  team: 'api-integration',
})(IntegrationDetails);

type IntegrationDetailsContentProps = Props & {
  navigateToList: () => void;
  disconnect: (options?: { silent: boolean }) => Promise<void>;
};

const IntegrationDetailsContent = (props: IntegrationDetailsContentProps) => {
  if (isKomboIntegration(props.integration.id)) {
    return <KomboIntegrationDetailsContainer {...props} />;
  }

  switch (props.integration.id) {
    case 'spendesk-oauth2':
      return <OAuth2TokenContent />;
    default:
      return <DefaultIntegrationDetails {...props} />;
  }
};

const DefaultIntegrationDetails = ({
  integration,
  navigateToList,
  disconnect,
}: IntegrationDetailsContentProps) => {
  const { t } = useTranslation('global');

  const [modalState, setModalState] = useState<ModalState>({ isOpen: false });
  const fullDescription = getIntegrationDetailsDescription(t, integration.id);
  const { error } = integration;

  return (
    <>
      <div className="flex flex-col gap-8">
        <span className="text-16 text-secondary-bg-primary">
          {fullDescription}
        </span>
      </div>
      {error ? (
        <IntegrationErrorCallout
          error={error}
          onAction={async () => {
            await disconnect({ silent: true });
            setModalState({
              isOpen: true,
              integration,
            });
          }}
        />
      ) : null}
      <IntegrationBenefitsBox integration={integration} />
      <Button
        variant="primaryAlert"
        className="self-start"
        text={t('integration.disconnect')}
        onClick={async () => {
          await disconnect();
          navigateToList();
        }}
      />
      {modalState.isOpen ? (
        <ConnectionModal
          integration={modalState.integration}
          closeModal={() => setModalState({ isOpen: false })}
        />
      ) : null}
    </>
  );
};
