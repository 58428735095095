import { Tag } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  status: 'disabled' | 'completed' | 'ongoing';
};
export const StatusTag = ({ status }: Props) => {
  const { t } = useTranslation('global');
  if (status === 'disabled') {
    return (
      <Tag iconName="lock-close" variant="neutral">
        {t('onboardingHub.misc.disabledTag')}
      </Tag>
    );
  }
  if (status === 'completed') {
    return (
      <Tag iconName="circle-check" variant="success">
        {t('misc.done')}
      </Tag>
    );
  }
  if (status === 'ongoing') {
    return (
      <Tag iconName="circle-information" variant="info">
        {t('onboardingHub.misc.toDo')}
      </Tag>
    );
  }
};
