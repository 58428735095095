import React from 'react';

import styles from './TransferActionsBar.module.css';
import { WithTransfersSelected } from './WithTransfersSelected';
import { WithoutTransfersSelected } from './WithoutTransfersSelected';
import { selectors, useSelectorWithContext } from '../../redux';

type Props = {
  canApproveTransfers: boolean;
};

export const TransferActionsBar = (props: Props) => {
  const transfersSelectedCount = useSelectorWithContext(
    selectors.selectTransfersSelectedCount,
  );

  return (
    <div className={styles.TransferActionsBar}>
      {transfersSelectedCount > 0 ? (
        <WithTransfersSelected {...props} />
      ) : (
        <WithoutTransfersSelected {...props} />
      )}
    </div>
  );
};
