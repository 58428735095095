import queryString from 'query-string';
import { useEffect, useState } from 'react';

import { companyAPI } from 'src/core/api/axios';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { logger } from 'src/utils/datadog-log-wrapper';

import { AffidavitSignature } from './AffidavitSignature';

export const AffidavitSignatureContainer = () => {
  const companyId = useCompanyId();
  const { paymentId, callbackUrl, reason } = queryString.parse(
    window.location.search,
  );

  const redirectToCallbackUrl = (success: boolean) => {
    window.location.href = `${callbackUrl}?success=${success}`;
  };

  const handleSignSuccess = async () => {
    try {
      await companyAPI.post(
        `/missing-receipt/${paymentId}/affidavit/signed`,
        {},
        {
          companyId,
        },
      );
      redirectToCallbackUrl(true);
    } catch {
      redirectToCallbackUrl(false);
    }
  };
  const handleSignError = () => redirectToCallbackUrl(false);

  const [signingUrl, setSigningUrl] = useState();
  useEffect(() => {
    const getAffidavit = async () => {
      try {
        const { data } = await companyAPI.post(
          `/missing-receipt/${paymentId}`,
          {
            type: 'affidavit',
            reason,
          },
          {
            companyId,
          },
        );
        setSigningUrl(data.missingReceipt.affidavit.signatureUrl);
      } catch (error) {
        logger.warn('Error while fetching the affidavit signature URL', {
          team: 'travel-and-expenses',
          scope: 'affidavit-signature',
          errorMessage: error?.message ?? '',
          paymentId,
        });
        handleSignError();
      }
    };

    getAffidavit();
  }, []); // `[]` as second arg to make the call only once

  useEffect(() => {
    logger.info('Mounting the AffidavitSignatureContainer', {
      signingUrl,
      href: window.location.href,
      team: 'travel-and-expenses',
      scope: 'affidavit-signature',
    });
  }, [signingUrl]);

  logger.info('Rendering the AffidavitSignatureContainer', {
    paymentId,
    callbackUrl,
    reason,
    signingUrl,
    team: 'travel-and-expenses',
    scope: 'affidavit-signature',
  });

  return (
    <AffidavitSignature
      signingUrl={signingUrl}
      onSignSuccess={() => {
        logger.info('HelloSign client signed', {
          href: window.location.href,
          team: 'travel-and-expenses',
          scope: 'affidavit-signature',
        });
        handleSignSuccess();
      }}
      onSignError={(error) => {
        logger.warn('HelloSign client error', {
          href: window.location.href,
          team: 'travel-and-expenses',
          scope: 'affidavit-signature',
          error,
        });
        handleSignError();
      }}
    />
  );
};
