import { CostCenterSelect } from './CostCenterSelect';
import { SpendTypeSelect } from './SpendTypeSelect';
import { type Dimension } from '../../approvalWorkflow';

type Props = {
  dimension: Dimension;
  selectedValues: string[] | undefined | null;
  onSelect: (values: string[] | null) => void;
};

export const DimensionValuesSelect = ({
  dimension,
  selectedValues,
  onSelect,
}: Props) => {
  if (dimension.type === 'spendType') {
    return (
      <SpendTypeSelect selectedValues={selectedValues} onSelect={onSelect} />
    );
  }
  if (dimension.type === 'costCenter') {
    return (
      <CostCenterSelect selectedValues={selectedValues} onSelect={onSelect} />
    );
  }
  return null;
};
