import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { type DangerNotif } from 'src/core/modules/app/notifications';
import {
  MAX_SIZE,
  isFileTooLarge,
  isFileTypesNotSupported,
} from 'src/core/utils/files';
import { logger } from 'src/utils/datadog-log-wrapper';

import { type BulkExpenseDraft } from './model';
import { type ExpenseClaimForm } from '../../expense-form/utils';

export const handleLocalFileUpload = (
  t: TGlobalFunctionTyped,
  filesToAdd: FileList | null,
) => {
  const errors: string[] = [];
  const filteredFiles: File[] = [];
  if (!filesToAdd) {
    return { errors, filteredFiles };
  }

  Array.from(filesToAdd).forEach((file) => {
    if (isFileTypesNotSupported(file)) {
      errors.push(
        t('requests.panel.thisFileTypeIsNotSupported', { name: file.name }),
      );
    } else if (isFileTooLarge(file)) {
      errors.push(
        t('requests.panel.thisFileIsTooLarge', {
          name: file.name,
          max: MAX_SIZE,
        }),
      );
    } else {
      filteredFiles.push(file);
    }
  });

  return { errors, filteredFiles };
};

export const updateBulkExpenseDraftValues = ({
  bulkExpenseDraft,
  formValues,
  additionalFiles,
}: {
  bulkExpenseDraft: BulkExpenseDraft;
  formValues: ExpenseClaimForm;
  additionalFiles: File[];
}) => ({
  ...bulkExpenseDraft,
  additionalFiles,
  request: {
    ...bulkExpenseDraft.request,
    currency_declared: formValues.currency,
    amount_declared: formValues.amount,
    expense_paid_at: formValues.expenseDate,
    group: {
      id: formValues.teamId ?? '',
      name: '',
    },
    cost_center: {
      id: formValues.costCenterId ?? '',
      name: '',
    },
    customFieldsAssociations: formValues.customFieldsAssociations,
    expenseCategoryId: formValues.expenseCategoryId,
    description: formValues.description,
    supplier: {
      id: formValues.supplier?.id ?? null,
      name: formValues.supplier?.name ?? '',
      url: formValues.supplier?.url ?? null,
    },
    beneficiaries: formValues.beneficiaries,
  },
});

export const handleReceiptUploadError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  dangerNotif: DangerNotif,
  t: TGlobalFunctionTyped,
) => {
  if (
    error.response?.status === 413 ||
    error.response?.data?.error === 'file_size_error'
  ) {
    logger.info('handleSubmitExpenseClaim - file too big on submit', {
      scope: 'ReimbursementRequestPanel',
      team: 'travel-and-expenses',
      error,
    });
    dangerNotif(t('requests.panel.warningFileTooBig'));
  } else if (error.response?.data?.error === 'unsupported_media_type') {
    logger.info('handleSubmitExpenseClaim - unsupported media type on submit', {
      scope: 'ReimbursementRequestPanel',
      team: 'travel-and-expenses',
      error,
    });
    const currentMimeType = error.response.data.mimeType;
    const supportedTypes = error.response.data.supportedTypes.join(', ');
    dangerNotif(
      t('requests.panel.unsupportedMediaType', {
        currentMimeType,
        supportedTypes,
      }),
    );
  } else {
    dangerNotif(t('misc.somethingWrong'));
  }
};
