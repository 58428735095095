import {
  Button,
  HighlightIcon,
  Icon,
  ModalBody,
  Avatar,
  DATE_FORMAT,
  Tooltip,
} from '@dev-spendesk/grapes';
import React, { useEffect } from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { capitalize } from 'common/utils/string';
import { type Company } from 'modules/app/hooks/useCompany';
import { onMemberDetailsModalOpened as trackOnMemberDetailsModalOpened } from 'modules/members/tracking';
import FEATURES from 'src/core/constants/features';
import { type AccountingSoftware } from 'src/core/modules/bookkeep/integration/status';
import { useHasWireTransferDelegationFeature } from 'src/core/modules/delegation/hooks/useHasWireTransferDelegationFeature';
import { useIsUserManagedByKombo } from 'src/core/modules/integrations/hooks';

import { MemberDetailsRow as Row } from './MemberDetailsModalRow';
import { MemberDetailsModalControlRule } from './components/MemberDetailsModalControlRule';
import { MemberDetailsModalPolicy } from './components/MemberDetailsModalPolicy';
import {
  getMemberName,
  getMemberPhoneNumber,
  getMemberTeams,
  getMemberRoleNamesForDisplay,
  type MemberDetails,
} from '../../models/member';
import { MemberSyncStatus } from '../MemberSyncStatus/MemberSyncStatus';

import './MemberDetailsModal.css';

type Props = {
  member: MemberDetails;
  company: Company;
  isCurrentUser: boolean;
  memberSync?: {
    isEnabled: boolean;
    integrationName?: AccountingSoftware;
    status?: { synced: boolean; url: string | null };
  };
  onEdit(): void;
  onDelete(): void;
  onResendInvitation(): void;
  onUpdateCardsAccess(hasAccess: boolean): Promise<void>;
};

export function MemberDetailsModal({
  member,
  company,
  isCurrentUser,
  memberSync,
  onEdit,
  onDelete,
  onResendInvitation,
  onUpdateCardsAccess,
}: Props) {
  useEffect(() => {
    trackOnMemberDetailsModalOpened({ member });
  }, []);

  const { t, localeFormat } = useTranslation('global');

  const isTeamsFeatureEnabled = useFeature(FEATURES.TEAMS);
  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );
  const isPlayByRulesFeatureEnabled = useFeature(FEATURES.PLAY_BY_RULES);
  const isCardsAccessFeatureEnabled = useFeature(FEATURES.CARDS_ACCESS);

  const hasReportingManagerFeature = useFeature(FEATURES.REPORTING_MANAGERS);
  const hasWireTransferConfirmationFeature =
    useHasWireTransferDelegationFeature();

  const isCardsAccessManuallyRestricted =
    member.cardsAccess?.hasAccess === false &&
    member.cardsAccess.initiator === 'user';

  const hasPlasticCard = Boolean(member.plasticCard);

  const isManagedByKombo = useIsUserManagedByKombo({ userId: member.id });

  const translatedRoleNames = getMemberRoleNamesForDisplay(member, t).map(
    capitalize,
  );
  const memberTeams = getMemberTeams(member);

  return (
    <ModalBody>
      <div className="my-24 text-left">
        <div className="text-center">
          <Avatar src={member.avatar} text={getMemberName(member)} size={56} />

          <div
            id="MemberDetailsModal"
            className="MemberDetailsModal__header__name heading-l"
          >
            {getMemberName(member)}
            {isCardsAccessManuallyRestricted && (
              <HighlightIcon
                size={24}
                variant="alert"
                name="card-lock-close"
                className="ml-8"
              />
            )}
          </div>

          {memberSync?.isEnabled && memberSync.status && (
            <MemberSyncStatus
              status={memberSync.status}
              integrationName={memberSync.integrationName}
            />
          )}

          <div className="MemberDetailsModal__header__container">
            <div className="flex items-center">
              <div className="text-secondary-bg-primary">
                {t(
                  member.isPending
                    ? 'members.invitedOn'
                    : 'members.memberSince',
                  {
                    date: localeFormat(
                      new Date(member.createdAt),
                      DATE_FORMAT.SHORT,
                    ),
                  },
                )}
              </div>
              {hasPlasticCard && (
                <>
                  <div className="MemberDetailsModal__verticalDivider" />
                  {t('members.countPlasticCards', { count: 1 })}
                </>
              )}

              {member.subscriptionsCount > 0 && (
                <>
                  <div className="MemberDetailsModal__verticalDivider" />
                  {t('members.countActiveSubscriptions', {
                    count: member.subscriptionsCount,
                  })}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="separator my-16" />

        <div className="pb-8">
          <div className="MemberDetailsModal__main__heading title-l">
            {t('members.detailsModal.memberInformation')}
            <Button
              variant="primaryBrand"
              text={t('members.detailsModal.editButton')}
              onClick={() => onEdit()}
            />
          </div>

          <Row label={t('misc.emailAddress')}>
            <div className="text-secondary-bg-secondary">{member.email}</div>
          </Row>

          <Row label={t('misc.phoneNumber')}>
            {!member.mobileNo || !member.mobileExt ? (
              <div className="text-secondary-bg-secondary">
                {t('members.detailsModal.noMobileNumber')}
              </div>
            ) : (
              <div className="text-secondary-bg-secondary">
                {getMemberPhoneNumber(member)}
              </div>
            )}
          </Row>

          <Row label={t('misc.roles')}>
            {translatedRoleNames.length === 0 ? (
              <div className="text-secondary-bg-secondary">
                {t('members.memberHasNoRole')}
              </div>
            ) : (
              <div className="text-secondary-bg-secondary">
                {translatedRoleNames.join(', ')}
              </div>
            )}
          </Row>
          {hasReportingManagerFeature && (
            <Row label={t('members.detailsModal.manager')}>
              <div className="text-secondary-bg-secondary">
                {member.manager
                  ? member.manager.fullNameOrEmail
                  : t('members.detailsModal.noManager')}
              </div>
            </Row>
          )}

          {hasWireTransferConfirmationFeature && (
            <Row label={t('members.detailsModal.additionalRights')}>
              {member.canConfirmPayments ? (
                <div className="text-secondary-bg-secondary">
                  {t('members.detailsModal.confirmPayments')}
                </div>
              ) : (
                <div className="text-secondary-bg-secondary">
                  {t('members.detailsModal.noAdditionalRights')}
                </div>
              )}
            </Row>
          )}

          {isCostCentersFeatureEnabled && (
            <Row label={t('members.detailsModal.costCenter')}>
              {!member.costCenter ? (
                <div className="text-secondary-bg-secondary">
                  {t('members.detailsModal.noCostCenter')}
                </div>
              ) : (
                <div className="text-secondary-bg-secondary">
                  {member.costCenter?.name}

                  {member.costCenter.selectionMode === 'suggested' && (
                    <span> ({t('members.suggestedCostCenter')})</span>
                  )}
                </div>
              )}
            </Row>
          )}

          {isPlayByRulesFeatureEnabled && (
            <Row label={t('members.detailsModal.controlRule')}>
              <MemberDetailsModalControlRule controlRule={member.controlRule} />
            </Row>
          )}

          {isTeamsFeatureEnabled && (
            <Row label={t('misc.teams')}>
              {memberTeams.length === 0 ? (
                <div className="text-secondary-bg-secondary">
                  {t('members.memberHasNoGroup')}
                </div>
              ) : (
                <div className="text-secondary-bg-secondary">
                  {memberTeams.map((team) => team.name).join(', ')}
                </div>
              )}
            </Row>
          )}

          <Row label={t('misc.policy')}>
            <MemberDetailsModalPolicy member={member} company={company} />
          </Row>

          {company.type !== 'branch_expense_entity' && (
            <Row
              label={t('members.bankInfo')}
              containerClassNames={
                member.bankInfo ? '' : 'MemberDetailsModal__warningContainer'
              }
            >
              {member.bankInfo ? (
                <div className="text-secondary-bg-secondary">
                  {t('members.bankInfoCorrect')}
                </div>
              ) : (
                <div className="flex items-center justify-between">
                  {t('members.detailsModal.noBankInfos')}
                  <Icon name="triangle-warning" size="s" />
                </div>
              )}
            </Row>
          )}
        </div>
        <div className="separator my-16" />

        <div className="MemberDetailsModal__actions">
          {member.isPending && (
            <div className="flex items-center gap-16">
              <Button
                iconName="bell"
                variant="secondaryNeutral"
                text={t('members.resendInvitation')}
                onClick={() => onResendInvitation()}
              />
              <Tooltip
                content={t(
                  'integration.members.invitationForHrManagedMemberCantBeRevokedTooltip',
                )}
                placement="top"
                isDisabled={!isManagedByKombo}
                maxWidth={320}
              >
                <Button
                  iconName="circle-minus"
                  variant="secondaryNeutral"
                  text={t('members.revokeInvitation')}
                  onClick={() => onDelete()}
                  isDisabled={isManagedByKombo}
                />
              </Tooltip>
            </div>
          )}

          {!member.isPending &&
            !isCurrentUser &&
            !member.isAccountOwner &&
            !member.isOrganisationOwner && (
              <div className="flex items-center gap-16">
                {isCardsAccessFeatureEnabled && (
                  <Button
                    variant="secondaryNeutral"
                    iconName={
                      isCardsAccessManuallyRestricted
                        ? 'lock-close'
                        : 'lock-open'
                    }
                    text={
                      isCardsAccessManuallyRestricted
                        ? t('members.detailsModal.allowCardsAccess')
                        : t('members.detailsModal.restrictCardsAccess')
                    }
                    onClick={() =>
                      onUpdateCardsAccess(isCardsAccessManuallyRestricted)
                    }
                  />
                )}
                <Tooltip
                  content={t(
                    'integration.members.hrManagedMemberCantBeDeletedTooltip',
                  )}
                  placement="top"
                  isDisabled={!isManagedByKombo}
                  maxWidth={320}
                >
                  <Button
                    iconName="circle-minus"
                    variant="secondaryAlert"
                    text={t('members.detailsModal.removeMemberButton')}
                    onClick={onDelete}
                    isDisabled={isManagedByKombo}
                  />
                </Tooltip>
              </div>
            )}
          {member.isAccountOwner && (
            <div className="MemberDetailsModal__actions_ao-cant-be-deleted text-primary">
              {t('members.detailsModal.aoCantBeDeleted')}
            </div>
          )}
          {member.isOrganisationOwner && (
            <div className="MemberDetailsModal__actions_ao-cant-be-deleted text-primary">
              {t('members.detailsModal.ooCantBeDeleted')}
            </div>
          )}
          {!member.isAccountOwner &&
            !member.isOrganisationOwner &&
            isCurrentUser && (
              <div className="MemberDetailsModal__actions_ao-cant-be-deleted text-primary">
                {t('members.detailsModal.cantDeleteOwnAccount')}
              </div>
            )}
        </div>
      </div>
    </ModalBody>
  );
}
