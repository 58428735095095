// Fetch requests list
export const FETCH_REQUESTS_LOADING = 'FETCH_REQUESTS_LOADING';
export const FETCH_REQUESTS_SUCCESS = 'FETCH_REQUESTS_SUCCESS';
export const FETCH_REQUESTS_FAILURE = 'FETCH_REQUESTS_FAILURE';

export const FETCH_REQUESTS_SECTIONS_STATS_SUCCESS =
  'FETCH_REQUESTS_SECTIONS_STATS_SUCCESS';

// filters
export const SET_REQUEST_FILTERS = 'SET_REQUEST_FILTERS';
export const RESET_REQUEST_FILTERS = 'RESET_REQUEST_FILTERS';
export const SET_TEXT_FILTER = 'SET_TEXT_FILTER';
export const RESET_TEXT_FILTER = 'RESET_TEXT_FILTER';

// Fetch draft requests
export const FETCH_DRAFT_REQUESTS_LOADING = 'FETCH_DRAFT_REQUESTS_LOADING';
export const FETCH_DRAFT_REQUESTS_SUCCESS = 'FETCH_DRAFT_REQUESTS_SUCCESS';
export const FETCH_DRAFT_REQUESTS_FAILURE = 'FETCH_DRAFT_REQUESTS_FAILURE';

// Create request
export const ADD_REQUEST_LOCALLY = 'ADD_REQUEST_LOCALLY';

// Update one request
export const UPDATE_REQUEST_SUCCESS = 'UPDATE_REQUEST_SUCCESS';
export const UPDATE_REQUEST_FAILURE = 'UPDATE_REQUEST_FAILURE';

// Locally update a request
export const UPDATE_REQUEST_LOCALLY = 'UPDATE_REQUEST_LOCALLY';
export const REMOVE_REQUEST_LOCALLY = 'REMOVE_REQUEST_LOCALLY';

// Approve
export const APPROVE_REQUEST_LOADING = 'APPROVE_REQUEST_LOADING';
export const APPROVE_REQUEST_SUCCESS = 'APPROVE_REQUEST_SUCCESS';
export const APPROVE_REQUEST_FAILURE = 'APPROVE_REQUEST_FAILURE';

// Top-up request
export const LOAD_CARD_REQUEST_LOADING = 'LOAD_CARD_REQUEST_LOADING';
export const LOAD_CARD_REQUEST_FAILURE = 'LOAD_CARD_REQUEST_FAILURE';

// Fetch requests stats
export const FETCH_REQUESTS_STATS_SUCCESS = 'FETCH_REQUESTS_STATS_SUCCESS';

// Update draft requests locally
export const ADD_DRAFT_REQUESTS_LOCALLY = 'ADD_DRAFT_REQUESTS_LOCALLY';
export const REMOVE_DRAFT_REQUEST_LOCALLY = 'REMOVE_DRAFT_REQUEST_LOCALLY';

export type RequestsActions =
  | { type: 'FETCH_REQUESTS_LOADING' }
  | { type: 'FETCH_REQUESTS_SUCCESS' }
  | { type: 'FETCH_REQUESTS_FAILURE' }
  | { type: 'FETCH_REQUESTS_SECTIONS_STATS_SUCCESS' }
  | { type: 'SET_REQUEST_FILTERS' }
  | { type: 'SET_TEXT_FILTER' }
  | { type: 'RESET_TEXT_FILTER' }
  | { type: 'FETCH_DRAFT_REQUESTS_LOADING' }
  | { type: 'FETCH_DRAFT_REQUESTS_SUCCESS' }
  | { type: 'FETCH_DRAFT_REQUESTS_FAILURE' }
  | { type: 'ADD_REQUEST_LOCALLY' }
  | { type: 'UPDATE_REQUEST_SUCCESS' }
  | { type: 'UPDATE_REQUEST_FAILURE' }
  | { type: 'UPLOAD_INVOICE_FILES_LOADING' }
  | { type: 'UPLOAD_INVOICE_FILES_SUCCESS' }
  | { type: 'UPLOAD_INVOICE_FILES_FAILURE' }
  | { type: 'UPDATE_REQUEST_LOCALLY' }
  | { type: 'REMOVE_REQUEST_LOCALLY' }
  | { type: 'REQUEST_FAILURE_RESET' }
  | { type: 'APPROVE_REQUEST_LOADING' }
  | { type: 'APPROVE_REQUEST_SUCCESS' }
  | { type: 'APPROVE_REQUEST_FAILURE' }
  | { type: 'LOAD_CARD_REQUEST_LOADING' }
  | { type: 'LOAD_CARD_REQUEST_SUCCESS' }
  | { type: 'LOAD_CARD_REQUEST_FAILURE' }
  | { type: 'FETCH_REQUESTS_STATS_SUCCESS' }
  | { type: 'ADD_DRAFT_REQUESTS_LOCALLY' }
  | { type: 'REMOVE_DRAFT_REQUEST_LOCALLY' };
