import {
  AutocompleteNoOptions,
  Callout,
  Icon,
  colors,
  AmountInput,
  Tooltip,
  DropdownItem,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import {
  toNumber as toNumberMoney,
  fromNumber as fromNumberMoney,
} from 'ezmoney';
import get from 'lodash/get';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import {
  type I18nKey,
  useTranslation,
  type TGlobalFunctionTyped,
} from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { CreateExpenseAccountModal } from 'modules/bookkeep/components/CreateExpenseAccountModal';
import { type IntegrationStatus } from 'modules/bookkeep/integration/status';
import { InvalidAccountCallout } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/components/InvalidAccountCallout';
import { type ItemLineExpenseAccount } from 'modules/bookkeep/prepare-payables/models';
import { type ExpenseAccount } from 'modules/bookkeep/settings/accounting';
import {
  type ExpenseAccountAutomation,
  type ExpenseAmountAutomation,
} from 'modules/payable/models';
import { routeFor, routes } from 'src/core/constants/routes';
import { expenseAccountToLabel } from 'src/core/modules/bookkeep/prepare-payables/components/PreparePayablesFiltersBar/components/PreparePayablesFiltersAdditionalFilters/hooks/reshaper';
import { getCurrencyOptionByKey } from 'src/core/utils/money';

import { checkIfExpenseAccountIsInvalid } from './checkIfExpenseAccountIsInvalid';

type Props = {
  values: ItemLineExpenseAccount | null;
  initialValues: ItemLineExpenseAccount | null;
  errors?: {
    accountId?: string;
    amount?: string;
  };
  hasAmountError: boolean;
  expenseAccounts: ExpenseAccount[];
  expenseAccount: ExpenseAccount | undefined;
  currency: string;
  accountAutomation?: ExpenseAccountAutomation;
  amountAutomation?: ExpenseAmountAutomation;
  shouldDisplayAmountValue: boolean;
  expenseAccountPlaceholder?: I18nKey;
  onChange: (expenseAccount: ItemLineExpenseAccount) => void;
  integrationStatus: IntegrationStatus;
  className?: string;
};

const AutomationIcon = ({
  automationKind,
  t,
  fieldKind,
  className = '',
}: {
  automationKind:
    | ExpenseAccountAutomation['kind']
    | ExpenseAmountAutomation['kind']
    | undefined;
  t: TGlobalFunctionTyped;
  fieldKind: 'account' | 'amount';
  className?: string;
}) => {
  switch (automationKind) {
    case 'prediction': {
      if (fieldKind === 'amount') {
        return null;
      }

      return (
        <Tooltip
          content={t(
            'expenseInbox.expenseEditor.expenseAccountPrefilledByPrediction',
          )}
          triggerAsChild
        >
          <Icon
            color={colors.contentBrandDefault}
            name="robot"
            className={className}
          />
        </Tooltip>
      );
    }
    case 'recommendation': {
      if (fieldKind === 'account') {
        return null;
      }

      return (
        <Tooltip
          content={t(
            'expenseInbox.expenseEditor.expenseAmountPrefilledByRecommendation',
          )}
          triggerAsChild
        >
          <Icon
            color={colors.contentBrandDefault}
            name="robot"
            className={className}
          />
        </Tooltip>
      );
    }
    case 'supplierRule': {
      if (fieldKind === 'amount') {
        return null;
      }

      return (
        <Tooltip
          content={t(
            'expenseInbox.expenseEditor.expenseAccountPrefilledByRule',
          )}
          triggerAsChild
        >
          <Icon name="link" />
        </Tooltip>
      );
    }
    case 'expenseCategoryRule': {
      if (fieldKind === 'amount') {
        return null;
      }

      return (
        <Tooltip
          content={t(
            'expenseInbox.expenseEditor.expenseAccountPrefilledByExpenseCategoryExpenseAccountRule',
          )}
          triggerAsChild
        >
          <Icon name="link" className={className} />
        </Tooltip>
      );
    }
    case 'default': {
      // Even though this is "automated" this is for accountants just throwing in a default account to fix after export
      return null;
    }
    default:
      return null;
  }
};

const checkIsAutomatedExpenseAccount = (
  initialValues: Props['initialValues'],
  automation: Props['accountAutomation'],
  value?: string | null,
): automation is ExpenseAccountAutomation => {
  return Boolean(
    automation?.isAppliedOnPayable &&
      automation?.kind !== 'default' &&
      initialValues?.accountId === value,
  );
};

const checkIsAutomatedExpenseAmount = (
  initialValues: Props['initialValues'],
  automation: Props['amountAutomation'],
  value?: number | null,
): automation is ExpenseAmountAutomation => {
  return Boolean(
    automation?.isAppliedOnPayable &&
      initialValues?.amount &&
      toNumberMoney(initialValues.amount) === (value || 0),
  );
};

export const PayableExpenseAccountField = ({
  values,
  initialValues,
  errors,
  hasAmountError,
  expenseAccounts,
  expenseAccount,
  currency,
  accountAutomation,
  amountAutomation,
  shouldDisplayAmountValue,
  expenseAccountPlaceholder = 'expenseInbox.expenseEditor.expenseAccount',
  integrationStatus,
  onChange,
  className = '',
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const [newExpenseAccountToAdd, setNewExpenseAccountToAdd] = useState('');

  const handleAccountChange = (
    selected:
      | {
          key: string;
          label: string;
        }
      | undefined,
  ) => {
    if (selected?.key !== selected?.label) {
      onChange({
        amount: values?.amount ?? null,
        accountId: selected?.key || null,
        name: selected?.label,
      });
    }
  };

  const handleAmountChange = (amountNumber: number | null) => {
    // Pass `null` as value when the input has been cleared.
    const amountValue =
      amountNumber || amountNumber === 0
        ? fromNumberMoney(
            Number(amountNumber),
            // If the input has a `null` amount (which is
            // not a  MonetaryValue), use the provided `currency` to
            // build the output MonetaryValue.
            get(values, 'amount.currency', currency),
            2,
          )
        : null;
    onChange({ accountId: values?.accountId ?? null, amount: amountValue });
  };

  const handleAmountBlur = (amountNumber: number | null) => {
    if (!amountNumber) {
      // Set field to "0" if empty on blur because it's mandatory
      onChange({
        accountId: values?.accountId ?? null,
        amount: fromNumberMoney(0, get(values, 'amount.currency', currency), 2),
      });
    }
  };

  const isInvalidExpenseAccount = checkIfExpenseAccountIsInvalid(
    integrationStatus,
    values?.accountId,
  );

  const code = expenseAccounts.find(({ id }) => id === values?.accountId)?.code;

  const selectedExpenseAccount = expenseAccounts.find(
    (account) => account.id === values?.accountId,
  );

  const displayValue = selectedExpenseAccount
    ? {
        key: selectedExpenseAccount.id,
        label: expenseAccountToLabel(selectedExpenseAccount),
      }
    : undefined;

  const isArchivedExpenseAccount = expenseAccount && expenseAccount.isArchived;

  const options = expenseAccounts.map((account) => ({
    key: account.id,
    label: expenseAccountToLabel(account),
  }));

  const value = (values?.amount && toNumberMoney(values.amount)) ?? null;

  const handleAddExpenseAccount = async (
    rawValue: string,
  ): Promise<{ key: string; label: string }> => {
    setNewExpenseAccountToAdd(rawValue);
    return { key: rawValue, label: rawValue };
  };

  const handleCloseAddExpenseAccountModal = (
    newAccount: ExpenseAccount | undefined,
  ) => {
    if (newAccount) {
      handleAccountChange({ key: newAccount.id, label: newAccount.name });
    }
    setNewExpenseAccountToAdd('');
  };

  const isSelectedExpenseAccountAnAutomatedValue =
    checkIsAutomatedExpenseAccount(
      initialValues,
      accountAutomation,
      selectedExpenseAccount?.id,
    );

  const hasAmountPredictionOrRecommendation = checkIsAutomatedExpenseAmount(
    initialValues,
    amountAutomation,
    value,
  );

  return (
    <>
      <div className={`relative flex items-center ${className}`}>
        <AutocompleteSearch
          fit="parent"
          className={cx('[&_input]:w-full', {
            'max-w-full': !shouldDisplayAmountValue,
          })}
          placeholder={t(expenseAccountPlaceholder)}
          options={options}
          value={displayValue}
          isInvalid={Boolean(errors?.accountId || isInvalidExpenseAccount)}
          onSelect={handleAccountChange}
          renderAddOption={(rawValue) => {
            return (
              <DropdownItem
                label={
                  <Trans
                    i18nKey="misc.addAutocompleteOption"
                    values={{ option: rawValue }}
                    components={[
                      <span
                        key="addAutocompleteOption"
                        className="text-primary"
                      />,
                    ]}
                  />
                }
              />
            );
          }}
          onAddOption={handleAddExpenseAccount}
          renderNoOptions={(rawValue) => (
            <AutocompleteNoOptions className="text-center body-m">
              <Trans
                i18nKey="misc.noOptions"
                values={{ account: rawValue }}
                components={[<span key="noOptions" className="text-primary" />]}
              />
            </AutocompleteNoOptions>
          )}
          inputVariant={
            isSelectedExpenseAccountAnAutomatedValue &&
            accountAutomation.kind === 'prediction'
              ? 'magicGradient'
              : 'default'
          }
          renderPrefix={() => {
            if (isSelectedExpenseAccountAnAutomatedValue) {
              return (
                <AutomationIcon
                  automationKind={accountAutomation?.kind}
                  t={t}
                  fieldKind="account"
                />
              );
            }
          }}
          renderOption={(
            option,
            { isSelected } = {
              isSelected: false,
            },
          ) => {
            return (
              <DropdownItem
                key={option.key}
                label={option.label}
                isSelected={isSelected}
                suffix={
                  checkIsAutomatedExpenseAccount(
                    initialValues,
                    accountAutomation,
                    option.key,
                  ) ? (
                    <AutomationIcon
                      automationKind={accountAutomation.kind}
                      t={t}
                      fieldKind="account"
                    />
                  ) : null
                }
              />
            );
          }}
        />

        <CreateExpenseAccountModal
          integrationStatus={integrationStatus}
          isOpen={!!newExpenseAccountToAdd}
          onClose={handleCloseAddExpenseAccountModal}
          defaultAccountName={newExpenseAccountToAdd}
        />

        {shouldDisplayAmountValue && (
          <AmountInput
            className="ml-8 w-[104px]"
            fit="parent"
            placeholder={t('expenseInbox.inputs.vat.amount')}
            value={value}
            currency={getCurrencyOptionByKey(currency)}
            isInvalid={Boolean(errors?.amount) || hasAmountError}
            onChange={(e) => handleAmountChange(e.target.valueAsNumber)}
            onBlur={(e) => handleAmountBlur(e.target.valueAsNumber)}
            hasNegativeValueAllowed
            variant={
              hasAmountPredictionOrRecommendation ? 'magicGradient' : 'default'
            }
            leftAddon={
              hasAmountPredictionOrRecommendation && (
                <AutomationIcon
                  automationKind={amountAutomation.kind}
                  t={t}
                  className="ml-8"
                  fieldKind="amount"
                />
              )
            }
          />
        )}
      </div>
      {isArchivedExpenseAccount && (
        <Callout
          variant="warning"
          className="mb-8 mt-8"
          title={
            <Trans
              i18nKey="expenseInbox.expenseEditor.expenseAccountDeleted"
              values={{ account: expenseAccount?.name }}
              components={[<span key="expenseAccountDeleted" />]}
            />
          }
        />
      )}
      {isInvalidExpenseAccount && !isArchivedExpenseAccount && (
        <InvalidAccountCallout
          title={t(
            'expenseInbox.expenseEditor.invalidFormatExpenseAccount.title',
          )}
          message={t(
            'expenseInbox.expenseEditor.invalidFormatExpenseAccount.message',
            { code },
          )}
          button={t(
            'expenseInbox.expenseEditor.invalidFormatExpenseAccount.button',
          )}
          route={routeFor(routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path, {
            company: companyId,
          })}
        />
      )}
    </>
  );
};
