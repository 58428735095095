import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useUser } from 'modules/app/hooks/useUser';
import { type AppDispatch } from 'modules/app/redux/store';
import {
  hasApproverSubNav,
  SubnavigationItem,
} from 'modules/requests/utils/navigation';
import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { fetchRequestsStats } from 'src/core/modules/requests/redux/legacy/actions';
import { getRequestsStats } from 'src/core/modules/requests/redux/legacy/selectors';

export const RequestHeader = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useUser();
  const companyId = useCompanyId();
  const { t } = useTranslation('global');
  const stats = useSelector(getRequestsStats);

  const isSubmitMyInvoiceFeatureEnabled = useFeature(
    FEATURES.SUBMIT_MY_INVOICE,
  );

  const { search: filters } = useLocation();

  useEffect(() => {
    dispatch(fetchRequestsStats());
  }, []);

  const getRequestNavigationLinks = () => {
    const links = [];

    if (hasApproverSubNav(user)) {
      links.push({
        key: 'sub_nav_requests_to_approve',
        path: `${routeFor(routes.REQUESTS.path, {
          company: companyId,
          type: SubnavigationItem.ToApprove,
        })}${filters}`,
        text: t('requests.toApprove'),
        count: stats.to_approve,
      });
      links.push({
        key: 'sub_nav_requests_mine',
        path: `${routeFor(routes.REQUESTS.path, {
          company: companyId,
          type: SubnavigationItem.Mine,
        })}${filters}`,
        text: t('requests.yourRequests'),
        count: stats.mine,
      });
    }

    links.push({
      key: SubnavigationItem.All,
      path: `${routeFor(routes.REQUESTS.path, {
        company: companyId,
        type: SubnavigationItem.All,
      })}${filters}`,
      text: t('requests.allRequests'),
      count: stats.all,
    });

    if (isSubmitMyInvoiceFeatureEnabled) {
      links.push({
        key: SubnavigationItem.Drafts,
        path: `${routeFor(routes.REQUESTS.path, {
          company: companyId,
          type: SubnavigationItem.Drafts,
        })}${filters}`,
        text: t('requests.draftsSection'),
        count: stats.drafts,
      });
    }

    return links;
  };

  return (
    <ErrorBoundary
      context={{ scope: 'requests::subnav', team: 'travel-and-expenses' }}
      fallbackComponent={<></>}
    >
      <HeaderWithNavigation links={getRequestNavigationLinks()}>
        {t('misc.request_plural')}
      </HeaderWithNavigation>
    </ErrorBoundary>
  );
};
