import { type ReactNode } from 'react';

import { StepperHeader } from './StepperHeader';

type Props = {
  stepsTitles: string[];
  currentStepIndex: number;
  children: ReactNode;
  hasSpendeskLogo?: boolean;
};

export function Stepper({
  stepsTitles,
  currentStepIndex,
  children,
  hasSpendeskLogo,
}: Props) {
  return (
    <div className="mx-auto">
      <StepperHeader
        steps={stepsTitles}
        currentStepIndex={currentStepIndex}
        hasSpendeskLogo={hasSpendeskLogo}
      />
      {children}
    </div>
  );
}
