import {
  ReactFlow,
  Controls,
  Background,
  BackgroundVariant,
} from '@xyflow/react';
import { useEffect } from 'react';
import '@xyflow/react/dist/style.css';

import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';

import { useWorkflowContext } from '../../WorkflowContext';
import { WorkflowProvider } from '../../WorkflowProvider';
import { edgeTypes } from '../../edge-types';
import { transformApprovalWorkflowToNodesAndEdges } from '../../helpers/transformApprovalWorkflowToNodesAndEdges';
import { useApprovalWorkflowQuery } from '../../hooks/useApprovalWorkflowQuery';
import { nodeTypes } from '../../node-types';
import { NodeSettingsPanel } from '../NodeSettingsPanel';

const ApprovalByDimensionsPageInternal = () => {
  const { nodes, edges, setNodes, setEdges } = useWorkflowContext();
  const approvalWorkflowQueryState = useApprovalWorkflowQuery();

  useEffect(() => {
    if (approvalWorkflowQueryState.status === 'success') {
      const transformed = transformApprovalWorkflowToNodesAndEdges(
        approvalWorkflowQueryState.data,
      );
      setNodes(transformed.nodes);
      setEdges(transformed.edges);
    }
  }, [
    approvalWorkflowQueryState.status,
    approvalWorkflowQueryState.data,
    setNodes,
  ]);

  return (
    <div className="grow">
      <ReactFlow
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={edges}
        fitView
        fitViewOptions={{ padding: 1.4 }}
      >
        <Controls />
        <Background gap={12} size={1} variant={BackgroundVariant.Dots} />
      </ReactFlow>
      <NodeSettingsPanel />
    </div>
  );
};

export const ApprovalByDimensionsPage = () => {
  return (
    <ErrorBoundary
      context={{ team: 'control', scope: 'approval-by-dimensions' }}
    >
      <WorkflowProvider>
        <ApprovalByDimensionsPageInternal />
      </WorkflowProvider>
    </ErrorBoundary>
  );
};
