import { LogoutButton } from 'common/components/LogoutButton/LogoutButton';
import { TopBanner } from 'common/components/TopBanner/TopBanner';
import { useSupervisor } from 'modules/app/hooks/useSupervisor';
import { useUser } from 'modules/app/hooks/useUser';

export const Supervision = () => {
  const supervisor = useSupervisor();
  const user = useUser();
  if (!supervisor || !user) {
    return null;
  }

  const permissions = !supervisor.canWrite ? 'read-only' : 'read+write';

  return (
    <TopBanner
      variant="alert"
      title={`Welcome ${supervisor.name}! You are logged in as ${user.fullname} with ${permissions}permissions.`}
    >
      <LogoutButton className="px-4 text-complementary underline" />
    </TopBanner>
  );
};
