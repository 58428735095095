import React from 'react';

import { ScheduledPaymentsByUserListSkeleton } from 'modules/reimbursements/components/ScheduledPaymentsByUserList/ScheduledPaymentsByUserListSkeleton';
import { SendToPaymentLoader } from 'modules/reimbursements/components/SendToPaymentLoader';

export const ExpenseClaimsPayPageSkeleton = () => {
  return (
    <div className="h-full">
      <div className="mx-auto mt-48 grid max-h-full w-full max-w-lg grid-cols-2 gap-32">
        <ScheduledPaymentsByUserListSkeleton numberOfRows={5} />
        <div className="box">
          <SendToPaymentLoader />
        </div>
      </div>
    </div>
  );
};
