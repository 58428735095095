import React from 'react';

import { SiderPageLayout } from 'common/components/layout/SiderPageLayout';
import { TransferListSkeleton } from 'modules/invoices/transfer';

export const TransferConfirmPageSkeleton = () => (
  <SiderPageLayout>
    <div className="mx-auto w-full max-w-[670px]">
      <TransferListSkeleton />
    </div>
  </SiderPageLayout>
);
