import {
  Autocomplete,
  AutocompleteNoOptions,
  type AutocompleteProps,
  DropdownItem,
  FormField,
  Icon,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import { type ReactChild } from 'react';

import type { BasicInputProps, Form } from './types';
import { useErrorMessage } from './utils/useErrorMessage';
import { type Option } from '../../components/AutocompleteSearch/option';
import { useTranslation } from '../../hooks/useTranslation';

type FormAutocompleteProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TForm extends Form<any>,
  T extends Option,
> = BasicInputProps<TForm, string> &
  Omit<
    AutocompleteProps<Option>,
    'renderNoOptions' | 'onAddOption' | 'renderAddOption' | 'value' | 'onSelect'
  > & {
    hint?: string;
    onSelect?: (option: Option | undefined) => void;
  } & (
    | {
        onAddOption(newOptionLabel: string): T | Promise<T>;
      }
    | {
        onAddOption?: never;
      }
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function FormAutocomplete<TForm extends Form<any>>({
  name,
  form,
  label,
  hint,
  options,
  onAddOption,
  onSelect,
  className,
  ...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
FormAutocompleteProps<TForm extends Form<any> ? TForm : never, Option>) {
  const { t } = useTranslation('global');
  const error = useErrorMessage({
    form,
    key: name,
  });

  const baseProps: AutocompleteProps<Option> = {
    name,
    fit: 'parent',
    ...props,
    value: form.values[name]
      ? options.find((option) => option.key === form.values[name])
      : undefined,
    options,
    onSelect: (selectedOption) => {
      form.setFieldValue(name, selectedOption?.key);
      onSelect?.(selectedOption);
    },
    // if there is renderPrefix, then reuse it for all options
    renderOption: (
      option,
      { isSelected } = {
        isSelected: false,
      },
    ) =>
      !props.renderOption ? (
        <DropdownItem
          prefix={props.renderPrefix?.(option)}
          key={option.key}
          label={option.label}
          isSelected={isSelected}
        />
      ) : (
        props.renderOption?.(option, {
          isSelected: isSelected || form.values[name] === option.key,
        })
      ),
    renderNoOptions: (rawValue) => (
      <AutocompleteNoOptions>
        <div>
          {t('forms.global.autocomplete.noResults', { search: rawValue })}
        </div>
      </AutocompleteNoOptions>
    ),
  };

  return (
    <FormField
      label={label}
      alertMessage={error}
      hint={hint}
      className={className}
    >
      {onAddOption ? (
        <Autocomplete
          {...baseProps}
          onAddOption={onAddOption}
          renderAddOption={(
            inputValue,
            { isHighlighted } = {
              isHighlighted: false,
            },
          ) =>
            (
              <>
                <div className="separator" />
                <div className="flex cursor-pointer items-center justify-between gap-8 px-8 py-4 text-primary body-m hover:bg-primary-hover hover:text-primary">
                  <div className="flex items-center gap-4 py-8">
                    {t('misc.create')}
                    <span
                      className={cx(
                        'Text--bold w-[200px] truncate text-primary',
                        {
                          'bg-secondary-default': isHighlighted,
                        },
                      )}
                    >
                      {inputValue}
                    </span>
                  </div>
                  <div className="flex gap-4">
                    <Icon name="arrow-uturn-left-down" />
                  </div>
                </div>
              </>
            ) as ReactChild
          }
        />
      ) : (
        <Autocomplete {...baseProps} />
      )}
    </FormField>
  );
}
