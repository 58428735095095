import { HighlightIcon, IconButton } from '@dev-spendesk/grapes';
import { Handle, Position, type NodeProps } from '@xyflow/react';
import classNames from 'classnames';

import { useWorkflowContext } from '../../WorkflowContext';

export type StandardApprovalFlowNodeData = {
  schemeId: string;
};

const handleStyle = {
  background: 'transparent',
  border: 'none',
  width: 0,
  height: 0,
};

export const StandardApprovalFlowNode = ({ id }: NodeProps) => {
  const { setSelectedNodeId, selectedNodeId } = useWorkflowContext();
  const isSelected = selectedNodeId === id;

  return (
    <div className="flex items-center">
      <div
        className={classNames(
          'flex h-[94px] w-[240px] flex-col justify-center rounded p-16',
          isSelected
            ? 'border-transparent border border-solid bg-secondary-brand-default'
            : 'border border-solid border-default bg-primary-default',
        )}
      >
        <div className="flex items-center justify-between">
          <HighlightIcon variant="ocean" name="magic-wand" size={24} />
          <div className="text-content-primary title-m">Default approvers</div>
          <div className="flex items-center">
            <IconButton
              aria-label="edit"
              onClick={() => {
                setSelectedNodeId(id);
              }}
              iconName="pen"
            />
          </div>
        </div>
      </div>
      <Handle style={handleStyle} type="target" position={Position.Left} />
    </div>
  );
};
