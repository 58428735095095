interface DeliveryNoteBase {
  id: string;
  deliveryNumber?: string;
  deliveryDate: string;
  description: string;
  fileKey?: string;
  mimetype?: string;
  checksum?: string;
  amountDelivered?: number;
  items?: DeliveryNoteItem[];
}

export type DeliveryNoteItem = {
  purchaseOrderItemId: string;
  quantity: number;
};

export interface DeliveryNoteWithAmount extends DeliveryNoteBase {
  items: undefined;
  amountDelivered: NonNullable<DeliveryNoteBase['amountDelivered']>;
}

export interface DeliveryNoteWithItems extends DeliveryNoteBase {
  items: NonNullable<DeliveryNoteBase['items']>;
  amountDelivered: undefined;
}

export type DeliveryNote = DeliveryNoteWithAmount | DeliveryNoteWithItems;

type DeliveryNotesItemsQuantityDelivered = {
  quantityDelivered: number;
  totalToDeliver: number;
  items: {
    id: string;
    description: string;
    quantityDelivered: number;
    totalToDeliver: number;
  }[];
};

type DeliveryNotesAmountDelivered = {
  amountDelivered: number;
  totalAmount: number;
};

export type DeliveryNotesQuantityDelivered =
  | DeliveryNotesItemsQuantityDelivered
  | DeliveryNotesAmountDelivered;

export const isDeliveryNoteWithAmount = (
  deliveryNote: DeliveryNote,
): deliveryNote is DeliveryNoteWithAmount =>
  deliveryNote.items === undefined || deliveryNote.items.length === 0;

export const isDeliveryNotesAmountDelivered = (
  deliveryNotesQuantityDelivered: DeliveryNotesQuantityDelivered,
): deliveryNotesQuantityDelivered is DeliveryNotesAmountDelivered =>
  'totalAmount' in deliveryNotesQuantityDelivered;

export const computeTotalQuantityDelivered = (
  deliveryNoteWithItems: DeliveryNoteWithItems,
): number =>
  deliveryNoteWithItems.items.reduce((total, item) => total + item.quantity, 0);
