import {
  SkeletonTable,
  SkeletonText,
  SkeletonAvatar,
  SkeletonTag,
} from '@dev-spendesk/grapes';
import React from 'react';

type Props = {
  title?: string;
};

export const PurchaseOrdersTableSkeleton = ({ title = '' }: Props) => (
  <SkeletonTable
    columns={[
      {
        cell: (
          <div className="flex w-full gap-16 py-16">
            <SkeletonAvatar variant="square" />
            <div className="grow">
              <div className="flex justify-between">
                <SkeletonText size="m" width="30%" />
                <SkeletonText size="m" width="15%" />
              </div>
              <div className="mt-4 flex justify-between">
                <SkeletonText size="s" width="50%" />
                <SkeletonText size="s" width="8%" />
              </div>
              <SkeletonTag className="mt-8" />
            </div>
          </div>
        ),
        header: title,
      },
    ]}
    withHeader
  />
);
