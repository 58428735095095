import { createAction } from '@reduxjs/toolkit';
import type { Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import i18next from 'i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import queryString from 'query-string';

import { addNotification, NotificationType } from 'modules/app/notifications';
import { type CustomFieldAssociation } from 'modules/budgets/models/customFieldAssociation';
import { addExpenseCategoryToCustomFieldAssociations } from 'modules/budgets/models/expenseCategory';
import { useRequestsToApproveQueryKey } from 'modules/requests/api/useRequestsToApproveQuery';
import { companyAPI } from 'src/core/api/axios';
import { appQueryClient } from 'src/core/api/client';
import FEATURES from 'src/core/constants/features';
import { type AppState } from 'src/core/reducers';
import { getIsFeatureEnabled } from 'src/core/selectors/globalSelectors';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';
import { apiUrl } from 'src/core/utils/api';

import * as types from './actionTypes';
import type { Stats, RequestsSections } from './reducer';
import { type RequestAPI } from '../../types';

const getRequestsFiltersQueryParams = (filters: {
  custom_fields?: Record<string, string>;
  period?: string;
  amount?: string;
  toApprove?: boolean;
}) => {
  // Stringify CF filters
  const cfs = filters.custom_fields
    ? { custom_fields: JSON.stringify(get(filters, 'custom_fields', {})) }
    : {};

  // stringify period filter
  let period = {};
  if (filters.period) {
    const [from, to] = filters.period.split('/');
    period = { period: JSON.stringify({ from, to }) };
  }

  // stringify amount filter
  let amount = {};
  if (filters.amount) {
    const [$gte, $lte] = filters.amount.split('/');
    amount = {
      amount: JSON.stringify({ $gte: Number($gte), $lte: Number($lte) }),
    };
  }

  const filtersObject = {
    ...pick(filters, [
      'search',
      'requester',
      'status',
      'supplier',
      'team',
      'type',
      'toApprove',
      'costCenter',
    ]),
    ...amount,
    ...period,
    ...cfs,
  };

  return Object.fromEntries(
    Object.entries(filtersObject).filter(([_, value]) => {
      return !(isEmpty(value) && value !== true);
    }),
  );
};

// Fetch requests
const fetchRequestsLoading = createAction(types.FETCH_REQUESTS_LOADING);
export const fetchRequestsSuccess = createAction<{
  requestsSections: RequestsSections;
}>(types.FETCH_REQUESTS_SUCCESS);
export const fetchRequestsFailure = createAction(types.FETCH_REQUESTS_FAILURE);
export const fetchRequests =
  (filters = {}, groupBy: 'type' | 'typeAndMineTeam') =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const companyId = getCompanyId(getState());

    dispatch(fetchRequestsLoading());

    const params = getRequestsFiltersQueryParams(filters);
    const qs = queryString.stringify(
      { ...params, groupBy },
      { arrayFormat: 'bracket' },
    );

    try {
      const { data } = await companyAPI.get(`/requests?${qs}`, {
        companyId,
      });

      dispatch(fetchRequestsSuccess({ requestsSections: data }));
    } catch (error) {
      dispatch(fetchRequestsFailure(error));
    }
  };

export const fetchRequestsSectionsStatsSuccess = createAction<{
  sectionsStats: Stats;
}>(types.FETCH_REQUESTS_SECTIONS_STATS_SUCCESS);

export const fetchRequestsSectionsStats =
  (filters = {}, groupBy: 'type' | 'typeAndMineTeam') =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const companyId = getCompanyId(getState());
    const params = getRequestsFiltersQueryParams(filters);
    const qs = queryString.stringify(
      { ...params, groupBy, onlyStats: true },
      { arrayFormat: 'index' },
    );
    const { data } = await companyAPI.get(`/requests?${qs}`, {
      companyId,
    });
    dispatch(fetchRequestsSectionsStatsSuccess({ sectionsStats: data.stats }));
  };

export const setRequestFilters = createAction(types.SET_REQUEST_FILTERS);

export const resetRequestFilters = createAction(types.RESET_REQUEST_FILTERS);

export const setTextFilter = createAction(types.SET_TEXT_FILTER);

export const resetTextFilter = createAction(types.RESET_TEXT_FILTER);

// Fetch draft requests
const fetchDraftRequestsLoading = createAction(
  types.FETCH_DRAFT_REQUESTS_LOADING,
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchDraftRequestsSuccess = createAction<any[]>(
  types.FETCH_DRAFT_REQUESTS_SUCCESS,
);
export const fetchDraftRequestsFailure = createAction(
  types.FETCH_DRAFT_REQUESTS_FAILURE,
);
export const fetchDraftRequests =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const companyId = getCompanyId(getState());
    let draftRequests;

    dispatch(fetchDraftRequestsLoading());
    try {
      const res = await companyAPI.get('/drafts', { companyId });
      draftRequests = res.data;
    } catch (error) {
      dispatch(fetchDraftRequestsFailure(error));

      throw error;
    }

    dispatch(fetchDraftRequestsSuccess(draftRequests));
  };

// Fetch draft request
export const addRequestLocally = createAction<{
  id: string;
  type: string;
  state: string;
}>(types.ADD_REQUEST_LOCALLY);

// Save request remotely & update it locally
export const updateRequestSuccess = createAction<
  { id: string },
  'UPDATE_REQUEST_SUCCESS'
>(types.UPDATE_REQUEST_SUCCESS);
const updateRequestFailure = createAction(types.UPDATE_REQUEST_FAILURE);
export const updateRequestLocally = createAction(types.UPDATE_REQUEST_LOCALLY);
export const removeRequestLocally = createAction<string>(
  types.REMOVE_REQUEST_LOCALLY,
);
export const updateRequest =
  (
    request: { id: string },
    values: {
      custom_fields_associations?: CustomFieldAssociation[];
      expenseCategoryId?: string;
      amount_to_refund?: number;
    } = {},
    {
      updateLocally = false,
      successMessage,
      withCustomFieldsUpdate = true,
    }: {
      updateLocally?: boolean;
      successMessage?: string;
      withCustomFieldsUpdate?: boolean;
    },
  ) =>
  async (
    dispatch: ThunkDispatch<AppState, null, types.RequestsActions>,
    getState: () => AppState,
  ) => {
    const companyId = getCompanyId(getState());
    const state = getState();

    const getCustomFieldsAssociations = () =>
      addExpenseCategoryToCustomFieldAssociations({
        customFieldAssociations: values.custom_fields_associations ?? [],
        expenseCategoryId: values.expenseCategoryId,
        isExpenseCategoriesFeatureEnabled: getIsFeatureEnabled(
          state,
          FEATURES.EXPENSE_CATEGORIES,
        ),
        expenseCategoryCustomFieldId:
          state.requests.expenseCategoryCustomFieldId,
      });

    const newValues = {
      ...request,
      ...values,
      ...(withCustomFieldsUpdate
        ? { custom_fields_associations: getCustomFieldsAssociations() }
        : {}),
    };

    if (request && !isEmpty(values)) {
      try {
        const { data } = await companyAPI.put(
          `/requests/${request.id}`,
          newValues,
          { companyId },
        );
        dispatch(updateRequestSuccess({ id: request.id }));
        if (updateLocally) {
          dispatch(updateRequestLocally(data));
        }
        dispatch(
          addNotification({
            message: successMessage,
          }),
        );
      } catch (error) {
        dispatch(updateRequestFailure(error));
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18next.t('errors:somethingWrong_short'),
          }),
        );
        throw error;
      }
    }
  };

// Approve request
const approveRequestLoading = createAction(types.APPROVE_REQUEST_LOADING);
const approveRequestSuccess = createAction(types.APPROVE_REQUEST_SUCCESS);
const approveRequestFailure = createAction(types.APPROVE_REQUEST_FAILURE);
export const approveRequest =
  (
    request: RequestAPI,
    {
      amount_to_refund,
      updateLocally = false,
      callback = noop,
    }: {
      amount_to_refund?: number;
      updateLocally?: boolean;
      callback?: (data: unknown) => void;
    },
  ) =>
  async (
    dispatch: ThunkDispatch<AppState, null, types.RequestsActions>,
    getState: () => AppState,
  ) => {
    const companyId = getCompanyId(getState());

    if (request) {
      dispatch(approveRequestLoading());

      if (Number.isFinite(amount_to_refund)) {
        await dispatch(
          updateRequest(
            request,
            { amount_to_refund },
            {
              successMessage: i18next.t('requests.panel.successUpdate'),
              withCustomFieldsUpdate: false,
            },
          ),
        );
      }

      try {
        const { data } = await companyAPI.put(
          `/requests/${request.id}/approve`,
          null,
          { companyId },
        );
        if (callback) {
          callback(data);
        }
        dispatch(approveRequestSuccess(data));
        if (updateLocally) {
          dispatch(updateRequestLocally(data));
        }
        dispatch(fetchRequestsStats());
        dispatch(
          addNotification({
            message: i18next.t('requests.actions.successApprove'),
          }),
        );
        appQueryClient.invalidateQueries(['requests', request.id]);
        appQueryClient.invalidateQueries(useRequestsToApproveQueryKey);
      } catch (error) {
        dispatch(approveRequestFailure(error));
        let errorMessage;

        switch (error.response?.data?.reason) {
          case 'requesterIsDeleted':
            errorMessage = i18next.t('requests.errors.requesterIsDeleted');
            break;

          default:
            errorMessage = i18next.t('errors:somethingWrong_short');
        }

        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: errorMessage,
          }),
        );
        throw error;
      }
    }
  };

// Load plastic card (top-up request)
const loadCardRequestLoading = createAction(types.LOAD_CARD_REQUEST_LOADING);
const loadCardRequestFailure = createAction(types.LOAD_CARD_REQUEST_FAILURE);
export const loadCardRequest =
  (requestId: string, callback = noop) =>
  (dispatch: Dispatch, getState: () => AppState) => {
    const companyId = getCompanyId(getState());
    dispatch(loadCardRequestLoading());

    if (requestId) {
      // eslint-disable-next-line promise/catch-or-return
      fetch(apiUrl(`/requests/${requestId}/finalize-card-load`, companyId), {
        method: 'GET',
        credentials: 'include',
        // eslint-disable-next-line promise/prefer-await-to-then
      }).then((res) => {
        callback.call(null, res);
        // eslint-disable-next-line promise/always-return
        if (res.status !== 200) {
          dispatch(loadCardRequestFailure());
        }
      });
    }
  };

// Fetch requests stats (here stats refer to the requests page sub navbar)
export const fetchRequestsStatsSuccess = createAction<Stats>(
  types.FETCH_REQUESTS_STATS_SUCCESS,
);
export const fetchRequestsStats =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const companyId = getCompanyId(getState());
    const { data: stats } = await companyAPI.get('/requests/stats', {
      companyId,
    });

    dispatch(fetchRequestsStatsSuccess(stats));
  };

// Update draft requests locally
export const addDraftRequestsLocally = createAction(
  types.ADD_DRAFT_REQUESTS_LOCALLY,
);
export const removeDraftRequestLocally = createAction<{ id: string }>(
  types.REMOVE_DRAFT_REQUEST_LOCALLY,
);
