import { Button } from '@dev-spendesk/grapes';
import React from 'react';
import { useDispatch } from 'react-redux';

import { TopBanner } from 'common/components/TopBanner/TopBanner';
import { useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import { type AppDispatch } from 'modules/app/redux/store';

import { revokeApprovalRights } from '../../redux/actions';

export const DelegationHeader = () => {
  const user = useUser();
  const { t } = useTranslation('global');
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = () => {
    dispatch(revokeApprovalRights());
  };

  if (!user.is_delegating) {
    return null;
  }

  return (
    <TopBanner variant="complementary" title={t('delegationHeader.title')}>
      <Button
        variant="secondaryNeutral"
        text={t('delegationHeader.cta')}
        onClick={handleClick}
      />
    </TopBanner>
  );
};
