export const formatNumber = (value: number, decimals: number) => {
  return (Math.round(value * 100) / 100).toFixed(decimals);
};

export const transformAmount = (amount: number, precision: number) => {
  return amount / 10 ** precision;
};

export const transformNumberIntoMonetaryValue = ({
  amount,
  precision,
  currency,
}: {
  amount: number;
  precision: number;
  currency: string;
}) => {
  return {
    amount: Math.round(amount * 10 ** precision),
    precision,
    currency,
  };
};
