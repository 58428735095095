import { type MonetaryValue } from 'ezmoney';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { LocalStorageKey } from 'src/core/constants/storage';
import { apiV2Url } from 'src/core/utils/api';
import { getItem, setItem } from 'src/core/utils/storage';

import { type Quote } from '../../../models';
import { quotesActions, type TransferQuote } from '../../redux';
import { transformAmount } from '../../utils';

type CreateQuotePayload = {
  sourceCurrency: string;
  targetAmount: MonetaryValue;
  transferId: string;
};

const isQuoteExpired = (quote: Quote) =>
  quote?.rateExpirationTime && new Date() > new Date(quote.rateExpirationTime);
const useInitialiseLocalStorageQuotes = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  useEffect(() => {
    let localStorageQuotes: TransferQuote[];
    try {
      localStorageQuotes =
        JSON.parse(getItem(LocalStorageKey.IwtQuotes, localStorage) || '') ||
        [];
    } catch {
      localStorageQuotes = [];
    }

    for (const localStorageQuote of localStorageQuotes) {
      if (isQuoteExpired(localStorageQuote)) {
        continue;
      }

      queryClient.setQueryData(
        ['quotes', localStorageQuote.transferId],
        localStorageQuote,
      );
      dispatch(
        quotesActions.addQuote({
          quote: localStorageQuote,
        }),
      );
    }
  }, []);
};
const addToLocalStorageQuotes = (newQuote: TransferQuote) => {
  let localStorageQuotes: TransferQuote[];
  try {
    localStorageQuotes =
      JSON.parse(getItem(LocalStorageKey.IwtQuotes, localStorage) || '') || [];
  } catch {
    localStorageQuotes = [];
  }
  const existingQuoteIndex = localStorageQuotes.findIndex(
    (quote: TransferQuote) => quote.transferId === newQuote.transferId,
  );
  if (existingQuoteIndex >= 0) {
    if (isQuoteExpired(localStorageQuotes[existingQuoteIndex])) {
      localStorageQuotes[existingQuoteIndex] = newQuote;
    }
  } else {
    localStorageQuotes = [...localStorageQuotes, newQuote];
  }
  setItem(
    LocalStorageKey.IwtQuotes,
    JSON.stringify(localStorageQuotes),
    localStorage,
  );
};
const fetchCreateQuote = async (
  payload: CreateQuotePayload,
): Promise<Quote> => {
  const { targetAmount, sourceCurrency } = payload;

  const response = await fetch(apiV2Url(`/international-transfers/quotes`), {
    method: 'POST',
    credentials: 'include',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sourceCurrency,
      targetAmount: transformAmount(
        targetAmount.amount,
        targetAmount.precision,
      ),
      targetCurrency: targetAmount.currency,
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to create quote');
  }

  const responseJson = await response.json();

  return responseJson.data;
};

const useCreateQuote = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(fetchCreateQuote, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['quotes', variables.transferId], data);

      const newQuote = { ...data, transferId: variables.transferId };
      dispatch(
        quotesActions.addQuote({
          quote: newQuote,
        }),
      );
      addToLocalStorageQuotes(newQuote);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('Error creating quote:', error);
    },
  });
};

export const useCachedQuote = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, data } = useCreateQuote();
  useInitialiseLocalStorageQuotes();

  const getQuote = (payload: CreateQuotePayload) => {
    const cachedQuote = queryClient.getQueryData<Quote>([
      'quotes',
      payload.transferId,
    ]);
    if (!cachedQuote || isQuoteExpired(cachedQuote)) {
      mutate(payload);
    }

    return cachedQuote;
  };

  return { getQuote, isLoading, quoteData: data };
};
