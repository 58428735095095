import { AnalyticEventName, track } from 'src/core/utils/analytics';

export function trackPayableSavedOrMarkedAsReady({
  payableId,
  markedAsReady,
  editedFields,
  error,
  batchId,
  savedFilterId,
  isFilterApplied,
}: {
  payableId: string;
  markedAsReady: boolean;
  editedFields?: string[];
  error: boolean;
  batchId?: string;
  savedFilterId?: string;
  isFilterApplied?: boolean;
}): void {
  track(
    AnalyticEventName.BOOKKEEP_PREPARE_PAYABLE_SAVED_OR_MARKED_AS_READY,
    {
      payableId,
      markedAsReady,
      editedFields,
      error,
      batchId,
      pageUrl: window.location.href,
      savedFilterId,
      isFilterApplied,
    },
    { Amplitude: { groups: { payableId } } },
  );
}

export function trackReceiptDeclaredAsInvalid({
  payableId,
}: {
  payableId: string;
}): void {
  track(AnalyticEventName.BOOKKEEP_PREPARE_RECEIPT_DECLARED_AS_INVALID, {
    payableId,
  });
}

export function trackAccountingDateEdited(
  payableId: string,
  oldDate: string | undefined,
  newDate: string,
): void {
  track(AnalyticEventName.BOOKKEEP_PREPARE_ACCOUNTING_DATE_EDITED, {
    payableId,
    oldDate,
    newDate,
  });
}
