import {
  DropdownItem,
  DropdownMenu,
  IconButton,
  Tag,
} from '@dev-spendesk/grapes';
import { type PropsWithChildren } from 'react';

import { useModal } from 'common/hooks/useModalGrapes';
import { useTranslation } from 'common/hooks/useTranslation';
import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split/useAnalyticalSplitStatus';

import { formatList } from '../../../../common/utils/intl';
import { CustomFieldDeletionModalContainer } from '../../containers/CustomFieldDeletionModalContainer';
import { CustomFieldEditionModalContainer } from '../../containers/CustomFieldEditionModalContainer';
import { type CustomField } from '../../models/customField';

type Props = {
  customField: CustomField;
};

export const CustomFieldPanelHeader = ({
  customField,
  children,
}: PropsWithChildren<Props>) => {
  const { t, activeLanguage } = useTranslation('global');
  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();

  const [customFieldEditionModal, showCustomFieldEditionModal] = useModal(
    ({ onClose, isOpen }) => {
      return (
        <CustomFieldEditionModalContainer
          isOpen={isOpen}
          customField={customField}
          onClose={() => onClose()}
        />
      );
    },
  );

  const [customFieldDeletionModal, showCustomFieldDeletionModal] = useModal(
    ({ onClose, isOpen }) => {
      return (
        <CustomFieldDeletionModalContainer
          isOpen={isOpen}
          customField={customField}
          onClose={() => onClose()}
        />
      );
    },
  );

  const tags: string[] = [];

  if (customField.type === 'list' && isAnalyticalSplitActivated) {
    tags.push(
      customField.isSplittable
        ? t('customFields.settings.splittable')
        : t('customFields.settings.nonSplittable'),
    );
  }

  if (!customField.isRequired) {
    tags.push(t('misc.optional').toLocaleLowerCase());
  }

  const formattedTags = formatList(activeLanguage, tags, t);

  return (
    <>
      <div className="flex items-center justify-between py-16">
        <div className="flex flex-1 flex-col content-stretch gap-4">
          <div className="flex flex-row items-center gap-4">
            <h1 className="title-l">
              {customField.name}
              {tags.length > 0 && (
                <>
                  {' '}
                  <span className="text-primary body-l">({formattedTags})</span>
                </>
              )}
            </h1>
            {customField.type === 'boolean' && (
              <Tag className="ml-8" variant="info">
                {t('customFields.settings.booleanValue')}
              </Tag>
            )}
          </div>
        </div>
        <div className="flex items-center gap-8">
          {children}
          <DropdownMenu
            className="whitespace-nowrap"
            onSelect={(option) => {
              if (option.key === 'edit') {
                showCustomFieldEditionModal();
              } else {
                showCustomFieldDeletionModal();
              }
            }}
            placement="bottom-end"
            options={[
              {
                key: 'edit',
                label: t('customFields.creation.editCustomFieldButton'),
              },
              {
                key: 'delete',
                label: t('customFields.creation.deleteCustomFieldButton'),
              },
            ]}
            renderButton={(getToggleButtonProps) => {
              return (
                <IconButton
                  {...getToggleButtonProps()}
                  iconName="gear"
                  variant="secondaryNeutral"
                  aria-label={t('misc.openMenu')}
                />
              );
            }}
            renderOption={(option) => <DropdownItem label={option.label} />}
          />
        </div>
      </div>
      {customFieldEditionModal}
      {customFieldDeletionModal}
    </>
  );
};
