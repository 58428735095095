import {
  Avatar,
  Button,
  Callout,
  HighlightIcon,
  IconButton,
  Table,
  type TableColumn,
} from '@dev-spendesk/grapes';
import React from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useModal } from 'common/hooks/useModal';
import { useTranslation } from 'common/hooks/useTranslation';
import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';
import Features from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { formatMoney } from 'src/core/utils/money';

import { usePaging } from './usePaging';
import { AssignControlRuleModalContainer } from '../../containers/AssignControlRuleModalContainer';
import { type LeaderboardItem } from '../../leaderboardItem';

type Props = {
  currency: string;
  companyId: string;
  userRole: 'admin' | 'controller';
  leaderboardItems: LeaderboardItem[];
  isControlRulesUsed: boolean;
};

const getHelpCenterArticleLink = (language: string): string => {
  // TODO: i18n German
  return language === 'fr'
    ? 'https://helpcenter.spendesk.com/fr/articles/4240924-parametrer-les-regles-sur-justificatifs'
    : 'https://helpcenter.spendesk.com/en/articles/4240924-set-up-play-by-the-rules-to-collect-more-receipts-admin';
};

export const MissingReceipts = ({
  currency,
  companyId,
  userRole,
  leaderboardItems,
  isControlRulesUsed,
}: Props) => {
  const { t, i18n } = useTranslation('global');

  const [
    assignControlRuleModal,
    showAssignControlRuleModal,
    hideAssignControlRuleModal,
  ] = useModal((user: { fullname: string; id: string }) => (
    <AssignControlRuleModalContainer
      onExit={() => {
        hideAssignControlRuleModal();
      }}
      user={user}
    />
  ));

  const hasPlayByRulesFeature = useFeature(Features.PLAY_BY_RULES);
  const [visibleLeaderboardItems, , pagingInfo] = usePaging(leaderboardItems);

  if (leaderboardItems.length === 0) {
    return (
      <div className="box" data-testid="test__total">
        <div className="flex flex-col content-center items-center py-[180px] text-center">
          <img
            className="mb-8 h-64"
            src="/static/img/homepage/party.png"
            alt=""
          />
          <p className="max-w-[314px] leading-[20px] text-primary title-l">
            {t('homepage.missingReceipts.congratulations')}
          </p>
        </div>
      </div>
    );
  }

  const totalPaymentsAmount = formatMoney(
    Math.abs(
      leaderboardItems.reduce((total, item) => total + item.sumPayments, 0),
    ),
    currency,
  );
  const totalOverduePaymentsAmount = formatMoney(
    Math.abs(
      leaderboardItems.reduce(
        (total, item) =>
          item.sumPaymentsOverdue ? total + item.sumPaymentsOverdue : total,
        0,
      ),
    ),
    currency,
  );

  const displayedAmounts = hasPlayByRulesFeature
    ? [
        {
          key: 'total-overdue',
          amount: totalOverduePaymentsAmount,
          label: t('homepage.missingReceipts.spendLateReceipts'),
        },
        {
          key: 'total-payments',
          amount: totalPaymentsAmount,
          label: t('homepage.missingReceipts.totalAmountNoReceipt'),
        },
      ]
    : [
        {
          key: 'total-without-receipts',
          amount: totalPaymentsAmount,
          label: t('homepage.missingReceipts.spendNoReceipts'),
        },
      ];

  const columns: (TableColumn<LeaderboardItem> | undefined)[] = [
    {
      id: 'user',
      header: t('homepage.missingReceipts.userColumn'),
      width: '35%',
      renderCell: ({ user: { avatar, fullname } }) => {
        return (
          <div className="flex items-center gap-8" data-testid="cell-user-name">
            <Avatar
              variant="circle"
              src={avatar ?? undefined}
              text={fullname}
              className="min-w-32"
            />
            <EllipsisTooltip
              text={fullname}
              className="shrink leading-[18px] text-primary body-m"
            />
          </div>
        );
      },
      getSortValue: ({ user }) => user.fullname,
    },
    isControlRulesUsed
      ? {
          id: 'overdue-receipts',
          align: 'right',
          header: t('homepage.missingReceipts.overdueReceiptsColumn'),
          renderCell: ({ nbPaymentsOverdue, user }) => {
            return user.hasControlRule ? (
              <span data-testid="cell-payment-overdue-number">
                {nbPaymentsOverdue}
              </span>
            ) : (
              <Button
                variant="tertiaryNeutral"
                text={t('homepage.missingReceipts.noRule')}
                onClick={() => showAssignControlRuleModal(user)}
              />
            );
          },
        }
      : undefined,
    {
      id: 'nbPayments',
      align: 'right',
      header: t('homepage.missingReceipts.missingReceiptsColumn'),
      renderCell: ({ nbPayments }) => (
        <p className="text-end" data-testid="cell-payment-number">
          {nbPayments}
        </p>
      ),
    },
    {
      id: 'sumPayments',
      align: 'right',
      header: t('homepage.missingReceipts.spendColumn'),
      renderCell: ({ sumPaymentsOverdue, sumPayments }) => (
        <div className="flex flex-col content-stretch items-end overflow-visible">
          <p className="text-primary body-m" data-testid="cell-sum-payments">
            {isControlRulesUsed
              ? formatMoney(sumPaymentsOverdue ?? 0, currency)
              : formatMoney(sumPayments, currency)}
          </p>
          {isControlRulesUsed &&
            sumPayments !== undefined &&
            sumPayments > 0 && (
              <p className="text-primary body-m" data-testid="cell-additional">
                {t('homepage.missingReceipts.totalAmountNoReceipt', {
                  amount: formatMoney(sumPayments, currency),
                })}
              </p>
            )}
        </div>
      ),
    },
  ];

  const paymentsAllUrl = routeFor(routes.PAYMENTS_ALL.path, {
    company: companyId,
  });
  const viewAllUrl = `${paymentsAllUrl}?${
    isControlRulesUsed ? 'completionDeadline=late' : 'invoice=missing'
  }`;

  return (
    <>
      {assignControlRuleModal}
      <div className="box flex flex-col content-stretch gap-16">
        <div className="flex items-center gap-16">
          <HighlightIcon variant="blue" size={32} name="eye" />
          <div className="flex flex-col content-stretch">
            <h3 className="text-primary title-l">
              {isControlRulesUsed
                ? t('homepage.missingReceipts.keepWatchLate')
                : t('homepage.missingReceipts.keepWatchMissing')}
            </h3>
            <p className="text-secondary-bg-primary body-m">
              {t('homepage.missingReceipts.allTime')}
            </p>
          </div>
        </div>
        {!isControlRulesUsed && (
          <Callout
            variant="info"
            iconName="sparkle"
            title={t('homepage.missingReceipts.announcement.title')}
          >
            <p className="mb-16">
              {t('homepage.missingReceipts.announcement.description')}
            </p>
            {userRole === 'admin' ? (
              <Button
                variant="primaryBrand"
                href={routeFor(routes.COMPANY_CONTROL_RULES.path, {
                  company: companyId,
                })}
                text={t('homepage.missingReceipts.announcement.linkAdmin')}
                component="a"
              />
            ) : (
              <Button
                variant="primaryBrand"
                target="_blank"
                rel="noreferrer"
                href={getHelpCenterArticleLink(i18n.language)}
                text={t('homepage.missingReceipts.announcement.linkController')}
                component="a"
              />
            )}
          </Callout>
        )}
        <div className="grid grid-cols-2 gap-16">
          {displayedAmounts.map(({ key, amount, label }) => {
            return (
              <div key={key} className="rounded-8 bg-secondary-default p-16">
                <h3 className="text-primary title-xl" data-testid={key}>
                  {amount}
                </h3>
                <p className="text-secondary-bg-secondary body-m">{label}</p>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col">
          <Table
            data={visibleLeaderboardItems}
            columns={columns.filter(
              (col): col is TableColumn<LeaderboardItem> => Boolean(col),
            )}
          />
        </div>
        <div className="flex justify-between text-primary body-m">
          <Button
            variant="tertiaryNeutral"
            component="a"
            href={viewAllUrl}
            onClick={() => {
              track(AnalyticEventName.HOMEPAGE_LEADERBOARD_ALL_BUTTON_CLICKED);
            }}
            text={
              isControlRulesUsed
                ? t('homepage.missingReceipts.viewAllLate')
                : t('homepage.missingReceipts.viewAll')
            }
          />
          <div className="flex items-center gap-16">
            <IconButton
              iconName="chevron-left"
              disabled={pagingInfo.currentPage === 1}
              className={
                pagingInfo.currentPage === 1
                  ? 'text-primary'
                  : 'text-secondary-bg-secondary'
              }
              aria-label="previous"
              variant="tertiaryNeutral"
              hasNegativeMargins
              onClick={() => {
                pagingInfo.previousPage();
                track(
                  AnalyticEventName.HOMEPAGE_LEADERBOARD_PREVIOUS_PAGE_BUTTON_CLICKED,
                );
              }}
            />
            <div className="space-between flex items-center justify-center gap-8">
              <span className="text-primary">{pagingInfo.currentPage}</span>
              <span className="text-primary">/</span>
              <span className="text-primary">{pagingInfo.nbOfPages}</span>
            </div>
            <IconButton
              iconName="chevron-right"
              disabled={pagingInfo.currentPage === pagingInfo.nbOfPages}
              className={
                pagingInfo.currentPage === pagingInfo.nbOfPages
                  ? 'text-primary'
                  : 'text-secondary-bg-secondary'
              }
              aria-label="next"
              variant="tertiaryNeutral"
              hasNegativeMargins
              onClick={() => {
                pagingInfo.nextPage();
                track(
                  AnalyticEventName.HOMEPAGE_LEADERBOARD_NEXT_PAGE_BUTTON_CLICKED,
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
