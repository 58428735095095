import { IconButton } from '@dev-spendesk/grapes';
import { type ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  title: string;
  children: ReactNode;
  onClose: () => void;
  onDelete?: () => void;
};
export const FloatingPanel = ({
  children,
  isOpen,
  title,
  onClose,
  onDelete,
}: Props) => {
  return isOpen ? (
    <div
      className="fixed left-0 top-0 z-40 h-full w-full"
      style={{ backgroundColor: 'var(--color-backdrop)' }}
    >
      <div className="absolute right-0 top-0 z-50 m-0 flex h-full w-[576px] flex-col border-0 border-l border-solid border-default bg-primary-default p-0 shadow-l">
        <div className="flex items-center justify-between border-0 border-b border-solid border-b-default px-32 py-16">
          <div className="text-primary title-xl">{title}</div>
          <div className="flex gap-8">
            {onDelete && (
              <IconButton
                aria-label="delete"
                onClick={onDelete}
                iconName="trash"
              />
            )}
            <IconButton aria-label="cross" onClick={onClose} iconName="cross" />
          </div>
        </div>
        <div className="relative flex h-[calc(100%_-_142px)] grow flex-col">
          {children}
        </div>
      </div>
    </div>
  ) : null;
};
