import { FloatingActionBar } from '@dev-spendesk/grapes';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useNotifications } from 'src/core/modules/app/notifications';
import { getExportExtension } from 'src/core/modules/payments/utils/getExportExtension';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { getLegacyFiltersFromUrl } from './PaymentFilters/types';
import type { Props as PaymentsTableProps } from './PaymentsTable';

export const PaymentsFloatingActionBar = ({
  selectedRowIds,
  counters,
  selection,
  bulkEdit,
  download,
  bulkActions,
  remindInvoices,
  bulkMarkAsMissing,
  isSupervisionActive,
}: Pick<
  PaymentsTableProps,
  | 'counters'
  | 'selection'
  | 'bulkEdit'
  | 'download'
  | 'bulkActions'
  | 'remindInvoices'
  | 'bulkMarkAsMissing'
  | 'isSupervisionActive'
> & {
  selectedRowIds: string[];
}) => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const companyId = useCompanyId();
  const { successNotif, dangerNotif } = useNotifications();

  const downloadable = counters?.downloadable ?? 0;
  const editable = counters?.editable ?? 0;
  const remindableForInvoice = counters?.remindable_for_invoice ?? 0;
  const currentFilters = getLegacyFiltersFromUrl(history.location?.search);

  const getDownloadActions = () => {
    const extension = getExportExtension();

    if (downloadable > 10_000) {
      return [
        {
          text: t('payments.actions.tooManyPaymentsToDownload'),
          iconName: 'arrow-down-tray' as const,
          onClick: () => {},
          isDisabled: true,
        },
      ];
    }

    if (downloadable > 300) {
      return [
        {
          text: t('payments.actions.downloadPaymentCsvOnly', {
            extension,
          }),
          iconName: 'arrow-down-tray' as const,
          isLoading: bulkActions?.download?.processing,
          onClick: () => {
            download({
              selection,
              withReceipts: false,
              filters: currentFilters,
            });
            track(AnalyticEventName.PAYMENTS_DOWNLOAD_BUTTON_CLICKED, {
              withReceipts: true,
              fileType: 'purchaseJournal',
            });
          },
        },
        {
          text: t('payments.actions.tooManyPaymentsToDownloadWithReceipts'),
          iconName: 'arrow-down-tray' as const,
          onClick: () => {},
          isDisabled: true,
        },
      ];
    }

    return [
      {
        text: t('payments.actions.downloadPaymentCsvOnly', {
          extension,
        }),
        iconName: 'arrow-down-tray' as const,
        isLoading: bulkActions?.download?.processing,
        onClick: () => {
          download({
            selection,
            withReceipts: false,
            filters: currentFilters,
          });
          track(AnalyticEventName.PAYMENTS_DOWNLOAD_BUTTON_CLICKED, {
            withReceipts: true,
            fileType: 'purchaseJournal',
          });
        },
      },
      {
        text: t('payments.actions.downloadPaymentWithReceipts', {
          extension,
        }),
        iconName: 'arrow-down-tray' as const,
        isLoading: bulkActions?.download?.processing,
        onClick: () => {
          download({
            selection,
            withReceipts: true,
            filters: currentFilters,
          });
          track(AnalyticEventName.PAYMENTS_DOWNLOAD_BUTTON_CLICKED, {
            withReceipts: true,
            fileType: 'purchaseJournal',
          });
        },
      },
    ];
  };

  const actions = [
    ...(remindableForInvoice > 0
      ? [
          {
            text:
              remindableForInvoice > 100
                ? t('payments.actions.sendReminderLimitDisclaimer', {
                    count: 100,
                  })
                : t('payments.actions.sendReminder'),
            isDisabled: remindableForInvoice > 100,
            isLoading: bulkActions?.remindInvoices?.processing,
            onClick: () => {
              remindInvoices({ selection, filters: currentFilters });
            },
          },
        ]
      : []),

    ...(downloadable > 0 ? getDownloadActions() : []),

    ...(editable > 1
      ? [
          {
            text: t('payments.actions.editPayment'),
            iconName: 'pen' as const,
            onClick: () => {
              bulkEdit({ selection, filters: currentFilters });
              history.push({
                pathname: routeFor(routes.PAYMENTS_ALL.path, {
                  company: companyId,
                }),
                search: history.location?.search,
              });
            },
          },
        ]
      : []),

    ...(isSupervisionActive
      ? [
          {
            text: t('payments.actions.markAsMissing'),
            iconName: 'triangle-warning' as const,
            isLoading: bulkActions?.markAsMissing?.processing,
            onClick: async () => {
              try {
                const result = await bulkMarkAsMissing({
                  selection,
                  filters: currentFilters,
                });
                successNotif(
                  t('payments.actions.markAsMissingSuccess', {
                    count: result.nbPaymentsMarked,
                  }),
                );
              } catch {
                dangerNotif(t('payments.actions.markAsMissingError'));
              }
            },
          },
        ]
      : []),
  ];

  return (
    selectedRowIds.length > 0 &&
    actions.length > 0 && (
      <FloatingActionBar actions={actions}>
        {t('misc.nSelected', { count: selectedRowIds.length })}
      </FloatingActionBar>
    )
  );
};
