import React from 'react';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';

type Props = {
  label: string;
  value: string | React.ReactChild;
};

export const PanelSectionListItem = ({ label, value }: Props) => {
  return (
    <div className="body-m-m flex justify-between gap-24">
      <div className="flex-1 truncate text-secondary-bg-primary">
        <EllipsisTooltip text={label} tooltipMaxWidth={350} />
      </div>
      <div className="w-1/2 truncate text-left text-primary">
        <EllipsisTooltip text={value} tooltipMaxWidth={350} />
      </div>
    </div>
  );
};
