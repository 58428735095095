import { Button, Tag } from '@dev-spendesk/grapes';

import { ContactUs } from 'common/components/ContactUs';
import { ErrorState } from 'common/components/ErrorState';
import { useTranslation } from 'common/hooks/useTranslation';
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import { PendingKycEmptyState } from 'modules/kyc/components/PendingKycEmptyState';
import { useShouldCompleteKyc } from 'modules/kyc/hooks/useShouldCompleteKyc';

import { CompanyPlan } from './CompanyPlan';
import { useGetCompanySubscriptionQuery } from './hooks/useGetCompanySubscriptionQuery';
import { useGetInvoicesQuery } from './hooks/useGetInvoicesQuery';
import { CompanySectionLoader } from '../../../components/CompanySectionLoader';
import {
  getCompanyPlanTranslationKey,
  isChargebeeSubscription,
} from '../../models';

export const CompanyPlanContainer = () => {
  const { t } = useTranslation('global');
  const shouldCompleteKyc = useShouldCompleteKyc();

  const getCompanySubscriptionQuery = useGetCompanySubscriptionQuery();
  const getInvoicesQuery = useGetInvoicesQuery();
  const { isAccountOwner } = useUserRoles();

  if (shouldCompleteKyc) {
    return <PendingKycEmptyState />;
  }

  if (
    getCompanySubscriptionQuery.status === 'error' ||
    getInvoicesQuery.status === 'error'
  ) {
    return (
      <ErrorState
        title={t('billing.loadErrorTitle')}
        description={t('billing.loadErrorDescription')}
      />
    );
  }

  const shouldHideDescription =
    getCompanySubscriptionQuery.status === 'success' &&
    isChargebeeSubscription(getCompanySubscriptionQuery.data) &&
    getCompanySubscriptionQuery.data.plan.slug === 'subsidiary';

  return (
    <section aria-labelledby="companyPlan">
      <h1 id="companyPlan" className="flex items-center text-primary heading-l">
        {getCompanySubscriptionQuery.status === 'success' &&
        isChargebeeSubscription(getCompanySubscriptionQuery.data) ? (
          <>
            <span>{t('billing.companyPlan.title')}</span>
            {getCompanySubscriptionQuery.data.plan.slug !== 'subsidiary' && (
              <Tag className="ml-12 p-8" variant="purple">
                <span className="title-l">
                  {t(
                    getCompanyPlanTranslationKey(
                      getCompanySubscriptionQuery.data.plan.slug,
                    ),
                  )}
                </span>
              </Tag>
            )}
          </>
        ) : (
          t('billing.companyPlan.titleLegacy')
        )}
      </h1>
      {!shouldHideDescription && (
        <div className="mt-8 flex items-center justify-between text-secondary-bg-primary body-l">
          {t('billing.companyPlan.description')}
          {getCompanySubscriptionQuery.status === 'success' &&
            isChargebeeSubscription(getCompanySubscriptionQuery.data) &&
            isAccountOwner && (
              <ContactUs>
                {({ handleContactUs }) => (
                  <Button
                    className="ml-16 whitespace-nowrap"
                    variant="secondaryInfo"
                    text={t('billing.companyPlan.changePlan')}
                    onClick={() => {
                      handleContactUs();
                    }}
                  />
                )}
              </ContactUs>
            )}
        </div>
      )}
      {getCompanySubscriptionQuery.status === 'loading' ||
        (getInvoicesQuery.status === 'loading' && <CompanySectionLoader />)}
      {getCompanySubscriptionQuery.status === 'success' &&
        getInvoicesQuery.status === 'success' && (
          <CompanyPlan
            companySubscription={getCompanySubscriptionQuery.data}
            invoices={getInvoicesQuery.data}
            hasNextPage={getInvoicesQuery.hasNextPage}
            fetchNextPage={getInvoicesQuery.fetchNextPage}
          />
        )}
    </section>
  );
};
