import { type MonetaryValue } from 'ezmoney';
import { useQuery, useQueryClient } from 'react-query';

import { useCompany } from 'modules/app/hooks/useCompany';
import { apiV2Url } from 'src/core/utils/api';

import { type RequestState } from '../models/requestState';
import { type RequestType } from '../models/requestType';

export type RequestToApprove = {
  id: string;
  userId: string;
  state: RequestState;
  createdAt: string;
  amounts: {
    declared: {
      inOriginalCurrency?: MonetaryValue;
      inCompanyCurrency?: MonetaryValue;
      conversionRate?: number;
    };
  };
  description: string;
  supplierId: string | undefined;
} & (
  | {
      type: Exclude<RequestType, 'mileage_allowance'>;
    }
  | {
      type: 'mileage_allowance';
      mileageData?: {
        distance: number;
        distanceFromMap?: number;
        distanceUnit: 'km' | 'mi';
      };
    }
);

export type RequestsToApprove = {
  users: {
    id: string;
    avatarUrl: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    email: string;
    pending: boolean;
  }[];
  suppliers: {
    id: string;
    name: string | undefined;
  }[];
  requests: RequestToApprove[];
};

export const useRequestsToApproveQueryKey = ['v2', 'requests', 'to-approve'];
export const useRequestsToApproveQuery = ({
  enabled,
}: {
  enabled: boolean;
}) => {
  const company = useCompany();
  return useQuery(
    useRequestsToApproveQueryKey,
    async (): Promise<{ data: RequestsToApprove }> => {
      const response = await fetch(
        apiV2Url('/requests/to-approve', company.id),
        {
          method: 'GET',
          credentials: 'include',
        },
      );

      if (!response.ok) {
        throw new Error('Failed to fetch requests to approve');
      }
      return response.json();
    },
    {
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};

export const useRemoveRequestsFromRequestsToApproveCache = () => {
  const queryClient = useQueryClient();

  const removeRequestsFromRequestsToApproveCache = (requestIds: string[]) => {
    queryClient.setQueryData<{ data: RequestsToApprove } | undefined>(
      useRequestsToApproveQueryKey,
      (oldData) => {
        if (!oldData) {
          return undefined;
        }
        const updatedRequests = oldData.data.requests.filter(
          (request) => !requestIds.includes(request.id),
        );
        return {
          data: {
            ...oldData.data,
            requests: updatedRequests,
          },
        };
      },
    );
  };

  return {
    removeRequestsFromRequestsToApproveCache,
  };
};
