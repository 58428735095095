import { Button, Modal } from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { CostCenterDeletionDetails } from './CostCenterDeletionDetails';
import {
  type CostCenterReplacePreview,
  isCostCenterReplacementRequired,
} from './costCenterReplacePreview';
import type { FormValues } from './formValues';
import { type CostCenter } from '../../costCenter';

type Props = {
  costCenter: {
    name: string;
    hasBudgets: boolean;
    replacePreview: CostCenterReplacePreview;
  };
  isOpen: boolean;
  substituteCostCenters: CostCenter[];
  onComplete(costCenterId?: string): void;
  onCancel(): void;
};

export const CostCenterDeletionModal = ({
  costCenter,
  substituteCostCenters,
  isOpen,
  onCancel,
  onComplete,
}: Props) => {
  const { t } = useTranslation('global');
  const isReplacementRequired =
    substituteCostCenters.length > 0 &&
    isCostCenterReplacementRequired(costCenter.replacePreview);

  const formikProps = useFormik<FormValues>({
    initialValues: {
      costCenterId: undefined,
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.costCenterId) {
        errors.costCenterId = t('costCenters.deletion.noCostCenterSelected');
      }
      return errors;
    },
    onSubmit: (values) => {
      onComplete(values.costCenterId);
    },
  });

  const handleSubmit = () => {
    if (isReplacementRequired) {
      formikProps.submitForm();
      return;
    }
    onComplete();
  };

  return (
    <Modal
      isOpen={isOpen}
      iconName="hexagone-cross"
      iconVariant="alert"
      title={t('costCenters.deletion.deleteModalTitle', {
        costCenterName: costCenter.name,
      })}
      subtitle={
        hasCostCenterAttachedEntities(costCenter)
          ? t('costCenters.deletion.modalSubTitleSubmission', {
              costCenterName: costCenter.name,
            })
          : t('costCenters.deletion.modalSubTitleSubmissionNoEntities')
      }
      actions={[
        <Button
          key="1"
          variant="secondaryNeutral"
          text={t('costCenters.deletion.cancelButton')}
          onClick={onCancel}
        />,
        <Button
          key="2"
          variant="primaryAlert"
          text={t('costCenters.deletion.delete')}
          onClick={handleSubmit}
        />,
      ]}
    >
      {hasCostCenterAttachedEntities(costCenter) && (
        <div className="mt-8">
          <CostCenterDeletionDetails
            {...formikProps}
            costCenter={costCenter}
            isReplacementRequired={isReplacementRequired}
            substituteCostCenters={substituteCostCenters}
          />
        </div>
      )}
    </Modal>
  );
};

const hasCostCenterAttachedEntities = (
  costCenter: Props['costCenter'],
): boolean => {
  const attachedEntities = Object.values(costCenter.replacePreview).reduce(
    (sum, counter) => sum + counter,
    0,
  );
  return attachedEntities !== 0;
};
