/* eslint-disable @typescript-eslint/naming-convention */
import { HighlightIcon, Tag, DATE_FORMAT } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { type MonetaryValue, toNumber } from 'ezmoney';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { BudgetAmountEstimation } from './BudgetAmountEstimation';
import {
  type Breakdown,
  getSpentBreakdownPercentage,
  hasExceededAmounts,
} from '../../models/breakdown';

type Props = {
  title?: string;
  name: string;
  amount: MonetaryValue;
  breakdown: Breakdown;
  isUnavailable?: boolean;
  withDate?: boolean;
  mode: 'compact' | 'medium' | 'full';
  date: Date;
};

export const BudgetOverviewHeader = ({
  title,
  name,
  amount,
  breakdown,
  isUnavailable,
  withDate,
  mode,
  date,
}: Props) => {
  const { localeFormat, t } = useTranslation('global');
  const isExceeded = hasExceededAmounts(breakdown);

  const isCompact = mode === 'compact';

  return (
    <div className="w-full text-left">
      <div
        className={cx(
          'mb-16 flex w-full items-center justify-between text-primary title-l',
        )}
      >
        <div className="flex max-w-[345px] items-center overflow-hidden text-ellipsis">
          <span className={cx('truncate text-primary title-l')}>
            {title ?? name}{' '}
            {withDate && (
              <span className="text-primary body-m">
                (
                {localeFormat(date, DATE_FORMAT.CUSTOM, {
                  month: 'long',
                  year: 'numeric',
                })}
                )
              </span>
            )}
          </span>
          {isUnavailable && (
            <Tag className="ml-8" variant="neutral">
              {t('budget.unavailable')}
            </Tag>
          )}
        </div>
        <div
          className={cx(
            'flex h-16 items-center',
            isCompact ? 'body-m' : 'body-l',
            { 'text-warning-default': isExceeded },
          )}
        >
          {isExceeded && (
            <HighlightIcon
              className="mr-8"
              name="triangle-warning"
              size={24}
              variant="warning"
            />
          )}
          <div>
            {toNumber(amount) > 0
              ? `${getSpentBreakdownPercentage(breakdown)}%`
              : ''}
          </div>
        </div>
      </div>
      <BudgetAmountEstimation
        amount={amount}
        breakdown={breakdown}
        mode={mode}
      />
    </div>
  );
};
