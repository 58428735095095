import { useQueryClient } from 'react-query';

import { useFeature } from 'common/hooks/useFeature';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import FEATURES from 'src/core/constants/features';

const requestParams = {
  type: 'rest',
  target: 'companyAPI',
  endpoint: '/expense-categories/designated-custom-field',
  method: 'get',
} as const;

const queryKey = 'designatedCustomField';
const staleTime = 1000 * 60 * 5; // 5 minutes

export type UseDesignatedCustomFieldQueryRawResponse = {
  customFieldId: string;
};

export const useDesignatedCustomFieldQuery =
  (): QueryState<UseDesignatedCustomFieldQueryRawResponse> => {
    const isExpenseCategoriesFeatureEnabled = useFeature(
      FEATURES.EXPENSE_CATEGORIES,
    );

    return useQuery<UseDesignatedCustomFieldQueryRawResponse>({
      key: queryKey,
      isEnabled: isExpenseCategoriesFeatureEnabled,
      request: requestParams,
      options: {
        staleTime,
      },
    });
  };

export const useDesignatedCustomFieldQueryPromise = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  const designatedCustomFieldFetcher = () =>
    getFetcher<UseDesignatedCustomFieldQueryRawResponse>({
      companyId,
      getRequest: () => requestParams,
    });

  return async (): Promise<UseDesignatedCustomFieldQueryRawResponse> => {
    return queryClient.fetchQuery(queryKey, designatedCustomFieldFetcher(), {
      staleTime,
    });
  };
};
