import { apiV2Url } from 'src/core/utils/api';

export const deleteSpendLimit = async ({
  companyId,
  spendLimitId,
}: {
  companyId: string;
  spendLimitId: string;
}): Promise<void> => {
  const response = await fetch(
    apiV2Url(`/spend-limits/${spendLimitId}`, companyId),
    {
      method: 'DELETE',
      credentials: 'include',
    },
  );
  if (!response.ok) {
    throw new Error('Impossible to delete a spend limit');
  }
};
