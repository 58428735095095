import { logger } from 'src/utils/datadog-log-wrapper';

export type ConsentDetails = {
  services: Record<string, ServiceData>;
};

type ServiceData = {
  name: string;
  version: string;
  category: string;
  essential: boolean;
  consent?: {
    given: boolean;
    type: 'IMPLICIT' | 'EXPLICIT';
  };
  subservices?: Record<string, ServiceData>;
};

export const SERVICE_ID = {
  userCentrics: 'H1Vl5NidjWX',
  segment: 'O97xcRJFR',
  amplitude: 'Sk9kb5VoOi-7',
  intercom: 'ryDQcVoOoZQ',
  googleAnalytics: 'HkocEodjb7',
  datadog: 'Xl0HIOViY',
  fullStory: 'BkZ_qViOj-7',
  satisMeter: 'yLh46hLzDKkbmf',
  storyblok: 'jkQ9enxRQRt6VV',
};

// return whether the consent has been given by the user for a specific service (from UserCentrics)
// the serviceId should be the same as in UserCentrics > data-processing-services page
export const getIsServiceConsentGiven = async (
  serviceId: keyof typeof SERVICE_ID,
): Promise<boolean> => {
  if (window.__ucCmp) {
    try {
      const consent: ConsentDetails = await window.__ucCmp.getConsentDetails();
      const serviceConsent = consent.services[SERVICE_ID[serviceId]];
      if (serviceConsent?.consent) {
        return serviceConsent.consent.given;
      }
    } catch {
      logger.warn('Error while getting UserCentrics consent details', {
        team: 'growth',
        scope: 'consent-management',
        serviceId,
      });
    }
  }
  return appConfig.environmentName !== 'production'; // default value is true for all dev env for testing purposes
};
