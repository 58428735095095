import React from 'react';

import { useFetchDraftInvoiceRequest } from '@finance-review/apis/invoice';
import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useIsLineItemFeatureEnabled } from 'modules/requests/hooks/useIsLineItemFeatureEnabled';
import { type CurrenciesKey } from 'src/core/config/money';

import { InvoiceLineItemsPanelSection } from './InvoiceLineItemsPanelSection';
import { InvoiceLineItemsPanelSectionSkeleton } from './InvoiceLineItemsPanelSectionSkeleton';

type Props = {
  before?: React.ReactNode;
  requestId: string;
};

export const InvoiceLineItemsPanelSectionContainer = (props: Props) => {
  const isLineItemFeatureEnabled = useIsLineItemFeatureEnabled();
  const invoiceRequestQueryState = useFetchDraftInvoiceRequest(
    props.requestId,
    {
      isEnabled: isLineItemFeatureEnabled,
      filters: {
        requestState: ['draft', 'pending', 'approved'],
      },
    },
  );

  if (!isLineItemFeatureEnabled) {
    return null;
  }

  return (
    <QuerySuspense
      queryState={invoiceRequestQueryState}
      loading={
        <>
          {props.before}
          <InvoiceLineItemsPanelSectionSkeleton />
        </>
      }
      fallback={(queryError) => (
        <>
          {props.before}
          <QueryError
            queryError={queryError}
            componentType="Callout"
            translations={{
              serverError: 'requests.panel.invoice.lineItems.api.error',
            }}
          />
        </>
      )}
    >
      {({ draftInvoiceRequest }) =>
        draftInvoiceRequest.lineItems &&
        draftInvoiceRequest.lineItems.length > 0 ? (
          <InvoiceLineItemsPanelSection
            {...props}
            lineItems={draftInvoiceRequest.lineItems}
            currency={draftInvoiceRequest.currency as CurrenciesKey}
          />
        ) : null
      }
    </QuerySuspense>
  );
};
