import {
  AutocompleteNoOptions,
  Avatar,
  Button,
  DropdownItem,
  FormField,
  Label,
  TextInput,
} from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  AutocompleteMultipleSearch,
  AutocompleteSearch,
} from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { validateCostCenterForm } from './validate';
import { type CostCenter } from '../../cost-centers/hooks/useCostCenterQuery';
import { type Member } from '../../cost-centers/member';

export type FormValues = {
  name: string;
  ownerId: string | undefined;
  viewerIds: string[];
};

type MemberOption = {
  key: string;
  label: string;
  avatar: string;
};

type Props = {
  costCenter: CostCenter;
  members: Member[];
  onSubmit: (values: FormValues) => void;
};

export const CostCenterEditForm = ({
  costCenter,
  members,
  onSubmit,
}: Props) => {
  const companyId = useCompanyId();
  const { t } = useTranslation('global');
  const history = useHistory();
  const {
    values: formValues,
    setFieldValue,
    handleSubmit,
    handleBlur,
    isSubmitting,
  } = useFormik<FormValues>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      name: costCenter.name,
      ownerId: costCenter.ownerId,
      viewerIds: costCenter.viewerIds,
    },
    validate: (values) => {
      return validateCostCenterForm(values, t);
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit({
        name: values.name,
        ownerId: values.ownerId,
        viewerIds: values.viewerIds,
      });
      setSubmitting(false);
    },
  });

  const [sortedViewers, setSortedViewers] = useState<MemberOption[]>([]);
  useEffect(() => {
    setSortedViewers(
      sortViewersBySelected(reshapedMembers, formValues.viewerIds),
    );
  }, []);

  const reshapedMembers = members.map((member) => ({
    key: member.id,
    label: member.isPending ? member.email : member.fullname,
    avatar: member.avatar,
  }));

  const selectedViewers = reshapedMembers.filter((member) =>
    formValues.viewerIds.includes(member.key),
  );

  return (
    <>
      <div className="my-32 flex flex-col gap-24 text-left">
        <FormField label={t('costCenters.form.name')} alertMessage={undefined}>
          <TextInput
            name="name"
            value={formValues.name}
            placeholder={t('costCenters.form.namePlaceholder')}
            onChange={(event) => {
              setFieldValue('name', event.target.value);
            }}
          />
        </FormField>
        <div>
          <Label label={t('costCenters.form.owner')} />
          <AutocompleteSearch
            name="ownerId"
            fit="parent"
            options={reshapedMembers}
            value={undefined}
            placeholder={t('costCenters.form.owner')}
            renderPrefix={(option) =>
              option && (
                <Avatar size={24} src={option.avatar} text={option.label} />
              )
            }
            renderOption={(option, state) => (
              <DropdownItem
                {...option}
                {...state}
                prefix={
                  <Avatar size={24} src={option.avatar} text={option.label} />
                }
              />
            )}
            onSelect={(option) => {
              if (option) {
                setFieldValue('ownerId', 'option.key');
              }
            }}
            renderNoOptions={(rawValue) => (
              <AutocompleteNoOptions className="text-center body-m">
                <div>
                  <Trans
                    i18nKey="misc.noOptions"
                    values={{ account: rawValue }}
                    components={[
                      <span key="complementary" className="text-primary" />,
                    ]}
                  />
                </div>
              </AutocompleteNoOptions>
            )}
          />
        </div>
        <div>
          <Label
            infoTipContent={t('costCenters.form.viewersInfoText')}
            label={t('costCenters.form.viewers')}
          />
          <AutocompleteMultipleSearch
            name="viewerIds"
            fit="parent"
            options={sortedViewers}
            values={selectedViewers}
            placeholder={t('costCenters.form.viewersPlaceholder')}
            translations={{
              selected: t('costCenters.form.selectedMember', {
                count: formValues.viewerIds.length,
              }),
            }}
            onSelect={(viewers) =>
              setFieldValue(
                'viewerIds',
                viewers.map((viewer) => viewer.key),
              )
            }
            onBlur={(event) => {
              handleBlur(event);
              setSortedViewers(
                sortViewersBySelected(reshapedMembers, formValues.viewerIds),
              );
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-center gap-8">
        <Button
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={() => {
            history.push(
              routeFor(routes.COST_CENTERS.path, {
                company: companyId,
              }),
            );
          }}
        />
        <Button
          variant="primaryBrand"
          text={t('misc.save')}
          type="submit"
          onClick={() => {
            handleSubmit();
          }}
          disabled={isSubmitting}
        />
      </div>
    </>
  );
};

const sortViewersBySelected = (
  memberOptions: MemberOption[],
  viewerIds: string[],
) => {
  return [...memberOptions].sort((a, b) => {
    const aIncluded = viewerIds.includes(a.key);
    const bIncluded = viewerIds.includes(b.key);

    if (aIncluded && !bIncluded) {
      return -1;
    }
    if (!aIncluded && bIncluded) {
      return 1;
    }
    return 0;
  });
};
