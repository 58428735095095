import {
  AutocompleteNoOptions,
  Callout,
  colors,
  DropdownItem,
  Icon,
  AmountInput,
  Tooltip,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import * as Money from 'ezmoney';
import {
  toNumber as toNumberMoney,
  fromNumber as fromNumberMoney,
  type MonetaryValue,
} from 'ezmoney';
import get from 'lodash/get';
import React from 'react';
import { Trans } from 'react-i18next';

import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import {
  type TGlobalFunctionTyped,
  useTranslation,
  type I18nKey,
} from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type IntegrationStatus } from 'modules/bookkeep/integration/status';
import { InvalidAccountCallout } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/components/InvalidAccountCallout';
import { type ItemLineVat } from 'modules/bookkeep/prepare-payables/models';
import { type TaxAccountAutomation } from 'modules/payable/models';
import { routeFor, routes } from 'src/core/constants/routes';
import { taxAccountToLabel } from 'src/core/modules/bookkeep/prepare-payables/components/PreparePayablesFiltersBar/components/PreparePayablesFiltersAdditionalFilters/hooks/reshaper';
import { getCurrencyOptionByKey } from 'src/core/utils/money';

import {
  computeVatAmountFromNetAmount,
  getVatAccount,
  isReverseChargeAccount,
  type TaxAccount,
  isExemptTaxAccount,
  computeVatAmountFromGrossAmount,
} from './tax';

export type AutoComputeVatAmount =
  | {
      strategy: 'useNetAmount';
      netAmount: MonetaryValue;
      grossAmount?: never;
    }
  | {
      strategy: 'useGrossAmount';
      grossAmount: MonetaryValue;
      netAmount?: never;
    }
  | {
      strategy: 'none';
      grossAmount?: never;
      netAmount?: never;
    };

type Props = {
  vatAccounts?: TaxAccount[];
  taxAccount: TaxAccount | undefined;
  reverseChargeAmount?: MonetaryValue;
  automation?: TaxAccountAutomation;
  currency: string;
  walletCurrency: string;
  onChange: (vatItem: ItemLineVat) => void;
  values: ItemLineVat;
  initialValues: ItemLineVat | null | undefined;
  errors?: {
    accountId?: string;
    amount?: string;
  };
  hasAmountError: boolean;
  hideVatAmount?: boolean;
  taxAccountPlaceholder?: I18nKey;
  integrationStatus: IntegrationStatus;
  autoComputeVatAmount: AutoComputeVatAmount;
};

export const PayableTaxAccountField = ({
  vatAccounts = [],
  taxAccount,
  reverseChargeAmount,
  automation,
  currency,
  walletCurrency,
  onChange,
  values,
  initialValues,
  errors,
  hasAmountError,
  autoComputeVatAmount,
  hideVatAmount,
  taxAccountPlaceholder = 'expenseInbox.inputs.vat.account',
  integrationStatus,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  const handleAccountChange = (selectedKey: string | undefined) => {
    const nextVatAccount = vatAccounts.find(
      (vatAccount) => vatAccount.id === selectedKey,
    );
    let updatedVatItem = values;

    if (nextVatAccount) {
      if (isReverseChargeAccount(nextVatAccount)) {
        updatedVatItem = {
          ...values,
          amount: fromNumberMoney(0, currency, 2),
          accountId: nextVatAccount.id,
          rate: null,
        };
      } else {
        let amount: MonetaryValue | null = null;

        switch (autoComputeVatAmount.strategy) {
          case 'useNetAmount':
            amount = computeVatAmountFromNetAmount(
              autoComputeVatAmount.netAmount,
              nextVatAccount.rate,
            );
            break;
          case 'useGrossAmount':
            amount = computeVatAmountFromGrossAmount(
              autoComputeVatAmount.grossAmount,
              nextVatAccount.rate,
            );
            break;
          case 'none':
            amount = values.amount;
            break;
          default:
            break;
        }

        updatedVatItem = {
          ...values,
          amount,
          rate: nextVatAccount.rate,
          accountId: nextVatAccount.id,
        };
      }
    }

    onChange(updatedVatItem);
  };

  const handleAmountChange = (amount: number | null) => {
    // Pass `null` as value when the input has been cleared.
    const updatedAmount =
      amount || amount === 0
        ? fromNumberMoney(
            Number(amount),
            // If the input has a `null` amount (which is
            // not a  MonetaryValue), use the provided `currency` to
            // build the output MonetaryValue.
            get(amount, 'currency', currency) as string,
            2,
          )
        : null;
    const updatedVatItem = {
      ...values,
      amount: updatedAmount,
    };

    onChange(updatedVatItem);
  };

  const handleAmountBlur = (amountNumber: number | null) => {
    if (!amountNumber) {
      // Set field to "0" if empty on blur because it's mandatory
      onChange({
        ...values,
        amount: fromNumberMoney(0, currency, 2),
      });
    }
  };

  const isInvalidTaxAccount = checkIfTaxAccountIsInvalid(
    integrationStatus,
    values?.accountId,
  );

  const selectedVatAccount = getVatAccount(vatAccounts, values);

  const isArchivedVatAccount = taxAccount?.isArchived;

  const options = vatAccounts
    .filter(
      (vatAccount) =>
        !vatAccount.isArchived || vatAccount.id === selectedVatAccount?.id,
    )
    .map((vatAccount) => ({
      key: vatAccount.id,
      label: taxAccountToLabel(vatAccount, t),
    }));
  const shouldDisplayVatAmount = !(
    hideVatAmount ||
    (selectedVatAccount &&
      isReverseChargeAccount(selectedVatAccount) &&
      isExemptTaxAccount(selectedVatAccount))
  );
  const value =
    (selectedVatAccount && isReverseChargeAccount(selectedVatAccount)
      ? reverseChargeAmount && toNumberMoney(reverseChargeAmount)
      : values.amount && toNumberMoney(values.amount)) ?? null;

  const displayValue = selectedVatAccount
    ? {
        key: selectedVatAccount.id,
        label: taxAccountToLabel(selectedVatAccount, t),
      }
    : undefined;

  const hasAppliedAutomatedValue =
    automation?.isAppliedOnPayable && automation?.kind !== 'default';

  const isCurrentlyAutomatedValue =
    hasAppliedAutomatedValue &&
    initialValues?.accountId === selectedVatAccount?.id;

  const hasAmountPredictionOrRecommendation =
    hasAppliedAutomatedValue &&
    initialValues?.amount &&
    Money.toNumber(initialValues.amount) === (value || 0);

  return (
    <>
      <div className={cx('relative flex')}>
        <AutocompleteSearch
          fit="parent"
          className={cx('[&_input]:w-full', {
            'max-w-full mr-8': !shouldDisplayVatAmount,
          })}
          placeholder={t(taxAccountPlaceholder)}
          options={options}
          value={displayValue}
          isInvalid={Boolean(errors?.accountId || isInvalidTaxAccount)}
          onSelect={(selectedKey) => handleAccountChange(selectedKey?.key)}
          renderNoOptions={(rawValue) => (
            <AutocompleteNoOptions className="PreparePayablesTaxAccountField__no-options">
              <Trans
                i18nKey="misc.noOptions"
                values={{ account: rawValue }}
                components={[
                  <span
                    key="noOptions"
                    className="PreparePayablesTaxAccountField__no-options-value"
                  />,
                ]}
              />
            </AutocompleteNoOptions>
          )}
          inputVariant={isCurrentlyAutomatedValue ? 'magicGradient' : 'default'}
          renderPrefix={() => {
            if (isCurrentlyAutomatedValue) {
              return (
                <AutomationIcon
                  automationKind={automation.kind}
                  t={t}
                  fieldKind="account"
                />
              );
            }
          }}
          renderOption={(
            option,
            { isSelected } = {
              isSelected: false,
            },
          ) => {
            return (
              <DropdownItem
                key={option.key}
                label={option.label}
                isSelected={isSelected}
                suffix={
                  automation?.kind &&
                  initialValues?.accountId &&
                  initialValues.accountId === option.key ? (
                    <AutomationIcon
                      automationKind={automation.kind}
                      t={t}
                      fieldKind="account"
                    />
                  ) : null
                }
              />
            );
          }}
        />

        {shouldDisplayVatAmount && (
          <AmountInput
            fit="parent"
            className="ml-8 w-[104px]"
            placeholder={t('expenseInbox.inputs.vat.amount')}
            value={value}
            currency={getCurrencyOptionByKey(
              selectedVatAccount && isReverseChargeAccount(selectedVatAccount)
                ? walletCurrency
                : currency,
            )}
            isDisabled={
              !selectedVatAccount ||
              (selectedVatAccount && isReverseChargeAccount(selectedVatAccount))
            }
            isInvalid={errors?.amount !== undefined || hasAmountError}
            onChange={(e) => handleAmountChange(e.target.valueAsNumber)}
            onBlur={(e) => handleAmountBlur(e.target.valueAsNumber)}
            variant={
              hasAmountPredictionOrRecommendation ? 'magicGradient' : 'default'
            }
            leftAddon={
              hasAmountPredictionOrRecommendation && (
                <AutomationIcon
                  automationKind={automation.kind}
                  t={t}
                  className="ml-8"
                  fieldKind="amount"
                />
              )
            }
          />
        )}
      </div>
      {isArchivedVatAccount && (
        <Callout
          variant="warning"
          className="mt-8 [&:not(:last-child)]:mb-8"
          title={
            <Trans
              i18nKey="expenseInbox.expenseEditor.vatAccountDeleted"
              values={{ account: taxAccount?.name }}
              components={[<span key="vatAccountDeleted" />]}
            />
          }
        />
      )}
      {!isArchivedVatAccount && isInvalidTaxAccount && (
        <InvalidAccountCallout
          className="mt-8"
          title={t('expenseInbox.expenseEditor.invalidTaxAccount.title')}
          message={t('expenseInbox.expenseEditor.invalidTaxAccount.message')}
          button={t('expenseInbox.expenseEditor.invalidTaxAccount.button')}
          route={routeFor(routes.COMPANY_ACCOUNTING_TAX_ACCOUNTS.path, {
            company: companyId,
          })}
        />
      )}
    </>
  );
};

/**
 * Helpers
 */

export const checkIfTaxAccountIsInvalid = (
  integrationStatus: IntegrationStatus,
  accountId?: string | null,
): boolean =>
  !!(
    integrationStatus.integration !== 'noIntegration' &&
    integrationStatus.integration !== 'switchInProgress' &&
    integrationStatus.settingsValidation.taxAccounts.some(
      (taxAccount) => taxAccount.id === accountId,
    )
  );

const AutomationIcon = ({
  automationKind,
  t,
  fieldKind,
  className = '',
}: {
  automationKind: TaxAccountAutomation['kind'];
  t: TGlobalFunctionTyped;
  fieldKind: 'account' | 'amount';
  className?: string;
}) => {
  switch (automationKind) {
    case 'prediction':
      if (fieldKind === 'amount') {
        return null;
      }

      return (
        <Tooltip
          triggerAsChild
          content={t(
            'expenseInbox.expenseEditor.taxAccountPrefilledByPrediction',
          )}
        >
          <Icon
            color={colors.contentBrandDefault}
            name="robot"
            className={className}
          />
        </Tooltip>
      );
    case 'recommendation':
      if (fieldKind === 'amount') {
        return (
          <Tooltip
            triggerAsChild
            content={t(
              'expenseInbox.expenseEditor.taxAmountPrefilledByRecommendation',
            )}
          >
            <Icon
              color={colors.contentBrandDefault}
              name="robot"
              className={className}
            />
          </Tooltip>
        );
      }

      return (
        <Tooltip
          triggerAsChild
          content={t(
            'expenseInbox.expenseEditor.taxAccountPrefilledByRecommendation',
          )}
        >
          <Icon
            color={colors.contentBrandDefault}
            name="robot"
            className={className}
          />
        </Tooltip>
      );
    case 'default': {
      // Even though this is "automated" this is for accountants just throwing in a default account to fix after export
      return null;
    }
    default:
      return null;
  }
};
