import { Tag, Avatar, DATE_FORMAT } from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';

import { PanelHeader } from 'common/components/Panel';
import { fallbackSupplierLogoSrc } from 'common/components/SupplierLogo';
import { useTranslation } from 'common/hooks/useTranslation';
import { isInvoiceBill } from 'modules/invoices/models';
import { SupplierBankInfoPreviewContainer } from 'modules/suppliers';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { type TransferDetails } from '../../../models';

type Props = {
  transfer: TransferDetails;
};

export const HeaderSection = ({ transfer }: Props) => {
  const { t, localeFormat } = useTranslation('global');
  const {
    bill: { counterparty },
  } = transfer;

  return (
    <PanelHeader
      top={
        <>
          <h3 className="flex items-center text-primary title-l">
            <Avatar
              src={counterparty.logo}
              fallbackSrc={fallbackSupplierLogoSrc}
              size={32}
              text={counterparty.name}
              variant="square"
              className="mr-16"
            />
            <span>{counterparty.name}</span>
          </h3>
          {isInvoiceBill(transfer.bill) && (
            <SupplierBankInfoPreviewContainer
              className="mt-24"
              supplierId={transfer.bill.counterparty.id}
            />
          )}
        </>
      }
      textPrimary={formatMonetaryValue(transfer.amount)}
      textSecondary={
        <Trans
          i18nKey="invoices.transfer.panel.header.scheduledFor"
          values={{
            date: localeFormat(
              new Date(transfer.scheduledForDate),
              DATE_FORMAT.SHORT,
            ),
          }}
          components={[<strong key="bold" />]}
        />
      }
    >
      <Tag variant="neutral">{t('invoices.transfer.panel.header.tag')}</Tag>
    </PanelHeader>
  );
};
