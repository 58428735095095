import { SkeletonText } from '@dev-spendesk/grapes';

import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useCostCentersQuery } from 'src/core/modules/budgets/apis';

import { CommonSelect, type Option } from './CommonSelect';

export const CostCenterSelect = ({
  selectedValues,
  onSelect,
}: {
  selectedValues: string[] | undefined | null;
  onSelect: (values: string[] | null) => void;
}) => {
  const costCentersQueryState = useCostCentersQuery();

  const baseOptions: Option[] =
    costCentersQueryState.status === 'success'
      ? costCentersQueryState.data.map((costCenter) => ({
        key: costCenter.id,
        label: costCenter.name,
      }))
      : [];

  return (
    <QuerySuspense
      queryState={costCentersQueryState}
      loading={<SkeletonText size="xxl" />}
      fallback={(error) => (
        <QueryError queryError={error} componentType="Callout" />
      )}
    >
      {() => (
        <CommonSelect
          selectedValues={selectedValues}
          onSelect={onSelect}
          baseOptions={baseOptions}
          placeholder="Select a cost center"
          dimensionName="costCenter"
        />
      )}
    </QuerySuspense>
  );
};
