import { Redirect, Switch, Route } from 'react-router-dom';

import { ApiAccessManagementSection } from './api-access-management';
import ApiOAuth2AuthorizeModal from './api-oauth2-authorize/ApiOAuth2AuthorizeModal';
import withErrorBoundary from '../../../../common/components/withErrorBoundary';
import { useFeature } from '../../../../common/hooks/useFeature';
import FEATURES from '../../../../constants/features';
import { routes } from '../../../../constants/routes';
import IntegrationsList from '../containers/IntegrationsList';

const IntegrationsPage = ({ key }: { key: string }) => {
  const hasApiKeysPageFeature = useFeature(FEATURES.API_KEYS_PAGE);

  return (
    <div
      key={key}
      className="relative flex flex-row items-start justify-center gap-24 px-40 pb-32 pt-24"
    >
      <div className="grow">
        <Switch>
          <Redirect
            exact
            from={routes.COMPANY_INTEGRATIONS.path}
            to={routes.COMPANY_INTEGRATIONS_ALL.path}
          />
          <Route path={routes.COMPANY_INTEGRATIONS_ALL.path}>
            <IntegrationsList />
            <ApiOAuth2AuthorizeModal />
          </Route>
          {hasApiKeysPageFeature && (
            <Route
              path={routes.COMPANY_INTEGRATIONS_API_ACCESS_MANAGEMENT.path}
            >
              <ApiAccessManagementSection />
            </Route>
          )}
        </Switch>
      </div>
    </div>
  );
};

export default withErrorBoundary({
  scope: 'integrations-page',
  team: 'api-integration',
})(IntegrationsPage);
