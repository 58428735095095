import * as NEA from '@dev-spendesk/general-type-helpers/NonEmptyArray';
import { Button, Modal } from '@dev-spendesk/grapes';
import { type Filter } from '@spendesk/bookkeeping-core-types';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useNotifications } from 'modules/app/notifications';
import { trackPayableAllRequested } from 'modules/bookkeep/export/analytics';
import {
  PayableList,
  PayableListSkeleton,
  PayableListTitle,
  useActivePayableFilter,
} from 'modules/payable/components';
import { useGetPayableCount, useGetPayableList } from 'modules/payable/hooks';
import {
  type ExportPayableReportPayload,
  useExportPayableReport,
  useExportPayableReportErrorMessage,
} from 'modules/payable-report/hooks/api';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import {
  type FilterWithOptionalField,
  withoutOptionalFields,
} from 'src/core/common/components/FilterBuilder';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { PayableListEmptyResult } from 'src/core/modules/payable/components';
import { toCoreTypeFilter } from 'src/core/modules/payable/hooks/api/payable-filter/payableFilterTransformer';

import { useFiltersContext } from './PayablesFiltersContainer/hooks';
import { type FiltersState } from './PayablesFiltersContainer/hooks/useFiltersContext';
import { trackFiltersV2 } from '../../utils/track-filters';

type Props = {
  payableId: string;
  onClick(payableId: string): void;
};

export const PayableListContainer = ({ payableId, onClick }: Props) => {
  const { t } = useTranslation('global');
  const { state, updateUrlParams, actions } = useFiltersContext();
  const companyId = useCompanyId();
  const history = useHistory();

  const activeFilter = useActivePayableFilter({ variant: 'all-payables' });

  const payablesQueryState = useGetPayableList({
    filters: state,
    filtersV2: activeFilter?.filter,
    options: {
      onSuccess: () =>
        activeFilter?.filter ? undefined : updateUrlParams(state),
    },
  });

  const countPayablesQueryState = useGetPayableCount({
    filters: state,
    filtersV2: activeFilter?.filter,
  });

  useEffect(() => {
    trackFiltersV2(activeFilter, 'all-payables');
  }, [JSON.stringify(activeFilter)]);

  const queryStates = useQueryStates({
    states: {
      payables: payablesQueryState,
      totalPayables: countPayablesQueryState,
    },
    reshapeData: ({ payables, totalPayables }) => ({
      payables,
      totalPayables: totalPayables.totalCount,
    }),
  });

  return (
    <QuerySuspense
      queryState={queryStates}
      loading={<PayableListSkeleton />}
      fallback={(error) => (
        <QueryError
          queryError={error}
          componentType="ErrorState"
          translations={{}}
        />
      )}
    >
      {({ payables, totalPayables }) => {
        if (totalPayables === 0) {
          return (
            <PayableListEmptyResult
              onResetFilters={() => {
                actions.reset();
                history.push(
                  routeFor(routes.PAYABLES_ALL.path, {
                    company: companyId,
                  }),
                );
              }}
            />
          );
        }
        return (
          <>
            <PayableListTitle
              title={
                <>
                  {t('payables.export.title', {
                    count: totalPayables,
                  })}
                  <span className="ml-4 inline-block font-medium text-primary">
                    ({totalPayables})
                  </span>
                </>
              }
            />
            <PayableList
              activePayableId={payableId}
              payables={payables}
              totalPayables={totalPayables}
              hasNextPage={payablesQueryState.hasNextPage}
              onFetchNextPage={payablesQueryState.fetchNextPage}
              onClick={onClick}
              renderActions={(props) => (
                <PayableListActions {...props} filter={activeFilter?.filter} />
              )}
            />
          </>
        );
      }}
    </QuerySuspense>
  );
};

/**
 * List actions
 */

const PayableListActions = ({
  numberOfSelectedRows,
  areAllRowsSelected,
  unselectedRows,
  selectedRows,
  filter,
}: {
  numberOfSelectedRows: number;
  areAllRowsSelected: boolean;
  unselectedRows: string[];
  selectedRows: string[];
  filter: FilterWithOptionalField | undefined;
}) => {
  const { t } = useTranslation('global');

  const { state } = useFiltersContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [exportPayableReport] = useExportPayableReport();

  const exportPayableReportErrorMessage = useExportPayableReportErrorMessage();

  const { dangerNotif } = useNotifications();

  const filtersV2 = filter
    ? filtersV2ToApi({
        filter: toCoreTypeFilter(withoutOptionalFields(filter)),
        payableIdsToFilter: !areAllRowsSelected
          ? NEA.fromArray(selectedRows)
          : undefined,
      })
    : undefined;

  const exportPayables = async () => {
    trackPayableAllRequested();
    setIsModalOpen(true);
    try {
      await exportPayableReport({
        filtersV2,
        ...(areAllRowsSelected ? reshapeFilters(state) : {}),
        ids: !areAllRowsSelected ? selectedRows : undefined,
        excludeIds:
          areAllRowsSelected && unselectedRows.length > 0
            ? unselectedRows
            : undefined,
      });
    } catch (error) {
      const errorMessage = exportPayableReportErrorMessage(error);
      dangerNotif(errorMessage);
    }
  };

  return (
    <>
      <Button
        text={t('payables.export.downloadSelection')}
        variant="secondaryNeutral"
        onClick={exportPayables}
        isDisabled={numberOfSelectedRows === 0}
      />
      <Modal
        isOpen={isModalOpen}
        iconName="circle-information"
        iconVariant="info"
        title={t('wallet.statements.downloadModal.title')}
        actions={[
          <Button
            key="no"
            variant="primaryBrand"
            text={t('wallet.statements.downloadModal.hide')}
            onClick={() => setIsModalOpen(false)}
          />,
        ]}
      >
        <div>{t('wallet.statements.downloadModal.subtitle')}</div>
      </Modal>
    </>
  );
};

const reshapeFilters = (state: FiltersState): ExportPayableReportPayload => {
  const reshapedPayableStates: ExportPayableReportPayload['payableStates'] = [];
  if (state.payableStates) {
    if (state.payableStates.includes('toPrepare')) {
      reshapedPayableStates.push('created', 'unprepared');
    }
    if (state.payableStates.includes('toExport')) {
      reshapedPayableStates.push(
        'prepared',
        'to_accounting_pending',
        'to_accounting_failed',
      );
    }
    if (state.payableStates.includes('exported')) {
      reshapedPayableStates.push('in_accounting', 'in_accounting_manually');
    }
  } else {
    // If we don't have state filters, we fetch every state except "discarded"
    reshapedPayableStates.push(
      'created',
      'prepared',
      'unprepared',
      'notBookkept',
      'to_accounting_pending',
      'to_accounting_failed',
      'in_accounting',
      'in_accounting_manually',
    );
  }

  return {
    payableStates:
      reshapedPayableStates.length > 0 ? reshapedPayableStates : undefined,
    payableTypes:
      state.payableTypes as ExportPayableReportPayload['payableTypes'],
    settlementState:
      state.settlementState as ExportPayableReportPayload['settlementState'],
    documentaryEvidenceStatus:
      state.documentaryEvidenceStatus as ExportPayableReportPayload['documentaryEvidenceStatus'],
    costCenters: state.costCenter ? [state.costCenter] : undefined,
    search: state.search,
    creationDate: state.creationDate,
  };
};

const filtersV2ToApi = ({
  filter,
  payableIdsToFilter,
}: {
  filter: Filter;
  payableIdsToFilter?: NEA.NonEmptyArray<string>;
}): Filter => {
  if (payableIdsToFilter) {
    return {
      field: 'id',
      operator: '=',
      value: payableIdsToFilter,
    };
  }

  return filter;
};
