import classNames from 'classnames';
import type * as Money from 'ezmoney';
import React, { useId } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import {
  type DetailedWalletSummary,
  getWalletTotalAllocated,
} from 'modules/company/wallet/walletSummary';
import { routeFor, routes } from 'src/core/constants/routes';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  HomepageBankAccountNotConnected,
  HomepageFirstFundingInProgress,
  HomepageWalletDetailKybNotValidated,
  HomepageWalletDetailNoFunds,
  HomepageWalletDetailPrimaryAmount,
  HomepageWalletDetailSecondaryAmount,
} from '../HomepageWalletDetail';

type Props = {
  company: {
    id: string;
    is_kyb_validated: boolean;
  };
  hasMissingFunds: boolean;
  hasPendingGpaOrders: boolean;
  isAccountLoaded: boolean;
  isMarqeta: boolean;
  mustConnectBankAccount: boolean;
  walletSummary: DetailedWalletSummary;
};

export const HomepageWallet = ({
  company,
  hasMissingFunds,
  hasPendingGpaOrders,
  isAccountLoaded,
  isMarqeta,
  mustConnectBankAccount,
  walletSummary,
}: Props) => {
  const { t } = useTranslation('global');
  const titleId = useId();

  const totalAllocated = formatMonetaryValue(
    getWalletTotalAllocated(walletSummary),
  );

  const totalAvailableForRequest = walletSummary.walletDetails
    ? formatMonetaryValue(walletSummary.walletDetails.available.totalAmount, {
        signDisplay: 'never' as Money.SignDisplay,
      })
    : '-';

  // TODO@financeOps: scheduledTransfers will always be present on detailed wallet when fully rolled out on the backend side
  const hasScheduledTransfersDetails =
    !!walletSummary.walletDetails?.scheduledTransfers;

  const totalWallet = walletSummary.totalAmount
    ? formatMonetaryValue(walletSummary.totalAmount)
    : '-';

  const formatedAmountDescription = hasMissingFunds
    ? t('homepage.wallet.expectedShortfall')
    : t('homepage.wallet.availableForRequest');

  const ctaText = hasMissingFunds
    ? t('homepage.wallet.topUpWallet')
    : t('homepage.wallet.link');

  const ctaLink = hasMissingFunds
    ? routeFor(routes.COMPANY_BANK_FUNDING.path, { company: company.id })
    : routeFor(routes.COMPANY_BANK.path, { company: company.id });

  const renderConnectBankAccount = (): JSX.Element => (
    <HomepageBankAccountNotConnected company={company} />
  );
  const renderFirstFundingInProgress = (): JSX.Element => (
    <HomepageFirstFundingInProgress company={company} />
  );
  const renderNoFunds = (isKybValidated: boolean): JSX.Element =>
    isKybValidated ? (
      <HomepageWalletDetailNoFunds />
    ) : (
      <HomepageWalletDetailKybNotValidated />
    );

  const renderPrimaryAmount = (): JSX.Element => (
    <HomepageWalletDetailPrimaryAmount
      ctaText={ctaText}
      ctaLink={ctaLink}
      formatedAmount={totalAvailableForRequest}
      formatedAmountDescription={formatedAmountDescription}
      hasWarning={hasMissingFunds}
    />
  );

  const formatedAllocatedAmountDescription = hasScheduledTransfersDetails
    ? t('homepage.wallet.totalAllocated')
    : t('homepage.wallet.allocatedOnCards');

  const renderSecondaryAmounts = (): JSX.Element => (
    <div className="flex flex-col content-stretch gap-24 self-center">
      <HomepageWalletDetailSecondaryAmount
        formatedAmount={totalWallet}
        formatedAmountDescription={t('homepage.wallet.walletDescription')}
        isAccountLoaded={isAccountLoaded}
        iconName="wallet"
        hasWarning={hasMissingFunds}
      />
      <HomepageWalletDetailSecondaryAmount
        formatedAmount={totalAllocated}
        formatedAmountDescription={formatedAllocatedAmountDescription}
        isAccountLoaded={isAccountLoaded}
        iconName={
          hasScheduledTransfersDetails ? 'calendar' : 'card-rounded-arrows'
        }
        hasWarning={hasMissingFunds}
      />
    </div>
  );

  const renderHomepageWallet = (): JSX.Element => {
    if (isMarqeta) {
      if (!isAccountLoaded) {
        if (hasPendingGpaOrders) {
          return renderFirstFundingInProgress();
        }
        if (mustConnectBankAccount) {
          return renderConnectBankAccount();
        }
        return renderNoFunds(company.is_kyb_validated);
      }
      return (
        <div className="grid grid-cols-2 content-center gap-24">
          {renderPrimaryAmount()}
          {renderSecondaryAmounts()}
        </div>
      );
    }

    return (
      <div className="grid grid-cols-2 content-center gap-24">
        {!isAccountLoaded ? renderNoFunds(true) : renderPrimaryAmount()}
        {renderSecondaryAmounts()}
      </div>
    );
  };

  return (
    <section
      aria-labelledby={titleId}
      className={classNames(
        'h-full content-center rounded-8 p-24',
        hasMissingFunds
          ? 'bg-secondary-warning-default'
          : 'bg-secondary-default',
      )}
    >
      {renderHomepageWallet()}
    </section>
  );
};
