import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { AppState } from 'src/core/reducers';

import { type Quote } from '../../models';

export type TransferQuote = Quote & {
  transferId: string;
};

type QuoteState = {
  quotes: Array<TransferQuote>;
};

const initialState: QuoteState = {
  quotes: [],
};

const quotesSlice = createSlice({
  name: 'invoices/quotes',
  initialState,
  reducers: {
    addQuote: (state, { payload }: PayloadAction<{ quote: TransferQuote }>) => {
      const filteredQuotes = state.quotes.filter(
        (quote) => quote.transferId !== payload.quote.transferId,
      );
      state.quotes = [...filteredQuotes, payload.quote];
    },

    removeQuote: (
      state,
      { payload }: PayloadAction<{ transferId: string }>,
    ) => {
      state.quotes = state.quotes.filter(
        (quote) => quote.transferId !== payload.transferId,
      );
    },
  },
});

const selectQuotesSlice = (state: AppState): QuoteState =>
  state.invoices.quotes;

const selectedPaymentsToScheduleQuotesSlice = createSelector(
  [
    (state: AppState) =>
      state.invoices.paymentsScheduling.selectedPaymentsToSchedule,
    (state: AppState) => state.invoices.quotes.quotes,
  ],
  (selectedPayments, quotes) => {
    const selectedIds = selectedPayments.map((p) => p.id);
    const result = quotes.filter((quote) =>
      selectedIds.includes(quote.transferId),
    );
    return { quotes: result };
  },
);

const selectedPaymentsToConfirmQuotesSlice = createSelector(
  [
    (state: AppState) => state.invoices.transfers.invoice.transfersSelected,
    (state: AppState) => state.invoices.quotes.quotes,
  ],
  (selectedPayments, quotes) => {
    const selectedIds = selectedPayments.map((p) => p.id);
    const result = quotes.filter((quote) =>
      selectedIds.includes(quote.transferId),
    );

    return { quotes: result };
  },
);

const selectQuotesByType = (isScheduling: boolean) =>
  createSelector(
    [
      selectedPaymentsToScheduleQuotesSlice,
      selectedPaymentsToConfirmQuotesSlice,
    ],
    (schedulingQuotes, confirmQuotes) =>
      isScheduling ? schedulingQuotes : confirmQuotes,
  );

const selectQuoteByTransferId = (transferId: string) =>
  createSelector(
    [selectedPaymentsToScheduleQuotesSlice],
    (schedulingQuotes) => ({
      quoteForTransfer: schedulingQuotes.quotes.find(
        (quote) => quote.transferId === transferId,
      ),
    }),
  );

export const quotesSelectors = {
  selectQuotesSlice,
  selectedPaymentsToScheduleQuotesSlice,
  selectedPaymentsToConfirmQuotesSlice,
  selectQuotesByType,
  selectQuoteByTransferId,
};

export const { actions: quotesActions, reducer: quotesReducer } = quotesSlice;
