import { HighlightIcon } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { type PaymentsToCompleteResult } from 'src/core/modules/control-rules/hooks/usePaymentsToCompleteQuery';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { type ControlRule } from '../../controlRule';
import { OverdueSection } from '../OverdueSection';
import { PaymentsList } from '../PaymentsList';
import { PaymentsListEmptyState } from '../PaymentsListEmptyState';

type Props = {
  context: {
    companyId: string;
    userHasMadePayments: boolean;
  };
  incompletePayments: PaymentsToCompleteResult;
  controlRule: ControlRule;
};

export const PaymentsListSection = ({
  context,
  incompletePayments,
  controlRule,
}: Props) => {
  const { t } = useTranslation('global');
  const history = useHistory();

  const handleNewRequestClick = () => {
    track(AnalyticEventName.REQUEST_NEW_BUTTON_CLICKED, {
      from: 'homepage_empty',
    });
    const requestRoute = routeFor(routes.REQUESTS.path, {
      company: context.companyId,
      id: 'new',
      type: 'all',
    });
    history.push(requestRoute);
  };

  const latePayments = incompletePayments.payments.filter(
    (payment) => payment.completionDeadline?.status === 'late',
  );

  const count = incompletePayments.payments.length;

  return (
    <div className="flex flex-col content-stretch gap-24">
      {count > 0 && ( // we don't need header if there are no payments
        <div className="flex items-center gap-16">
          <HighlightIcon size={32} variant="alert" name="hexagone-cross" />
          <div>
            <h2 className="text-primary title-l">
              {incompletePayments.hasNextPage
                ? t('homepage.paymentsList.titleWithOptimisticCount', {
                    count,
                  })
                : t('homepage.paymentsList.title', {
                    count,
                  })}
            </h2>
            <p className="text-secondary-bg-primary body-m">
              {t('homepage.paymentsList.subTitle')}
            </p>
          </div>
        </div>
      )}
      {latePayments.length > 0 && (
        <OverdueSection
          incompletePaymentsLimit={controlRule.incompletePaymentsLimit}
          latePaymentsCount={latePayments.length}
          isOptimistic={!incompletePayments.hasNextPage}
        />
      )}
      {count === 0 ? (
        <PaymentsListEmptyState
          userHasMadePayments={context.userHasMadePayments}
          onButtonClick={handleNewRequestClick}
        />
      ) : (
        <PaymentsList
          payments={incompletePayments.payments.map((payment) => ({
            ...payment,
            routeLink: routeFor(routes.PAYMENTS_ALL.path, {
              company: context.companyId,
              id: payment.id,
            }),
          }))}
        />
      )}
    </div>
  );
};
