import { type Filter } from '@spendesk/bookkeeping-core-types';

import { type PayableId } from 'modules/payable';
import { useMutation } from 'src/core/api/hooks/useMutation';
import { type QueryError } from 'src/core/api/queryError';
import {
  type Translations,
  useQueryError,
} from 'src/core/common/components/QueryError';

import { type PayableReportExportId } from '../../types';

type ExportPayableReportError = {
  reason: 'invalidBodyParams';
  error: Error;
};

type ExportPayableReportResponse = {
  exportId: PayableReportExportId;
};

export type ExportPayableReportPayload = {
  filtersV2?: Filter | undefined;
  search?: string;
  ids?: PayableId[];
  excludeIds?: PayableId[];
  payableStates?: (
    | 'created'
    | 'prepared'
    | 'notBookkept'
    | 'unprepared'
    | 'discarded'
    | 'to_accounting_pending'
    | 'to_accounting_failed'
    | 'in_accounting'
    | 'in_accounting_manually'
  )[];
  payableTypes?: (
    | 'creditNote'
    | 'claimedBill'
    | 'mileageExpense'
    | 'perDiemExpense'
    | 'reverseBill'
    | 'cardPurchase'
    | 'invoicePurchase'
    | 'cardReversal'
  )[];
  settlementState?: 'settled' | 'not_settled';
  documentaryEvidenceStatus?: 'provided' | 'not_provided' | 'missing';
  creationDate?: { from?: string; to: string };
  costCenters?: string[];
};

/**
 * Mutation
 **/

export const useExportPayableReport = () => {
  return useMutation<
    ExportPayableReportPayload,
    ExportPayableReportResponse,
    ExportPayableReportResponse,
    ExportPayableReportError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/export/payable-report',
      method: 'post',
    },
    options: {
      throwOnError: true,
    },
    reshapeData(data) {
      return data;
    },
  });
};

/**
 * Error messages
 **/

const exportPayableReportErrorTranslations: Translations<ExportPayableReportError> =
  {
    requestError: ({ reason }) => {
      switch (reason) {
        default:
          return 'payableReport.api.exportPayableReport.error';
      }
    },
    serverError: 'payableReport.api.exportPayableReport.error',
  };

export const useExportPayableReportErrorMessage = () => {
  const getErrorMessage = useQueryError<ExportPayableReportError>(
    exportPayableReportErrorTranslations,
  );

  return (
    queryError: QueryError<ExportPayableReportError>,
    translationParams?: object,
  ): string => getErrorMessage(queryError, translationParams);
};
